<template>
  <system-page :page-settings="page">
    <div class="c-content u-text-center">
      <error-msg class="u-mb-lg" v-if="error !== null">
        <p>{{ error }}</p>
      </error-msg>
      <p class="message">
        登録されているメールアドレス宛に、<br>
        パスワード再設定のためのメールをお送りいたします。
      </p>
      <contact-adress-box
        class="adressBox"
        :addressName="addressName"
        :addressValue="addressValue"
      ></contact-adress-box>
      <button class="sendButton c-btn c-btn-primary" @click="sendMail()">メールを送信する</button>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import contactAdressBox from '@/component/ContactAdressBox.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
  name: 'MypagePasswordReissuePage',
  components: {
    SystemPage,
    errorMsg,
    contactAdressBox,
  },
  data: function() {
    return {
      addressName: 'メールアドレス',
      addressValue: '',
      birthdayValue: '',
      error: null,
      page: {
        title: 'パスワード変更',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ パスワード変更のページ。',
        breadcrumb: [
          { name: 'マイページ', path: '/mypage' },
          { name: 'ユーザー情報', path: '/mypage/profile' },
        ],
      }
    }
  },
  created: function() {
    this.init()
  },
  methods: {
    init() {
      const getPlayerApi = '/get_player_account'

      this.axios.post(getPlayerApi)
      .then(response => {
        let player = response.data.player
        this.addressValue = player.mail_address
        this.birthdayValue = player.birthday
      })
      .catch(err => {
        if (err.response && (err.response.data.status === 401 || err.response.data.code === 404)) {
            this.$store.commit('setLogoutState')
            this.$router.push({name: 'Login'})
        }
        this.error = err.response.data.message
      })
    },
    sendMail() {
      const sendChangePasswordEmailAPI = '/send_change_mail'
      let params = new URLSearchParams()

      params.append('mail_address', this.addressValue)
      params.append('birthday', this.dayjsFormat(this.birthdayValue, 'YYYY/MM/DD'))
      params.append('is_mypage', '1')
      this.axios.post(sendChangePasswordEmailAPI, params)
      .then(() => {
          this.$router.push({name: 'ProfilePasswordMailComplete'})
        })
      .catch(err => {
        this.error = err.response.data.message
      })
    },
    dayjsFormat(date, format) {
        dayjs.locale(ja)
        if (date !== null && typeof date === 'object' && 'date' in date) {
            return dayjs(date.date).format(format)
        }
        return format
    },
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

@include g.pc {
  .message {
    margin-bottom: 40px;
  }

  .adressBox {
    margin: 0 auto 55px;
    max-width: 720px;
  }
}

@include g.sp {
  .message {
    margin-bottom: 30px;
    text-align: left;
  }

  .adressBox {
    margin-bottom: 50px;
  }
}
</style>
