<template>
    <pcg-page :page-settings="page">
        <h1 class="c-title c-title-page">{{ page.title }}</h1>
        <div class="c-content search" v-if="0 < pagination.totalCount">
            <div class="searchResult u-display-pc">
                {{ pagination.currentPage }}ページ ／ {{ pagination.totalPage }}ページ中
            </div>
            <div class="searchList">
                <event-result-list-item
                    v-for="(eventData, index) in renderData"
                    :key="'list' + index"
                    :eventData="eventData"
                />
            </div>
            <pcg-pager
                class="u-mb-lg"
                :page="pagination"
                @pageFeed="setPage"
            ></pcg-pager>
            <div class="searchResult u-text-center">
                {{ pagination.currentPage }}ページ ／ {{ pagination.totalPage }}ページ中
            </div>
        </div>
        <div class="c-content search" v-else>
            <div class="searchList">
                <no-result>
                    対象のイベントがみつかりませんでした。
                </no-result>
            </div>
        </div>
        <div class="c-content u-text-center result-bottom-link">
            <router-link
                :to="{name: 'EventTop'}"
                class="c-btn c-btn-primary-outline"
            >
                イベントトップへ
            </router-link>
        </div>
    </pcg-page>
</template>

<script>
import pcgPage from "@/component/Page.vue"
import pcgPager from "@/component/Pager.vue"
import noResult from '@/component/NoResult.vue'
import EventResultListItem from "./component/EventResultListItem.vue"

export default {
    name: "ResultListPage",

    components: {
        pcgPage,
        pcgPager,
        noResult,
        EventResultListItem,
    },

    data: function () {
        return {
            page: {
                title: "イベント結果一覧",
                desc: 'ポケモンカードゲーム プレイヤーズクラブ イベント詳細のページ。',
                breadcrumb: [{ name: "イベント", path: "/" }],
            },
            inflection: require("inflection"),
            searchParameters: {
                offset: 0,
                order: 4,
                resultResist: 1,
                eventType: [
                    '3:1',
                    '3:2',
                    '3:7',
                ]
            },
            sendQuery: {},
            pagination: {
                currentPage: 1,
                totalPage: 1,
                totalCount: 0,
                offset: 0,
                perPage: 20,
            },
            renderData: null,
            total: null,
            hasSearchParams: false,
        }
    },

    created: function () {
        this.pagination.currentPage = isNaN(this.$route.query["offset"])
                ? 1
                : Math.ceil(this.$route.query["offset"] / this.pagination.perPage) + 1

        this.fetchData()
    },

    watch: {
        $route: "fetchData",
    },

    methods: {
        fetchData: async function () {
            const params = await this.getConditions()
            await this.searchEventsByQuery(params)
        },

        getConditions: async function () {
            Object.keys(this.searchParameters).forEach((searchName) => {
                let underscoreSearchName = this.inflection.underscore(searchName)
                this.searchParameters[searchName] = this.$route.query[underscoreSearchName] == null
                    ? this.searchParameters[searchName]
                    : this.$route.query[underscoreSearchName]
            })

            let params = Object.assign({}, this.$route.query)
            if (!params.offset) {
                params.offset = this.searchParameters.offset
            }
            if (!params.order) {
                params.order = this.searchParameters.order
            }
            if (!params.result_resist) {
                params.result_resist = this.searchParameters.resultResist
            }
            if (!params.event_type) {
                params.event_type = this.searchParameters.eventType
            }

            return params
        },

        searchEventsByQuery: async function (params) {
            const targetUrl = "/event_search"
            await this.axios.get(targetUrl, { params: params })
            .then((response) => {
                this.renderData = response.data.event
                this.pagination.totalCount = response.data.eventCount ? response.data.eventCount : 0
                this.pagination.totalPage = Math.ceil(response.data.eventCount / this.pagination.perPage)
            })
        },

        searchSubmit: async function () {
            await this.createSendQuery()
            localStorage.setItem(
                "searchParameters",
                JSON.stringify(this.searchParameters)
            )
            this.$router.replace({
                path: "/event/result/list",
                redirect: "/event/result/list",
            })
            this.$router.replace({
                path: "/event/result/list",
                redirect: "/event/result/list",
                query: this.sendQuery,
            })
        },

        createSendQuery: async function () {
            if (!this.hasSearchParams) {
                this.searchParameters.offset = (this.pagination.currentPage - 1) * this.pagination.perPage
            }
            this.pagination.sendQuery = {}

            Object.keys(this.searchParameters).forEach((searchName) => {
                if (this.searchParameters[searchName]) {
                    this.sendQuery[this.inflection.underscore(searchName)] = this.searchParameters[searchName]
                }
            })

            this.sendQuery["offset"] = this.searchParameters.offset
            this.sendQuery["result_resist"] = this.searchParameters.resultResist
        },

        setPage(newPage) {
            this.pagination.currentPage = newPage
            this.pagination.offset = (newPage - 1) * this.pagination.perPage
            this.searchSubmit()
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-title-page {
    margin-bottom: 35px;
}

.result {
    @include g.sp {
        display: none;
    }

    &-bottom {
        width: 100%;
        padding-top: g.$margin-large;
        padding-bottom: 70px;
        text-align: center;
        @include g.sp {
            padding-top: 25px;
            padding-bottom: 55px;
        }
    }
}

.noResult {
    margin-top: 34px;
    @include g.sp {
        margin-top: 32px;
    }
}

.result-bottom-link {
    margin-top: 72px;
    margin-bottom: 72px;
    @include g.sp {
        margin-top: 52px;
    }
}

::v-deep .pagination {
    padding-top: 70px;
    @include g.sp {
        padding-top: 60px;
    }
}

@include g.sp {
    .resultList {
        padding: 0 9px;
    }
}
</style>
