<template>
    <system-page :page-settings="page">
        <div class="c-content u-text-center">
            <div class="mail-text">
                <p>メールアドレスの変更を行います。新しいメールアドレスを入力してください。</p>
                <p>入力いただいたメールアドレスへ、変更のメールをお送りします。</p>
            </div>
        </div>
        <error-msg class="u-mb-lg error-msg" v-if="error" :message="error">
        </error-msg>
        <div class="c-box mail">
            <div class="u-mb-lg u-text-center mailaddress">
                <div class="u-mb-sm">現在のメールアドレス</div>
                <div class="u-text-normal">{{ mailaddress }}</div>
            </div>
            <form @submit.prevent="send(email, reEmail)">
                <div class="u-mb-exlg">
                    <div class="u-mb-sm">新しいメールアドレス</div>
                    <div class="u-mb">
                        <input
                            v-model="email"
                            id="mailAddress"
                            type="mail"
                            class="c-input c-input-text u-width-100"
                            :class="{ 'c-input-error': hasMailError }"
                        >
                    </div>
                    <div>
                        <input
                            v-model="reEmail"
                            id="reMailAddress"
                            type="mail"
                            class="c-input c-input-text u-width-100"
                            :class="{ 'c-input-error': hasReEnterMailError }"
                            placeholder="再度メールアドレスを入力してください"
                        >
                    </div>
                </div>
                <div class="u-text-center">
                    <button
                        type="submit"
                        class="c-btn c-btn-primary"
                    >メールを送信する</button>
                </div>
            </form>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import formMethod from '@/utils/form'

export default {
    name: 'MailChangePage',
    components: {
        SystemPage,
        errorMsg,
    },
    data: function() {
        return {
            page: {
                title: 'メールアドレス変更',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ メールアドレス変更のページ。',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                    { name: 'ユーザー情報', path: '/mypage/profile' },
                ],
            },
            error: null,
            hasMailError: false,
            hasReEnterMailError: false,
            mailaddress: ''
        }
    },
    created: function() {
        this.init()
    },
    methods: {
        init() {
            const getPlayerApi = '/get_player_account'

            this.axios.post(getPlayerApi)
            .then(response => {
                this.mailaddress = response.data.player.mail_address
            })
            .catch(err => {
                if (err.response && (err.response.data.status === 401 || err.response.data.code === 404)) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                this.error = err.response.data.message
            })
        },
        send(email, reEmail) {
            this.hasMailError = false
            this.hasReEnterMailError = false

            if (!email || !reEmail) {
                this.error = 'メールアドレスを入力してください。'
                this.hasMailError = true
                window.scrollTo({top: 0,})

                return
            } else if (!formMethod.isMailAddress(email)) {
                this.error = '正しいメールアドレスを入力してください。'
                this.hasMailError = true
                window.scrollTo({top: 0,})

                return
            }

            if (email !== reEmail) {
                this.error = 'メールアドレスが一致しません。'
                this.hasReEnterMailError = true
                window.scrollTo({top: 0,})
                return
            }

            let params = new URLSearchParams
            params.append('mail_address', email)

            this.axios.post('/mail_change', params)
            .then(() => {
                this.$router.push({name: 'MailChangeComplete'})
            })
            .catch(err => {
                this.error = err.response.data.message
                window.scrollTo({top: 0,})
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style.scss';

$border: #B3D8EE;

.error-msg {
    @include g.pc {
        width: 620px;
    }
    @include g.sp {
        width: 335px;
    }
}

.mail {
    &-text {
        margin-bottom: 40px;
    }

    @include g.sp {
        text-align: left;
        &-text {
            margin-bottom: 30px;
            text-align: left;
        }
    }
}

.mailaddress {
    padding: g.$margin 0;
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;

    @include g.sp {
        text-align: left;
    }
}
</style>
