<template>
  <div class="contactAdressBox">
    <h3>
      {{ addressName }}
    </h3>
    <p>
      {{ addressValue }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'contactAdressBox',
  props: {
    addressName: {
      type: String,
      required: true,
    },
    addressValue: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.contactAdressBox {
  border-radius: 5px;
  background: g.$gray-10;
  text-align: center;

  h3 {
    padding: 15px 0 16px;
    font-size: g.$font-size-small;
    line-height: g.$line-height-small;
  }

  p {
    background: g.$color-white;
  }
}

@include g.pc {
  .contactAdressBox {
    padding: 0 20px 20px;

    p {
      padding: 13px 0 15px;
    }
  }
}

@include g.sp {
  .contactAdressBox {
    padding: 0 15px 15px;

    p {
      padding: 25px 0;
    }
  }
}

</style>
