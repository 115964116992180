<template>
  <section class="c-content">
    <div class="u-text-center u-mb-lg">
      <img src="@/assets/img/common/trainers_logo.png" class="u-mb">
      <h2 class="c-title c-title-page u-mb">
        ポケモンカードゲーム<br>
      プレイヤーズクラブ<br class="spOnly">追加情報入力確認
      </h2>
      <p class="u-text">以下の内容で登録します。よろしいですか？</p>
    </div>
    <form action="post">
        <div class="form u-mb c-table-list">
          <div class="c-table-list-row">
            <div class="column-left">
              メールアドレス
            </div>
            <div class="column-right">
              {{ email }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              ニックネーム
            </div>
            <div class="column-right">
              {{ nickname }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              お名前
            </div>
            <div class="column-right">
              {{ lastName }} {{ firstName }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              フリガナ
            </div>
            <div class="column-right">
              {{ lastNameKana }} {{ firstNameKana }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              生年月日
            </div>
            <div class="column-right">
              {{  dayjsFormat(birthday, 'YYYY年MM月DD日') }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              性別
            </div>
            <div class="column-right">
              {{ dispGender }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              郵便番号
            </div>
            <div class="column-right">
              {{ postcode }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              都道府県
            </div>
            <div class="column-right">
              {{ dispPrefectures }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              住所
            </div>
            <div class="column-right">
              {{ address }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              携帯電話番号
            </div>
            <div class="column-right">
              {{ phoneNumber }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              マイページ公開/非公開
            </div>
            <div class="column-right">
              {{ dispMypage }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              プロモーションメール
            </div>
            <div class="column-right">
              {{ dispPromo }}
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              アバター
            </div>
            <div class="column-right">
              <div class="c-input-avatar-wrap" v-if="avatarInfo">
                <div class="c-input c-input-avatar">
                    <input id="avatar-1" type="radio" disabled>
                    <label for="avatar-1" class="avater-label">
                        <img :src="avatarInfo.image_url">
                    </label>
                </div>
              </div>
            </div>
          </div>
          <div class="c-table-list-row">
            <div class="column-left">
              パスワード
            </div>
            <div class="column-right">
              {{ dispPassword }}
            </div>
          </div>
        </div>
        <div class="u-text-center button-container">
          <button
            class="c-btn c-btn-primary"
            type="button"
            @click="register()"
          >
            登録する
          </button>
          <button
            class="c-btn c-btn-primary-outline"
            type="button"
            @click="back()"
          >
            修正する
          </button>
        </div>
    </form>
    <div class="u-text-center bottom-image">
      <img src="@/assets/img/common/trainers_logo.png" class="u-mt u-mb">
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
  name: 'MigrationConfirm',
  components: {},
  props: {
    authCode: String,
    playerId: String,
    email: String,
    nickname: String,
    lastName: String,
    firstName: String,
    lastNameKana: String,
    firstNameKana: String,
    gender: String,
    postcode: String,
    prefectures: String,
    address: String,
    phoneNumber: Number,
    mypage: String,
    promotionalEmail: String,
    parentalConsent: Boolean,
    termsOfService: Boolean,
    password: String,
    passwordReEnter: String,
    birthday: Object,
    avatarInfo: Object
  },
  data: function() {
    return {
      dispPrefectures: '',
      dispGender: '',
      dispMypage: '',
      dispPromo: '',
      dispPassword: '',
      error: null,
    }
  },
  created: function() {
    this.init()
  },
  methods: {
    back() {
      this.$router.push({ hash: "#input" });
    },
    register() {
      let params = new URLSearchParams
      params.append('auth_code', this.authCode)
      params.append('mail', this.email)
      params.append('nickname',this.nickname)
      params.append('sex_id',this.gender)
      params.append('prefecture_id',this.prefectures)
      params.append('birthday',this.birthday)
      params.append('phone_number',this.phoneNumber)
      params.append('avatar_id','1') //1固定
      params.append('publish_flg',this.mypage)
      params.append('postal_code',this.postcode)
      params.append('address',this.address)
      params.append('mail_notification_flg',this.promotionalEmail)
      params.append('password',this.password)

      const api = '/regist_account_migration_additional'
      this.axios.post(api, params)
      .then((response) => {
        this.$store.commit('setPhoneNumber', this.phoneNumber)
        this.$store.commit('setMail', this.email)
        this.$router.push({name: 'MigrationComplete' , params: { league: response.data.league }})
      })
      .catch(err =>{
        if (err.response.status === 404) {
            this.$router.push({ name: "MigrationError", params: { message: err.response.data.message } })
        }
        this.error = err.response.data.message
      })
    },
    init() {
      let prefList = this.$store.getters.getPrefectureList();
      for(let key in prefList) {
        let pref = prefList[key];
        if (pref.id === this.prefectures){
            this.dispPrefectures = pref.title
        }
      }
      if (this.gender == 1)
      {
        this.dispGender = '男性'
      }
      else if (this.gender == 2)
      {
        this.dispGender = '女性'
      }
      else
      {
        this.dispGender = '指定しない'
      }
      this.dispMypage = this.mypage == 1 ? '公開' : '非公開'
      this.dispPromo = this.promotionalEmail == 1 ? '受け取る' : '受け取らない'
      this.dispPassword ='*'.repeat(this.password.length)
    },
    dayjsFormat(date, format) {
      dayjs.locale(ja)
      if (date !== null && typeof date === 'object' && 'date' in date) {
          return dayjs(date.date).format(format)
      }
      return format
    },
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@include g.pc {
  .spOnly {
    display: none;
  }
}

@include g.sp {
  .pcOnly {
    display: none;
  }
}

.c-content {
    @include g.pc {
        width: 760px;
    }
}
.c-title-page {
    margin-bottom: 36px;
}
.c-table-list {
  border-top: 1px solid #B3D8EE;
  border-bottom: 1px solid #B3D8EE;
  @include g.pc {
      padding-top: 56px;
      padding-bottom: 56px;
  }
  @include g.sp {
      padding-top: 33px;
      padding-bottom: 33px;
  }

  &::before {
    border: 0;
  }
  &-row {
    &:nth-of-type(1) {
      border-top: 0;
    }
    &:not(:last-child) {
      border-bottom: 0;
    }
    .column {
        @include g.pc {
            &-left {
                position: relative;
                flex: 0 0 220px;
                padding-right: 0;
                padding-left: 30px;
                padding-top: 22px;
                padding-bottom: 22px;
                box-sizing: content-box;
            }
            &-right {
                padding-right: 30px;
                padding-left: 0;
                padding-top: 22px;
                padding-bottom: 22px;
            }
        }
        @include g.sp {
            &-left {
                font-size: 14px;
                height: 20px;
                line-height: 20px;
                padding: 0;
                margin-bottom: 10px;
            }
            &-right {
                font-size: 16px;
                min-height: 24px;
                line-height: 24px;
                padding: 0;
                margin-bottom: 32px;
            }
        }
        &-left {
            background-color: g.$color-white;
        }
    }
  }
}

.bottom-image {
    margin-bottom: 42px;
    padding-top: 80px;
    @include g.sp {
        padding-top: 48px;
        margin-bottom: 18px;
    }
}

.button-container {
  padding-top: 15px;
  .c-btn {
    margin: g.$margin auto;
    display: block;
  }
}

.no-border {
  padding-top: 0;
  border: 0;
}
</style>
