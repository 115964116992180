<template>
  <system-page :page-settings="page">
    <div class="c-content">
      <p class="u-mb-lg">
        登録されたメールへアカウント移行のメールをお送りしました。<br>
        お送りしたメールから、アカウント移行を行なってください。
      </p>
      <p class="u-mb-lg">
        しばらくたってもメールが届かない場合は、以下のご確認をお願いいたします。<br>
        ・入力内容に間違いはないか<br>
        ・登録されているメールアドレスは正しいか<br>
        ・ご利用のキャリアの設定によって、メールが「迷惑メール」に分類されていないか<br>
        ・「info@mail.players.pokemon-card.com」からのメールが受信できる設定になっているか<br>
      </p>
      <p class="u-mb-lg">
        上記の問題が解決しない場合、「<a href="https://www.pokemon-card.com/contact/" class="link c-link" target="_blank">ポケモンカードゲームサポートデスク</a>」にお問い合わせ下さい。
      </p>
    </div>
    <div class="button-container">
      <router-link
        to="/migration"
        class="c-btn c-btn-primary-outline"
      >メールを再送する</router-link>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
  name: 'MailRegisterCompletePage',
  components: {
    SystemPage,
  },
  data: function() {
    return {
      page: {
        title: 'メール送信完了',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ メール送信完了のページ。',
        breadcrumb: [
          { name: 'アカウント移行', path: '/migration' },
        ],
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.button-container {
  text-align: center;
  line-height: 1;
}
@include g.pc {
  .c-content {
    margin-bottom: 55px;
    text-align: center;
  }
}

@include g.sp {
  .c-content {
    margin-bottom: 50px;
  }
}
</style>
