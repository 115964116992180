<template>
    <div
        class="cover"
        v-if="display"
    >
        <div class="spinner"></div>
    </div>
</template>

<script>
export default {
    name: 'LoadingCover',
    props: {
        display: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
.cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(g.$color-black, 0.5);
    z-index: 9999;
}
.spinner {
    animation: rotator 1s linear infinite;
    width: 65px;
    height: 65px;
    border: 4px solid g.$color-gradietion-end;
    border-right-color: g.$color-gradietion-start;
    border-radius: 100%;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    margin: auto;
}
@keyframes rotator {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
