import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/utils/router'
import store from '@/utils/store'
import Axios from 'axios'
import VueAxios from 'vue-axios'

require('@/assets/style/main.scss')

Axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
Axios.defaults.headers.common['X-Accept-Version'] = 'v1'
Axios.defaults.timeout = '60000'


createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, Axios)
    .mount('#app')
