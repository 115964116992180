<template>
    <div class="noResult">
        <slot></slot>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
.noResult {
    padding: 16px;
    text-align: center;
    background: g.$color-black-a005;
}
</style>
