<template>
  <system-page :page-settings="page">
      <div class="c-content u-text-center">
      <error-msg class="u-mb-lg error-msg" v-if="error" :message="error">
      </error-msg>
      <p class="message">
        アカウント連携をすると、連携したSNSからログインできます
      </p>
      <p class="message">
        ※現在、ソーシャルアカウントでのログインはメンテナンス中です。
      </p>
      <!-- <table class="snsTable">
        <tr>
          <th>
            <img src="@/assets/img/sns/facebook.png">Facebook
          </th>
          <td v-if="authorizedSnsTypeIds.includes('3')">
            <span class="done">連携済み</span>
            <button
              type="button"
              class="c-btn c-btn-primary-outline"
              @click="openDessolveAlignmentModal('facebook')"
            >
              解除
            </button>
          </td>
          <td v-else>
            <button
              type="button"
              class="c-btn c-btn-primary"
              @click="authSNS('facebook')"
            >
              連携
            </button>
          </td>
        </tr>
        <tr>
          <th>
            <img src="@/assets/img/sns/twitter.png">Twitter
          </th>
          <td v-if="authorizedSnsTypeIds.includes('2')">
            <span class="done">連携済み</span>
            <button
              type="button"
              class="c-btn c-btn-primary-outline"
              @click="openDessolveAlignmentModal('twitter')"
            >
              解除
            </button>
          </td>
          <td v-else>
            <button
              type="button"
              class="c-btn c-btn-primary"
              @click="authSNS('twitter')"
            >
              連携
            </button>
          </td>
        </tr>
        <tr>
          <th>
            <img src="@/assets/img/sns/google.png">Google
          </th>
          <td v-if="authorizedSnsTypeIds.includes('1')">
            <span class="done">連携済み</span>
            <button
              type="button"
              class="c-btn c-btn-primary-outline"
              @click="openDessolveAlignmentModal('google')"
            >
              解除
            </button>
          </td>
          <td v-else>
            <button
              type="button"
              class="c-btn c-btn-primary"
              @click="authSNS('google')"
            >
              連携
            </button>
          </td>
        </tr>
      </table> -->
      <div class="u-text-center to-mypage">
          <router-link
              :to="{name: 'Mypage'}"
              class="c-btn c-btn-primary-outline"
          >
              マイページへ
          </router-link>
      </div>
    </div>

    <pcg-modal
      :isActive="activeModal === 'dessolveAlignmentModal'"
      @close="closeModal()"
    >
      <div class="modalBodyWrapper">
        <h2 class="c-title c-title-page">連携を解除します</h2>
        <div class="buttonWrapper">
          <button
            type="button"
            class="c-btn c-btn-primary-outline"
            @click="closeModal()"
          >
            キャンセル
          </button>
          <button
            type="button"
            class="c-btn c-btn-primary"
            @click="dessolveAlignment()"
          >
            解除する
          </button>
        </div>
      </div>
    </pcg-modal>

    <pcg-modal
      :isActive="activeModal === 'dessolveAlignmentCompleteModal'"
      @close="closeModal()"
    >
      <div class="modalBodyWrapper">
        <h2 class="c-title c-title-page">連携を解除しました</h2>
          <div class="buttonWrapper">
          <button
            type="button"
            class="c-btn c-btn-primary-outline"
            @click="closeModal()"
          >
            閉じる
          </button>
        </div>
      </div>
    </pcg-modal>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import pcgModal from '@/component/Modal.vue'

export default {
  name: 'AccountAlignmentPage',
  components: {
    SystemPage,
    pcgModal,
    errorMsg,
  },
  data: function() {
    return {
      activeModal: null,
      targetSns: null,
      player: null,
      authorizedSnsTypeIds: [],
      error: null,
      errorMessage: {
        100: '認証中にエラーが発生しました｡',
        200: '既に登録されているアカウントです。',
        300: 'パラメータエラーです。',
        400: 'プレイヤーが存在しません。',
      },
    }
  },
  created: function() {
    // アカウント認証を実行してリダイレクトされてきた場合はcodeとmessageに結果を持っている
    if (this.$route.query.code && this.$route.query.code != 200) {
      this.error = this.errorMessage[this.$route.query.message];
    }
    this.init()
  },
  methods: {
    init() {
      const getPlayerApi = '/get_player_account'

      this.axios.post(getPlayerApi)
      .then(response => {
        this.player = response.data.player
        if (this.player.sns_type_ids !== null) {
          this.authorizedSnsTypeIds = this.player.sns_type_ids.split(',')
        } else {
          this.authorizedSnsTypeIds = []
        }
      })
      .catch(err => {
        if (err.response && err.response.data.status === 401) {
            this.$store.commit('setLogoutState')
            this.$router.push({name: 'Login'})
        }
        this.error = err.response.data.message
      })
    },
    authSNS(sns) {
      const authSNSApi = `/auth_${sns}`
      this.axios.get(authSNSApi)
      .then(response => {
        window.location.href = response.data.url
      })
      .catch(err => {
        this.error = this.errorMessage[err.response.data.error_code];
      })
    },
    openDessolveAlignmentModal(sns) {
      this.targetSns = sns
      this.activeModal = 'dessolveAlignmentModal'
    },
    dessolveAlignment() {
      let url = ''
      if (this.targetSns == 'google') {
        url = 'unlink_google_account'
      } else if (this.targetSns == 'twitter') {
        url = 'unlink_twitter_account'
      } else if (this.targetSns == 'facebook') {
        url = 'unlink_facebook_account'
      } else {
        return;
      }

      this.axios.post(url)
      .then(() => {
        this.init()
        this.activeModal = 'dessolveAlignmentCompleteModal'
      })
      .catch(err => {
        this.error = err.response.data.message
      })
    },
    closeModal() {
      this.activeModal = null
      this.targetSns = null
    }
  },
  computed: {
      page() {
          let page =  {
              title: 'アカウント連携',
              breadcrumb: [
                  { name: 'マイページ', path: '/mypage' },
              ],
          }

          let player = this.$store.getters.getUser();
          if (!player) {
              return page
          }
          let defaultTitle = page.title;
          page.titleForHeader = player.nickname + "さんの" + defaultTitle
          page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんの" + defaultTitle + "。";
          return page
      },
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.snsTable {
  width: 450px;
  margin: 0 auto 56px;

  tr {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid g.$color-blue-a040;

    &:last-child {
      border-bottom: 1px solid g.$color-blue-a040;
    }
  }

  th {
    display: flex;
    align-items: center;
    padding: 24px 2px;

    img {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }

  td {
    display: flex;
    align-items: center;
    padding: 24px 2px;

    .c-btn {
      width: 108px;
      height: 36px;
      font-size: g.$font-size-small;
      line-height: 36px;
    }
  }

  .done {
    margin-right: 12px;
    font-size: 12px;
    line-height: 20px;

    &::before {
      content: "\e90a";
      display: inline-block;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      background: #36b5ba;
      color: white;
      font-family: "pcg_icon";
      font-size: 12px;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
      transform: scale(0.5);
    }
  }
}
.to-mypage {
  line-height: 1;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

@include g.pc {
  .message {
    margin-bottom: 38px;
  }

  .flexWrapper {
    display: flex;
    gap: 40px;
  }

  .modalBodyWrapper {
    padding: 70px 0;

    h2 {
      margin-bottom: 50px;
      font-size: 32px;
    }

    .buttonWrapper {
      gap: 40px;
    }
  }
}

@include g.sp {
  .message {
    margin-bottom: 50px;
    text-align: left;
  }

  .snsTable {
    width: 100%;
    margin: 0 auto 50px;
  }

  .modalBodyWrapper {
    padding: 40px 0;

    h2 {
      margin-bottom: 35px;
      font-size: 24px;
    }
  }

  .buttonWrapper {
    flex-flow: column-reverse;
    gap: g.$margin;

    button {
      width: 275px;
    }
  }
}
</style>
