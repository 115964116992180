<template>
  <system-page :page-settings="page">
    <error-msg class="u-mb-lg error-msg" v-if="error" :message="error">
    </error-msg>
    <div class="c-box login">
      <div class="form">
        <form>
          <div class="u-mb">
            <label for="formMailAddress" class="u-mb-sm u-display-block">メールアドレスまたはプレイヤーID</label>
            <input
              v-model="playerId"
              id="formMailAddress"
              type="text"
              class="c-input c-input-text u-width-100"
              :class="{'c-input-error': hasPlayerIdError}"
            >
          </div>
          <div class="u-mb">
            <label for="formMailAddress" class="u-mb-sm u-display-block">パスワード</label>
            <password-input
                v-model:password="password"
                :hasError="hasPasswordError"
                id="formPassword"
            ></password-input>
            <p class="u-text-center">
              <router-link :to="{ name: 'PasswordReissue' }">パスワードを忘れた方</router-link>
            </p>
          </div>
          <div class="u-text-center">
            <button
              @click.prevent="login"
              type="submit"
              :disabled="processing"
              class="c-btn c-btn-primary"
            >
              ログイン
            </button>
          </div>
        </form>
      </div>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import passwordInput from '@/component/PasswordInput.vue'

export default {
  name: 'QrLoginForClJudge',
  components: {
    SystemPage,
    errorMsg,
    passwordInput,
  },
  props: {},
  data: function() {
    return {
      page: {
        title: 'ジャッジ向け大会チェックイン',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ ジャッジ向け大会チェックインのページ。',
      },
      error: null,
      playerId: '',
      password: '',
      hasPlayerIdError: false,
      hasPasswordError: false,
      processing: false,
    }
  },
  created: function() {
    if (this.$route.query.code && this.$route.query.code != 200) {
      this.error = this.$route.query.message;
    }
    this.init()
  },
  methods: {
    init() {
      this.apiParamsUrl = null;
    },
    login() {
        if (!this.playerId) {
          this.error = 'メールアドレスを入力してください。'
          this.hasPlayerIdError = true
          return
        }
        if (!this.password) {
          this.error = 'パスワードを入力してください。'
          this.hasPasswordError = true
          return
        }
      this.processing = true;

      let params = new URLSearchParams;
      params.append('player_id', this.playerId)
      params.append('password', this.password)
      if (this.$route.query.event_holding_id) {
        params.append('event_holding_id', this.$route.query.event_holding_id)
      }
      if (this.$route.query.event_holding_hash) {
        params.append('event_holding_hash', this.$route.query.event_holding_hash)
      }

      const api = '/qr_login_for_cl_judge'

      this.axios.post(api, params)
      .then(response => {
        if (0 === Number(response.data.status)) {
          this.error = response.data.message;
          this.processing = false;

          return
        }

        this.$store.commit('setLoginState', {
            userToken: response.data.token,
            userTokenDeadline: response.data.token_deadline,
            userName: response.data.user_name,
            mail: response.data.email,
            phoneNumber: response.data.phone_number
        })

        this.$router.push({name: 'MyJudgeEventDetail', params: {id: response.data.event_holding_id}})

        return
      })
      .catch(err => {
        this.processing = false;
        if (err.response.data.code == '400') {
          this.$router.push({name: 'QrLoginForClJudgeError', params: {message: err.response.data.message}})
          return
        }
        this.error = err.response.data.message
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.login {
  padding: 50px;
  width: 640px;
  margin: 0 auto;
  font-size: 14px;

  .form {
    padding: 0 50px g.$margin-large;

    .birthday {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .password-outer {
    margin-bottom: 24px;

    .password {
      margin-bottom: 17px;
    }
  }
}

.note {
  color: g.$color-black-a060;
}

.center-line {
  position: relative;
  text-align: center;
  margin: 0 50px g.$margin-large;

  span {
    background: g.$color-white;
    padding: 0 20px;
  }
}

.link {
  font-size: g.$font-size;
}

@include g.sp {
  .login {
    width: 100%;
    padding: 0 20px;

    .form {
      padding: 0;
    }
  }

  .center-line {
    margin: 20px 0 20px;

    span {
      padding: 0;
    }
  }
}

.error-msg {
  @include g.pc {
    width: 480px;
  }
  @include g.sp {
    width: 335px;
  }
}
</style>
