<template>
  <system-page :page-settings="page">
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'

export default {
  name: 'SnsQrLogin',
  components: {
    SystemPage,
  },
  props: {},
  data: function() {
    return {
      page: {
        title: 'QRコードからログイン',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ QRコードからログインのページ。',
      },
    }
  },
  created: function() {
    if (this.$route.query.code && this.$route.query.code != 200) {
      this.$router.push({name: 'QrPlayerError', params: {message: this.$route.query.message}})
    } else {
      this.$store.commit('setLoginState', {
          userToken: this.$route.query['token'],
          userTokenDeadline: this.$route.query['token_deadline'],
          userName: this.$route.query['user_name']
      })
      this.$router.push({name: 'QrPlayerSuccess', params: {message: this.$route.query.message}})
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.login {
  padding: 50px;
  width: 640px;
  margin: 0 auto;
  font-size: 14px;

  .form {
    padding: 0 50px g.$margin-large;

    .birthday {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .password {
    margin-bottom: g.$margin;
  }
}

.note {
  color: g.$color-black-a060;
}

.center-line {
  position: relative;
  text-align: center;
  margin: 0 50px g.$margin-large;

  span {
    background: g.$color-white;
    padding: 0 20px;
  }
}


.social-buttons {
    font-size: 0;
    padding-bottom: 28px;
    border-bottom: 1px solid #B3D8EE;
    width: 420px;
    margin: 0 auto 36px;

    .c-btn {
        color: g.$color-white;
        border-radius: 10px;
        margin: 0 auto 12px;
        font-size: g.$font-size-small;
        position: relative;
        width: 214px;
        height: 40px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .26);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        padding-left: 30px;

        &:hover {
            text-decoration: none;
        }

        @mixin buttonIcon {
            content: '';
            font-family: 'pcg_icon';
            position: absolute;
            left: 10px;
            font-size: 20px;
        }

        &.facebook {
            background: g.$color-facebook;

            &::before {
                @include buttonIcon;
                content: '\e922';
            }
        }

        &.twitter {
            background: g.$color-twitter;

            &::before {
                @include buttonIcon;
                content: '\e930';
            }
        }

        &.google {
            display: inline-flex;
            font-family: 'Roboto', sans-serif;
            color: #757575;
            padding-left: 0;

            .google-icon {
                padding: 0 10px;
                font-size: 0;
                line-height: 0;

                img {
                    height: 20px;
                    width: 20px;
                }
            }

            p {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.link {
  font-size: g.$font-size;
}

@include g.sp {
  .login {
    width: 100%;
    padding: 0 20px;

    .form {
      padding: 0;
    }
  }

  .center-line {
    margin: 0;
    margin-bottom: 20px;

    span {
      padding: 0;
    }
  }

  .social-buttons {
    width: 100%;
    padding-top: 20px;
  }
}
</style>
