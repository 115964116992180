<template>
    <router-link
        :to="{ name: 'EventDetail', params: { id: eventData.event_holding_id,
            trainersFlg: eventData.trainers_flg,
            shopId: eventData.shop_id ? eventData.shop_id : 0,
            eventDate: eventData.event_date_params ? eventData.event_date_params : 0,
            dateId: eventData.date_id ? eventData.date_id : 0} }"
        class="eventListItem"
    >
        <div class="left">
            <div class="eventDate">
                <div class="date" :class="{ 'sat': eventData.event_date_week === '土', 'sun': eventData.event_date_week === '日' || eventData.holiday_flg === 1 }">
                    <span class="day">{{ eventData.event_date }}</span>
                    <span class="week">{{ '（' + eventData.event_date_week + '）' }}</span>
                </div>
                <span class="time">{{ eventData.event_started_at + ' ～ ' + eventData.event_ended_at }}</span>
                <span class="pref">{{ eventData.prefecture_name }}</span>
                <span class="distance" v-if="isSearchByDistance">{{ distanceFormat(eventData.distance) }}</span>
            </div>
            <div class="status"
                v-if="eventData.discontinuance_flg === 0 && !isOrganizer && eventData.entryTypeId !== null && eventData.recruitFlg === 1"
                :class="{
                    'status-1': eventData.entryStatusCode === 1,
                    'status-2': eventData.entryStatusCode === 2,
                    'status-3': eventData.entryStatusCode === 3,
                    'status-5': eventData.entryStatusCode === 5,
                    'status-6': eventData.entryStatusCode === 6
                }"
            >{{ eventData.entryStatus }}</div>
            <div class="status status-4"
                v-else-if="eventData.discontinuance_flg !== 0 && !isOrganizer"
            >イベント中止</div>
        </div>
        <div class="right">
            <div class="title">{{ eventData.event_title }}</div>
            <div
                class="league"
                v-if="
                    eventData.event_type !== 4 &&
                    eventData.event_type !== 6 &&
                    eventData.leagueName !== 'その他'
                ">◆ {{ eventData.leagueName }}</div>
            <div class="status"
                v-if="eventData.discontinuance_flg === 0"
                :class="{
                    'status-1': eventData.entryStatusCode === 1,
                    'status-2': eventData.entryStatusCode === 2,
                    'status-3': eventData.entryStatusCode === 3,
                    'status-5': eventData.entryStatusCode === 5,
                    'status-6': eventData.entryStatusCode === 6
                }"
            >{{ eventData.entryStatus }}</div>
            <div class="status status-4"
                v-else
            >イベント中止</div>
            <div class="detail">
                <span class="regulation">{{ eventData.regulation }}</span>
                <span v-if="eventData.deck_count">{{ '（' + displayDeckCount(eventData.deck_count) + '）' }}</span>
                <span v-if="eventData.regulation"> / </span>
                <span class="entryFee" v-if="eventData.entry_fee">参加費 {{ displayEntryFee(eventData.entry_fee) }}</span>
                <span class="entryFee" v-else>参加費 {{ '無料' }}</span>
                <span class="capacity"> / 定員 {{ eventData.capacity }}人</span>
            </div>
            <div
                class="address-box"
            >
                <template v-if="!isStore">
                    <div
                        v-if="eventData.shop_id"
                        :class="eventData.shop_term === 2 ? 'organizer' : 'shop'"
                    >
                        <router-link
                            :to="`/event/search/${eventData.shop_id}/list`"
                            class="c-link"
                        >
                            {{ eventData.shop_name }}
                        </router-link>
                    </div>
                    <div
                        v-else-if="eventData.event_type === 1"
                        class="shop"
                    >
                        {{ '株式会社ポケモン' }}
                    </div>
                    <div
                        v-else
                        :class="eventData.shop_term === 2 ? 'organizer' : 'shop'"
                    >
                        {{ eventData.shop_name }}
                    </div>
                </template>
                <div
                    class="address"
                    v-if="eventData.event_attr_id !== 4"
                    :class="{
                        storeList: isStore
                    }"
                >
                    <span class="building">
                        {{ displayAddress(eventData) }}
                    </span>
                </div>
                <div class="address" v-if="eventData.venue">
                    <span :class="{building: eventData.event_attr_id === 4}">{{ eventData.venue }}</span>
                </div>
            </div>
            <div class="classification">
                <event-kinds type="rule" v-if="eventData.event_attr_id === 1" />
                <event-kinds type="beginner" v-if="eventData.event_attr_id === 2" />
                <event-kinds type="serious-game" v-if="eventData.event_attr_id === 3"/>
                <event-kinds type="remote" v-if="eventData.event_attr_id === 4" />
                <span
                    v-if="eventData.csp_flg === 1"
                    class="c-eventPoint c-eventPoint-championship"
                >{{ 'チャンピオンシップポイント対象' }}</span>
            </div>
        </div>
    </router-link>
</template>

<script>
import eventKinds from '@/component/EventKinds.vue'
export default {
    props: {
        eventData: {
            require: true,
        },
        isStore: {
            type: Boolean,
            default: false,
        },
        isOrganizer: {
            type: Boolean,
            default: false,
        },
        isSearchByDistance: {
            type: Boolean,
            default: false
        },
    },
    components: {
        eventKinds,
    },
    methods: {
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? '〒' + zip : '〒' + zip.slice(0, 3) + '-' + zip.slice(3)
        },
        distanceFormat(distance) {
            if (distance < 1) {
                return '約' +  Math.round(distance * 1000) + 'm'
            } else {
                return '約' + Math.round(distance * 10) / 10 + 'km'
            }
        },
        displayDeckCount(deckCount) {
            if (!isNaN(deckCount)) {
                return deckCount + '枚'
            } else {
                return deckCount
            }
        },
        displayEntryFee(entryFee) {
            if (!isNaN(entryFee)) {
                return entryFee + '円'
            } else {
                return entryFee
            }
        },
        displayAddress(eventData) {
            let address = ''
            if (this.$store.state.isSp) {
                address += eventData.address !== null && eventData.address !== '' ? eventData.address + ' ' : ''
            } else {
                address += eventData.event_type === 2 || (eventData.zip_code === null || eventData.zip_code === 0 || eventData.zip_code === '') ? '' : this.zipCodeFormat(eventData.zip_code) + ' '
                address += eventData.address !== null && eventData.address !== '' ? eventData.address + ' ' : ''
            }
            return address
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '../style/listItem';
.afterBuilding {
    padding-left: 1em;
}
</style>
