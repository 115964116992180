<template>
    <section class="c-content">
        <form>
            <error-msg
                v-if="errors !== null"
                class="u-mb-lg error-msg"
                :messages="errors"
                :justifySp="'left'"
            >
            </error-msg>
            <div class="form u-mb c-table-list">
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formNickname">
                            ニックネーム
                        </label>
                    </div>
                    <div class="column-right form-input-nickname">
                        <input
                            class="c-input c-input-text u-width-100"
                            placeholder="20文字まで"
                            id="formNickname"
                            type="text"
                            v-model="player.nickname"
                            :class="{'c-input-error': formNicknameValid}"
                        >
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        お名前
                    </div>
                    <div class="column-right no-input">
                        {{ player.family_name }} {{ player.first_name }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        フリガナ
                    </div>
                    <div class="column-right no-input">
                        {{ player.family_name_kana }} {{ player.first_name_kana }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        生年月日
                    </div>
                    <div class="column-right form-input-flex no-input">
                        {{ dayjsFormat(player.birthday, 'YYYY年MM月DD日') }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        性別
                    </div>
                    <div class="column-right form-input-gender">
                        <div class="u-display-flex">
                            <div class="c-radio u-mr-lg">
                                <input
                                    type="radio"
                                    id="formMale"
                                    name="gender"
                                    v-model="player.sex"
                                    value="1"
                                >
                                <label for="formMale">
                                    男性
                                </label>
                            </div>
                            <div class="c-radio u-mr-lg">
                                <input
                                    type="radio"
                                    id="formFemale"
                                    name="gender"
                                    v-model="player.sex"
                                    value="2"
                                >
                                <label for="formFemale">
                                    女性
                                </label>
                            </div>
                            <div class="c-radio">
                                <input
                                    type="radio"
                                    id="formUnspecified"
                                    name="gender"
                                    v-model="player.sex"
                                    value="3"
                                >
                                <label for="formUnspecified">
                                    指定しない
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formPostcode">
                            郵便番号
                        </label>
                    </div>
                    <div class="column-right form-input-postcode u-flex">
                        <div class="input-container">
                            <input
                                type="text"
                                id="formPostcode"
                                class="c-input c-input-text u-mr-lg"
                                v-model="player.postal_code"
                                :class="{'c-input-error': formPostcodeValid || formPostcodeSearchValid}"
                            >
                            <p class="u-text-kome u-text-exsmall">ハイフンなしで入力してください</p>
                        </div>
                        <div class="postcode-btn-container">
                            <button
                                type="button"
                                class="postcode-btn"
                                @click="serachPostCode()"
                            >
                                郵便番号から住所を入力
                            </button>
                        </div>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formPrefectures">
                            都道府県
                        </label>
                    </div>
                    <div class="column-right form-input-prefecture">
                        <div
                            class="c-input c-input-select"
                            :class="{'c-input-error': formPrefecturesValid}"
                        >
                            <select
                                id="formPrefectures"
                                v-model="player.prefecture"
                            >
                                <option value="">選択してください</option>
                                <option
                                    v-for="pref in prefectureList"
                                    :key="pref.id"
                                    :value="pref.title"
                                >
                                    {{ pref.title }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formAddress">
                            住所
                        </label>
                    </div>
                    <div class="column-right">
                        <input
                            type="text"
                            id="formAddress"
                            class="c-input c-input-text u-width-100"
                            v-model="player.address"
                            :class="{'c-input-error': formAddressValid}"
                        >
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formPhoneNumber">
                            携帯電話番号
                        </label>
                    </div>
                    <div class="column-right form-input-tel">
                        <input
                            type="tel"
                            id="formPhoneNumber"
                            class="c-input c-input-text u-width-100"
                            v-model="player.phone_number"
                            :class="{'c-input-error': formPhoneNumberValid}"
                        >
                        <p class="u-text-kome u-text-exsmall">ハイフンなしで入力してください</p>
                        <p class="u-text-kome u-text-exsmall">SMSを受け取れる電話番号を登録してください</p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        マイページ<br class="u-display-pc">公開/非公開
                    </div>
                    <div class="column-right">
                        <div class="u-display-flex u-mb-sm">
                            <div class="c-radio u-mr">
                                <input
                                    type="radio"
                                    id="formMypageDisplay"
                                    name="myPage"
                                    v-model.number="player.publish_flg"
                                    :value="1"
                                >
                                <label for="formMypageDisplay">
                                    公開
                                </label>
                            </div>
                            <div class="c-radio u-mr">
                                <input
                                    type="radio"
                                    id="formMypageHide"
                                    name="myPage"
                                    v-model.number="player.publish_flg"
                                    :value="0"
                                >
                                <label for="formMypageHide">
                                    非公開
                                </label>
                            </div>
                        </div>
                        <p class="u-text-kome u-text-exsmall">ニックネーム、都道府県、イベント参加情報が公開されます</p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        プロモーション<br class="u-display-pc">メール
                    </div>
                    <div class="column-right">
                        <div class="u-display-flex u-mb-sm">
                            <div class="c-radio u-mr">
                                <input
                                    type="radio"
                                    id="formPromotionalReceive"
                                    name="promotionalEmail"
                                    v-model.number="player.mail_notification_flg"
                                    :value="1"
                                >
                                <label for="formPromotionalReceive">
                                    受け取る
                                </label>
                            </div>
                            <div class="c-radio u-mr">
                                <input
                                    type="radio"
                                    id="formPromotionalUnreceive"
                                    name="promotionalEmail"
                                    v-model.number="player.mail_notification_flg"
                                    :value="0"
                                >
                                <label for="formPromotionalUnreceive">
                                    受け取らない
                                </label>
                            </div>
                        </div>
                        <p class="u-text-kome u-text-exsmall">当社からのお知らせ（広告メール）をお送りします</p>
                    </div>
                </div>
            </div>
            <div class="submit-button-container u-text-center">
                <button
                    type="button"
                    class="c-btn c-btn-primary confirm-btn"
                    @click="submit()"
                >
                    確認する
                </button>
                <router-link
                    to="/mypage/profile"
                    class="c-btn c-btn-primary-outline confirm-btn"
                >
                    戻る
                </router-link>
            </div>
        </form>
    </section>
</template>

<script>
import errorMsg from '@/component/ErrorMsg.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import axiosJsonpAdapter from 'axios-jsonp'

export default {
    name: 'ProfileEditInputPage',

    components: {
        errorMsg,
    },

    props: {
        modelValue: {
            type: Object,
            required: true,
        },
    },

    data: function() {
        return {
            errors: null,
            formNicknameValid: false,
            formPostcodeValid: false,
            formPostcodeSearchValid: false,
            formPrefecturesValid: false,
            formAddressValid: false,
            formPhoneNumberVali: false,
        }
    },

    computed: {
        player: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            },
        },
        prefectureList() {
            return this.$store.getters.getPrefectureList()
        },
    },

    methods: {
        dayjsFormat(date, format) {
            dayjs.locale(ja)
            if (date !== null && typeof date === 'object' && 'date' in date) {
                return dayjs(date.date).format(format)
            }
            return format
        },
        serachPostCode() {
            this.formPostcodeSearchValid = false
            let api = process.env.VUE_APP_ZIP_CODE_URL
            api = api + this.player.postal_code
            this.axios.get(api, {adapter: axiosJsonpAdapter})
            .then(response => {
                if (response.data.status === 400) {
                    this.formPostcodeSearchValid = true
                    this.errors = new Array('郵便番号から住所を特定できませんでした。')
                } else if (response.data.results === null) {
                    this.formPostcodeSearchValid = true
                    this.errors = new Array('郵便番号から住所を特定できませんでした。')
                } else {
                    let prefecture;
                    for(let key in this.prefectureList) {
                        let pref = this.prefectureList[key];
                        if (pref.title == response.data.results[0].address1) {
                            prefecture = pref
                        }
                    }
                    this.player.prefecture = prefecture.title;
                    this.player.address = response.data.results[0].address2 + response.data.results[0].address3;
                }
            })
            .catch(() =>{
                this.formPostcodeSearchValid = true
                this.errors = new Array('郵便番号から住所を特定できませんでした。')
            })
        },

        submit() {
            const params = new URLSearchParams
            const paramKeys = [
                'nickname',
                'sex',
                'phone_number',
                'publish_flg',
                'postal_code',
                'address',
                'mail_notification_flg',
            ]
            for (const key of paramKeys) {
                params.append(key, this.player[key])
            }

            let prefecture_id = null
            for (const [, value] of Object.entries(this.prefectureList)) {
                if (value.title === this.player.prefecture) {
                    prefecture_id = value.id
                    break
                }
            }
            params.append('prefecture_id', prefecture_id)

            this.axios.post('/edit_player_valid', params)
            .then(() => {
                this.$router.push({hash: '#confirm'})
            })
            .catch(err => {
                const message = err.response.data.message
                this.errors = message.split('\\n')

                this.formNicknameValid    = /ニックネーム/.test(message)
                this.formPostcodeValid    = /郵便番号/.test(message)
                this.formPrefecturesValid = /都道府県/.test(message)
                this.formAddressValid     = /住所/.test(message)
                this.formPhoneNumberValid = /携帯電話番号/.test(message)

                window.scrollTo(0, 0)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.error-msg {
    @include g.pc {
        width: 760px;
    }
    @include g.sp {
        width: 335px;
        text-align: left;
    }
}
.c-content {
    @include g.pc {
        width: 760px;
    }
}
.c-title-page {
    margin-bottom: 36px;
}
.page-description {
    @include g.pc {
        margin-bottom: 56px;
    }
    @include g.sp {
        margin-bottom: 28px;
    }
}
.form {
    width: 100%;
    border-top: 1px solid #B3D8EE;
    border-bottom: 1px solid #B3D8EE;
    padding-top: 36px;
    padding-bottom: 40px;
    &-input {
        &-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-nickname {
            @include g.sp {
                & > input[type="text"] {
                    width: 100%;
                }
            }
        }
        &-gender {
            .u-display-flex {
                @include g.pc {
                    .c-radio {
                        margin-right: 40px;
                    }
                }
                @include g.sp {
                    justify-content: space-between;
                }
            }
        }
        &-postcode {
            @include g.sp {
                display: block;
            }
            .input-container {
                @include g.pc {
                    & > input[type="text"] {
                        width: 230px;
                        margin-bottom: 8px;
                    }
                }
                @include g.sp {
                    & > input[type="text"] {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 8px;
                    }
                }
            }

            .postcode-btn-container {
                    @include g.sp {
                        width: 100%;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    & > button {
                        display: inline-block;
                        font-size: g.$font-size-small;
                        color: g.$blue-40;
                        cursor: pointer;

                        @include g.pc {
                            &:hover {
                                text-decoration: underline;
                                color: g.$color-link-hover;
                            }
                        }
                }
            }
        }
        &-prefecture {
            .c-input-select {
                select {
                    width: 230px;
                }
            }
        }
        &-tel {
            & > input[type="tel"] {
                margin-bottom: 8px;
            }
        }
    }
}
.c-table-list {
    &::before {
        border: 0;
    }
    &-row {
        &:nth-of-type(1) {
            border-top: 0;
        }
        &:not(:last-child) {
            border-bottom: 0;
        }
        .column {
            @include g.pc {
                &-left {
                    position: relative;
                    flex: 0 0 220px;
                    padding-right: 0;
                    padding-left: 30px;
                    padding-top: 22px;
                    padding-bottom: 22px;
                    box-sizing: content-box;
                    &.wide {
                        align-items: normal;
                    }
                    label {
                        position: absolute;
                        top: 22px;
                        height: 48px;
                        line-height: 48px;
                    }
                }
                &-right {
                    padding-right: 30px;
                    padding-left: 0;
                    padding-top: 22px;
                    padding-bottom: 22px;
                }
            }
            @include g.sp {
                &-left {
                    font-size: 14px;
                    height: 20px;
                    line-height: 20px;
                    padding: 0;
                    margin-bottom: 10px;
                }
                &-right {
                    font-size: 16px;
                    min-height: 24px;
                    line-height: 24px;
                    padding: 0;
                    margin-bottom: 20px;
                    &.no-input {
                        margin-bottom: 32px;
                    }
                }
            }
            &-left {
                background-color: g.$color-white;
            }
        }
    }
}

.submit-button-container {
    @include g.pc {
        padding: 56px;
        padding-bottom: 0;
        .confirm-btn {
            margin: 0 auto 15px;
            display: block;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    @include g.sp {
        padding: 56px 10px;
        padding-bottom: 0;
        .confirm-btn {
            width: 100%;
            margin: 0 auto 15px;
            display: block;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
