<template>
    <div class="p-searchForm">
        <form @submit.prevent="formSubmit">
            <div class="p-searchForm-wrapper">
                <div class="p-searchForm-content">
                    <div class="p-searchForm-tab">
                        <button
                            type="button"
                            class="p-searchForm-tab-btn"
                            :class="selectTabName === 'detail' ? setTabClass('detail') : 'tab' "
                            @click="changeTab('detail')"
                            :disabled="selectTabName === 'detail'"
                        >詳細条件で探す</button>
                        <button
                            type="button"
                            class="p-searchForm-tab-btn"
                            :class="selectTabName === 'place' ? setTabClass('place') : 'tab'"
                            @click="changeTab('place')"
                            :disabled="selectTabName === 'place'"
                        ><span class="u-display-sp">現在地・</span>場所で探す</button>
                    </div>
                    <div
                        class="p-searchForm-form p-searchForm-bg"
                        :class="{ place: displayTabContent('place') }"
                    >
                        <div class="p-searchForm-result u-display-pc">
                            <span>{{ innerTotal }}</span>件
                        </div>
                        <template v-if="displayTabContent('detail')">
                            <div class="p-searchForm-keyword">
                                <input
                                    type="text"
                                    v-model="syncedSearchParameters.keyword"
                                    class="c-input c-input-text search"
                                    placeholder="キーワードから絞り込む"
                                    @change="formSubmit"
                                />
                            </div>
                            <div class="p-searchForm-date">
                                <span class="u-display-sp">開催日</span>
                                <div class="c-input c-input-date">
                                    <span class="input input-date-area">
                                        <datepicker
                                            placeholder="指定しない"
                                            v-model="innerTotalStartDate"
                                            inputFormat="yyyy/MM/dd"
                                            :locale="jaLocal"
                                        />
                                    </span>
                                    <span>～</span>
                                    <span class="input input-date-area">
                                        <datepicker
                                            placeholder="指定しない"
                                            v-model="innerTotalEndDate"
                                            inputFormat="yyyy/MM/dd"
                                            :locale="jaLocal"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div class="p-searchForm-place">
                                <span class="u-display-sp">開催地</span>
                                <div class="c-input c-input-select">
                                    <button
                                        @click="togglePrefectureModal"
                                        class="select-prefecture"
                                        id="selectPrefectureText"
                                        type="button"
                                    >
                                        都道府県から絞り込む
                                    </button>
                                </div>
                                <div
                                    v-show="openPrefecture"
                                    class="select-prefecture-wrapper"
                                >
                                    <div class="select-prefecture-header u-display-sp">
                                        <button
                                            @click="togglePrefectureModal"
                                            type="button"
                                            class="c-btn c-btn-primary-outline icon-clear"
                                        >×</button>
                                        <div class="select-prefecture-selected">
                                            {{ searchParameters.prefecture.length }}件選択中
                                        </div>
                                        <button
                                            @click="searchPrefectureModal"
                                            type="button"
                                            class="c-btn c-btn-primary"
                                        >決定</button>
                                    </div>
                                    <div
                                        v-for="(areaKey, index) in Object.keys(areaList)"
                                        :key="`area${index}`"
                                        class="select-prefecture-content"
                                    >
                                        <div class="left">
                                            <div class="c-checkbox">
                                                <input
                                                    :id="`prefectureArea${index}`"
                                                    type="checkbox"
                                                    name="prefectureAreaCheck"
                                                    :value="areaKey"
                                                    v-model="prefectureAreaCheck"
                                                    @change="checkAllRegion"
                                                >
                                                <label :for="`prefectureArea${index}`">
                                                    {{ areaList[areaKey].name }}
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="prefectureList" class="right">
                                            <template
                                                v-for="(prefectureId, prefectureIndex) in areaList[areaKey].pref"
                                                :key="`prefecture${prefectureIndex}`"
                                            >
                                                <template
                                                    v-for="prefecture in prefectureList"
                                                    :key="prefecture.id"
                                                >
                                                    <div
                                                        v-if="prefectureId === prefecture.id"
                                                        class="c-checkbox"
                                                    >
                                                        <input
                                                            :id="`prefecture${prefecture.id}`"
                                                            :class="`check-prefecture-${areaKey}`"
                                                            name="prefecture"
                                                            type="checkbox"
                                                            :value="`${prefecture.id}`"
                                                            v-model="syncedSearchParameters.prefecture"
                                                            @change="changePrefecture"
                                                        >
                                                        <label :for="`prefecture${prefecture.id}`">
                                                            {{ trimPrefecture(prefecture.title) }}
                                                        </label>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-searchForm-tag-list u-display-sp">
                                <div
                                    v-for="label in syncedSearchParameterLabels"
                                    :key="`${label.name}: ${label.value}`"
                                    class="tag"
                                >
                                    <button
                                        class="tag-button"
                                        @click.stop="deleteSearchParameterLabel(label)"
                                    >×</button>
                                    {{ label.displayName }}
                                </div>
                            </div>
                            <div class="p-searchForm-add">
                                <button
                                    type="button"
                                    class="c-btn c-btn-primary p-searchForm-btn"
                                    @click="openModal"
                                >
                                    <span>条件を<br />追加する</span>
                                </button>
                            </div>
                        </template>
                        <template v-if="displayTabContent('place')">
                            <div class="p-searchForm-selectPlace u-display-sp">
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="selectPlace1"
                                        v-model="syncedSelectSpCurrentPlace"
                                        :value="true"
                                        @change="formSubmit"
                                        :checked="isSelectPlace1"
                                        name="place"
                                    />
                                    <label for="selectPlace1">現在地から探す</label>
                                </div>
                                <div class="c-radio p-searchForm-selectPlace-place">
                                    <input
                                        type="radio"
                                        id="selectPlace2"
                                        v-model="syncedSelectSpCurrentPlace"
                                        :value="false"
                                        :checked="isSelectPlace2"
                                        name="place"
                                    />
                                    <label for="selectPlace2">場所から探す<span class="arrow"></span></label>
                                </div>
                            </div>
                            <div
                                v-if="!isSp || (isSp && !syncedSelectSpCurrentPlace)"
                                class="p-searchForm-address"
                            >
                                <input
                                    type="text"
                                    v-model="syncedSearchParameters.address"
                                    class="c-input c-input-text search"
                                    placeholder="駅名や住所を入力"
                                    @change="formSubmit"
                                />
                            </div>
                            <div class="p-searchForm-distance">
                                <span class="p-searchForm-distance-label">距離：</span>
                                <div class="c-input c-input-select">
                                    <select
                                        id="select2"
                                        v-model="syncedSearchParameters.distance"
                                        @change="formSubmit"
                                    >
                                        <option value="1">1km以内</option>
                                        <option value="3">3km以内</option>
                                        <option value="5">5km以内</option>
                                        <option value="10">10km以内</option>
                                        <option value="50">50km以内</option>
                                    </select>
                                </div>
                            </div>
                            <div class="p-searchForm-tag-list u-display-sp">
                                <div
                                    v-for="label in syncedSearchParameterLabels"
                                    :key="`${label.name}: ${label.value}`"
                                    class="tag"
                                >
                                    <button
                                        class="tag-button"
                                        @click.stop="deleteSearchParameterLabel(label)"
                                    >×</button>
                                    {{ label.displayName }}
                                </div>
                            </div>
                            <div class="p-searchForm-add">
                                <button
                                    type="button"
                                    class="c-btn c-btn-primary p-searchForm-btn"
                                    @click="openModal"
                                >
                                    <span>条件を<br />追加する</span>
                                </button>
                            </div>
                        </template>
                        <div class="u-display-sp p-searchForm-result">
                            <span>{{ innerTotal }}</span>件
                        </div>
                        <div class="u-display-sp p-searchForm-form-buttons">
                            <button
                                type="button"
                                class="c-btn c-btn-primary-outline clear p-searchForm-btn"
                                @click="clearInputForm"
                            >条件<br />クリア</button>
                            <button
                                type="submit"
                                class="c-btn c-btn-primary p-searchForm-btn"
                            >検索</button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="p-searchForm-bottom">
                <div class="p-searchForm-tag-list u-display-pc">
                    <div
                        v-for="label in syncedSearchParameterLabels"
                        :key="`${label.name}: ${label.value}`"
                        class="tag"
                    >
                        <button
                            class="tag-button"
                            @click.stop="deleteSearchParameterLabel(label)"
                        >×</button>
                        {{ label.displayName }}
                    </div>
                </div>
                <div class="p-searchForm-bottom-option">
                    <div>
                        <div class="order-posion">
                            <div
                                class="c-checkbox order-posion-checkbox"
                                :class="{ 'center-sp': displayTabContent('place') }"
                            >
                                <input
                                    type="checkbox"
                                    id="checkbox4"
                                    name="checkbox4"
                                    v-model="syncedSearchParameters.accepting"
                                    @change="formSubmit"
                                >
                                <label for="checkbox4">本サイトで応募受付中/受付予定のみ</label>
                            </div>
                            <div
                                v-if="displayTabContent('place')"
                                class="c-input c-input-select order-posion-select"
                            >
                                <select
                                    id="select3"
                                    v-model="syncedSearchParameters.order"
                                    @change="formSubmit"
                                >
                                    <option value="1">開催日順</option>
                                    <option value="3">距離順</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pcg-modal
                class="p-searchFormFilter"
                :isActive="activeModal === 'searchModal'"
                :noPadding="true"
                :footerShadow="true"
                :bgImage="true"
                @close="activeModal = null"
            >
                <template #modalHeader>
                    <h2 class="c-title c-title-page">イベント検索 詳細条件</h2>
                </template>
                <form-detail
                    v-model:eventType="syncedSearchParameters.eventType"
                    v-model:leagueType="syncedSearchParameters.leagueType"
                    v-model:eventDayOfWeek="syncedSearchParameters.eventDayOfWeek"
                    v-model:eventOpenTime="syncedSearchParameters.eventOpenTime"
                    v-model:reservation="syncedSearchParameters.reservation"
                    @searchCount="formSubmitCount"
                />
                <template #modalFooter>
                    <div class="p-searchFormFilter-footer">
                        <div class="p-searchFormFilter-result">
                            <span>{{ innerModalTotal }}</span>件
                        </div>
                        <div class="p-searchFormFilter-buttons">
                            <button
                                type="button"
                                class="c-btn c-btn-primary-outline u-mr"
                                @click="clearInputForm"
                            >
                                条件<br class="u-display-sp">クリア
                            </button>
                            <button
                                type="submit"
                                class="c-btn c-btn-primary"
                            >
                                検索
                            </button>
                        </div>
                    </div>
                </template>
            </pcg-modal>
        </form>
    </div>
</template>

<script>
import displayMethodMixin from "./methods/Display.js";
import prefectureMethodMixin from "./methods/Prefecture.js";
import FormDetail from "./parts/FormDetail.vue";
import pcgModal from "@/component/Modal.vue";
import Datepicker from "vue3-datepicker";
import { ja } from 'date-fns/locale';

export default {
    el: "#app",
    name: "eventListSearchForm",
    props: {
        total: Number,
        modalTotal: Number,
        searchParameters: Object,
        searchParameterLabels: Array,
        selectSpCurrentPlace: Boolean,
        selectSearchTab: String,
    },
    emits: [
        'setSearchParameterLabels',
        'searchSubmit',
        'searchSubmitCount',
        'searchEvents',
        'update:searchParameterLabels',
        'update:selectSpCurrentPlace',
        'update:selectSearchTab',
    ],
    components: {
        pcgModal,
        FormDetail,
        Datepicker,
    },
    mixins: [
        displayMethodMixin,
        prefectureMethodMixin,
    ],
    mounted() {
        if((this.syncedSearchParameters.address !== null && this.syncedSearchParameters.address !== '') ||
           (this.syncedSearchParameters.distance !== null && this.syncedSearchParameters.distance !== ''))
        {
            const tabName = 'place'
            this.selectTab(tabName);
            this.syncedSelectSearchTab = tabName;
            if(this.syncedSearchParameters.address !== null && this.syncedSearchParameters.address !== '') {
                this.isSelectPlace2= true
            }
            else {
                this.isSelectPlace1 = true
            }
        }
        this.mountedCheckAllRegion()
        this.setPrefectureList()
        this.setSelectorClass()

        window.addEventListener('click', this.checkClickElement);
    },
    data() {
        return {
            activeModal: "",
            isSelectPlace1: false,
            isSelectPlace2: false,
        }
    },
    methods: {
        setPrefectureList: async function () {
            if (localStorage.getItem('prefectureList') != null && this.$route.query['prefecture'] == null && this.$route.query['link'] == null) {
                await new Promise((resolve) => setTimeout(resolve, 500))
                this.syncedSearchParameters.prefecture = localStorage.getItem('prefectureList').split(',')
                this.setSelectorClass()
            }
        },
        deleteSearchParameterLabel: async function (label) {
            this.syncedSearchParameterLabels = []
            if (label.name === "eventType" || label.name === "leagueType" || label.name === "prefecture") {
                this.syncedSearchParameters[label.name] = this.syncedSearchParameters[label.name].filter(value => value !== label.value)
            }
            else if (label.name === "startDate" || label.name === "endDate") {
                this.syncedSearchParameters[label.name] = null
            }
            else {
                this.syncedSearchParameters[label.name] = ""
            }
            await this.$emit("setSearchParameterLabels")
            await this.$emit("searchSubmit", this.syncedSearchParameters)
            this.changePrefecture()
            this.setSelectorClass()
        },
        formSubmit: function () {
            this.activeModal="";
            this.setSelectorClass()
            this.searchSubmit()
        },
        searchSubmit: async function () {
            this.setSelectorClass()
            this.syncedSearchParameters.offset = 0;
            await this.$emit("searchSubmit", this.syncedSearchParameters);
        },
        formSubmitCount: function () {
            this.searchSubmitCount();
        },
        searchSubmitCount: async function () {
            await this.$emit("searchSubmitCount", this.syncedSearchParameters);
        },
        clearInputForm: function () {
            this.clearParameters();
            this.$emit("clearPosition");
            this.$emit("searchSubmit");
            this.$emit("setSearchLabels");
        },
        clearInputFormTab: function () {
            this.clearParameters();
            this.$emit("clearPosition");
            this.$emit("setSearchLabels");
        },
        clearParameters() {
            this.syncedSearchParameters.shopId = "";
            this.syncedSearchParameters.keyword = "";
            this.syncedSearchParameters.startDate = null;
            this.syncedSearchParameters.endDate = null;
            this.syncedSearchParameters.prefecture = [];
            this.syncedSearchParameters.address = "";
            this.syncedSearchParameters.distance = "";
            this.syncedSearchParameters.currentLat = "";
            this.syncedSearchParameters.currentLng = "";
            this.syncedSearchParameters.eventType = [];
            this.syncedSearchParameters.leagueType = [];
            this.syncedSearchParameters.eventDayOfWeek = "";
            this.syncedSearchParameters.eventOpenTime = "";
            this.syncedSearchParameters.reservation = "";
            this.prefectureAreaCheck = []
        },
        changeTab: function (tabName) {
            this.selectTab(tabName);
            this.syncedSelectSearchTab = tabName;
            this.clearInputFormTab();
            this.isSelectPlace1 = false
            this.isSelectPlace2 = false
        },
        openModal() {
            this.activeModal = 'searchModal'
            this.openPrefecture = false
        },
        checkAllRegion($event) {
            const that = this
            const selectArea = $event.target.value
            const prefList = this.areaList[selectArea].pref
            const isChecked = this.prefectureAreaCheck.indexOf(selectArea) > -1

            if (isChecked) {
                // あとから配列ごと書き換えるためにDeepCopyを作成
                const replaceList = that.syncedSearchParameters.prefecture.map((val) => {return val})
                prefList.forEach(function(i) {
                    const value = `${i}`
                    const checkArray = that.syncedSearchParameters.prefecture.indexOf(value)
                    if (checkArray === -1) {
                        replaceList.push(value)
                    }
                })
                // 1件ずつpushしても変更を検知してくれないため変更後の配列を丸ごと代入する
                that.syncedSearchParameters.prefecture = replaceList
            } else {
                let filterList = that.syncedSearchParameters.prefecture
                prefList.forEach(function(i) {
                    const value = `${i}`
                    filterList = filterList.filter(n => n !== value)
                })
                that.syncedSearchParameters.prefecture = filterList
            }
            if (!this.$store.state.isSp) {
                this.formSubmit()
            } else {
                this.setSelectorClass()
            }
        },
        mountedCheckAllRegion() {
            for (const [key, ] of Object.entries(this.areaList)) {
                const list = this.areaList[key]
                let checkNum = 0
                this.syncedSearchParameters.prefecture.forEach((selectArea) => {
                    if (list.pref.indexOf(Number(selectArea)) > -1) {
                        checkNum++
                    }
                })
                if (list.pref.length === checkNum) {
                    if (this.prefectureAreaCheck.indexOf(key) === -1) {
                        this.prefectureAreaCheck.push(key)
                    }
                } else {
                    if (this.prefectureAreaCheck.indexOf(key) > -1) {
                        this.prefectureAreaCheck = this.prefectureAreaCheck.filter(n => n !== key)
                    }
                }
            }
        },
        changePrefecture() {
            for (const [key, ] of Object.entries(this.areaList)) {
                const CheckboxList = document.querySelectorAll(`input.check-prefecture-${key}`)
                let checkNum = 0
                CheckboxList.forEach(i => {
                    if (i.checked) {
                        checkNum++
                    }
                })
                if (CheckboxList.length !== 0) {
                    if (CheckboxList.length === checkNum) {
                        if (this.prefectureAreaCheck.indexOf(key) === -1) {
                            this.prefectureAreaCheck.push(key)
                        }
                    } else {
                        if (this.prefectureAreaCheck.indexOf(key) > -1) {
                            this.prefectureAreaCheck = this.prefectureAreaCheck.filter(n => n !== key)
                        }
                    }
                }
            }
            if (!this.$store.state.isSp) {
                this.formSubmit()
            } else {
                this.setSelectorClass()
            }
        },
        togglePrefectureModal() {
            if (this.$store.state.isSp) {
                if (this.openPrefecture) {
                    // ×で閉じたときは開く前の選択状態に戻す
                    this.syncedSearchParameters.prefecture = this.selectedSpPrefecture
                    this.prefectureAreaCheck = this.prefectureSpAreaCheck
                } else {
                    this.selectedSpPrefecture = this.syncedSearchParameters.prefecture.map((val) => {return val})
                    this.prefectureSpAreaCheck = this.prefectureAreaCheck.map((val) => {return val})
                }
            }
            this.openPrefecture = !this.openPrefecture
        },
        searchPrefectureModal() {
            this.openPrefecture = !this.openPrefecture
            this.formSubmit()
        },
        checkClickElement(event) {
            if (!this.$el.querySelector('.select-prefecture-wrapper')) {
                return
            }

            if(
                'selectPrefectureText' !== event.target.id &&
                !this.$el.querySelector('.select-prefecture-wrapper').contains(event.target) &&
                this.openPrefecture
            ) {
                this.openPrefecture = false;
            }
        },
        trimPrefecture(name) {
            return name.replace(/(都|府|県)$/g, '')
        }
    },
    computed: {
        isSp() {
            return this.$store.state.isSp;
        },
        prefectureList: function () {
            return this.$store.getters.getPrefectureList();
        },
        innerTotal: {
            get() {
                return this.$props.total;
            },
            set() {},
        },
        innerModalTotal: {
            get() {
                return this.$props.modalTotal;
            },
            set() {},
        },
        innerTotalStartDate: {
            get() {
                return this.syncedSearchParameters.startDate;
            },
            set(val) {
                this.syncedSearchParameters.startDate = val;
                this.formSubmit();
            },
        },
        innerTotalEndDate: {
            get() {
                return this.syncedSearchParameters.endDate;
            },
            set(val) {
                this.syncedSearchParameters.endDate = val;
                this.formSubmit();
            },
        },
        innerTotalStartDateModal: {
            get() {
                return this.syncedSearchParameters.startDate;
            },
            set(val) {
                this.syncedSearchParameters.startDate = val;
                this.formSubmitCount();
            },
        },
        innerTotalEndDateModal: {
            get() {
                return this.syncedSearchParameters.endDate;
            },
            set(val) {
                this.syncedSearchParameters.endDate = val;
                this.formSubmitCount();
            },
        },
        syncedSearchParameters: {
            get() {
                return this.$props.searchParameters;
            },
            set(val) {
                this.$emit("update:searchParameters", val);
            },
        },
        syncedSearchParameterLabels: {
            get() {
                return this.$props.searchParameterLabels;
            },
            set(val) {
                this.$emit("update:searchParameterLabels", val);
            },
        },
        syncedSelectSpCurrentPlace: {
            get() {
                return this.$props.selectSpCurrentPlace;
            },
            set(val) {
                this.$emit("update:selectSpCurrentPlace", val);
            },
        },
        syncedSelectSearchTab: {
            get() {
                return this.$props.selectSearchTab;
            },
            set(val) {
                this.$emit("update:selectSearchTab", val);
            },
        },
        jaLocal() {
            return ja;
        },
    },
    watch: {
        '$props.selectSpCurrentPlace': function() {
            // 現在地検索を有効にした場合は住所の入力を消去しておく
            if (this.$props.selectSpCurrentPlace) {
                this.syncedSearchParameters.address = ''
            }
            else
            {
                this.syncedSearchParameters.currentLat = "";
                this.syncedSearchParameters.currentLng = "";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './styles/modal';
.searchForm {
    &-filtering {
        width: g.$content-width-pc;
        margin: auto;
        padding-top: 14px;
        text-align: right;
    }
}
.order-posion {
    display: flex;
    position: relative;
    .order-posion-select {
        width: 180px;
        @include g.sp {
            margin-left: 20px;
        }
    }
    .order-posion-checkbox {
        margin: auto;
        @include g.sp {
            font-size: 14px;
            &.center-sp {
                label::before, label::after {
                    margin-top: 12px;
                }
            }
        }
    }
}
.order-search-btn {
    width: 100%;
}
</style>
