<template>
    <ul class="topics-list">
        <li
            v-for="(event, index) in events"
            :key="`judgeEventListId${index}`"
        >
            <router-link
                :to="{
                    name: 'MyJudgeEventDetail',
                    params: {
                        id: event.event_holding_id
                    }
                }"
            >
                <div class="data">
                    <div class="date">
                        <span
                            :class="{
                                sat: event.event_date_week === '土',
                                sun: event.event_date_week === '日' || event.holiday_flg === 1,
                            }"
                        >{{ dayjsFormat(event.event_date.date, 'YYYY/MM/DD') }}（{{ event.event_date_week }}）</span>
                        <span class="time">{{ dayjsFormat(event.event_started_at.date, 'HH:mm') }} ～ {{ event.event_ended_at ? dayjsFormat(event.event_ended_at.date, 'HH:mm') : '' }}</span>
                    </div>
                    <h4>{{ event.event_name }}</h4>
                    <div class="league">{{ event.league_title }}リーグ</div>
                    <div class="shopName" :class="shopIconClass(event)">
                        <span v-if="event.event_type === 1">株式会社ポケモン</span>
                        <span v-else>{{ event.shop_name }}</span>
                    </div>
                </div>
            </router-link>
        </li>
    </ul>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    props: {
        events: {
            type: Array,
            require: true
        }
    },
    methods: {
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
        shopIconClass(event) {
            if (event.event_type === 1) {
                return 'shop'
            } else {
                return event.shop_term === 2 ? 'organizer' : 'shop'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
$new-graient: linear-gradient(#c22b8a 0%, #c22b8a 26.11%, #e51ddd 100%);

.topics-list {
    padding-top: 28px;

    & > li {
        & > a {
            display: flex;
            gap: 25px;
            margin-bottom: 10px;
            border: 1px solid g.$color-black-a025;
            border-radius: 12px;
            background: g.$color-white;
            @include g.pc {
                padding: 24px 32px;

                &:hover {
                    text-decoration: none;
                    border: 1px solid g.$color-black-a060;
                    box-shadow: 2px 2px 8px rgba(g.$color-black, .12);
                }
            }
            @include g.sp {
                display: block;
                overflow: hidden;

                & > * {
                    padding: 20px;
                }
            }
        }
    }

    .data {
        flex-grow: 1;
        position: relative;
        padding-right: 15px;
        color: g.$color-black;

        @include g.pc {
            &::after {
                @include g.setIconFont('\e909');
                position: absolute;
                display: inline-block;
                top: 50%;
                right: 0;
                margin-top: -15px;
                color: g.$color-black;
            }
        }

        h4 {
            margin-top: 8px;
            font-size: g.$font-size-large;
            line-height: 28px;
            font-weight: bolder;

            @include g.sp {
                padding-right: 15px;
                &::after {
                    @include g.setIconFont('\e909');
                    position: absolute;
                    display: inline-block;
                    top: 50%;
                    right: 15px;
                    margin-top: -15px;
                    color: g.$color-black;
                }
            }
        }

        .period {
            font-size: 15px;
            line-height: 24px;

            @include g.sp {
                margin-bottom: g.$margin;
            }
        }

        .new::before {
            content: 'NEW';
            display: block;
            width: 48px;
            height: 24px;
            padding: 0 5px;
            margin-bottom: 4px;
            border-radius: 4px;
            background: $new-graient;
            color: g.$color-white;
            font-size: g.$font-size-small;
            font-weight: bold;
            line-height: 24px;
            text-align: center;
        }

        .date {
            line-height: 1;
            font-weight: bold;
            color: g.$color-black;

            .sat {
                color: g.$color-font-blue;
            }
            .sun {
                color: g.$color-font-red;
            }
            .time {
                margin-left: 1em;
            }
        }

        .league {
            @include g.font-sm;
            margin-top: 8px;
            color: g.$color-league;
            font-weight: bold;
            line-height: 1;
            &::before {
                content: '◆';
                font-size: 12px;
                font-weight: normal;
                margin-right: 4px;
            }
        }

        .shopName {
            @include g.font-sm;
            margin-top: 8px;
            color: g.$gray-100;
            &.organizer {
                &::before {
                    @include g.setIconFont('\e92d');
                    display: inline-block;
                    margin-right: 11px;
                    margin-left: -1.5px;
                }
            }
            &.shop {
                &::before {
                    @include g.setIconFont('\e907');
                    display: inline-block;
                    margin-right: 11px;
                    margin-left: -1.5px;
                }
            }
        }
    }

    .status {
        display: flex;
        text-align: center;

        @include g.pc {
            flex-flow: column;
            justify-content: center;
            align-items: flex-end;
            gap: 0 10px;
            flex: 0 0 135px;
            padding: 10px 0;
            border-left: 1px solid g.$color-border-gray;
        }

        @include g.sp {
            flex-flow: row;
            padding: 15px;
            justify-content: space-between;
            align-items: center;
            background: g.$color-black-a005;
        }

        .tag {
            width: 98px;
            height: 32px;
            padding: 5px 0 7px;
            border: 1px solid g.$color-black;
            border-radius: 4px;
            background: g.$color-white;
            color: g.$color-black;
            font-size: g.$font-size-small;
            line-height: g.$line-height-small;
            font-weight: bolder;

            &.not-applied {
                border-color: g.$color-red;
                color: g.$color-red;
            }

            & + p {
                margin-top: 10px;
                font-size: g.$font-size-small;
                line-height: g.$line-height-small;
                a {
                    font-weight: 500;
                }
            }
        }
    }
}
</style>
