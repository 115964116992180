<template>
  <system-page :page-settings="page">
    <div class="u-text-center u-mb-exlg message">
      <p class="u-mb">
        パスワード再設定のためのメールを送信しました。<br>
        メールに記載されているURLを24時間以内にクリックして 、 再設定を完了してください 。
      </p>
      <p class="u-mb">
        しばらくたってもメールがこない場合は、<br>
        入力したメールアドレス、生年月日に間違いはないかをご確認ください。
      </p>
      <p class="u-mb-lg">
        問題が解決しない場合、「<a href="https://www.pokemon-card.com/contact/" target="_blank">ポケモンカードゲーム サポートデスク</a>」にお問い合わせ下さい。
      </p>
    </div>
    <div class="u-text-center to-login">
      <router-link
        to="/login"
        class="c-btn c-btn-primary-outline"
      >ログインへ</router-link>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'

export default {
  name: 'PasswordMailComplate',
  components: {
    SystemPage,
  },
  data: function() {
    return {
      page: {
        title: 'メール送信完了',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ メール送信完了のページ。',
        breadcrumb: [
          { name: 'パスワードを忘れた方', path: '/login/password/reissue' }
        ],
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

@include g.sp {
  .message {
    padding: 0 20px;
    text-align: left;
  }
}
.to-login {
  line-height: 1;
}
</style>
