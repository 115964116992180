<template>
    <system-page :page-settings="page">
        <div class="c-content u-text-center complete">
            <div class="c-warningBox">
                <p class="u-text-warning-icon">
                    {{ message }}
                </p>
            </div>
            <div class="complete-text">
                <p class="u-mb-lg">再度「アカウント移行」を行ってください。</p>
            </div>
            <div class="to-register">
                <router-link
                    :to="{name: 'MigrationSetting'}"
                    class="c-btn c-btn-primary-outline"
                >アカウント移行</router-link>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
    components: {
        SystemPage,
    },
    data: function() {
        return {
            page: {
                title: 'アカウント移行エラー',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ アカウント移行エラーのページ。',
                breadcrumb: [],
            },
            message: ''
        }
    },
    created: function() {
        this.init()
    },
    methods: {
        init()
        {
            if (this.$route.params.message) {
                this.message = this.$route.params.message
            } else {
                this.$router.push({name: 'NotFound'})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.to-register {
    line-height: 1;
}

.complete {
    &-text {
        margin-top: 30px;
        text-align: center;
    }

    @include g.pc {
        .c-btn {
            margin-top: 55px;
        }
    }

    @include g.sp {
        .c-btn {
            margin-top: 50px;
        }
    }
}
</style>
