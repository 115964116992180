<template>
    <section class="c-content">
        <div id="pageTop" class="text-angle page-description">
            <p>
                ポケモンカードゲーム プレイヤーズクラブへ登録すると<br class="u-display-pc">
                ポケモンカードゲームのイベント応募や参加したイベントの成績を管理できるよ。
            </p>
        </div>
        <form>
            <error-msg
                v-if="error"
                :message="error"
                :justifySp="'left'"
                class="u-mb-lg error-msg"
            >
            </error-msg>
            <error-msg
                v-if="isInputError"
                :justifySp="'left'"
                message="入力内容に不備があります。エラー内容を確認のうえ、入力をお願いします。"
                class="u-mb-lg error-msg"
            >
            </error-msg>
            <div class="form u-mb c-table-list">
                <div class="c-table-list-row">
                    <div class="column-left">
                        メールアドレス
                    </div>
                    <div class="column-right">
                        {{ syncedMail }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formNickname">
                            ニックネーム
                        </label>
                    </div>
                    <div class="column-right form-input-nickname">
                        <input
                            class="c-input c-input-text u-width-100"
                            placeholder="20文字まで"
                            id="formNickname"
                            type="text"
                            v-model="syncedNickname"
                            maxlength="20"
                            :class="{'c-input-error': formNicknameValid || formNicknameNGWordValid}"
                        >
                        <p
                            v-if="formNicknameValid"
                            class="error u-text-warning-icon u-mt-sm">
                            ニックネームを20文字以内で入力してください。
                        </p>
                        <p
                            v-if="formNicknameNGWordValid"
                            class="error u-text-warning-icon u-mt-sm">
                            使用できないニックネームです。別の名前を入力してください。
                        </p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label>
                            お名前
                        </label>
                    </div>
                    <div class="column-right">
                        <div class="form-input-flex form-input-name">
                            <input
                                class="c-input c-input-text"
                                placeholder="姓"
                                id="formLastName"
                                type="text"
                                v-model="syncedLastName"
                                maxlength="20"
                                :class="{'c-input-error': formLastNameValid}"
                            >
                            <input
                                class="c-input c-input-text"
                                placeholder="名"
                                id="formFirstName"
                                type="text"
                                v-model="syncedFirstName"
                                maxlength="20"
                                :class="{'c-input-error': formFirstNameValid}"
                            >
                        </div>
                        <div>
                            <p v-if="formLastNameValid" class="error u-text-warning-icon u-mb-sm u-mt-sm">姓を入力してください。</p>
                            <p v-if="formFirstNameValid" class="error u-text-warning-icon u-mb-sm u-mt-sm">名を入力してください。</p>
                        </div>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label>
                            フリガナ
                        </label>
                    </div>
                    <div class="column-right">
                        <div class="form-input-flex form-input-name">
                            <input
                                class="c-input c-input-text"
                                placeholder="セイ"
                                id="formLastNameKana"
                                type="text"
                                v-model="syncedLastNameKana"
                                maxlength="40"
                                :class="{'c-input-error': formLastNameKanaValid}"
                            >
                            <input
                                class="c-input c-input-text"
                                placeholder="メイ"
                                id="formFirstNameKana"
                                type="text"
                                v-model="syncedFirstNameKana"
                                maxlength="40"
                                :class="{'c-input-error': formFirstNameKanaValid}"
                            >
                        </div>
                        <div>
                            <p v-if="formLastNameKanaValid" class="error u-text-warning-icon u-mb-sm u-mt-sm">セイをカタカナで入力してください。</p>
                            <p v-if="formFirstNameKanaValid" class="error u-text-warning-icon u-mb-sm u-mt-sm">メイをカタカナで入力してください。</p>
                        </div>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        生年月日
                    </div>
                    <div class="column-right">
                        {{ dayjsFormat(syncedBirthday, 'YYYY年MM月DD日') }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        性別
                    </div>
                    <div class="column-right form-input-gender">
                        <div class="u-display-flex">
                            <div class="c-radio">
                                <input
                                    id="formMale"
                                    name="gender"
                                    type="radio"
                                    value="1"
                                    v-model="syncedGender"
                                >
                                <label for="formMale">
                                    男性
                                </label>
                            </div>
                            <div class="c-radio">
                                <input
                                    id="formFemale"
                                    name="gender"
                                    type="radio"
                                    value="2"
                                    v-model="syncedGender"
                                >
                                <label for="formFemale">
                                    女性
                                </label>
                            </div>
                            <div class="c-radio">
                                <input
                                    id="formUnspecified"
                                    name="gender"
                                    type="radio"
                                    value="3"
                                    v-model="syncedGender"
                                >
                                <label for="formUnspecified">
                                    指定しない
                                </label>
                            </div>
                        </div>
                        <p v-if="genderValid" class="error u-text-warning-icon u-mt-sm">
                            性別を選択してください。
                        </p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formPostcode">
                            郵便番号
                        </label>
                    </div>
                    <div class="column-right form-input-postcode">
                        <div class="u-flex">
                            <div class="input-container">
                                <input
                                    class="c-input c-input-text u-mr-lg"
                                    placeholder="例）1066108"
                                    id="formPostcode"
                                    type="text"
                                    v-model="syncedPostcode"
                                    maxlength="7"
                                    :class="{'c-input-error': formPostcodeValid || formPostcodeSearchValid}"
                                >
                                <p class="u-text-kome u-text-exsmall">ハイフンなしで入力してください</p>
                                <p class="error u-text-warning-icon u-mt-sm" v-if="formPostcodeIncludeHyphenValid">
                                    ハイフンなしで入力してください。
                                </p>
                                <p class="error u-text-warning-icon u-mt-sm" v-if="formPostcodeValid">
                                    郵便番号を7桁の数字で入力してください。
                                </p>
                                <p class="error icon-exclamation u-mt-sm" v-if="formPostcodeSearchValid">
                                    郵便番号から住所を特定できませんでした。
                                </p>
                            </div>
                            <div class="postcode-btn-container">
                                <button type="button" class="postcode-btn" @click="serachPostCode()">郵便番号から住所を入力</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formPrefectures">
                            都道府県
                        </label>
                    </div>
                    <div class="column-right form-input-prefecture">
                        <div
                            class="c-input c-input-select"
                            :class="{'c-input-error': formPrefecturesValid}"
                        >
                            <select id="formPrefectures" v-model="syncedPrefectures">
                                <option selected value="">─</option>
                                <option
                                    v-for="(prefecture, index) in prefectureList"
                                    :key="index"
                                    :value="prefecture.id + ':' + prefecture.title"
                                >{{ prefecture.title }}</option>
                            </select>
                        </div>
                        <p v-if="formPrefecturesValid" class="error u-text-warning-icon u-mt-sm">
                            都道府県を選択してください。
                        </p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formAddress">
                            住所
                        </label>
                    </div>
                    <div class="column-right">
                        <input
                            class="c-input c-input-text u-width-100"
                            placeholder="例）渋谷区六本木6-10-1"
                            id="formAddress"
                            type="text"
                            v-model="syncedAddress"
                            maxlength="200"
                            :class="{'c-input-error': formAddressValid}"
                        >
                        <p v-if="formAddressValid" class="error u-text-warning-icon u-mt-sm">
                            住所を入力してください。
                        </p>
                        <p v-if="formAddressLengthValid" class="error u-text-warning-icon u-mt-sm">
                            住所を200文字以内で入力してください。
                        </p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        <label for="formPhoneNumber">
                            携帯電話番号
                        </label>
                    </div>
                    <div class="column-right form-input-tel">
                        <input
                            class="c-input c-input-text u-width-100"
                            placeholder="例）09012345678"
                            id="formPhoneNumber"
                            type="tel"
                            v-model="syncedPhoneNumber"
                            maxlength="20"
                            :class="{'c-input-error': formPhoneNumberValid || formPhoneNumberDuplicateValid}"
                        >
                        <p class="u-text-kome u-text-exsmall">ハイフンなしで入力してください</p>
                        <p class="u-text-kome u-text-exsmall">SMSを受け取れる電話番号を登録してください</p>
                        <p v-if="formPhoneNumberValid" class="error u-text-warning-icon u-mt-sm">
                            携帯電話番号を入力してください。
                        </p>
                        <p v-if="formPhoneNumberLengthValid" class="error u-text-warning-icon u-mt-sm">
                            携帯電話番号は、11桁で入力してください。
                        </p>
                        <p v-if="formPhoneNumberDuplicateValid" class="error u-text-warning-icon u-mt-sm">
                            {{ phoneErrMessage }}
                        </p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        マイページ<br class="u-display-pc">公開/非公開
                    </div>
                    <div class="column-right">
                        <div class="u-display-flex u-mb-sm">
                            <div class="c-radio u-mr">
                                <input
                                    id="formMypageDisplay"
                                    name="mypage"
                                    type="radio"
                                    value="1"
                                    v-model="syncedMypage"
                                >
                                <label for="formMypageDisplay">
                                    公開
                                </label>
                            </div>
                            <div class="c-radio u-mr">
                                <input
                                    id="formMypageHide"
                                    name="mypage"
                                    type="radio"
                                    value="0"
                                    v-model="syncedMypage"
                                >
                                <label for="formMypageHide">
                                    非公開
                                </label>
                            </div>
                        </div>
                        <p class="u-text-kome u-text-exsmall">ニックネーム、都道府県、イベント参加情報が公開されます</p>
                        <p v-if="formMypageValid" class="error u-text-warning-icon u-mt-sm">
                            マイページ公開/非公開を選択してください。
                        </p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        プロモーション<br class="u-display-pc">メール
                    </div>
                    <div class="column-right">
                        <div class="u-display-flex u-mb-sm">
                            <div class="c-radio u-mr">
                                <input
                                    id="formPromotionalReceive"
                                    name="promotionalEmail"
                                    type="radio"
                                    value="1"
                                    v-model="syncedPromotionalEmail"
                                >
                                <label for="formPromotionalReceive">
                                    受け取る
                                </label>
                            </div>
                            <div class="c-radio u-mr">
                                <input
                                    id="formPromotionalUnreceive"
                                    name="promotionalEmail"
                                    type="radio"
                                    value="0"
                                    v-model="syncedPromotionalEmail"
                                >
                                <label for="formPromotionalUnreceive">
                                    受け取らない
                                </label>
                            </div>
                        </div>
                        <p class="u-text-kome u-text-exsmall">当社からのお知らせ（広告メール）をお送りします</p>
                        <p v-if="formPromotionalValid" class="error u-text-warning-icon u-mt-sm">
                            プロモーションメールの受け取り可否を選択してください。
                        </p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left wide">
                        <label>
                            アバター
                        </label>
                    </div>
                    <div class="column-right">
                            <div class="c-input-avatar-wrap" v-if="avatarInfo">
                                <div class="c-input c-input-avatar">
                                    <input id="avatar-1" type="radio" disabled>
                                    <label for="avatar-1" class="avater-label">
                                        <img :src="avatarInfo.image_url">
                                    </label>
                                </div>
                            </div>
                        <p class="u-text-kome u-text-exsmall">アバターは会員登録完了後、マイページメニュー「アバター変更」より変更が可能です</p>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left wide">
                        <label>
                            同意事項
                        </label>
                    </div>
                    <div class="column-right">
                        <template v-if="minorsFlg">
                            <div
                                class="parental-consent-wrapper"
                                :class="{'parental-consent-wrapper-error': formParentalConsentValid}"
                            >
                                <div class="c-checkbox u-mb-sm">
                                    <input
                                        id="formParentalConsent"
                                        name="parentalConsent"
                                        type="checkbox"
                                        v-model="syncedParentalConsent"
                                    >
                                    <label for="formParentalConsent">
                                        保護者の同意を得ました
                                    </label>
                                </div>
                                <p class="u-text-kome u-text-exsmall">
                                    18歳未満の方は、保護者の同意が必要です。<br>
                                    同意を得た上で、チェックボックスにチェックを入れてください
                                </p>
                                <p v-if="formParentalConsentValid" class="error u-text-warning-icon u-mt-sm">
                                    同意にチェックをしてください。
                                </p>
                            </div>
                        </template>
                        <div
                            class="tos-wrapper"
                            :class="{'tos-wrapper-error': formTermsOfServiceValid}"
                        >
                            <div class="c-checkbox u-mb-sm">
                                <input
                                    id="formTermsOfService"
                                    name="termsOfService"
                                    type="checkbox"
                                    v-model="syncedTermsOfService"
                                >
                                <label for="formTermsOfService">
                                    プレイヤーズクラブの利用規約に同意する
                                </label>
                            </div>
                            <p class="u-text-small"><a class="c-link" :href="getPolicyLinkURL()" target="_blank">プレイヤーズクラブの利用規約</a>をご確認の上、チェックして登録してください</p>
                            <p v-if="formTermsOfServiceValid" class="error u-text-warning-icon u-mt-sm">
                                同意にチェックをしてください。
                            </p>
                        </div>
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left wide">
                        <label for="formPassword">
                            パスワード
                        </label>
                    </div>
                    <div class="column-right form-input-password">
                        <password-input
                            v-model:password="syncedPassword"
                            :hasError="formPasswordValid"
                            placeholderMEssage="8文字以上"
                            id="formPassword"
                            name="password"
                            :isNewPassword="true"
                        ></password-input>
                        <p class="u-text-kome u-text-exsmall">アルファベット大文字、アルファベット小文字、数字、記号のうち2種類以上を含めて入力してください</p>
                        <p v-if="formPasswordValid" class="error u-text-warning-icon">
                            アルファベット大文字、アルファベット小文字、数字、記号のうち2種類以上を含めた8文字以上を入力してください。
                        </p>
                        <password-input
                            v-model:password="syncedPasswordReEnter"
                            :hasError="formPasswordReEnterValid"
                            placeholderMEssage="パスワードを再入力してください"
                            id="formPasswordReEnter"
                            name="passwordReEnter"
                            :isNewPassword="true"
                        ></password-input>
                        <p v-if="formPasswordReEnterValid" class="error u-text-warning-icon">
                            パスワードが一致しません。
                        </p>
                    </div>
                </div>
            </div>
            <div class="submit-button-container u-text-center">
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    @click="inputCheck"
                >
                    確認する
                </button>
            </div>
        </form>
    </section>
</template>

<script>
import errorMsg from '@/component/ErrorMsg.vue'
import axiosJsonpAdapter from 'axios-jsonp'
import passwordInput from '@/component/PasswordInput.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    name: 'UserRegisterPage',
    components: {
        errorMsg,
        passwordInput,
    },
    props: {
        email: String,
        nickname: String,
        lastName: String,
        firstName: String,
        lastNameKana: String,
        firstNameKana: String,
        birthdayYear: String,
        birthdayMonth: String,
        birthdayDay: String,
        gender: String,
        postcode: String,
        prefectures: String,
        address: String,
        phoneNumber: Number,
        mypage: String,
        promotionalEmail: String,
        parentalConsent: Boolean,
        termsOfService: Boolean,
        password: String,
        passwordReEnter: String,
        avatarInfo: Object,
        authCode: String
    },
    data: function() {
        return {
            isPasswordView: false,
            isPasswordReEnterView: false,
            formNicknameValid: false,
            formNicknameNGWordValid: false,
            formLastNameValid: false,
            formFirstNameValid: false,
            formLastNameKanaValid: false,
            formFirstNameKanaValid: false,
            genderValid: false,
            formPostcodeIncludeHyphenValid: false,
            formPostcodeValid: false,
            formPostcodeSearchValid: false,
            formPrefecturesValid: false,
            formAddressValid: false,
            formAddressLangthValid: false,
            formPhoneNumberValid: false,
            formPhoneNumberLengthValid: false,
            formMypageValid: false,
            formPromotionalValid: false,
            formParentalConsentValid: false,
            formTermsOfServiceValid: false,
            formPasswordValid: false,
            formPasswordReEnterValid: false,
            formPhoneNumberDuplicateValid: false,
            scrollElementId: 'pageTop',
            phoneErrMessage: '',
            error: null,
            isInputError: false
        }
    },
    computed: {
        minorsFlg: function() {
            var today = new Date();
            if (this.syncedBirthday != null) {
                var birthdayDate = dayjs(this.syncedBirthday);
                var thisYearBirthday = new Date(
                    today.getFullYear(),
                    birthdayDate.month(),
                    birthdayDate.date()
                );
                var age = today.getFullYear() - birthdayDate.year();
                if ((today < thisYearBirthday ? age - 1 : age) < 18) {
                    //18才未満なら保護者同意欄表示フラグをオンにする
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        },
        syncedMail: {
            get() {
                return this.email
            },
            set(value) {
                this.$emit('update:email', value)
            }
        },
        syncedAuthCode: {
            get() {
                return this.authCode;
            },
            set(value) {
                this.$emit("update:authCode", value);
            },
        },
        syncedBirthday: {
            get() {
                if (this.birthdayYear == null || this.birthdayYear == ''
                    || this.birthdayMonth == null || this.birthdayMonth == ''
                    || this.birthdayDay == null || this.birthdayDay == ''
                ) {
                    return null
                }
                return this.birthdayYear + '/' + this.birthdayMonth + '/' + this.birthdayDay
            }
        },
        syncedBirthdayYear: {
            get() {
                return this.birthdayYear
            },
            set(value) {
                this.$emit('update:birthdayYear', value)
            }
        },
        syncedBirthdayMonth: {
            get() {
                return this.birthdayMonth
            },
            set(value) {
                this.$emit('update:birthdayMonth', value)
            }
        },
        syncedBirthdayDay: {
            get() {
                return this.birthdayDay
            },
            set(value) {
                this.$emit('update:birthdayDay', value)
            }
        },
        syncedNickname: {
            get() {
                return this.nickname
            },
            set(value) {
                this.$emit('update:nickname', value)
            }
        },
        syncedLastName: {
                get() {
                return this.lastName
            },
            set(value) {
                this.$emit('update:lastName', value)
            }
        },
        syncedFirstName: {
            get() {
                return this.firstName
            },
            set(value) {
                this.$emit('update:firstName', value)
            }
        },
        syncedLastNameKana: {
            get() {
                return this.lastNameKana
            },
            set(value) {
                this.$emit('update:lastNameKana', value)
            }
        },
        syncedFirstNameKana: {
            get() {
                return this.firstNameKana
            },
            set(value) {
                this.$emit('update:firstNameKana', value)
            }
        },
        syncedGender: {
            get() {
                return this.gender
            },
            set(value) {
                this.$emit('update:gender', value)
            }
        },
        syncedPostcode: {
            get() {
                return this.postcode
            },
            set(value) {
                this.$emit('update:postcode', value)
            }
        },
        syncedPrefectures: {
            get() {
                return this.prefectures
            },
            set(value) {
                this.$emit('update:prefectures', value)
            }
        },
        syncedAddress: {
            get() {
                return this.address
            },
            set(value) {
                this.$emit('update:address', value)
            }
        },
        syncedPhoneNumber: {
            get() {
                return this.phoneNumber
            },
            set(value) {
                this.$emit('update:phoneNumber', value)
            }
        },
        syncedMypage: {
            get() {
                return this.mypage
            },
            set(value) {
                this.$emit('update:mypage', value)
            }
        },
        syncedPromotionalEmail: {
            get() {
                return this.promotionalEmail
            },
            set(value) {
                this.$emit('update:promotionalEmail', value)
            }
        },
        syncedParentalConsent: {
            get() {
                return this.parentalConsent
            },
            set(value) {
                this.$emit('update:parentalConsent', value)
            }
        },
        syncedTermsOfService: {
            get() {
                return this.termsOfService
            },
            set(value) {
                this.$emit('update:termsOfService', value)
            }
        },
        syncedPassword: {
            get() {
                return this.password
            },
            set(value) {
                this.$emit('update:password', value)
            }
        },
        syncedPasswordReEnter: {
            get() {
                return this.passwordReEnter
            },
            set(value) {
                this.$emit('update:passwordReEnter', value)
            }
        },
        inputPasswordType: function () {
            return this.isPasswordView ? "text" : "password";
        },
        inputPasswordReEnterType: function () {
            return this.isPasswordReEnterView ? "text" : "password";
        },
        prefectureList: function () {
            return this.$store.getters.getPrefectureList();
        }
    },
    methods: {
        serachPostCode() {
            this.formPostcodeSearchValid = false
            let api = process.env.VUE_APP_ZIP_CODE_URL
            api = api + this.syncedPostcode
            this.axios.get(api, {adapter: axiosJsonpAdapter})
            .then(response => {
                if (response.data.status === 400) {
                    this.formPostcodeSearchValid = true
                    this.scrollElementId = this.scrollElementId === '' ? 'formPostcode': this.scrollElementId
                    let element = document.getElementById(this.scrollElementId)
                    element.scrollIntoView({block: 'center'})
                } else if (response.data.results === null) {
                    this.formPostcodeSearchValid = true
                    this.scrollElementId = this.scrollElementId === '' ? 'formPostcode': this.scrollElementId
                    let element = document.getElementById(this.scrollElementId)
                    element.scrollIntoView({block: 'center'})
                } else {
                    let prefecture;
                    for(let key in this.prefectureList) {
                        let pref = this.prefectureList[key];
                        if (pref.title == response.data.results[0].address1) {
                            prefecture = pref
                        }
                    }
                    this.syncedPrefectures = prefecture.id + ':' + prefecture.title;
                    this.syncedAddress = response.data.results[0].address2 + response.data.results[0].address3;
                }
            })
            .catch(() =>{
                this.formPostcodeSearchValid = true
                this.scrollElementId = this.scrollElementId === '' ? 'formPostcode': this.scrollElementId
                let element = document.getElementById(this.scrollElementId)
                element.scrollIntoView({block: 'center'})
            })
        },
        changePasswordView() {
            this.isPasswordView = !this.isPasswordView
        },
        changePasswordReEnterView() {
            this.isPasswordReEnterView = !this.isPasswordReEnterView
        },
        inputCheck() {
            //入力チェック
            if (this.isInvalid())
            {
                this.isInputError = true
                const element = document.getElementById(this.scrollElementId)
                element.scrollIntoView({block: 'center'})
                return
            }
            let params = new URLSearchParams
            params.append('phone_number',this.phoneNumber)
            params.append('birthday',this.syncedBirthday)
            params.append('nickname',this.syncedNickname)

            const api = '/regist_valid'
            this.axios.post(api, params)
            .then(() =>{
                this.$router.push({hash: '#confirm'})
            })
            .catch(err =>{
                let element = ''
                const message = err.response.data.message

                this.formNicknameNGWordValid       = /ニックネーム/.test(message)
                this.formPhoneNumberDuplicateValid = /携帯/.test(message)

                if (this.formNicknameNGWordValid) {
                    this.nicknameErrMessage = err.response.data.message
                    element = document.getElementById('formNickname')
                } else if (this.formPhoneNumberDuplicateValid) {
                    this.phoneErrMessage = err.response.data.message
                    element = document.getElementById('formPhoneNumber')
                }
                element.scrollIntoView({block: 'center'})
            })
        },
        isInvalid() {
            let retBool = false
            this.formPhoneNumberDuplicateValid = false
            this.phoneErrMessage = ''
            this.scrollElementId = 'pageTop'
            this.formNicknameValid = false
            this.formNicknameNGWordValid = false
            this.formLastNameValid = false
            this.formFirstNameValid = false
            this.formLastNameKanaValid = false
            this.formFirstNameKanaValid = false
            this.genderValid = false
            this.formPostcodeIncludeHyphenValid = false
            this.formPostcodeValid = false
            this.formPostcodeSearchValid = false
            this.formPrefecturesValid = false
            this.formAddressValid = false
            this.formAddressLangthValid = false
            this.formPhoneNumberValid = false
            this.formPhoneNumberLengthValid = false
            this.formMypageValid = false
            this.formPromotionalValid = false
            this.formParentalConsentValid = false
            this.formTermsOfServiceValid = false
            this.formPasswordValid = false
            this.formPasswordReEnterValid = false
            this.isInputError = false

            //ニックネーム
            if(!this.nickname)
            {
                this.formNicknameValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formNickname' : this.scrollElementId
            }

            //性
            if(!this.lastName)
            {
                this.formLastNameValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formLastName': this.scrollElementId
            }

            //名
            if(!this.firstName)
            {
                this.formFirstNameValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formFirstName': this.scrollElementId
            }

            //セイ
            if(!this.lastNameKana || !this.lastNameKana.match(/[ァ-ヶ]/gi))
            {
                this.formLastNameKanaValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formLastNameKana': this.scrollElementId
            }

            //メイ
            if(!this.firstNameKana || !this.firstNameKana.match(/[ァ-ヶ]/gi))
            {
                this.formFirstNameKanaValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formFirstNameKana': this.scrollElementId
            }

            //性別
            if(!this.gender)
            {
                this.genderValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formMale': this.scrollElementId
            }

            //郵便番号
            if(!this.postcode || !this.postcode.match(/[0-9]{7}/gi))
            {
                this.formPostcodeValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formPostcode': this.scrollElementId
            }
            if(this.postcode && this.postcode.match(/^(?=.*-).*$/gi))
            {
                this.formPostcodeIncludeHyphenValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formPostcode': this.scrollElementId
            }

            //都道府県
            if(!this.prefectures)
            {
                this.formPrefecturesValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formPrefectures': this.scrollElementId
            }

            //住所
            if(!this.address)
            {
                this.formAddressValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formAddress': this.scrollElementId
            }

            if(this.address && this.address.length > 200) {
                this.formAddressLangthValid = true
                retBool = true
                this.scrollElementId = this.scrollElementId === '' ? 'formAddress': this.scrollElementId
            }

            //電話番号
            if(!this.phoneNumber)
            {
                this.formPhoneNumberValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formPhoneNumber': this.scrollElementId
            }
            if (this.phoneNumber && !this.phoneNumber.match(/^([0-9]{11})$/gi)) {
                this.formPhoneNumberLengthValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formPhoneNumber': this.scrollElementId
            }

            //公開情報
            if(!this.mypage)
            {
                this.formMypageValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formMypageDisplay': this.scrollElementId
            }

            //プロモーションメール
            if(!this.promotionalEmail)
            {
                this.formPromotionalValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formPromotionalReceive': this.scrollElementId
            }

            //保護者同意
            if(this.minorsFlg && !this.parentalConsent)
            {
                this.formParentalConsentValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formParentalConsent': this.scrollElementId
            }

            //プレイヤーズ同意
            if(!this.termsOfService)
            {
                this.formTermsOfServiceValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formTermsOfService': this.scrollElementId
            }

            //パスワード
            if(!this.password || !this.password.match(/^((?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])|(?=.*[A-Z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])|(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]))([a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]){8,}$/g))
            {
                this.formPasswordValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formPassword': this.scrollElementId
            }

            //パスワード(再入力)
            if(this.password !== this.passwordReEnter)
            {
                this.formPasswordReEnterValid = true
                retBool= true
                this.scrollElementId = this.scrollElementId === '' ? 'formPasswordReEnter': this.scrollElementId
            }

            return retBool
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja)
            if (date !== null) {
                return dayjs(date).format(format)
            }
            return format
        },
        getPolicyLinkURL() {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/event/players_policy/'
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.error-msg {
    @include g.pc {
        width: 100%;
        text-align: center;
    }
    @include g.sp {
        width: 335px;
        text-align: left;
    }
}
.c-content {
    @include g.pc {
        width: 760px;
    }
}
.c-title-page {
    color: g.$color-black;
    margin-bottom: 36px;
}
.page-description {
    color: g.$color-black;
    @include g.pc {
        margin-bottom: 56px;
    }
    @include g.sp {
        margin-bottom: 28px;
    }
}
.disable-color {
    background-color: gray;
}
.form {
    width: 100%;
    border-top: 1px solid #B3D8EE;
    border-bottom: 1px solid #B3D8EE;
    padding-top: 36px;
    padding-bottom: 40px;

    @include g.sp {
        border-bottom: 0;
    }
    &-input {
        &-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-nickname {
            @include g.sp {
                & > input[type="text"] {
                    width: 100%;
                }
            }
        }
        &-name {
            width: 100%;
            @include g.pc {
                & > input[type="text"] {
                    width: 230px;
                }
            }
            @include g.sp {
                & > input[type="text"] {
                    width: 50%;
                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }
            }
        }
        &-birthday {
            width: 100%;
            @include g.pc {
                & > input[type="text"] {
                    width: 120px;
                }
            }
            @include g.sp {
                & > input[type="text"] {
                    width: 50%;
                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }
            }
        }
        &-gender {
            .u-display-flex {
                @include g.pc {
                    .c-radio {
                        margin-right: 40px;
                    }
                }
                @include g.sp {
                    justify-content: space-between;
                }
            }
        }
        &-postcode {
            @include g.sp {
                display: block;
                .u-flex {
                    display: block;
                }
            }
            .input-container {
                @include g.pc {
                    & > input[type="text"] {
                        width: 230px;
                        margin-bottom: 8px;
                    }
                }
                @include g.sp {
                    & > input[type="text"] {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 8px;
                    }
                }
            }

            .postcode-btn-container {
                @include g.sp {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                & > button {
                    display: inline-block;
                    font-size: g.$font-size-small;
                    color: g.$blue-40;
                    cursor: pointer;

                    @include g.pc {
                        margin-top: 14px;
                        height: 20px;
                        line-height: 20px;
                        &:hover {
                            text-decoration: underline;
                            color: g.$color-link-hover;
                        }
                    }
                }
            }
        }
        &-prefecture {
            .c-input-select {
                select {
                    width: 230px;
                }
            }
        }
        &-tel {
            @include g.pc {
                & > input[type="tel"] {
                    margin-bottom: 8px;
                }
            }
            @include g.sp {
                p {
                    margin-top: 8px;
                }
            }
        }
        &-password {
            @include g.pc {
                & > p {
                    margin-top: 8px;
                }
                & > .c-input-password {
                    &:nth-of-type(1) {
                        margin-top: 0;
                    }
                    &:nth-of-type(2) {
                        margin-top: 20px;
                    }
                }
            }
            @include g.sp {
                & > p {
                    margin-top: 8px;
                }
                & > .c-input-password {
                    &:nth-of-type(2) {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    .avater-label {
        cursor: default;
    }
}
.c-table-list {
    &::before {
        border: 0;
    }
    &-row {
        &:nth-of-type(1) {
            border-top: 0;
        }
        &:not(:last-child) {
            border-bottom: 0;
        }
        .column {
            @include g.pc {
                &-left {
                    position: relative;
                    flex: 0 0 220px;
                    padding-right: 0;
                    padding-left: 30px;
                    padding-top: 22px;
                    padding-bottom: 22px;
                    box-sizing: content-box;
                    &.wide {
                        align-items: normal;
                    }
                    label {
                        position: absolute;
                        top: 22px;
                        height: 48px;
                        line-height: 48px;
                    }
                }
                &-right {
                    padding-right: 30px;
                    padding-left: 0;
                    padding-top: 22px;
                    padding-bottom: 22px;
                }
            }
            @include g.sp {
                &-left {
                    padding: 0;
                    margin-bottom: 10px;
                }
                &-right {
                    padding: 0;
                    margin-bottom: 20px;
                }
            }
            &-left {
                background-color: g.$color-white;
            }
        }
    }
}
.parental-consent-wrapper,
.tos-wrapper {
    border: 1px solid g.$color-border-gray;
    border-radius: 10px;
    padding: 22px 24px 20px;
    &:not(:last-child) {
        margin-bottom: 12px;
    }
    &-error {
        border-color: g.$color-error-red;
        background-color: g.$color-error-red-bg;
    }
    @include g.sp {
        padding: g.$margin;
    }
}

.submit-button-container {
    @include g.pc {
        padding-top: 56px;
        padding-bottom: 80px;
    }
    @include g.sp {
        padding: 56px 10px;
        .confirm-btn {
            width: 100%;
        }
    }
}
.error {
    color: g.$color-error-red;
    font-size: g.$font-size-small;
    line-height: 20px;
    font-weight: bolder;
}
.width-100 {
    width: 100% !important;
}
.error-parental-consent {
    padding-bottom: 10px;
}
.c-link {
    &[target="_blank"] {
        &::after {
            margin-right: 8px;
        }
    }
}
.text-angle {
    @include g.pc {
        text-align: center;
    }
    @include g.sp {
        text-align: left;
    }
}
</style>
