<template>
    <system-page :page-settings="page">
        <div class="c-content c-content-wide-sp">
            <section class="mypage-tab">
                <div class="c-tab-container">
                    <router-link
                        :to="{hash: ''}"
                        @click="selectedTab = 'player'"
                        class="c-tab-btn u-display-inline-block"
                        :class="{'active': selectedTab === 'player'}"
                    >
                        <span>プレイヤー</span>
                    </router-link>

                    <template v-if="player.judge_flg === 1 || player.eo_flg === 1">
                        <router-link
                            :to="{hash: '#judge'}"
                            @click="selectedTab = 'judge'"
                            class="c-tab-btn u-display-inline-block"
                            :class="{'active': selectedTab === 'judge'}"
                        >
                            <img
                                v-if="true"
                                src="@/assets/img/mypage/new_tooltip.png"
                                alt="new"
                            >
                            <span>オーガナイザー</span>
                        </router-link>
                    </template>
                </div>
            </section>
        </div>
        <template v-if="selectedTab === 'player'">
            <div class="c-content c-content-wide-sp">
                <section class="mypage-top">
                    <div class="container c-box">
                        <div class="avatar">
                            <img
                                v-if="player.avatar_image"
                                :src="player.avatar_image"
                                alt="アバター"
                            >
                        </div>
                        <div class="info">
                            <p class="league">{{ player.current_league }}リーグ</p>
                            <p class="name">{{ player.nickname }}</p>
                            <p class="small">
                                <span class="player-id">プレイヤーID : {{ player.player_id }}</span>
                                <span class="prefecture">エリア : {{ player.prefecture }}</span>
                            </p>

                            <div class="badge u-flex">
                                <ul class="badge-images u-flex u-mr-sm">
                                    <li class="badge-image" v-if="player.judge_flg === 1 || player.eo_flg === 1">
                                        <img
                                            src="@/assets/img/mypage/badge_1.png"
                                            alt=""
                                        >
                                    </li>
                                    <li class="badge-image" v-if="player.judge_flg === 1">
                                        <img
                                            src="@/assets/img/mypage/badge_2.png"
                                            alt=""
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="player-status">
                            <ul class="ps-table">
                                <li class="ps-tr">
                                    <div class="u-display-flex u-width-100">
                                        <h4 class="ps-th">チャンピオンシップ<br>ポイント</h4>
                                        <p class="ps-td">
                                            <template v-if="player.champion_ship_point != null">{{ player.champion_ship_point }}<span class="unit">pt</span></template>
                                            <template v-else>0<span class="unit">pt</span></template><br>
                                            <span class="small">トレーナーズリーグ：{{ player.csp_from_trainers ? player.csp_from_trainers : '0' }}pt/最大30pt</span>
                                        </p>
                                    </div>
                                </li>
                                <li class="ps-tr">
                                    <div class="u-display-flex u-width-100">
                                        <h4 class="ps-th">ランキング</h4>
                                        <p class="ps-td ranking">{{ player.current_ranking ? player.current_ranking : '-' }}<span class="unit">位</span></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <div class="c-content">
                    <error-msg class="u-mb-lg" v-if="error !== null">
                        <p>{{ error }}</p>
                    </error-msg>
                    <error-msg class="u-mb-lg" v-if="player.sms_auth_flg === 0">
                        <p class="u-mb" v-if="player.sms_auth_date === null">
                            SMS認証が完了していないか、再登録が必要なため、イベント応募ができません。
                        </p>
                        <p class="u-mb" v-else>
                            SMS認証が完了していないか、再登録が必要なため、イベント応募ができません。
                        </p>
                        <p>
                            <a href="" @click.prevent="smsIdentify" class="link-arrow">SMS認証する</a>
                        </p>
                    </error-msg>
                    <error-msg class="u-mb-lg" v-else-if="player.new_phone_number !== null">
                        <p class="u-mb">
                            電話番号の変更が完了しておりません。SMS認証完了後に電話番号が変更されます。
                        </p>
                        <p>
                            <a href="" @click.prevent="smsIdentify" class="link-arrow">SMS認証する</a>
                        </p>
                    </error-msg>
                    <error-msg class="u-mb-lg" v-if="noDeckCount !== 0">
                        <p>
                            デッキが未登録のイベントが <span class="text-h3">{{ noDeckCount }}</span> 件あります。<br>
                            デッキの登録は、イベント開催当日の8:30までに登録してください。<br>
                            <br>
                            デッキ登録を行わないまま大会に出場した場合は、敗北・失格などペナルティの対象となります。<br>
                            イベント出場予定ページから使用するデッキを登録してください。
                        </p>
                    </error-msg>
                </div>
            </div>

            <div class="c-content-wide mypage-event-wrapper">
                <section class="c-content c-content-wide-sp mypage-event" v-if="todayEntryEvents.length > 0">
                    <h2><span class="icon-calendar before-calendar"></span>本日（{{ dayjsFormat(new Date(), 'MM/DD') }}）開催の<br class="u-display-sp">イベント<span class="icon-calendar after-calendar"></span></h2>
                    <div
                        class="event-wrap"
                        v-for="(event, index) in todayEntryEvents"
                        :key="index"
                    >
                        <div class="eventBox">
                            <header>
                                <router-link :to="{ name: 'EventDetail', params: {id: event.id,
                                    trainersFlg: event.trainers_flg,
                                    shopId: event.shop_id ? event.shop_id : 0,
                                    eventDate: event.event_date_params ? event.event_date_params : 0,
                                    dateId: event.date_id ? event.date_id : 0} }"
                                >
                                    <div class="tagsBlock">
                                        <eventPlayerStatus
                                            :statusId="event.status_id"
                                            :entryTypeId="event.entry_type_id"
                                            :lotteryDeadline="event.lottery_deadline"
                                        ></eventPlayerStatus>
                                        <eventDeckSubmissionStatus
                                            v-if="event.deck_regist_flg === 1 && event.status_id !== 4 && event.status_id !== 6"
                                            :deckRegisterFlg="event.deck_code !== '' && event.deck_code !== null"
                                        ></eventDeckSubmissionStatus>
                                    </div>
                                    <h3>
                                        {{ event.event_title }}
                                    </h3>
                                    <div class="leaguesBlock">
                                        <span class="league">{{ event.league_title }}リーグ</span>
                                    </div>
                                </router-link>
                            </header>
                            <main>
                                <div class="spDetail closed">
                                    <table>
                                        <tr>
                                            <th>開催日</th>
                                            <td>
                                                <time
                                                    :datetime="dayjsFormat(event.event_date.date, 'YYYY/MM/DD')"
                                                    class="u-text-bold"
                                                >
                                                    {{ dayjsFormat(event.event_date.date, 'YYYY/MM/DD（dd）') }}{{ dayjsFormat(event.event_started_at.date, 'HH:mm') }} 〜
                                                    <span v-if="event.event_ended_at">
                                                        {{ dayjsFormat(event.event_ended_at.date, 'HH:mm') }}
                                                    </span>
                                                </time>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>開催時間</th>
                                            <td>
                                                <time :datetime="dayjsFormat(event.event_started_at.date, 'HH:mm')">{{ dayjsFormat(event.event_started_at.date, 'HH:mm') }}</time><span class="font-small" v-if="event.event_day_supply !== null">（{{ event.event_day_supply }}）</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>主催者</th>
                                            <td v-if="event.event_type_id === 1">株式会社ポケモン</td>
                                            <td v-else>{{ event.shop_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>会場</th>
                                            <td>
                                                <p><template v-if="event.zip_code !== null">〒{{ zipCodeFormat(event.zip_code) + ' ' }}</template>{{ event.address }}<br></p>
                                                <p v-if="event.venue !== null && event.venue !== ''">{{ event.venue }}</p>
                                                <p class="mapLink"><a :href="'https://www.google.com/maps/search/' + encodeURIComponent(event.geocoding)" target="_blank">Google Maps</a></p>
                                                <p class="access" v-if="event.access !== null && event.access !== ''">
                                                    {{ event.access }}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>参加費</th>
                                            <td v-if="event.entry_fee">
                                                {{ displayEntryFee(event.entry_fee) }}
                                            </td>
                                            <td v-else>無料</td>
                                        </tr>
                                        <tr>
                                            <th>定員</th>
                                            <td>{{ event.capacity }}人</td>
                                        </tr>
                                    </table>
                                    <div class="detailButtonBlock spDetailClosed">
                                        <button
                                            type="button"
                                            class="c-btn c-btn-secondary-outline open"
                                            @click="openDetail($event)"
                                        >
                                            <span class="icon-plus u-mr-sm"></span>詳細を表示する
                                        </button>
                                        <button
                                            type="button"
                                            class="c-btn c-btn-secondary-outline close"
                                            @click="closeDetail($event)"
                                        >
                                            <span class="icon-minus u-mr-sm"></span>詳細を閉じる
                                        </button>
                                    </div>
                                </div>
                                <template v-if="event.status_id !== 5 && (event.deck_regist_flg === 1 || (event.qr_checkin_flg === 1 && event.checkin_url !== null))">
                                    <nav>
                                        <div v-if="event.deck_regist_flg === 1">
                                            <div
                                                class="deckRegistrationBlock"
                                                v-if="new Date() <= getDeckRegistDate(event.event_date.date)"
                                            >
                                                <template v-if="event.deck_code === null">
                                                    <p class="alert u-mb-20">
                                                        {{ dayjsFormat(event.event_date.date, 'YYYY/MM/DD (dd)') }} 08:30までに<br>
                                                        デッキを登録してください。
                                                    </p>
                                                    <button
                                                        type="button"
                                                        class="c-btn c-btn-primary"
                                                        @click="openDeckRegistrationModal(event)"
                                                    >
                                                        <span class="icon-deck u-mr-sm"></span>デッキを登録する
                                                    </button>
                                                </template>
                                                <template v-else>
                                                    <p class="u-mb-20">
                                                        {{ dayjsFormat(event.event_date.date, 'YYYY/MM/DD (dd)')}} 08:30までデッキの変更が可能です。
                                                    </p>
                                                    <button
                                                        type="button"
                                                        class="c-btn c-btn-primary-outline"
                                                        @click="openDeckRegistrationModal(event)"
                                                    >
                                                        デッキを確認/変更する
                                                    </button>
                                                </template>
                                            </div>
                                            <div
                                                class="deckRegistrationBlock"
                                                v-else-if="event.deck_code !== null"
                                            >
                                                <a
                                                    :href="getRegisterdDeckLinkURL(event.deck_code)"
                                                    class="c-link c-link-arrow"
                                                    target="_blank"
                                                    @click.stop
                                                >
                                                    登録デッキを見る
                                                </a>
                                            </div>
                                            <div
                                                class="deckRegistrationBlock"
                                                v-else
                                            >
                                                デッキが登録されていません
                                            </div>
                                        </div>
                                        <!--
                                        <div v-if="event.status_id !== 5" class="barcodeCheckinBlock">
                                            <router-link
                                                :to="{ name: 'BarcodeCheckin', params: {eventHoldingId: event.id, trainersFlg: event.trainers_flg}}"
                                                type="button"
                                                class="c-btn c-btn-primary icon-qr"
                                            >
                                                バーコードチェックイン
                                            </router-link>
                                        </div>
                                        -->
                                        <div v-if="event.deck_regist_flg === 1 && (event.qr_checkin_flg === 1 && event.checkin_url !== null)" class="barcodeCheckinBlock">
                                        </div>
                                        <div v-if="event.qr_checkin_flg === 1 && event.checkin_url !== null">
                                            <div class="u-text-center">
                                                <a :href="event.checkin_url" class="c-btn c-btn-primary" target="_blank"><span class="icon-qr"></span>入場用バーコードを表示</a>
                                            </div>
                                        </div>
                                    </nav>
                                    <!--
                                    <template v-else>
                                        <nav>
                                            <div class="deckRegistrationBlock">
                                            <p>
                                                【チェックイン】<br>
                                                {{ dayjsFormat(event.status_started_at.date, 'YYYY/MM/DD HH:mm') }}
                                            </p>
                                            </div>
                                        </nav>
                                    </template>
                                    -->
                                </template>
                            </main>
                        </div>
                    </div>
                </section>
            </div>

            <div class="c-content c-content-wide-sp">
                <section class="mypage-news">
                    <h2 class="c-title c-title-h2 icon-news">お知らせ</h2>
                    <template v-if="informations.length !== 0">
                        <ul class="news-list">
                            <li
                                v-for="(information, index) in informations"
                                :key="index"
                            >
                                <template v-if="information.link_flg === 1">
                                    <a
                                        :href="information.link_url"
                                        class="information"
                                        target="_blank"
                                    >
                                        <time>{{ dayjsFormat(information.update_at.date, 'YYYY/MM/DD') }}</time>
                                        <h4 v-bind:class="[isNewInformation(information.update_at.date) ? 'new' : '']">{{ information.title }}</h4>
                                    </a>
                                </template>
                                <template v-else>
                                    <router-link
                                        :to="{name: 'NewsDetail', params: {id: information.id } , query:{routePage: 'Mypage'}}"
                                        class="information"
                                    >
                                        <time>{{ dayjsFormat(information.update_at.date, 'YYYY/MM/DD') }}</time>
                                        <h4 v-bind:class="[isNewInformation(information.update_at.date) ? 'new' : '']">{{ information.title }}</h4>
                                    </router-link>
                                </template>
                            </li>
                        </ul>
                        <div class="to-news-list">
                            <router-link
                                :to="{name: 'NewsList' , query:{routePage: 'Mypage'}}"
                                class="c-link-arrow"
                            >お知らせ一覧へ</router-link>
                        </div>
                    </template>
                    <no-result v-else>
                        表示できるお知らせがありません。
                    </no-result>
                </section>

                <section class="mypage-menu">
                    <h2 class="c-title c-title-h2">マイページメニュー</h2>
                    <ul class="menu-list">
                        <li>
                            <router-link :to="{ name: 'MyEventList'}">
                                <h4 class="c-title c-title-h3 event-schedule">
                                    出場予定のイベント
                                </h4>
                                <p>エントリー済みのイベントの当選結果や、デッキの登録状況を確認できます。</p>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'MypageEventResult'}">
                                <h4 class="c-title c-title-h3 event-trophy">
                                    イベント成績
                                </h4>
                                <p>参加したイベントの結果を確認できます。</p>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'DeckList'}">
                                <h4 class="c-title c-title-h3 event-deck">
                                    マイデッキ
                                </h4>
                                <p>マイデッキの登録と削除ができます。</p>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'Avatar' }">
                                <h4 class="c-title c-title-h3 event-avatar">
                                    アバター変更
                                </h4>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'Profile'}">
                                <h4 class="c-title c-title-h3 event-user">
                                    ユーザー情報
                                </h4>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'Faq'}">
                                <h4 class="c-title c-title-h3 event-faq">
                                    よくあるご質問
                                </h4>
                            </router-link>
                        </li>
                        <!-- TODO: ジャッジ専用ページ画面実装後リンク作成 -->
                        <!--
                        <li :class="player.judge_flg !== 1 ? 'hidden-judge' : ''">
                            <a href="">
                                <h4 class="c-title c-title-h3 event-judge">
                                    ジャッジ専用ページ
                                </h4>
                            </a>
                        </li>
                        -->
                    </ul>
                    <div class="logout">
                        <a href="#" class="c-link" @click="logout()">ログアウト</a>
                    </div>
                </section>
            </div>
        </template>


        <pcg-modal
            class="deckRegistrationModal"
            :isActive="activeModal === 'deckRegistrationModal'"
            :bgImage="true"
            @close="closeModal()"
        >
            <template #modalHeader>
                <h2>デッキ登録</h2>
            </template>
            <header>
                <h3>{{ eventOnModal.event_title }}</h3>
                <div class="leaguesBlock">
                    <span class="league">{{ eventOnModal.league_title }}</span>
                </div>
            </header>
            <div class="dateBlock">
                <time
                    :datetime="dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD')"
                    class="u-text-bold"
                >{{ dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD（dd）') }}{{ dayjsFormat(eventOnModal.event_started_at.date, 'HH:mm') }} 〜 {{ eventOnModal.event_ended_at ? dayjsFormat(eventOnModal.event_ended_at.date, 'HH:mm') : '' }}</time>
            </div>
            <div class="decksBlock">
                <p class="u-mb-lg u-text-center">大会で使用するデッキを選択して、<br class="u-display-sp">デッキを登録してください。</p>
                <cityleague-message :event="eventOnModal"  class="u-mb-lg"></cityleague-message>
                <error-msg class="u-mb-lg" v-if="hasRegulationError()">
                    <p class="u-text-warning-icon">レギュレーションを満たしておりません。</p>
                </error-msg>
                <error-msg class="u-mb-lg" v-if="deckRegistError !== null">
                    <p class="u-text-warning-icon">{{ deckRegistError }}</p>
                </error-msg>
                <div class="decksWrapper">
                    <div
                        v-for="deck in mydecks" :key="deck.id"
                        class="deck"
                        :class="{ 'selected': selectedDeck == deck }"
                        @click="selectedDeck = deck"
                    >
                        <div class="c-radio">
                            <input
                                type="radio"
                                :id="'deck:' + deck.id"
                                v-model="selectedDeck"
                                name="deck"
                                :value="deck"
                            >
                            <label for="deck:1"></label>
                        </div>
                        <h4>{{ deck.deck_name }}</h4>
                        <p class="deckCode">{{ deck.deck_code }}</p>
                        <p class="u-mb">登録日：{{ dayjsFormat(deck.created_at, 'YYYY/MM/DD') }}</p>
                        <div class="deckTagsBlock">
                            <span
                                v-for="regulation in deck.available_regulations"
                                :key="regulation"
                                class="deckTag"
                            >
                                {{ regulation }}
                            </span>
                        </div>
                        <a
                            :href="getRegisterdDeckLinkURL(deck.deck_code)"
                            class="u-text-normal"
                            target="_blank"
                            @click.stop
                        >
                            デッキを見る
                        </a>
                    </div>
                </div>
            </div>
            <!-- shadowのスタイル当てるためcomponentのfooterスロットを使わず実装 -->
            <div class="modalFooter">
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    :disabled="selectedDeck === null"
                    @click="registerDeck()"
                >
                    デッキを登録する
                </button>
            </div>
        </pcg-modal>

        <pcg-modal
            class="deckRegistrationCompleteModal"
            :isActive="activeModal === 'deckRegistrationCompleteModal'"
            :bgImage="true"
            @close="closeModal()"
        >
            <template #modalHeader>
                <h2>デッキ登録完了</h2>
            </template>
            <p class="message strong">デッキの登録が完了しました！</p>
            <p class="message">{{ dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD (dd)') }} 08:30 までは<br class="u-text-sp">何度でも登録デッキの変更ができます。</p>
            <cityleague-message :event="eventOnModal"  class="u-mb-lg"></cityleague-message>
            <div class="c-box">
                <h3>{{ eventOnModal.event_title }}</h3>
                <div class="leaguesBlock u-text-center u-mb">
                    <span class="league">{{ eventOnModal.league_title }}</span>
                </div>
                <div class="dateBlock">
                    <time
                        :datetime="dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD')"
                        class="u-text-bold"
                    >{{ dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD（dd）') }}{{ dayjsFormat(eventOnModal.event_started_at.date, 'HH:mm') }} 〜 {{ eventOnModal.event_ended_at ? dayjsFormat(eventOnModal.event_ended_at.date, 'HH:mm') : '' }}</time>
                </div>
            </div>
            <template #modalFooter>
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </template>
        </pcg-modal>

        <template v-if="selectedTab === 'judge'">
            <div class="c-content c-content-wide-sp">
                <section class="mypage-top">
                    <div class="container c-box">
                        <div class="avatar">
                            <img
                                v-if="player.avatar_image"
                                :src="player.avatar_image"
                                alt="アバター"
                            >
                        </div>
                        <div class="info">
                            <p class="name">{{ player.nickname }}</p>
                            <p class="small">
                                <span class="judge-id">オーガナイザーNo. : {{ player.judge_id }}</span>
                            </p>

                            <div class="badge u-flex">
                                <ul class="badge-images u-flex u-mr-sm">
                                    <li class="badge-image" v-if="player.judge_flg === 1 || player.eo_flg === 1">
                                        <img
                                            src="@/assets/img/mypage/badge_1.png"
                                            alt=""
                                        >
                                    </li>
                                    <li class="badge-image" v-if="player.judge_flg === 1">
                                        <img
                                            src="@/assets/img/mypage/badge_2.png"
                                            alt=""
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="c-content" v-if="judgeError !== null">
                    <error-msg class="u-mb-lg">
                        <p>{{ judgeError }}</p>
                    </error-msg>
                </div>

                <section v-if="judgeEvents.length > 0" class="mypage-topics">
                    <h2 class="c-title c-title-h2"><span class="icon-handshake u-mr"></span>参加中のイベント</h2>
                    <judge-events :events="judgeEvents"></judge-events>
                </section>

                <section v-if="finishedJudgeEvents.length > 0" class="mypage-topics">
                    <h2 class="c-title c-title-h2"><span class="icon-handshake u-mr"></span>稼働報告可能イベント</h2>
                    <h3>開催日から2日後の23:59まで報告可能です。</h3>
                    <judge-events :events="finishedJudgeEvents"></judge-events>
                </section>

                <section class="mypage-menu organizer">
                    <h2 class="c-title c-title-h2 u-mb-lg"><span class="icon-star_laurel u-mr"></span>イベントオーガナイザーの皆様へ</h2>
                    <ul class="menu-list">
                        <li>
                            <a href="https://www.pokemon-card.com/assets/document/format/regulation_02.pdf">
                                <h4 class="c-title c-title-h3 after-icon ilter_none">
                                    <span class="pdf">規約</span>
                                </h4>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.pokemon-card.com/event/organized-events/format/faq.html">
                                <h4 class="c-title c-title-h3 after-icon arrow">
                                    よくあるご質問
                                </h4>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.pokemon-card.com/assets/document/event_material.zip">
                                <h4 class="c-title c-title-h3 after-icon download">
                                    イベント用素材
                                </h4>
                            </a>
                        </li>
                    </ul>
                </section>

                <section class="mypage-menu judge">
                    <h2 class="c-title c-title-h2 u-mb-lg"><span class="icon-star_shield u-mr"></span>公認ジャッジの皆様へ</h2>
                    <h3>
                        <i class="judge-icon icon-schedule"></i>活動履歴
                    </h3>
                    <ul class="menu-list">
                        <li>
                            <router-link :to="{ name: 'MyJudgeHistory'}">
                                <h4 class="c-title c-title-h3 after-icon arrow">
                                    ジャッジ履歴
                                </h4>
                            </router-link>
                        </li>
                    </ul>
                    <h3>
                        <i class="judge-icon icon-book"></i>研修資料
                    </h3>
                    <ul class="menu-list">
                        <li>
                            <a href="https://www.pokemon-card.com/assets/document/JudgeSeminar2023.pdf">
                                <h4 class="c-title c-title-h3 after-icon ilter_none">
                                    <span class="pdf">ジャッジ研修資料</span>
                                </h4>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.pokemon-card.com/assets/document/JudgeSeminar2023_EX.pdf">
                                <h4 class="c-title c-title-h3 after-icon ilter_none">
                                    <span class="pdf">エクストラレギュレーション<br class="u-display-sp">資料</span>
                                </h4>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.pokemon-card.com/assets/document/cityleague_manual.pdf">
                                <h4 class="c-title c-title-h3 after-icon ilter_none">
                                    <span class="pdf two-lines">ジャッジ報告対応マニュアル<br>（2024年02月02日更新）</span>
                                </h4>
                            </a>
                        </li>
                    </ul>
                    <h3>
                        <i class="judge-icon icon-pen"></i>ツール
                    </h3>
                    <ul class="menu-list">
                        <li>
                            <a href="https://www.pokemon-card.com/assets/document/rules/deck-sheet.pdf">
                                <h4 class="c-title c-title-h3 after-icon ilter_none">
                                    <span class="pdf">デッキシート</span>
                                </h4>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.pokemon-card.com/assets/document/rules/deck-sheet.xlsx">
                                <h4 class="c-title c-title-h3 after-icon ilter_none">
                                    <span class="excel">デッキシート</span>
                                </h4>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.pokemon-card.com/assets/document/penalty-sheet.pdf">
                                <h4 class="c-title c-title-h3 after-icon ilter_none">
                                    <span class="pdf">ペナルティシート</span>
                                </h4>
                            </a>
                        </li>
                    </ul>
                    <h3>
                        <i class="judge-icon icon-spark"></i>その他
                    </h3>
                    <ul class="menu-list">
                        <li>
                            <a href="https://www.pokemon-card.com/event/organized-events/format/news/191218.html">
                                <h4 class="c-title c-title-h3 after-icon arrow">
                                    レベルアッププログラムについて
                                </h4>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.pokemon-card.com/event/organized-events/format/community-facebook.html">
                                <h4 class="c-title c-title-h3 after-icon arrow">
                                    Facebookコミュニティ申請
                                </h4>
                            </a>
                        </li>
                    </ul>
                </section>
            </div>
        </template>
    </system-page>
    <sp-menu />
</template>

<script>
import systemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import eventPlayerStatus from '@/component/EventPlayerStatus.vue'
import eventDeckSubmissionStatus from '@/component/EventDeckSubmissionStatus.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import pcgModal from '@/component/Modal.vue'
import noResult from '@/component/NoResult.vue'
import SpMenu from './component/SpMenu.vue'
import judgeEvents from './component/JudgeEvents.vue'
import cityleagueMessage from './component/CityleagueMessage.vue'

export default {
    name: 'MypagePage',
    components: {
        errorMsg,
        systemPage,
        eventPlayerStatus,
        eventDeckSubmissionStatus,
        pcgModal,
        noResult,
        SpMenu,
        judgeEvents,
        cityleagueMessage
    },
    props: {},
    data: function() {
        return {
            error: null,
            judgeError: null,
            player: {},
            entryEvents: [],
            informations: [],
            noDeckCount: 0,
            waitingCount:0,
            reserverCount: 0,
            displayPlayerStatus: [2, 3, 5], // 参加確定・リザーバー・参加
            selectedTab: 'player',
            activeModal: null,
            selectedDeck: null,
            eventOnModal: null,
            mydecks: [],
            deckRegistError: null,
            judgeEvents: [],
            finishedJudgeEvents: [],
        }
    },
    created: function() {
        this.init()
    },
    watch: {
        '$route.hash': function() {
            if (this.$route.hash === '#judge') {
                this.selectedTab = 'judge'
            } else {
                this.selectedTab = 'player'
            }
        }
    },
    computed: {
        todayEntryEvents() {
            return this.entryEvents.filter(this.isDisplayEvent)
        },
        page() {
            let page =  {
                title: 'マイページ',
                titleForHeader: '',
                desc: ''
            }
            let player = this.$store.getters.getUser();
            if (!player) {
                return page
            }
            let defaultTitle = page.title;
            page.titleForHeader = player.nickname + "さん（"+ player.current_league + "）の" + defaultTitle
            page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さん（"+ player.current_league + "）の" + defaultTitle + "。";
            return page
        },
    },
    methods: {
        init() {
            const getPlayerApi = '/get_player_account'
            const getEntryEventApi = '/get_entry_event_info'
            const getInformationApi = '/api/information'

            this.axios.post(getPlayerApi)
            .then(response => {
                this.player = response.data.player

                if (this.player.judge_flg === 1 || this.player.eo_flg === 1) {
                    this.selectedTab = this.$route.hash === '#judge' ? 'judge' : 'player'
                    this.getJudgeEntryEventApi()
                }
            })
            .catch(err => {
                if (err.response && (err.response.data.status === 401 || err.response.data.code === 404)) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                this.error = err.response.data.message
            })

            this.axios.post(getEntryEventApi)
            .then(response => {
                this.entryEvents = response.data.event
                this.noDeckCount = response.data.no_deck_count
                this.waitingCount = response.data.waiting_count
                this.reserverCount = response.data.reserver_count
            })
            .catch(err => {
                if (err.response && err.response.data.status === 401) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                if (err.response.status !== 404) {
                    this.error = err.response.data.message
                }
            })

            this.axios.get(getInformationApi)
            .then(response => {
                this.informations = response.data.information
            })
            .catch(err => {
                this.error = err.response.data.message
            })
        },
        getJudgeEntryEventApi() {
            const getJudgeApi = '/get_joining_event'

            this.axios.post(getJudgeApi)
            .then(response => {
                this.judgeEvents = response.data.joining_event_list
                this.finishedJudgeEvents = response.data.joining_finished_event_list
            })
            .catch(() => {
                return null
            })
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? zip : zip.slice(0, 3) + '-' + zip.slice(3)
        },
        isDisplayEvent(event) {
            let today = new Date()

            return (this.dayjsFormat(today, 'YYYY/MM/DD') === this.dayjsFormat(event.event_date.date, 'YYYY/MM/DD')) && this.displayPlayerStatus.includes(event.status_id)
        },
        isNewInformation(updateAt) {
            dayjs.locale(ja)
            dayjs.extend(isSameOrAfter)

            let today = dayjs(new Date()).startOf('day')
            let updateAt7day = dayjs(updateAt).startOf('day').add(7, 'd')

            return updateAt7day.isSameOrAfter(today)
        },
        logout() {
            this.$store.commit('setLogoutState')
            this.$router.push({name: 'Login'})
        },
        openDetail(event) {
            event.currentTarget.closest('.spDetail').classList.remove('closed')
        },
        closeDetail(event) {
            event.currentTarget.closest('.spDetail').classList.add('closed')
        },
        openDeckRegistrationModal(event) {
            if (new Date() >= this.getDeckRegistDate(event.event_date.date))
            {
                this.error = 'デッキ登録可能時間を過ぎているため、登録/変更できません。'
                window.scroll({top: 0, behavior: 'smooth'})
                return
            }
            this.eventOnModal = event
            if (this.mydecks.length === 0)
            {
                this.fetchMyDeckList()
            }
            if (event.deck_code) {
                for (const deck of this.mydecks) {
                    if (event.deck_code == deck.deck_code) {
                        this.selectedDeck = deck
                    }
                }
            }

            this.activeModal = 'deckRegistrationModal'
        },
        async registerDeck() {
            let params = new URLSearchParams
            const url = '/event_deck_register'
            params.append('deck_code', this.selectedDeck.deck_code)
            params.append('event_holding_id', this.eventOnModal.id)

            await this.axios.post(url, params)
                .then(() => {
                    this.activeModal = 'deckRegistrationCompleteModal'
                    this.fetchEventList()
                })
                .catch(err => {
                    this.deckRegistError = err.response.data.message
                })
        },
        closeModal() {
            this.eventOnModal = null
            this.selectedDeck = null
            this.activeModal = null
            this.deckRegistError = null
        },
        async fetchMyDeckList() {
            let params = new URLSearchParams
            const url = '/deck_search'
            await this.axios.post(url, params)
                .then(response => {
                    this.mydecks = response.data.deck
                })
                .catch(
                    //返却されるエラーがデッキが存在しない場合のみのため、メッセージは出力しない
                )
        },
        hasRegulationError() {
            if (this.selectedDeck == null || this.eventOnModal == null) {
                return false;
            }

            if (Object.values(this.selectedDeck.available_regulations).includes(this.eventOnModal.regulation_name)) {
                return false;
            }

            return true;
        },
        getDeckLinkURL(deckCode) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/deck/deck.html?deckID=' + deckCode
        },
        getRegisterdDeckLinkURL(deckCode) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/deck/confirm.html/deckID/' + deckCode
        },
        getDeckRegistDate(eventDate) {
            // APIから来たままのdateではnew Date()時にSafariで変換できないため
            // `XXXX-XX-XX XX:XX:XX.XXXXXX` -> `XXXX-XX-XXTXX:XX:XX.XXXXXX`の形にする
            const setEventDate = eventDate.replace(/^(\d+-\d+-\d+)\s([:\d\\.]+)$/g, '$1T$2')
            let retEventDate = new Date(setEventDate)
            retEventDate.setHours(retEventDate.getHours() + 8)
            retEventDate.setMinutes(retEventDate.getMinutes() + 30)
            return retEventDate
        },
        smsIdentify() {
            this.$store.commit('setPhoneNumber', this.player.new_phone_number ? this.player.new_phone_number : this.player.phone_number)
            this.$router.push({name: 'SmsIdentify', params: { isMypage: '1' },})
        },
        displayEntryFee(entryFee) {
            if (!isNaN(entryFee)) {
                return entryFee + '円'
            } else {
                return entryFee
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@use './style/mypage-top';
@use './style/mypage';
</style>
