<template>
    <nav class="pagination">
        <ul class="pagination-list">
            <li class="pagination-item nav left">
                <button
                    class="btn prev"
                    v-if="page.currentPage != 1"
                    @click="click(page.currentPage - 1)"
                >
                    <span>前のページ</span>
                  </button>
                </li>
                <template
                    v-for="index in page.totalPage"
                    :key="index"
                >
                    <li
                        v-if="setPage(index)"
                        class="pagination-item"
                    >
                        <button
                            class="btn"
                            :disabled="page.currentPage === index"
                            @click="click(index)"
                        >
                            {{ index }}
                         </button>
                    </li>
                </template>
            <li class="pagination-item nav right">
                <button
                    class="btn next"
                    v-if="page.currentPage != page.totalPage"
                    @click="click(page.currentPage + 1)"
                >
                    <span>次のページ</span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'pcgPager',
    components: {
    },
    data() {
        return {
            pageCount: 0,
        }
    },
    props: {
        page: {
            type: Object,
            require: true,
        },
    },
    methods: {
        click: function(value) {
            this.pageCount = value
            if (this.pageCount <= 0) {
                this.pageCount = 1
            }
            this.$emit('pageFeed', this.pageCount)
        },
        setPage(index) {
            if(this.page.currentPage >= 1 && this.page.currentPage <= 3 && index <= 5)
            {
                return true
            }
            else if(this.page.currentPage < this.page.totalPage - 1 &&this.page.currentPage >= 4 && index >= this.page.currentPage - 2 && index <= this.page.currentPage + 2)
            {
                return true
            }
            else if(this.page.currentPage == this.page.totalPage - 1 && index >= this.page.currentPage - 3 && index <= this.page.currentPage + 1)
            {
                return true
            }
            else if(this.page.currentPage == this.page.totalPage && index >= this.page.currentPage - 4)
            {
                return true
            }
            else {
                return false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

$btn-size: 48px;
$btn-size-sp: 40px;

.pagination {
    &-list {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-item {
        &:not(:first-child) {
            margin-left: g.$margin;
        }

        & > .btn {
            width: $btn-size;
            height: $btn-size;
            text-align: center;
            font-size: g.$font-size-small;
            font-weight: bolder;
            color: g.$color-font-white;
            background-color: g.$color-black;
            border-radius: g.$border-radius-7;
            cursor: pointer;

            &.prev,
            &.next {
                position: relative;
                &::before,
                &::after {
                    position: absolute;
                    display: inline-block;
                    width: fit-content;
                    height: fit-content;
                    pointer-events: none;
                }
            }
            &.prev {
                &::before {
                    @include g.setIconFont('\e909');
                    transform: rotate(180deg);
                }
            }
            &.next {
                &::after {
                    @include g.setIconFont('\e909');
                }
            }

            &:disabled {
                color: g.$color-black;
                background-color: g.$color-white;
                border: 1px solid g.$color-border-gray;
                cursor: default;
            }
        }
    }

    @include g.pc {
        .btn {
            &.prev,
            &.next {
                width: 118px;
                white-space: nowrap;
            }
            &.prev {
                padding: 0 17px 0 31px;
                &::before {
                    left: 9px;
                }
            }
            &.next {
                padding: 0 31px 0 17px;
                &::after {
                    right: 9px;
                }
            }

            &:hover {
                transition: background-color g.$animation;
            }
            &:not(:disabled):hover {
                opacity: .6;
            }
            &:not(a) {
                will-change: transform;
                transform-origin: center;
                &:not(:disabled):active {
                    transform: scale(0.985);
                }
            }
        }
    }

    @include g.sp {
        .btn {
            width: $btn-size-sp;
            height: $btn-size-sp;
            &.prev,
            &.next {
                & > span {
                    opacity: 0;
                    font-size: 0;
                }
                &::before,
                &::after {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
        .pagination {
            &-item {
                &:not(:first-child) {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
