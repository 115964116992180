<template>
    <pcg-page :page-settings="page" ref="page">
        <div class="c-content c-content-wide-sp">
            <section class="mypage-top">
                <div class="container c-box">
                    <div class="avatar">
                        <img
                            :src="player.avatar_image"
                            alt="アバター"
                        >
                    </div>
                    <div class="info">
                        <p class="league u-mb-sm league-sp">{{ player.current_league }}リーグ</p>
                        <p class="name">{{ player.nickname }}</p>
                        <p class="small">
                            <span class="player-id">プレイヤーID : {{ player.player_id }}</span>
                            <span class="prefecture">エリア : {{ player.prefecture }}</span>
                        </p>

                        <div class="badge u-flex">
                            <ul class="badge-images u-flex u-mr-sm">
                                <li class="badge-image" v-if="player.judge_flg === 1 || player.eo_flg === 1">
                                    <img
                                        src="@/assets/img/mypage/badge_1.png"
                                        alt=""
                                    >
                                </li>
                                <li class="badge-image" v-if="player.judge_flg === 1">
                                    <img
                                        src="@/assets/img/mypage/badge_2.png"
                                        alt=""
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="player-status">
                        <ul class="ps-table">
                            <li class="ps-tr">
                                <div class="u-display-flex u-width-100">
                                    <h4 class="ps-th">チャンピオンシップ<br>ポイント</h4>
                                    <!-- TODO: playerにトレーナーズリーグptのカラムが追加されたら参照 -->
                                    <p class="ps-td">
                                        <template v-if="player.champion_ship_point != null">{{ player.champion_ship_point }}<span class="unit">pt</span></template>
                                        <template v-else>0<span class="unit">pt</span></template><br>
                                        <span class="small league-pt">トレーナーズリーグ：{{ player.csp_from_trainers ? player.csp_from_trainers : '0' }}pt/最大30pt</span>
                                    </p>
                                </div>
                            </li>
                            <li class="ps-tr">
                                <div class="u-display-flex u-width-100">
                                    <h4 class="ps-th">ランキング</h4>
                                    <p class="ps-td ranking">{{ player.current_ranking ? player.current_ranking : '-' }}<span class="unit">位</span></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <error-msg class="u-mb-lg" v-if="eventResultError !== null">
                <p>{{ eventResultError }}</p>
            </error-msg>
            <error-msg class="u-mb-lg" v-if="deckError !== null">
                <p>{{ deckError }}</p>
            </error-msg>
        </div>
        <div class="c-content c-content-wide-sp">
            <div class="c-tab-container">
                <button
                    @click="setTab('result')"
                    type="button"
                    class="c-tab-btn mypage-tab-tab-btn-width"
                    :class="setTabClass('result')"
                >イベント成績</button>
                <button
                    @click="setTab('deck')"
                    type="button"
                    class="c-tab-btn mypage-tab-tab-btn-width"
                    :class="setTabClass('deck')"
                >マイデッキ</button>
            </div>
        </div>
        <template v-if="selectTab === 'result'">
            <div class="c-content eventResult-select">
                <div class="c-input c-input-select">
                    <select v-model="selectYear" id="select1" @change="filerYear">
                        <option :value="null">すべて</option>
                        <option v-for="year in yearList" :key="year" :value="year">{{year}}/01/01〜{{year}}/12/31</option>
                    </select>
                </div>
            </div>
            <div class="c-content" v-if="eventList.length === 0">
                <div class="eventResult-noResult">表示できるイベントがありません。</div>
            </div>
            <event-result-table :resultList="eventList" v-else />
            <div class="eventResult-pager c-content" v-if="page.totalPage > 1">
                <pcg-pager @pageFeed="pageFeed" :page="page"/>
                <div class="eventResult-pager-number" v-if="0 < page.total">
                    {{ page.currentPage }}ページ ／ {{ page.totalPage }}ページ中
                </div>
            </div>
        </template>
        <template v-else>
            <!-- マイデッキ一覧を表示する -->
            <section class="c-content my-deck-content">
                <div class="eventResult-noResult" v-if="deckList.length === 0">
                    表示できるデッキはありません。
                </div>
                <ul class="my-deck-list" v-else>
                    <li
                        v-for="(deck, index) in deckList"
                        :key="index"
                        class="my-deck"
                    >
                        <h3 class="deck-title">{{ deck.deck_name }}</h3>
                        <p class="deck-info">
                            <span class="deck-code">{{ deck.deck_code }}</span>
                            <span class="deck-date">登録日：{{ formatDate(deck.create_at.date) }}</span>
                        </p>
                        <ul class="tags">
                            <li class="tag" v-for="reguration in deck.available_regulations" :key="reguration.id">{{ reguration }}</li>
                        </ul>
                        <div class="buttons">
                            <a
                                :href="getDeckLinkURL(deck)"
                                class="deck-show c-btn c-btn-primary c-btn-arrow"
                                target="_blank"
                            >
                                デッキを見る
                            </a>
                        </div>
                    </li>
                </ul>
            </section>
        </template>
        <div class="c-content eventResult-bottom">
            <button
                @click="back"
                type="button"
                class="c-btn c-btn-primary-outline"
            >戻る</button>
        </div>
        <div class="u-text-center bottom-image">
            <img src="@/assets/img/common/players_logo.png">
        </div>
    </pcg-page>
</template>

<script>
import pcgPage from '@/component/Page.vue'
import pcgPager from '@/component/Pager.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import EventResultTable from '@/views/Mypage/EventResult/EventResultTable.vue'
import dayjs from "dayjs"

export default {
    name: 'EventResultPage',
    components: {
        pcgPage,
        pcgPager,
        errorMsg,
        EventResultTable,
    },
    data() {
        return {
            player: {},
            playerError: null,
            oldestYear: 2017,
            selectYear: null,
            yearList: [],
            deckError: null,
            eventResultError: null,
            page: {
                currentPage: 0,
                totalPage: 0,
                total: 0,
                perPage: 20,
                title: '○○さんのマイページ',
                desc: '',
                breadcrumb: [
                ],
            },
            selectTab: 'result',
            eventList: [],
            deckList: [],
        }
    },
    created: function() {
        this.init()
    },
    watch: {
        $route() {
            location.reload()
        }
    },
    methods: {
        init() {
            const getPlayerApi = '/get_player_account_other'
            let that = this
            let params = new URLSearchParams()

            params.append('player_id', this.$route.params.id)
            this.axios.post(getPlayerApi, params)
            .then(response => {
                let player = response.data.player
                that.player = player
                that.page.title = player.nickname + 'さん（' + player.current_league + 'リーグ）のマイページ'
                that.page.desc = 'ポケモンカードゲーム プレイヤーズクラブ ' + player.nickname + 'さん（' + player.current_league + 'リーグ）のマイページ。'
                that.$refs.page.createPageTitle()
            })
            .catch(() => {
                if (this.$store.state.isBrowserBack === true && this.$router.referrer.name === 'NotFound') {
                    // 404からブラウザバックしていた場合はさらに前の画面へ遷移
                    this.$router.go(-1)
                } else {
                    this.$router.push({name: 'NotFound'})
                }
            })

            this.getEventResults()
            this.getDeckList()
        },
        getEventResults() {
            const getEventResultApi = '/get_event_result'
            let that = this
            let result = []
            let params = new URLSearchParams()

            this.createYearList()
            that.page.currentPage = isNaN(this.$route.query["page"]) ? 1 : Number(this.$route.query["page"])
            that.selectYear = isNaN(this.$route.query["year"]) ? null : Number(this.$route.query["year"])

            params.append('player_id', this.$route.params.id)
            if (this.selectYear) {
                params.append('year', this.selectYear)
            }
            this.axios.post(getEventResultApi, params)
            .then(response => {
                result = response.data.result
                let maxEventNum = result.length
                that.page.total = result.length
                that.page.totalPage = Math.ceil(result.length / this.page.perPage)

                if (maxEventNum <= (this.page.currentPage - 1) * this.page.perPage) {
                    that.eventList = []
                } else if (maxEventNum > this.page.currentPage * this.page.perPage) {
                    that.eventList = result.slice((this.page.currentPage - 1) * this.page.perPage, this.page.currentPage * this.page.perPage)
                } else {
                    that.eventList = result.slice((this.page.currentPage - 1) * this.page.perPage)
                }
            })
            .catch(err => {
                if (err.response.status === 404) {
                    that.page.currentPage = 0
                    that.eventList = []
                } else {
                    that.eventResultError = err.response.data.message
                }
            })
        },
        getDeckList() {
            const getMyDeckApi = '/deck_search'
            let that = this
            let params = new URLSearchParams()

            params.append('player_id', this.$route.params.id)
            this.axios.post(getMyDeckApi,params)
            .then(response => {
                that.deckList = response.data.deck
            })
            .catch(err => {
                if (err.response.status === 404) {
                    that.deckList = []
                } else {
                    that.deckError = err.response.data.message
                }
            })
        },
        createYearList() {
            let currentYear = (new Date).getFullYear()

            this.yearList = [...Array(currentYear - this.oldestYear + 1)].map((_, i) => i + this.oldestYear)
        },
        formatDate(datetime) {
            return dayjs(datetime).format("YYYY/MM/DD");
        },
        filerYear() {
            if (this.selectYear === null) {
                this.$router.push({query:{}})
            } else {
                this.$router.push({query: {year: this.selectYear}})
            }
        },
        pageFeed(count)
        {
            let queryData = Object.assign({}, this.$route.query)
            queryData.page = count
            this.$router.push({query: queryData})
        },
        setTab(name) {
            this.selectTab = name
        },
        setTabClass(name) {
            if (this.selectTab === name) {
                return 'active'
            } else if (this.selectTab === '' && name === 'result') {
                return 'active'
            } else {
                return
            }
        },
        getDeckLinkURL(deck) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/deck/confirm.html/deckID/' + deck.deck_code
        },
        back() {
            history.back()
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '~@/views/Mypage/EventResult/style';
@use '~@/views/Mypage/style/mypage-top';
@use '~@/views/Deck/style/my-deck-content';

main {
    @include g.sp {
        padding: 0px 0 56px;
    }
}

.c-tab-container {
    margin-top: 66px;
    @include g.sp {
        margin-top: 48px;
    }
}

.bottom-image {
    margin-bottom: 42px;
    padding-top: 80px;
}

@include g.pc {
    .my-deck-content {
        .no-event,
        .my-deck-list {
            margin-top: 28px;
        }
    }
}

@include g.sp {
    .my-deck-content {
        .no-event,
        .my-deck-list {
            margin-top: 24px;
        }
    }
    .bottom-image {
        padding-top: 45px;
        margin-bottom: 18px;

        img {
            width: 213px;
        }
    }
    .league-pt {
        width: 180px;
    }
}

.mypage-tab-tab-btn-width {
    @include g.sp {
        width: 100%;
    }
}
</style>
