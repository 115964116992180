<template>
    <pcg-modal
        class="eventApplicationModal"
        :isActive="isActiveModal"
        :bgImage="true" >
        <template v-if="!modalStatus">
            <div class="header u-text-center">
                <div class="status" v-if="isReservoir">{{ 'リザーバー応募受付' }}</div>
                <div class="status" v-else-if="isArrivingFirst">{{ '先着応募受付' }}</div>
                <div class="status" v-else-if="isDrawing">{{ '抽選応募受付' }}</div>
                <div class="c-title c-title-page u-mb">
                        {{ eventData.orgEventTitle ? eventData.orgEventTitle : eventData.eventTitle }}<br>
                        {{ eventData.regulationTitle ? '【' + eventData.regulationTitle + 'レギュレーション】' : '' }}
                </div>
                <div class="league" v-if="eventData.leagueTitle">◆{{ eventData.leagueTitle + 'リーグ' }}</div>
            </div>
            <div class="detail">
                <div class="date u-text-center">
                    <div class="date-day">{{ dataFormat(eventData.eventDate,2) }}</div>
                    <div class="date-time">{{ dataFormat(eventData.eventStartedAt,4) + '～' + (eventData.eventEndedAt ? dataFormat(eventData.eventEndedAt,4) : '') }}</div>
                    <div class="supplement">{{ eventData.eventDaySupply }}</div>
                </div>
                <div class="detail-table">
                    <div class="shopTab">
                        <span class="tab">主催者</span>
                        <span>{{ eventData.eventTypeId === 1 ? '株式会社ポケモン' : eventData.shopName }}</span>
                    </div>
                    <div class="addressTab">
                            <span class="tab">会場</span>
                            <span class="postcode">{{ (eventData.zipCode ? '〒' + zipCodeFormat(eventData.zipCode) : '') + ' ' + (eventData.address ? eventData.address : '') }} </span>
                            <span>{{ eventData.venue }}</span>
                    </div>
                    <div>
                        <template v-if="eventData.entryFee">
                            <span class="tab">参加費</span>{{ displayEntryFee(eventData.entryFee) }}
                        </template>
                        <template v-else>
                            <span class="tab">参加費</span>無料
                        </template>
                    </div>
                    <div>
                        <span class="tab">定員</span>{{ eventData.capacity + '人' }}
                    </div>
                </div>
            </div>
            <div class="eventApplication-start" v-if="isArrivingFirst || isDrawing">
                <div class="title" v-if="isAdditionalEntry">{{ '追加応募受付期間' }}</div>
                <div class="title" v-else-if="isArrivingFirst">{{ '先着応募受付期間' }}</div>
                <div class="title" v-else-if="isDrawing">{{ '抽選応募受付期間' }}</div>
                <div class="date" v-if="!isAdditionalEntry">
                    <span v-if="!isEntrying">{{ dataFormat(eventData.entryStartedAt,1) }} </span>〜
                    <span>{{ dataFormat(eventData.entryEndedAt,1) }}</span>
                </div>
                <div class="date" v-else>
                    <span v-if="!isEntrying">{{ dataFormat(eventData.additionalEntryStartDate,1) }} </span>〜
                    <span>{{ dataFormat(eventData.additionalEntryEndDate,1) }}</span>
                </div>
                <div
                    v-if="isDrawing"
                >
                    <template v-if="eventData.entryTypeId === 2">
                        抽選結果発表：{{ dataFormat(eventData.lotteryDeadline,7) }}&emsp;※発表の時刻は前後する場合がございます。
                    </template>
                    <template v-else>
                        抽選結果発表：{{ dataFormat(eventData.lotteryDeadline,1) }}
                    </template>
                </div>
            </div>
            <div v-if="eventData.event_free_text" class="event-texts eventApplication-text">
                <div v-for="value in eventData.event_free_text" v-bind:key="value.freeTitle">
                    <div v-if="value.freeTitle">
                            <div class="event-text-header">{{ value.freeTitle }}</div>
                            <div class="event-text-content u-text-pre-line">
                                <free-text :freeText="value.freeText"></free-text>
                            </div>
                    </div>
                </div>
            </div>
            <div class="c-title c-title-h3 u-mb-20">同意事項</div>
            <div
                class="eventApplication-agreement u-mb"
                :class="{'eventApplication-agreement-error': isFormParentalConsent}"
                v-if="age < 18"
            >
                <div class="eventApplication-agreement-title">保護者の同意</div>
                <div class="u-mb">
                    <p>18歳未満の方は、保護者の同意が必要です。<br class="u-display-pc">
                    同意を得た上で、チェックボックスにチェックを入れてください。</p>
                </div>
                <div class="eventApplication-agreement-checkbox">
                    <div class="c-checkbox">
                        <input
                            id="agreement1"
                            name="agreement1"
                            type="checkbox"
                            v-model="formParentalConsent" >
                        <label for="agreement1">
                            保護者の同意を得ました
                        </label>
                    </div>
                </div>
                <div class="u-text-warning-icon u-text-small u-text-bold u-mt-sm" v-if="isFormParentalConsent">
                    保護者の同意にチェックをしてください。
                </div>
            </div>
            <div
                class="eventApplication-agreement u-mb"
                :class="{'eventApplication-agreement-error': isFormTermsOfService}"
            >
                <div class="eventApplication-agreement-title">個人情報の取り扱いについて</div>
                <div class="u-mb">
                    <p>
                        <a class="c-link" :href="getPolicyLinkURL()" target="_blank">肖像権および大会参加者の個人情報の取り扱いに関して</a>をご確認の上、同意いただける方は、「同意する」にチェックを入れ、「応募する」ボタンをクリックしてください。
                    </p>
                </div>
                <div class="eventApplication-agreement-title">デッキの公開について</div>
                <div class="u-mb">
                    <p>上位入賞の際、登録いただいたデッキはユーザー名とともに、イベント結果として公開されます。</p>
                    <p>あらかじめご了承ください。</p>
                </div>
                <div class="eventApplication-agreement-checkbox">
                    <div class="c-checkbox">
                        <input
                            id="agreement2"
                            name="agreement2"
                            type="checkbox"
                            v-model="formTermsOfService" >
                        <label for="agreement2">
                            個人情報の取り扱い / デッキの公開について同意する
                        </label>
                    </div>
                </div>
                <div class="u-text-warning-icon u-text-small u-text-bold u-mt-sm" v-if="isFormTermsOfService">
                    同意するにチェックをしてください。
                </div>
            </div>
            <div
                v-if="isReservoir"
                class="eventApplication-reservoir"
            >
                <div class="eventApplication-reservoir-text u-text-danger u-text-center u-text-bold">
                    この受付は、リザーバー応募です。
                </div>
                <div class="status-reservoir u-text-danger">
                    キャンセルが発生した際に、繰上げでの参加のご連絡を<span class="u-text-bold">{{  dataFormat(eventData.eventDate,5) + '18:00' }}</span>までにメールまたはマイページ内「出場予定のイベント」にてお知らせします。
                </div>
            </div>
            <div
                v-else-if="isDrawing"
                class="eventApplication-reservoir"
            >
                <div class="eventApplication-reservoir-text u-text-center">
                    <p>このイベントは抽選応募になります。</p>
                    <p>抽選の結果は<span class="u-text-bold"><template v-if="eventData.entryTypeId === 2">{{ dataFormat(eventData.lotteryDeadline,8) }}</template><template v-else>{{ dataFormat(eventData.lotteryDeadline,3) }}</template></span>までにメールまたはマイページ内「出場予定のイベント」にてお知らせします。</p>
                </div>
                <div class="status-reservoir u-text-danger u-text-center">
                    抽選結果には、「参加確定」<template v-if="eventData.reservoirCount > 0">「<router-link
                        :to="{name: 'Faq', hash: '#reservoir'}"
                        rel="stylesheet"
                        class="c-link"
                        target="_blank"
                    >リザーバー当選</router-link>」</template>「落選」があります。
                </div>
            </div>
            <template v-else>
                <div class="u-text-center u-mb">
                    <p>このイベントは先着応募になります。</p>
                    <p>結果は、マイページ内「出場予定のイベント」で確認できます。</p>
                </div>
                <!--<error-msg class="u-mb-lg">
                    <p>先着で応募しても、すでに定員に達している場合、「<router-link
                        :to="{name: 'Faq', hash: '#reservoir'}"
                        rel="stylesheet"
                        class="c-link"
                        target="_blank"
                    >リザーバー当選</router-link>」となることがあります。</p>
                </error-msg>-->
            </template>
            <template v-if="isTimeOut">
                <div class="u-text-danger u-text-center">
                    <p class="u-text-bold">ただいまサーバーが込み合っています</p>
                    <p>イベントへのエントリーは完了できませんでした。しばらく待って再度アクセスください。</p>
                </div>
            </template>
            <div
                class="status-apply c-gradientBox u-mb-exlg"
                :class="{
                    'priority-top': eventData.priorityFlg === 1 && !isUsedPriority,
                    'priority-bottom': eventData.priorityFlg === 1 && isUsedPriority
                }"
            >
                <template v-if="eventData.priorityFlg === 1 && !isUsedPriority && !isAdditionalEntry">
                    <div class="priorityText">
                        <div class="priorityText-title">第1希望指定</div>
                        <div class="priorityText-text">
                            <p>「第1希望」にチェックをつけたエントリーが、<br>他のエントリーよりも優先して当選しやすくなります。</p>
                            <p>（「第1希望」の設定は、同じ種類のイベントに対して1つまでです。）</p>
                        </div>
                        <div class="eventApplication-agreement-checkbox">
                            <div class="c-checkbox">
                                <input
                                    id="priority_application"
                                    name="priority_application"
                                    type="checkbox"
                                    v-model="formPriorityApplication"
                                >
                                <label for="priority_application">
                                    第1希望
                                </label>
                            </div>
                        </div>
                        <div class="faqLink">
                            <router-link
                                :to="{name: 'Faq', hash: '#priority'}"
                                rel="stylesheet"
                                class="c-link"
                                target="_blank"
                            >第１希望とは</router-link>
                        </div>
                    </div>
                </template>
                <button class="c-btn c-btn-primary" @click="send()">応募する</button>
                <template v-if="eventData.priorityFlg === 1 && isUsedPriority && !isAdditionalEntry">
                    <div class="priorityText">
                        <div class="priorityText-text">
                            <p>すでに同じ種類の別のイベントを「第1希望」で応募しているため、通常応募となります。</p>
                            <p>本エントリーを「第1希望」として応募する場合は、本エントリーの完了後、マイページの「出場予定のイベント」で設定を変更いただけます。</p>
                        </div>
                        <div class="faqLink">
                            <router-link
                                :to="{name: 'Faq', hash: '#priority'}"
                                rel="stylesheet"
                                class="c-link"
                                target="_blank"
                            >第１希望とは</router-link>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-else-if="modalStatus == 'complete'">
            <div class="header header-complete u-text-center">
                <div class="status" v-if="isReservoir">{{ 'リザーバー応募受付完了' }}</div>
                <div class="status" v-else-if="isArrivingFirst">{{ '先着応募受付完了' }}</div>
                <div class="status" v-else-if="isDrawing">{{ '抽選応募受付完了' }}</div>
                <div class="c-title c-title-page u-mb">
                    応募が完了しました！
                </div>
                <p class="under-title">結果は、マイページ内「出場予定のイベント」で確認できます。</p>
                <div class="c-warningBox" v-if="eventData.eventTypeId !== 7 && eventData.deckRegistFlg === 1">
                    <p v-if="!isDrawing">参加が確定していなくても、デッキの登録が可能です。</p>
                    <p><span v-if="isDrawing">当選後、</span><span class="u-text-bold">{{  dataFormat(this.eventData.deckRegistEndedAt,3) }}</span>までにデッキを登録してください。</p>
                </div>
            </div>
            <p class="u-mb u-text-center">登録されているメールアドレスにメールをお送りしました。</p>
            <div class="mailaddress u-mb-lg">
                <p>送信したメールアドレス</p>
                <div class="mail">
                    {{ sendMailAddress }}
                </div>
            </div>
            <template v-if="eventData.priorityFlg === 1 && !isAdditionalEntry">
                <div class="priorityText-complete">
                    <span class="title">「第1希望」について</span>
                    <p class="u-mb">「第1希望」を「ON」にしたエントリーは、自分が応募しているその他のエントリーよりも優先して当選しやすくなります。</p>
                    <p>各シーズンごとのシティリーグなど、複数の会場で行われる同じ種類のイベントへの抽選応募時に、設定できます。</p>
                    <p>抽選応募受付中であれば、マイページの「出場予定のイベント」から、いつでも「第1希望」の設定を変更することが可能です。</p>
                </div>
            </template>
            <div class="u-text-center to-mypage">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline sp-width-100"
                >
                    マイページへ
                </router-link>
            </div>
        </template>
        <template v-else-if="modalStatus == 'registered'">
            <div class="header header-registered u-text-center">
                <div class="status" v-if="isReservoir">{{ 'リザーバー応募受付' }}</div>
                <div class="status" v-else-if="isArrivingFirst">{{ '先着応募受付' }}</div>
                <div class="status" v-else-if="isDrawing">{{ '抽選応募受付' }}</div>
                <div class="c-title c-title-page-league u-mb">
                    {{ '【' + (eventData.orgEventTitle ? eventData.orgEventTitle : eventData.eventTitle) + ' ' + eventData.leagueTitle + '】' }}
                </div>
                <div class="league">◆{{ eventData.leagueTitle + 'リーグ' }}</div>
            </div>
            <error-msg class="u-mb-lg">
                <template v-if="message">
                    <p class="c-warningBox-icon u-text-bold u-text-normal u-mb newLine" >{{ message }}</p>
                </template>
                <template v-else>
                    <p class="c-warningBox-icon u-text-bold u-text-normal u-mb newLine" >応募に失敗しました。画面を再読み込みして再度応募を実施してください。</p>
                </template>
                <p class="u-text-black u-text-small error-msg" v-if="isEntryMessage">応募済みのイベントは、マイページの「出場予定のイベント」からご確認ください。</p>
            </error-msg>
            <div class="u-text-center to-mypage">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline sp-width-100"
                >
                    マイページへ
                </router-link>
            </div>
        </template>
    </pcg-modal>
</template>

<script>
import stateData from '../methods/data'
import pcgModal from '@/component/Modal.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import freeText from '@/component/FreeText.vue'

export default {
    name: 'EventApplicationModal',
    mixins: [stateData],
    components: {
        pcgModal,
        errorMsg,
        freeText
    },
    props: {
        isActiveModal: {
            type: Boolean,
            default: false,
        },
        eventData: {
            type: Array,
            require: true,
        },
        age: {
            type: Number,
            require: true,
        },
        isArrivingFirst: {
            type: Boolean,
            default: false,
        },
        isDrawing: {
            type: Boolean,
            default: false,
        },
        isReservoir: {
            type: Boolean,
            default: false,
        },
        eventHoldingId: {
            type: String,
            require: true,
        },
        isEntrying: {
            type: Boolean,
            default: false,
        },
        isAdditionalEntry: {
            type: Boolean,
            default: false,
        },
        isUsedPriority: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modalStatus: '',
            applicationMethod: '',
            scrollElementId: '',
            formParentalConsent: false,
            formPriorityApplication: true,
            formTermsOfService: false,
            isFormParentalConsent: false,
            isFormTermsOfService: false,
            sendMailAddress: '',
            message: '',
            isEntryMessage: true,
            isTimeOut: false
        }
    },
    computed: {},
    created: function() {
            this.modalStatus = ''
    },
    methods: {
        send() {
            this.isTimeOut = false

            if (this.isInvalid())
            {
                const element = document.getElementById(this.scrollElementId)
                element.scrollIntoView({block: 'center'})
                return
            }

            let params = new URLSearchParams()
            params.append('event_holding_id', this.eventData.id)
            params.append('event_type_id', this.eventData.eventTypeId)

            if (this.isArrivingFirst) {
                params.append('lottery_deadline', '')
            } else if (this.eventData.entryTypeId === 2) {
                params.append('lottery_deadline', this.dataFormat(this.eventData.lotteryDeadline,7))
            } else {
                params.append('lottery_deadline', this.dataFormat(this.eventData.lotteryDeadline,1))
            }

            if (this.eventData.deckRegistFlg) {
                params.append('deck_regist_ended_at', this.dataFormat(this.eventData.deckRegistEndedAt,1))
            } else {
                params.append('deck_regist_ended_at', '')
            }

            params.append('title', (this.eventData.orgEventTitle ? this.eventData.orgEventTitle : this.eventData.eventTitle))
            params.append('league', this.eventData.leagueTitle + 'リーグ')
            params.append('event_date', this.dataFormat(this.eventData.eventDate,2))
            params.append('event_started_at', this.dataFormat(this.eventData.eventStartedAt,4))
            params.append('venue', this.eventData.venue)
            params.append('access', this.eventData.access)
            params.append('entry_fee', this.eventData.entryFee)
            params.append('capacity', this.eventData.capacity + '人')
            if(this.eventData.priorityFlg)
            {
                params.append('priority_application', this.formPriorityApplication ? ( !this.isUsedPriority ? 1 : 0 ) : 0)
            } else {
                params.append('priority_application', 0)
            }

            this.axios.post('/event_participation', params)
            .then(response => {
                this.sendMailAddress = response.data.mailaddress
                this.modalStatus = 'complete'
            })
            .catch(err =>{
                this.err = err.response.data.message
                this.message = err.response.data.message
                this.isEntryMessage = err.response.data.isEntryMessage === '0' ? false:true
                if (err.response.data.code === 408)
                {
                    this.isTimeOut = true
                } else {
                    this.modalStatus = 'registered'
                }
            })

        },
        isInvalid(){
            var retBool = false

            this.isFormParentalConsent = false
            this.isFormTermsOfService = false
            this.scrollElementId = ''
            if (this.age < 18 && !this.formParentalConsent)
            {
                this.isFormParentalConsent = true
                retBool = true
                this.scrollElementId = this.scrollElementId === '' ? 'agreement1': this.scrollElementId
            }
            if (!this.formTermsOfService)
            {
                this.isFormTermsOfService = true
                retBool = true
                this.scrollElementId = this.scrollElementId === '' ? 'agreement2': this.scrollElementId
            }

            return retBool
        },
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? zip : zip.slice(0, 3) + '-' + zip.slice(3)
        },
        displayEntryFee(entryFee) {
            if (!isNaN(entryFee)) {
                return entryFee + '円'
            } else {
                return entryFee
            }
        },
        getPolicyLinkURL() {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/event/players_policy/'
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../style/applicationModal';
</style>
