<template>
  <div class="selectBirthday">
    <label class="u-text-small">生年月日</label>
    <div class="birthday">
      <div class="c-input c-input-select" :class="{ 'c-input-error': hasError }">
        <select v-model="selectedYear">
          <option :value="null">――</option>
          <option
            v-for="year in yearList"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </select>
      </div>
      <span class="u-mr-sm">年</span>
      <div class="c-input c-input-select" :class="{ 'c-input-error': hasError }">
        <select v-model="selectedMonth">
          <option :value="null">―</option>
          <option
            v-for="month in monthList"
            :key="month"
            :value="month"
          >
            {{ month }}
          </option>
        </select>
      </div>
      <span>月</span>
      <div class="c-input c-input-select" :class="{ 'c-input-error': hasError }">
        <select v-model="selectedDay">
          <option :value="null">―</option>
          <option
            v-for="day in dayList"
            :key="day"
            :value="day"
          >
            {{ day }}
          </option>
        </select>
      </div>
      <span>日</span>
    </div>
    <div class="picker-container" v-if="isShowPicker">
      <div class="button-container">
        <button type="button" @click="closeDatePicker">Done</button>
      </div>
      <div class="picker-group">
        <vue-scroll-picker :options="yearList" v-model="selectedYear" />
        <vue-scroll-picker :options="monthList" v-model="selectedMonth" />
        <vue-scroll-picker :options="dayList" v-model="selectedDay" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueScrollPicker } from 'vue-scroll-picker'

/*
  呼び出し
  <select-birthday v-model="birthday"></select-birthday>

  値の定義
  birthday: {
    year: null,
    month: null,
    day: null,
  }
*/
export default {
  name: 'SelectBirthday',

  props: {
    birthday: Object,
    hasError: {
      type: Boolean,
      default: false,
    }
  },

  components: {
    VueScrollPicker
  },

  data() {
    return {
      isShowPicker: false
    }
  },

  computed: {
    birthdayText() {
      if (!this.selectedYear|| !this.selectedMonth || !this.selectedDay) {
        return ''
      }
      return this.selectedYear + '/' + this.selectedMonth + '/' + this.selectedDay
    },
    yearList() {
      const nowYear = new Date().getFullYear()
      const yearList = []
      for (let i = 1900; i <= nowYear; i++) {
        yearList.push(i.toString())
      }
      return yearList
    },
    monthList() {
      const monthList = []
      for (let i = 1; i <= 12; i++) {
        monthList.push(i.toString().padStart(2, '0'))
      }
      return monthList
    },
    dayList() {
      let newDayList
      if (this.selectedMonth === '02') {
        // 4の倍数かつ100の非倍数であれば閏年、もしくは400の倍数であれば閏年
        const isLeapYear = this.selectedYear % 4 === 0
          && this.selectedYear % 100 !== 0
          || this.selectedYear % 400 === 0

        newDayList = this.generateDayList(isLeapYear ? 29 : 28)
      }
      else {
        const hasThirtyDays = ['04', '06', '09', '11'].includes(this.selectedMonth)
        newDayList = this.generateDayList(hasThirtyDays ? 30 : 31)
      }

      if (this.selectedDay !== null && !newDayList.includes(this.selectedDay)) {
        // computed内で直接変更してはならないため関数を呼び出して処理させる
        this.resetDay()
      }

      return newDayList
    },

    selectedYear: {
      get() {
        return this.birthday.year
      },
      set(year) {
        console.log(year)
        this.updateBirthday('year', year)
      }
    },

    selectedMonth: {
      get() {
        return this.birthday.month
      },
      set(month) {
        this.updateBirthday('month', month)
      }
    },

    selectedDay: {
      get() {
        return this.birthday.day
      },
      set(day) {
        this.updateBirthday('day', day)
      }
    },
  },

  methods: {
    openDatePicker() {
      this.isShowPicker = true
    },
    closeDatePicker() {
      this.isShowPicker = false
    },
    updateBirthday(target, value) {
      const newBirthday = { ...this.birthday } // シャローコピー
      newBirthday[target] = value
      this.$emit('update:birthday', newBirthday)
    },

    generateDayList(lastDay) {
      const dayList = []
      for (let i = 1; i <= lastDay; i++) {
        dayList.push(i.toString().padStart(2, '0'))
      }

      return dayList
    },

    resetDay() {
      this.selectedDay = null
    },
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

label {
  display: block;
  margin-bottom: 10px;
}

.birthday {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 420px;
}


@include g.pc {
  span {
    margin-left: -10px;
  }
}

@include g.sp {
  .dummy-select {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;

    &.disabled {
        color: g.$color-black-a025;
    }
    &::before {
        @include g.setIconFont('\e900');
        display: block;
        position: absolute;
        right: 11px;
        width: g.$font-size;
        pointer-events: none;
    }

    div {
        width: 100%;
        height: 100%;
        padding: 10px 20px 10px;
        padding-right: g.$font-size + 11px + 20px;
        appearance: none;
        border: 1px solid g.$color-black-a025;
        border-radius: g.$border-radius-6;
        cursor: pointer;
    }
  }
  .picker-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: g.$color-white;
    padding-right: 20px;
    padding-left: 20px;
    .button-container {
      text-align: right;
      button {
        color: g.$color-link;
      }
    }
    .picker-group {
      display: flex;
    }
  }
  
}
</style>
<style src="vue-scroll-picker/lib/style.css"></style>
