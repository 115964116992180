<template>
    <pcg-page :page-settings="pageSettings">
        <div class="c-content main">
            <h1 class="title">{{ pageSettings.title }}</h1>

            <p>アクセスしようとしたページが見つかりませんでした。</p>
            <p>URLが変更されたか、ページが削除された可能性があります。</p>

            <img
                class="image"
                src="@/assets/img/common/pikachu.png"
            >

            <router-link
                :to="{name: 'EventTop'}"
                class="c-btn c-btn-primary-outline"
            >
                トップページに戻る
            </router-link>
        </div>
    </pcg-page>
</template>

<script>
import pcgPage from '@/component/Page.vue'

export default {
    name: 'NotFound',
    components: {
        pcgPage,
    },
    data() {
        return {
            pageSettings: {
                title: '404 Not Found',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ 404のページ。',
                breadcrumb: [],
            },
        }
    },
    mounted() {
        document.title = this.pageSettings.title + ' | ポケモンカードゲーム プレイヤーズクラブ'
        document.querySelector("meta[name='description']").setAttribute('content', this.pageSettings.desc)
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

::v-deep(.breadcrumb) {
    display: none;
}

p {
    color: g.$color-black;
    font-weight: 500;
}

.main {
    text-align: center;
    @include g.pc {
        padding-top: 65px;
        padding-bottom: 110px;
    }
    @include g.sp {
        padding-top: 18px;
        padding-bottom: 72px;
    }
}

.title {
    font-weight: bold;
    color: #222;
    @include g.pc {
        font-size: 44px;
        line-height: 58px;
        margin-bottom: 48px;
    }
    @include g.sp {
        font-size: 32px;
        line-height: 43px;
        margin-bottom: 40px;
    }
}

.image {
    display: block;
    @include g.pc {
        width: 212px;
        margin: 48px auto 56px;
    }
    @include g.sp {
        width: 196px;
        margin: 32px auto 48px;
    }
}

</style>
