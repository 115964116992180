<template>
    <section class="c-content u-display-pc">
        <nav aria-label="breadcrumb" class="breadcrumb">
            <ol class="breadcrumb-list">
                <li class="breadcrumb-item">
                    <a :href="topUrl">トップ</a>
                </li>
                <li
                    v-for="item in pageSettings.breadcrumb"
                    :key="item.name"
                    class="breadcrumb-item"
                >
                    <router-link
                        v-if="item.path"
                        :to="item.path"
                    >{{ item.name }}</router-link>
                    <span v-else>{{ item.name }}</span>
                </li>
                <li class="breadcrumb-item">{{ pageSettings.title }}</li>
            </ol>
        </nav>
    </section>
</template>

<script>
export default {
    name: 'pcgBreadcrumb',
    props: {
        pageSettings: {
            type: Object,
            require: true,
        },
    },
    computed: {
        topUrl() {
            return process.env.VUE_APP_TRAINERS_WEB_URL
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.breadcrumb {
    margin-bottom: 20px;
    font-size: 11px;

    &-list {
        display: flex;
        align-items: center;
    }
    &-item {
        & > a {
        color: g.$color-font;

        @include g.pc {
            &:hover {
                color: g.$color-link-hover-gray;
            }
        }
        }
        &:not(:last-child) {
            &::after {
                content: '＞';
                margin: 0 7px;
            }
        }
    }
}
</style>
