export default {
    data() {
        return {
            selectedSpPrefecture: [],
            prefectureSpAreaCheck: [],
            prefectureAreaCheck: [],
            areaList: {
                1: {
                    name: '北海道・東北',
                    pref: [1, 2, 3, 4, 5, 6, 7],
                },
                2: {
                    name: '北信越',
                    pref: [15, 16, 17, 18, 20],
                },
                3: {
                    name: '関東',
                    pref: [8, 9, 10, 11, 12, 13, 14],
                },
                4: {
                    name: '中部',
                    pref: [19, 21, 22, 23, 24],
                },
                5: {
                    name: '関西',
                    pref: [25, 26, 27, 28, 29, 30],
                },
                6: {
                    name: '四国・中国',
                    pref: [31, 32, 33, 34, 35, 36, 37, 38, 39],
                },
                7: {
                    name: '九州',
                    pref: [40, 41, 42, 43, 44, 45, 46, 47],
                }
            },
        }
    },
    methods: {
    },
}
