<template>
    <div class="eventTop">
        <div class="eventTop-search c-content-wide">
            <pcg-breadcrumb :page-settings="page" />
            <div class="eventTop-heading c-content">
                <h1>{{ page.title }}</h1>
            </div>
            <div class="c-content">
                <div class="content">
                    <h3>イベントを探す</h3>
                    <event-search-form />
                </div>
            </div>
        </div>
        <renewal-panel />
        <div class="eventTop-join c-content">
            <div class="eventTop-heading c-content">
                <h2>JOIN THE EVENTS!</h2>
                <p>イベントに参加してみよう！</p>
            </div>
            <div class="content">
                <ul class="eventTop-join-link">
                    <li class="link-wrapper">
                        <a
                            href="https://www.pokemon-card.com/event/beginner/"
                            class="link event1"
                        >
                            <span>イベントに参加してみよう！</span>
                        </a>
                    </li>
                    <li class="link-wrapper">
                        <a
                            href="https://www.pokemon-card.com/event/type/"
                            class="link event2"
                        >
                            <span>こんなイベントがあるよ！</span>
                        </a>
                    </li>
                    <li class="link-wrapper">
                        <a
                            href="https://www.pokemon-card.com/event/wcs/"
                            class="link event3"
                        >
                            <span>ワールドチャンピオンシップスへの道</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="eventTop-faq c-content-wide">
            <div class="eventTop-heading c-content">
                <h2>FAQ</h2>
                <p>プレイヤーズクラブに関するよくある質問</p>
            </div>
            <div class="c-content content">
                <ul class="eventTop-faq-link">
                    <li>
                        <router-link :to="{name: 'Faq', hash: '#h2'}" class="link-wrapper">
                            <div class="link account">
                                <span class="link-text">アカウントについて</span>
                            </div>
                            <p class="description">アカウント情報の変更などについてはこちら</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'Faq', hash: '#h5'}" class="link-wrapper">
                            <div class="link event">
                                <span class="link-text">イベント応募について</span>
                            </div>
                            <p class="description">イベントへの応募、キャンセルなどについてはこちら</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'Faq', hash: '#h7'}" class="link-wrapper">
                            <div class="link championship">
                                <span class="link-text">チャンピオンシップ<br>ポイントについて</span>
                            </div>
                            <p class="description">チャンピオンシップポイントについてはこちら</p>
                        </router-link>
                    </li>
                </ul>
                <div class="link-faq">
                    <router-link :to="{name: 'Faq'}" class="c-link c-link-arrow">よくある質問一覧へ</router-link>
                </div>
            </div>
        </div>
        <div class="eventTop-players c-content-wide">
            <div class="eventTop-heading c-content">
                <h2>PLAYERS</h2>
                <p>プレイヤー名鑑</p>
            </div>
            <div class="c-content">
                <div class="content">
                    <p class="bold">プレイヤーの数だけ<br class="u-display-sp">楽しみ方がある！</p>
                    <p>プレイヤーの数だけ楽しみ方があるのが<br class="u-display-sp">ポケモンカードゲーム。</p>
                    <p>さぁ、一緒に楽しもう！</p>
                    <a
                        href="https://www.pokemon-card.com/event/topplayers/"
                        class="c-btn c-btn-primary"
                    >
                        <span>プレイヤー名鑑へ</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="eventTop-ranking c-content-wide">
            <div class="eventTop-heading c-content">
                <h2>RANKING</h2>
                <p>プレイヤーランキング</p>
            </div>
            <div class="c-content content">
                <ul class="eventTop-ranking-link">
                    <li class="link-wrapper">
                        <router-link
                            :to="{name: 'PlayerRanking', hash: '#master'}"
                            class="link"
                        >
                            <span class="link-text">マスター</span>
                        </router-link>
                    </li>
                    <li class="link-wrapper">
                        <router-link
                            :to="{name: 'PlayerRanking', hash: '#senior'}"
                            class="link"
                        >
                            <span class="link-text">シニア</span>
                        </router-link>
                    </li>
                    <li class="link-wrapper">
                        <router-link
                            :to="{name: 'PlayerRanking', hash: '#junior'}"
                            class="link"
                        >
                            <span class="link-text">ジュニア</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="eventTop-results c-content">
            <div class="eventTop-heading c-content">
                <h2>RESULTS</h2>
                <p>イベント 結果発表！</p>
            </div>
            <div class="content">
                <template v-if="event.length">
                    <event-result-list-item
                        v-for="(singleEvent, i) in event"
                        :key="`news-${i}`"
                        :eventData="singleEvent"
                    />
                </template>
                <div class="list-link">
                    <router-link
                        :to="{name: 'EventResultList'}"
                        class="c-btn c-btn-primary"
                    >結果発表イベント一覧へ</router-link>
                </div>
            </div>
        </div>
        <div class="eventTop-news c-content">
            <div class="eventTop-heading c-content">
                <h2>NEWS</h2>
                <p>お知らせ</p>
            </div>
            <div class="content">
                <template v-if="news.length">
                    <news-list-item
                        v-for="(information, i) in news"
                        :key="`news-${i}`"
                        :information="information"
                    />
                </template>
                <div class="list-link">
                    <router-link
                        :to="{name: 'NewsList'}"
                        class="c-btn c-btn-primary"
                    >お知らせ一覧へ</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pcgBreadcrumb from '@/component/Breadcrumb.vue'
import eventSearchForm from '@/component/EventSearchForm/EventSearchForm.vue'
import RenewalPanel from '@/layout/RenewalPanel.vue'
import NewsListItem from '@/views/News/component/NewsListItem.vue'
import EventResultListItem from "./component/EventResultListItem.vue"
export default {
    name: 'EventTopPage',
    components: {
        pcgBreadcrumb,
        eventSearchForm,
        RenewalPanel,
        EventResultListItem,
        NewsListItem,
    },
    data() {
        return {
            page: {
                title: 'イベント',
                pageTitle: 'イベント | ポケモンカードゲーム プレイヤーズクラブ',
                desc: 'ポケモンカードゲームのイベント情報を確認したりイベントへのエントリーができる公式サイトです。',
                breadcrumb: [],
            },
            // 表示確認のために仮のデータを入れています
            event: [],
            news: [],
        }
    },
    computed: {},
    created: function () {
        document.title = this.page.pageTitle;
        document.querySelector("meta[name='description']").setAttribute('content', this.page.desc)
        this.searchEventResults()
        this.searchInformations()
    },
    methods: {
        searchEventResults: function () {
            const targetUrl = "/event_search"
            let params = {
                offset: 0,
                limit: 3,
                order: 4,
                result_resist: 1,
                event_type: [
                    '3:1',
                    '3:2',
                    '3:7',
                ],
            }
            this.axios.get(targetUrl, { params: params }).then((response) => {
                this.event = response.data.event
            })
        },
        searchInformations: function () {
            const targetUrl = "/api/information"
            let params = {
                offset: 0,
                limit: 3,
            }
            this.axios.get(targetUrl, { params: params }).then((response) => {
                this.news = response.data.information
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style/top';
@use './style/top-pc';
@use './style/top-sp';

.Renewal {
    margin-top: 56px;
    @include g.sp {
        margin-top: 40px;
    }
}
</style>
