<template>
    <div class="wrapper">
        <header class="header">
            <div class="header-container">
                <div class="header-nav-logo">
                    <h1>
                        <img src="@/assets/img/header/logo.svg" alt="site logo" />
                    </h1>
                </div>
            </div>
        </header>
        <main class="main">
            <div class="content">
                <h1 class="title">Server Error</h1>
                <p>サーバーにエラーが発生しました。</p>
                <p>申し訳ありませんが、しばらく時間を置いてから<br>再度アクセスしてください。</p>
            </div>
        </main>
        <footer class="footer">
            <button
                @click="toTop()"
                class="footer-toPageTop"
            >
                TO PAGE TOP
            </button>
            <div class="footer-content-wrap">
                <div class="footer-content">
                    <div class="footer-rightNotation">
                        <p>©Pokémon. ©Nintendo/Creatures Inc./GAME FREAK inc.</p>
                        <p>ポケットモンスター・ポケモン・Pokémonは任天堂・クリーチャーズ・ゲームフリークの登録商標です。</p>
                        <p>このホームページに掲載されている内容の著作権は(株)クリーチャーズ、(株)ポケモンに帰属します。 このホームページに掲載された画像その他の内容の無断転載はお断りします。</p>
                        <p>このウェブサイト(https://players.pokemon-card.com/)は、株式会社ポケモン(https://www.pokemon.co.jp/corporate/)が運営しております。</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'ServerError',
    mounted() {
        document.title = 'ServerError | ポケモンカードゲーム プレイヤーズクラブ'
        document.querySelector("meta[name='description']").setAttribute('content', 'ポケモンカードゲーム プレイヤーズクラブ システムエラーのページ。')
    },
    methods: {
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.header {
    &-container {
        position: relative;
        display: flex;
        z-index: g.$z-index-header;
        background-color: g.$color-black;
        background-image: url("~@/assets/img/header/logo-header.png");
        background-position: left top;
        background-repeat: no-repeat;
    }
    @include g.pc {
        &-container {
            height: g.$header-height-pc;
            align-items: center;
            justify-content: space-between;
            padding-right: g.$margin-large;
        }
        &-nav-logo {
            flex-shrink: 0;
            padding: 20px;
            height: 100%;
            img {
                width: 216px;
            }
        }
    }
    @include g.sp {
        position: fixed;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        z-index: g.$z-index-header;
        &-container {
            justify-content: space-between;
            height: g.$header-height-sp;
            background-size: auto 100%;
        }
        &-nav-logo {
            height: 100%;
            padding: 12px 7px 14px;
            & > a {
                display: inline-block;
            }
            a,
            h1,
            img {
                height: 100%;
            }
        }
    }
}

.main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('@/assets/img/common/bg_top.png'), url('@/assets/img/common/bg_bottom.png');
    background-repeat: repeat-x;
    background-position: top center, bottom center;
    @include g.sp {
        background-image: url('@/assets/img/common/bg_top_sp.png'), url('@/assets/img/common/bg_bottom_sp.png');
    }
}

.content {
    height: fit-content;
    text-align: center;
    color: g.$color-black;
    font-weight: 500;
}

.title {
    font-weight: bold;
    color: #222;
    @include g.pc {
        font-size: 44px;
        line-height: 58px;
        margin-bottom: 48px;
    }
    @include g.sp {
        font-size: 32px;
        line-height: 43px;
        margin-bottom: 40px;
    }
}

.footer {
    color: g.$color-font-white;
    margin-top: -56px;
    &-image {
        display: block;
        margin: 0 auto g.$margin-large;
        @include g.sp {
            display: none;
        }
    }
    &-toPageTop {
        display: block;
        width: 195px;
        height: 48px;
        font-size: 18px;
        margin: 0 auto -6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: g.$color-black;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        cursor: pointer;
        @include g.pc {
            &:hover {
                color: rgba(g.$color-white, .7);
            }
        }
    }
    &-content {
        @include g.pc {
            max-width: 1180px;
            min-width: g.$content-width-pc;
            margin-left: auto;
            margin-right: auto;
            padding: 42px 0 44px;
        }
        &-wrap {
            width: 100%;
            background-color: g.$color-black;
            @include g.sp {
                padding: 53px 20px 43px;
            }
        }
    }
    &-rightNotation {
        color: #676767;
        font-size: 10px;
    }
}
</style>
