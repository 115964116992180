import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    data() {
        return {
            // TODO: ステータスの値が不明なため、機能実装時に適切なものへ修正する
            stateText: {
                'drawingBeforeReception': '抽選応募受付前',
                'drawingAccepting': '抽選応募受付中',
                'arrivingFirstBeforeReception': '先着応募受付前',
                'arrivingFirstAccepting': '先着応募受付中',
                'reservoirAccepting': 'リザーバー応募受付中',
                'lotteryWaiting': '抽選待ち',
                'entried': '受付済み',
                'beforeAdditionalAccepting': '先着応募受付前',
                'beforeAdditionalReservoirAccepting': 'リザーバー応募受付前',
                'maxEntry': '満席',
                'close': 'このイベントの受付は終了しました',
                'cancel': 'このイベントは中止になりました',
            },
        }
    },
    computed: {
        isDetail() {
            return true
        },
        isReservoir() {
            if (
                this.state == 'drawingBeforeReception' ||
                this.state == 'arrivingFirstBeforeReception'
            ) {
                return true
            }
            return false
        },
        isApply() {
            if (
                this.state == 'drawingBeforeReception' ||
                this.state == 'arrivingFirstBeforeReception' ||
                this.state == 'entried' ||
                this.state == 'lotteryWaiting' ||
                this.state == 'beforeAdditionalAccepting' ||
                this.state == 'beforeAdditionalReservoirAccepting' ||
                this.state == 'maxEntry' ||
                this.state == 'close' ||
                this.state == 'cancel'
            ) {
                return false
            }
            return true
        },
        isCancel() {
            return this.state == 'cancel'
        },
        isClose() {
            return this.state == 'close'
        },
        statusClass() {
            switch (this.state) {
                case 'drawingBeforeReception':
                case 'arrivingFirstBeforeReception':
                    return 'beforeReception'
                case 'drawingAccepting':
                case 'arrivingFirstAccepting':
                    return 'accepting'
                case 'lotteryWaiting':
                case 'close':
                        return 'close'
                case 'maxEntry':
                    return 'maxEntry'
                case 'beforeAdditionalAccepting':
                case 'beforeAdditionalReservoirAccepting':
                    return 'beforeAdditionalAccepting'
                case 'cancel':
                    return 'cancel'
                default:
                    return ''
            }
        },
    },
    methods: {
        dataFormat(eventDate,formatID) {
            if (typeof eventDate === 'undefined' || eventDate === null
                || typeof eventDate.date === 'undefined' || eventDate.date === null) {
                return ''
            }
            let dateFormatDate = ''
            const date = eventDate.date

            dayjs.locale(ja)
            if (formatID === 1) {
                dateFormatDate = dayjs(date).format('YYYY年MM月DD日(ddd) HH:mm')
            } else if (formatID === 2) {
                dateFormatDate =  dayjs(date).format('YYYY年MM月DD日(ddd)')
            } else if (formatID === 3) {
                dateFormatDate =  dayjs(date).format('YYYY/MM/DD(ddd) HH:mm')
            } else if (formatID === 4) {
                dateFormatDate =  dayjs(date).format('HH:mm')
            } else if (formatID === 5) {
                // リザーバー受付(１日前に設定)
                dateFormatDate =  dayjs(date).subtract(1, 'd').format('YYYY/MM/DD(ddd)')
            } else if (formatID === 6) {
                dateFormatDate =  dayjs(date).format('ddd')
            } else if (formatID === 7) {
                // 自動抽選の表示抽選日時（実際の抽選日時+6時間に設定）
                dateFormatDate = dayjs(date).add(6, 'h').format('YYYY年MM月DD日(ddd) HH:mm')
            } else if (formatID === 8) {
                // 自動抽選の表示抽選日時（実際の抽選日時+6時間に設定）
                dateFormatDate = dayjs(date).add(6, 'h').format('YYYY/MM/DD(ddd) HH:mm')
            } else {
                dateFormatDate =  dayjs(date).format('YYYY/MM/DD HH:mm')
            }

            return dateFormatDate
        },
    },
}
