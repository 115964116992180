<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <template v-if="news.length">
                <news-list-item
                    v-for="(information, i) in news"
                    :information="information"
                    :key="`information${i}`"
                    :routePage="backPageName"
                />
                <pcg-pager
                    :page="pagination"
                    @pageFeed="setPage"
                    v-if="pagination.totalPage > 1"
                />
                <div class="u-text-center" v-if="0 < pagination.totalCount">
                    {{ pagination.currentPage }}ページ ／ {{ pagination.totalPage }}ページ中
                </div>
            </template>
            <div class="u-flex u-flex-center mypage-margin">
                <router-link
                    :to="backPageName ? {name: backPageName} : {name: 'EventTop'}"
                    class="c-btn c-btn-primary-outline"
                >戻る</router-link>
            </div>
        </div>
    </system-page>
</template>

<script>
import systemPage from '@/component/SystemPage.vue'
import pcgPager from '@/component/Pager.vue'
import NewsListItem from './component/NewsListItem.vue'

export default {
    name: 'NewsListPage',
    components: {
        systemPage,
        pcgPager,
        NewsListItem,
    },
    data() {
        return {
            page: {
                title: 'お知らせ一覧',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ お知らせ一覧のページ。',
                breadcrumb: [],
            },
            pagination: {
                currentPage: 1,
                totalPage: 1,
                totalCount: 0,
                modalTotal: 0,
                perPage: 20,
            },
            news: [],
            backPageName: '',
        }
    },
    created: function () {
        this.init()
        this.searchNews()
    },
    methods: {
        init() {
            if (this.$route.query["routePage"]){
                this.backPageName = this.$route.query["routePage"]
                this.page.breadcrumb = [
                    { name: 'マイページ', path: '/Mypage' },
                ]
            }
            else {
                this.page.breadcrumb = [
                    { name: 'イベント', path: '/' },
                ]
            }
        },
        searchNews() {
            const targetUrl = "/api/information"

            let params = {
                offset: this.pagination.offset,
                limit: this.pagination.perPage,
            }
            this.axios.get(targetUrl, { params: params }).then((response) => {
                this.news = response.data.information
                this.pagination.totalCount = response.data.count
                this.pagination.totalPage = Math.ceil(this.pagination.totalCount / this.pagination.perPage) || 1
            })
        },
        setPage(newPage) {
            this.pagination.currentPage = newPage
            this.pagination.offset = (newPage - 1) * this.pagination.perPage
            this.searchNews()
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.pagination {
    margin-top: 72px;
    margin-bottom: 28px;
}
.mypage-margin {
    margin-top: 60px;
}
</style>
