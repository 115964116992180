<template>
  <span class="tag" :class="style">
    {{ name }}
  </span>
</template>

<script>
export default {
  name: 'DeckSubmissionStatus',
  props: {
    deckRegisterFlg: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {}
  },
  computed: {
      name() {
          if (this.deckRegisterFlg) {
              return 'デッキ登録済み'
          } else {
              return 'デッキ未登録'
          }
      },
      style() {
          if (this.deckRegisterFlg) {
              return {
                  'deckRegistered': true,
              }
          } else {
              return {
                  'deckNotRegistered': true
              }
          }
      }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.tag {
    @include g.pc {
      width: 144px;
      height: 32px;
      line-height: 32px;
    }

    border-radius: 4px;
    background: #fff;
    border: 1px solid;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:not(:last-child) {
        margin-right: g.$margin-small;
    }

    &.deckNotRegistered {
        border-color: g.$color-error-red;
        background: g.$color-error-red;
        color: g.$color-font-white;
        @include g.sp {
          height: 28px;
          line-height: 28px;
          padding: 0 20px;
        }
    }

    &.deckRegistered {
        border-color: g.$color-black-a060;
        color: g.$color-black-a060;

        @include g.sp {
        height: 28px;
        line-height: 28px;
        padding: 0 20px;
      }
    }
}
</style>
