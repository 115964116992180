<template>
    <system-page :page-settings="page">
        <div class="c-content u-text-center complete">
            <div class="c-warningBox">
                <p class="u-text-warning-icon">
                    メールアドレス変更できません。
                </p>
            </div>
            <div class="complete-text">
                <p class="u-mb-lg">認証コードの期限が切れています。</p>
            </div>
            <div class="to-mypage">
                <router-link :to="{name: 'Mypage'}" class="c-btn c-btn-primary-outline">マイページ</router-link>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
    components: {
        SystemPage,
    },
    data: function() {
        return {
            page: {
                title: 'メールアドレス変更失敗',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ メールアドレス変更失敗のページ。',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' }
                ],
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style.scss';

.complete {
    &-text {
        margin-top: 30px;
        text-align: center;
    }
    .c-btn {
        margin-top: 55px;
    }

    @include g.sp {
        &-text {
            text-align: left;
        }
        .c-btn {
            margin-top: 50px;
        }
    }
}
.to-mypage {
    line-height: 1;
}
</style>
