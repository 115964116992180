<template>
    <error-msg v-if="displayMsg">
        <p>
            【注意】2024年1月26日(金)以降のシティリーグ シーズン2に参加される方は、
            デッキ登録で登録するデッキに、レギュレーションマークにEと書かれたカードが含まれないようにご注意ください。
        </p>
    </error-msg>
</template>

<script>
import dayjs from 'dayjs'
import errorMsg from '@/component/ErrorMsg.vue'

export default {
    name: 'CityLeagueMessage',
    components: {
        errorMsg
    },
    props: {
        event: {
            type: Object,
            require: true,
        },
    },
    data() {
    },
    computed: {
        displayMsg() {
            const eventId = 343
            const eventDate = dayjs(this.event.event_date.date).subtract(1, 'D').format('YYYYMMDDHHmmss')
            const startData = dayjs('2024/01/26').subtract(1, 'D').format('YYYYMMDDHHmmss')
            const endData   = dayjs('2024/02/04').subtract(1, 'D').format('YYYYMMDDHHmmss')

            if (this.event.event_id === eventId && startData <= eventDate && eventDate <= endData) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
</style>
