<template>
    <table class="c-rankTable">
        <thead>
            <tr class="c-rankTable-head">
                <th class="tr-width-rank" scope="col">順位</th>
                <th class="tr-width-point" scope="col">獲得<br>ポイント</th>
                <th class="tr-width-user" scope="col">ユーザー名</th>
                <th class="tr-width-area area" scope="col">エリア</th>
                <th class="tr-width-deck" scope="col">参加<br class="u-display-sp">デッキ</th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="user in rankList"
                :key="user.player_id"
                class="c-rankTable-row">
                <td
                    class="rank participation"
                    v-if="user.rank === 999"
                >
                    <span>参加</span>
                </td>
                <td
                    class="rank"
                    :class="`rank-${user.rank}`"
                    v-else
                >
                    <span>{{ user.rank }}</span>
                    位</td>
                <td class="point" v-if="user.point > 0">
                    <span>{{ user.point }}</span>pt
                </td>
                <td class="point" v-else>
                    <span>-</span>
                </td>

                <td class="user">
                    <router-link
                        v-if="user.show_profile"
                        :to="{ name: 'OtherPlayerMypage', params: {'id': user.player_id} }"
                        class="user-link"
                    >
                        <span class="user-name">{{ user.name }}</span>
                        <span class="user-id">プレイヤーID：{{ user.player_id }}</span>
                        <span class="user-area">エリア：{{ user.area }}</span>
                    </router-link>
                    <div v-else>
                        <span class="user-name">{{ user.name }}</span>
                        <span class="user-id">プレイヤーID：{{ user.player_id }}</span>
                        <span class="user-area">エリア：{{ user.area }}</span>
                    </div>
                </td>
                <td class="area">{{ user.area }}</td>
                <td class="deck">
                    <a
                        :href="`https://www.pokemon-card.com/deck/confirm.html/deckID/${user.deck_id}`"
                        target="_blank"
                        class="deck-link"
                        v-if="(user.deck_id !== null) && (user.deck_id !== '')"
                    >
                        デッキ<br class="u-display-sp">をみる
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'EventResultRankTable',
    props: {
        rankList: {
            required: true,
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-rankTable-row {
    .rank {
        padding-left: 0;
        padding-right: 0;
        &.participation {
            span {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
    .deck {
        padding-left: 0;
        padding-right: 0;
    }
}
.tr-width-rank {
    width: 94px;
}
.tr-width-point {
    width: 94px;
}
.tr-width-user {
    width: 308px;
}
.tr-width-area {
    width: 110px;
}
.tr-width-deck {
    width: 154px;
}
</style>
