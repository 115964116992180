<template>
    <pcg-page :page-settings="page">
        <h1 class="c-title c-title-page" v-html="page.breakedTitle"></h1>
        <div class="c-content c-content-wide-sp">
            <div class="c-tab-container">
                <label
                    class="c-tab-btn u-display-inline-block tab-league"
                    :class="{ 'active': activeTab === '#master' }"
                >
                    <input
                        type="radio"
                        name="tab"
                        v-model="activeTab"
                        value="#master"
                    >
                    <span>マスター</span>
                </label>
                <label
                    class="c-tab-btn u-display-inline-block tab-league"
                    :class="{ 'active': activeTab === '#senior' }"
                >
                    <input
                        type="radio"
                        name="tab"
                        v-model="activeTab"
                        value="#senior"
                    >
                    <span>シニア</span>
                </label>
                <label
                    class="c-tab-btn u-display-inline-block tab-league"
                    :class="{ 'active': activeTab === '#junior' }"
                >
                    <input
                        type="radio"
                        name="tab"
                        v-model="activeTab"
                        value="#junior"
                    >
                    <span>ジュニア</span>
                </label>
            </div>
        </div>
        <div class="c-content">
            <table v-if="ranking && ranking.length !== 0" class="c-rankTable">
                <thead>
                <tr class="c-rankTable-head">
                    <th class="rank" scope="col">順位</th>
                    <th class="name" scope="col">ユーザー名</th>
                    <th class="area" scope="col">エリア</th>
                    <th class="csp" scope="col">ポイント</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(value, index) in ranking"
                    :key="`${value.currentRanking}-${value.playerId ? value.playerId : index}`"
                    class="c-rankTable-row">
                    <td
                        class="rank"
                        :class="`rank-${value.currentRanking}`"
                    >
                        <span>{{ value.currentRanking }}</span>位
                    </td>
                    <td class="user">
                        <router-link
                            v-if="value.publicFlg"
                            :to="{
                                name: 'OtherPlayerMypage',
                                params: { id: value.playerId },
                            }"
                            class="user-link user-display"
                        >
                            <div class="user-display-left">
                                <img
                                    class="user-avatar"
                                    :src="value.avatarImage"
                                >
                            </div>
                            <div class="user-display-right">
                                <span class="user-name">{{ value.nickname }}</span>
                                <span class="user-id">プレイヤーID：{{ value.playerId }}</span>
                                <span class="user-area">エリア：{{ getPrefName(value.prefectureId) }}</span>
                            </div>
                        </router-link>
                        <div
                            v-else
                            class="user-display"
                        >
                            <div class="user-display-left">
                                <img
                                    class="user-avatar"
                                    :src="value.avatarImage"
                                >
                            </div>
                            <div class="user-display-right">
                                <span class="user-name">{{ value.nickname }}</span>
                                <span class="user-id">プレイヤーID：{{ value.playerId }}</span>
                                <span class="user-area">エリア：{{ getPrefName(value.prefectureId) }}</span>
                            </div>
                        </div>
                    </td>
                    <td class="area">
                        {{ getPrefName(value.prefectureId) }}
                    </td>
                    <td class="point">
                        <span class="point">{{ value.championShipPoint }}</span> <span class="unit">pt</span>
                    </td>
                </tr>
                </tbody>
            </table>
            <no-result v-else>
                表示できるランキングがありません。
            </no-result>
            <div class="ranking-pager" v-if="pagination.totalPage > 1">
                <pcg-pager
                    :page="pagination"
                    @pageFeed="setPage"
                ></pcg-pager>
                <div class="ranking-pager-number" v-if="0 < pagination.totalCount">
                    {{ pagination.currentPage }}ページ ／ {{ pagination.totalPage }}ページ中
                </div>
            </div>
            <div class="u-text-center to-top">
                <router-link
                    :to="{name: 'EventTop'}"
                    class="c-btn c-btn-primary-outline"
                >
                    イベントトップへ
                </router-link>
            </div>
        </div>
    </pcg-page>
</template>

<script>
import pcgPage from '@/component/Page.vue'
import pcgPager from '@/component/Pager.vue'
import noResult from '@/component/NoResult.vue'

export default {
    name: 'PlayerRankingPage',
    components: {
        pcgPage,
        pcgPager,
        noResult,
    },
    data() {
        return {
            page: {
                title: 'プレイヤーランキング',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ プレイヤーランキングのページ。',
                breakedTitle: 'プレイヤー<br class="u-display-sp">ランキング',
                breadcrumb: [
                    { name: 'イベント', path: '/' },
                ],
            },
            pagination: {
                currentPage: 1,
                totalPage: 1,
                totalCount: 0,
                offset: 0,
                perPage: 20,
            },
            activeTab: null,
            ranking: null,
        }
    },
    watch: {
        activeTab: function(newValue) {
            this.$router.push({ hash: newValue })
            this.pagination = {
                totalCount: 0,
                totalPage: 1,
                currentPage: 1,
                offset: 0,
                perPage: 20,
            }
            this.ranking = null
            this.search()
        },
    },
    created() {
        this.activeTab = this.$route.hash || '#master'
    },
    methods: {
        search() {
            const api = '/get_player_ranking'
            const params = {
                league: this.activeTab.slice(1),
                offset: this.pagination.offset,
            }
            this.axios.get(api, {params})
            .then(response => {
                this.pagination.totalCount = response.data.count
                this.pagination.totalPage = Math.ceil(this.pagination.totalCount / this.pagination.perPage) || 1
                this.ranking = response.data.result
            })
            .catch(err => {
                this.error = err.response.data.message
            })
        },
        setPage(newPage) {
            this.pagination.currentPage = newPage
            this.pagination.offset = (newPage - 1) * this.pagination.perPage
            this.search()
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        getPrefName(id) {
            return this.$store.getters.getPrefectureName(id)
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-title-page {
    margin-bottom: 36px;
}

.c-content {
    &:last-child {
        padding-bottom: 72px;
    }
    @include g.pc {
        width: g.$content-width-pc-sm;
    }
    @include g.sp {
        &:last-child {
            padding-bottom: 74px;
        }
    }
}

.c-tab-container {
    margin-bottom: 32px;
    @include g.sp {
        margin-bottom: 24px;
    }

    .tab-league {
        @include g.pc {
            width: 128px;
            user-select: none;
            transition: g.$animation;
            &.c-tab-btn.active {
                cursor: default;
            }
            &:not(.active):hover {
                color: rgba(g.$color-black-a025, .7);
                border-color: rgba(g.$color-black-a025, .7);
            }
        }
        @include g.sp {
            width: 107px;
        }
    }
}

.c-rankTable-head {
    @include g.pc {
        .rank {
            width: 122px;
        }
        .name {
            width: 383px;
        }
        .area {
            width: 130px;
        }
        .point {
            width: 125px;
        }
    }
}
.c-rankTable-row {
    .rank {
        @include g.sp {
            min-width: 70px;
        }
    }
    .point {
        @include g.sp {
            min-width: 70px;
            .point {
                font-size: 12px;
                margin-right: 0;
            }
            .unit {
                font-size: 10px;
                font-weight: normal;
            }
        }
    }
    .user {
        & > a,
        & > div {
            @include g.sp {
                padding: 16px 10px 16px 8px;
            }
        }
        & > .user-display {
            display: flex;
            @include g.pc {
                align-items: center;
            }
            & > .user-display {
                &-left {
                    flex-shrink: 0;
                    width: 72px;
                    height: 72px;
                    margin-right: 12px;
                    @include g.sp {
                        width: 44px;
                        height: 44px;
                        margin-right: 6px;
                    }
                    .user-id {
                        color: g.$color-black-a060;
                    }
                }
                &-right {
                    flex-basis: auto;
                    display: flex;
                    flex-direction: column;
                    .user-name {
                        margin-bottom: 0;
                    }
                    .user-id, .user-area {
                        color: g.$color-black-a060;
                    }
                }
            }
        }
        & > .user-link {
            &::after {
                @include g.sp {
                    right: 7px;
                    font-size: 10px;
                }
            }
        }
    }
}

.ranking-pager {
    width: 100%;
    padding-top: 72px;
    text-align: center;
    &-number {
        padding-top: 28px;
    }
    @include g.sp {
        padding-top: 60px;
        &-number {
            padding-top: 24px;
        }
    }
}
.to-top {
    margin-top: 72px;
    line-height: 1;
    @include g.sp {
        margin-top: 56px;
    }
}
</style>
