<template>
    <router-link
        v-if="eventData.event_holding_id"
        :to="{ name: 'EventResult', params: { id: eventData.event_holding_id } }"
        class="eventListItem"
    >
        <div class="left">
            <div class="eventDate">
                <div
                    class="date"
                    :class="{
                        sat: eventData.event_date_week === '土',
                        sun: eventData.event_date_week === '日' || eventData.holiday_flg === 1,
                    }"
                >
                    <span class="day">{{ eventData.event_date }}</span>
                    <span class="week">{{"（" + eventData.event_date_week + "）"}}</span>
                    <span v-if="eventData.event_ended_date" class="tilde">～</span>
                </div>
                <template v-if="!eventData.event_ended_date">
                    <span class="time">{{
                        eventData.event_started_at + " ～ " + eventData.event_ended_at
                    }}</span>
                </template>
                <template v-else>
                    <div
                        class="date"
                        :class="{
                            sat: eventData.event_ended_week === '土',
                            sun: eventData.event_ended_week === '日',
                        }"
                    >
                        <span class="day">{{ eventData.event_ended_date }}</span>
                        <span class="week">{{
                            "（" + eventData.event_ended_week + "）"
                        }}</span>
                    </div>
                </template>
                <span class="pref">{{ eventData.prefecture_name }}</span>
            </div>
        </div>
        <div class="right">
            <div class="title">{{ eventData.event_title }}</div>
            <div class="league">◆ {{ eventData.leagueName }}</div>
            <div class="detail">
                <span class="regulation">{{ eventData.regulation }}</span>
                <span class="entryFee" v-if="eventData.entry_fee"> / 参加費 {{ displayEntryFee(eventData.entry_fee) }}</span>
                <span class="entryFee" v-else> / 参加費 {{ "無料" }}</span>
                <span class="capacity"> / 定員 {{ eventData.capacity }}人</span>
            </div>
            <div class="address-box">
                <div
                    v-if="eventData.shop_id"
                    :class="eventData.shop_term === 1 ? 'shop' : 'organizer'"
                >
                    <router-link
                        :to="`/event/search/${eventData.shop_id}/list`"
                        class="c-link"
                    >
                        {{ eventData.shop_name }}
                    </router-link>
                </div>
                <div
                    v-else-if="eventData.event_type === 1"
                    class="shop"
                >
                        {{ '株式会社ポケモン' }}
                </div>
                <div
                    v-else
                    :class="eventData.shop_term === 1 ? 'shop' : 'organizer'"
                >
                    {{ eventData.shop_name }}
                </div>
                <div
                    v-if="eventData.event_attr_id !== 4"
                    class="address"
                >
                    <span class="building">{{ displayAddress(eventData) }}</span>
                </div>
                <div class="address" v-if="eventData.venue !== null && eventData.venue !== ''">
                    <span :class="{building: eventData.event_attr_id === 4}">{{ eventData.venue }}</span>
                </div>
            </div>
        </div>
    </router-link>
    <div class="eventListItem" v-else>
        <div class="left">
            <div class="eventDate">
                <div
                    class="date"
                    :class="{
                        sat: eventData.event_date_week === '土',
                        sun: eventData.event_date_week === '日' || eventData.holiday_flg === 1,
                    }"
                >
                    <span class="day">{{ eventData.event_date }}</span>
                    <span class="week">{{"（" + eventData.event_date_week + "）"}}</span>
                    <span v-if="eventData.event_ended_date" class="tilde">～</span>
                </div>
                <template v-if="!eventData.event_ended_date">
                    <span class="time">{{
                        eventData.event_started_at + " ～ " + eventData.event_ended_at
                    }}</span>
                </template>
                <template v-else>
                    <div
                        class="date"
                        :class="{
                            sat: eventData.event_ended_week === '土',
                            sun: eventData.event_ended_week === '日',
                        }"
                    >
                        <span class="day">{{ eventData.event_ended_date }}</span>
                        <span class="week">{{
                            "（" + eventData.event_ended_week + "）"
                        }}</span>
                    </div>
                </template>
                <span class="pref">{{ eventData.prefecture_name }}</span>
            </div>
        </div>
        <div class="right">
            <div class="title">{{ eventData.event_title }}</div>
            <div class="league">◆ {{ eventData.leagueName }}</div>
            <div class="detail">
                <span class="regulation">{{ eventData.regulation }}</span>
                <span class="entryFee" v-if="eventData.entry_fee"> / 参加費 {{ displayEntryFee(eventData.entry_fee) }}</span>
                <span class="entryFee" v-else> / 参加費 {{ "無料" }}</span>
                <span class="capacity"> / 定員 {{ eventData.capacity }}人</span>
            </div>
            <div class="address-box">
                <div
                    v-if="eventData.shop_id"
                    :class="eventData.shop_term === 1 ? 'shop' : 'organizer'"
                >
                    <router-link
                        :to="`/event/search/${eventData.shop_id}/list`"
                        class="c-link"
                    >
                        {{ eventData.shop_name }}
                    </router-link>
                </div>
                <div
                    v-else-if="eventData.event_type === 1"
                    class="shop"
                >
                        {{ '株式会社ポケモン' }}
                </div>
                <div
                    v-else
                    :class="eventData.shop_term === 1 ? 'shop' : 'organizer'"
                >
                    {{ eventData.shop_name }}
                </div>
                <div
                    v-if="eventData.event_attr_id !== 4"
                    class="address"
                >
                    <span class="building">{{ displayAddress(eventData) }}</span>
                </div>
                <div class="venue" v-if="eventData.venue !== null && eventData.venue !== ''">
                    {{ eventData.venue }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        eventData: {
            require: true,
        },
    },
    components: {},
    methods: {
        displayEntryFee(entryFee) {
            if (!isNaN(entryFee)) {
                return entryFee + '円'
            } else {
                return entryFee
            }
        },
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? '〒' + zip : '〒' + zip.slice(0, 3) + '-' + zip.slice(3)
        },
        displayAddress(eventData) {
            let address = ''
            if (this.$store.state.isSp) {
                address += eventData.address !== null && eventData.address !== '' ? eventData.address + ' ' : ''
            } else {
                address += eventData.event_type === 2 || (eventData.zip_code === null || eventData.zip_code === 0 || eventData.zip_code === '') ? '' : this.zipCodeFormat(eventData.zip_code) + ' '
                address += eventData.address !== null && eventData.address !== '' ? eventData.address + ' ' : ''
            }
            return address
        },
    },
};
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '../style/listItem';

.address-box {
    .pref {
        display: none;
        @include g.sp {
            display: inline-block;
            white-space: break-spaces;
        }
    }
}
</style>
