<template>
    <system-page :page-settings="page">
        <div class="c-content u-text-center complete">
            <error-msg
                :message="message"
            >
            </error-msg>
            <div class="complete-text">
                <p class="u-mb-lg">再度「新規会員登録」を行ってください。</p>
            </div>
            <div class="to-register">
                <router-link :to="{name: 'MailRegister'}" class="c-btn c-btn-primary-outline">新規会員登録</router-link>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
export default {
    components: {
        SystemPage,
        errorMsg,
    },
    data: function() {
        return {
            page: {
                title: '新規会員登録エラー',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ 新規会員登録エラーのページ。',
                breadcrumb: [],
            },
            message: ''
        }
    },
    created: function() {
        this.init()
    },
    methods: {
        init()
        {
            if (this.$route.params.message) {
                this.message = this.$route.params.message
            } else {
                this.$router.push({name: 'NotFound'})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.to-register {
    line-height: 1;
}

.complete {
    &-text {
        margin-top: 30px;
        text-align: center;
    }

    @include g.pc {
        .c-btn {
            margin-top: 55px;
        }
    }

    @include g.sp {   
        .c-btn {
            margin-top: 50px;
        }
    }
}
</style>
