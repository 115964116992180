<template>
  <system-page :page-settings="page">
    <error-msg class="u-mb-lg" v-if="error">
        <p>{{ error }}</p>
    </error-msg>
    <div class="c-content withdraw">
      <div class="withdraw-text">
        <p class="u-text-large u-mb">本当に退会しますか？</p>
        <p class="u-mb-exlg withdraw-text-left">
          退会するとイベントのエントリー情報や、<br>
          これまで獲得された全てのチャンピオンシップポイントが消失し、無効となります。
        </p>
      </div>
      <div class="withdraw-button">
        <button
          @click="withdraw()"
          class="c-btn c-btn-primary u-mb"
        >退会する</button>
        <router-link
          to="/mypage/profile"
          class="c-btn c-btn-primary-outline"
        >戻る</router-link>
      </div>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'

export default {
  name: 'WithdrawConfirmPage',
  components: {
    SystemPage,
    errorMsg,
  },
  data: function() {
    return {
      page: {
        title: '退会',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ 退会のページ。',
        breadcrumb: [
          { name: 'マイページ', path: '/mypage' },
          { name: 'ユーザー情報', path: '/mypage/profile' },
        ],
        error: null,
      }
    }
  },
  methods: {
    withdraw() {
      this.axios.post('/withdraw')
      .then(() => {
        this.$store.commit('setLogoutState')
        this.$router.push({name: 'WithdrawComplete'})
      })
      .catch(err => {
        if (err.response && err.response.data.status === 401) {
            this.$store.commit('setLogoutState')
            this.$router.push({name: 'Login'})
        }
        this.error = err.response.data.message
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@use './style.scss';
</style>
