<template>
    <div class="Renewal c-content">
        <div class="content">
            <router-link :to="{name: 'MailRegister'}" class="c-btn c-btn-primary">新規会員登録はこちら</router-link>
            <p class="guidance">既存の会員の方は、<br class="u-display-sp">会員移行手続きが可能です。</p>
            <router-link :to="{name: 'MigrationSetting'}" class="c-btn c-btn-primary">会員移行はこちら</router-link>
            <a
                href="https://www.pokemon-card.com/event/players_activate/"
                class="c-link c-link-arrow"
            >会員移行手続き方法</a>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

$bgColor: #FFF1EF;

.Renewal {
    & .content {
        background-color: $bgColor;
        border-radius: 8px;
        text-align: center;
        & > .notice {
            @include g.font-lg;
            font-weight: bold;
            border-bottom: 1px solid g.$color-black;
        }
        & > .guidance {
            font-weight: 500;
        }
        & > .c-btn {
            display: block;
            margin: auto;

            &:first-child {
                margin-top: 0;
            }
        }
        & > .c-link {
            @include g.font-sm;
            display: inline-block;
            color: g.$color-link;
        }
    }
    @include g.pc {
        & .content {
            padding-top: 48px;
            padding-bottom: 40px;
            & > .notice {
                width: 640px;
                margin: 0 auto 16px;
                padding-bottom: 16px;
            }
            & > .guidance {
                margin: 38px auto 16px;
            }
            & > .c-btn {
                margin-top: 24px;
            }
            & > .c-link {
                margin-top: 24px;
            }
        }
    }
    @include g.sp {
        & .content {
            padding: 32px 24px;
            & > .notice {
                width: 100%;
                margin: 0 auto 16px;
                padding-bottom: 24px;
            }
            & > .guidance {
                margin: 16px auto 0;
            }
            & > .c-btn {
                width: calc(100% - 20px);
                margin-top: 24px;
            }
            & > .c-link {
                margin-top: 24px;
            }
        }
    }
}
</style>
