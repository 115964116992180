<template>
    <system-page :page-settings="page">
        <div class="c-content u-text-center">
            <p>チェックインが完了しました。</p>
            <p>チェックイン後は、会場のアナウンスに従ってください。</p>
            <p>ルール・マナーを守って、最強のトレーナーを目指そう！</p>
            <div class="checkin-bottom u-text-center to-mypage">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイページへ
                </router-link>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
    name: 'QrCheckinPage',
    components: {
        SystemPage,
    },
    data() {
        return {
            page: {
                title: 'イベントチェックイン',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ イベントチェックインのページ。',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            },
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-content {
    color: g.$color-black;
    font-weight: 500;
}
.checkin {
    &-bottom {
        margin-top: 56px;
        @include g.sp {
            margin-top: 48px;
        }
    }
}
.to-mypage {
    line-height: 1;
}
</style>
