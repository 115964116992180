<template>
    <div class="p-searchForm eventTopForm">
        <form @submit.prevent="formSubmit">
            <div class="p-searchForm-wrapper">
                <div class="p-searchForm-content">
                    <div class="p-searchForm-tab">
                        <button
                            @click="changeTab('detail')"
                            type="button"
                            class="p-searchForm-tab-btn"
                            :class="selectTabName === 'detail' ? setTabClass('detail') : 'tab' "
                            :disabled="selectTabName === 'detail'"
                        >詳細条件で探す</button>
                        <button
                            @click="changeTab('place')"
                            type="button"
                            class="p-searchForm-tab-btn"
                            :class="selectTabName === 'place' ? setTabClass('place') : 'tab'"
                            :disabled="selectTabName === 'place'"
                        ><span class="u-display-sp">現在地・</span>場所で探す</button>
                    </div>
                    <div
                        class="p-searchForm-form p-searchForm-bg"
                        :class="{ place: displayTabContent('place') }"
                    >
                        <template v-if="displayTabContent('detail')">
                            <div class="p-searchForm-keyword">
                                <input
                                    type="text"
                                    v-model="searchParameters.keyword"
                                    class="c-input c-input-text search"
                                    placeholder="キーワードから絞り込む"
                                />
                            </div>
                            <div class="p-searchForm-date">
                                <span class="u-display-sp">開催日</span>
                                <div class="c-input c-input-date">
                                    <span class="input input-date-area">
                                        <datepicker
                                            placeholder="指定しない"
                                            v-model="searchParameters.startDate"
                                            inputFormat="yyyy/MM/dd"
                                            :locale="jaLocal"
                                        />
                                    </span>
                                    <span>～</span>
                                    <span class="input input-date-area">
                                        <datepicker
                                            placeholder="指定しない"
                                            v-model="searchParameters.endDate"
                                            inputFormat="yyyy/MM/dd"
                                            :locale="jaLocal"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div class="p-searchForm-place">
                                <span class="u-display-sp">開催地</span>
                                <div class="c-input c-input-select">
                                    <button
                                        @click="togglePrefectureModal"
                                        class="select-prefecture"
                                        id="selectPrefectureText"
                                        type="button"
                                    >
                                        都道府県から絞り込む
                                    </button>
                                </div>
                                <div
                                    v-show="openPrefecture"
                                    class="select-prefecture-wrapper"
                                >
                                    <div class="select-prefecture-header u-display-sp">
                                        <button
                                            @click="togglePrefectureModal"
                                            type="button"
                                            class="c-btn c-btn-primary-outline icon-clear"
                                        >×</button>
                                        <div class="select-prefecture-selected">
                                            {{ searchParameters.prefecture.length }}件選択中
                                        </div>
                                        <button
                                            @click="searchPrefectureModal"
                                            type="button"
                                            class="c-btn c-btn-primary"
                                        >決定</button>
                                    </div>
                                    <div
                                        v-for="(areaKey, index) in Object.keys(areaList)"
                                        :key="`area${index}`"
                                        class="select-prefecture-content"
                                    >
                                        <div class="left">
                                            <div class="c-checkbox">
                                                <input
                                                    :id="`prefectureArea${index}`"
                                                    type="checkbox"
                                                    name="prefectureAreaCheck"
                                                    :value="areaKey"
                                                    v-model="prefectureAreaCheck"
                                                    @change="checkAllRegion"
                                                >
                                                <label :for="`prefectureArea${index}`">
                                                    {{ areaList[areaKey].name }}
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="prefectureList" class="right">
                                            <template
                                                v-for="(prefectureId, prefectureIndex) in areaList[areaKey].pref"
                                                :key="`prefecture${prefectureIndex}`"
                                            >
                                                <template
                                                    v-for="prefecture in prefectureList"
                                                    :key="prefecture.id"
                                                >
                                                    <div
                                                        v-if="prefectureId === prefecture.id"
                                                        class="c-checkbox"
                                                    >
                                                        <input
                                                            :id="`prefecture${prefecture.id}`"
                                                            :class="`check-prefecture-${areaKey}`"
                                                            name="prefecture"
                                                            type="checkbox"
                                                            :value="`${prefecture.id}`"
                                                            v-model="searchParameters.prefecture"
                                                            @change="changePrefecture"
                                                        >
                                                        <label :for="`prefecture${prefecture.id}`">
                                                            {{ trimPrefecture(prefecture.title) }}
                                                        </label>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-searchForm-tag-list u-display-sp">
                                <div
                                    v-for="label in searchParameterLabels"
                                    :key="`${label.name}: ${label.value}`"
                                    class="tag"
                                >
                                    <button
                                        class="tag-button"
                                        @click.stop="deleteSearchParameterLabel(label)"
                                    >×</button>
                                    {{ label.displayName }}
                                </div>
                            </div>
                            <div class="p-searchForm-add">
                                <button
                                    type="button"
                                    class="c-btn c-btn-primary p-searchForm-btn"
                                    @click="openModal"
                                >
                                    <span>条件を<br />追加する</span>
                                </button>
                            </div>
                        </template>
                        <template v-if="displayTabContent('place')">
                            <div class="p-searchForm-selectPlace u-display-sp">
                                <div class="c-radio">
                                    <input
                                        id="selectPlace1"
                                        v-model="selectSpCurrentPlace"
                                        :value="true"
                                        type="radio"
                                    />
                                    <label for="selectPlace1">現在地から探す</label>
                                </div>
                                <div class="c-radio p-searchForm-selectPlace-place">
                                    <input
                                        type="radio"
                                        id="selectPlace2"
                                        v-model="selectSpCurrentPlace"
                                        :value="false"
                                    />
                                    <label for="selectPlace2">場所から探す<span class="arrow"></span>
                                    </label>
                                </div>
                            </div>
                            <div
                                v-if="!isSp || (isSp && !selectSpCurrentPlace)"
                                class="p-searchForm-address"
                            >
                                <input
                                    type="text"
                                    v-model="searchParameters.address"
                                    class="c-input c-input-text search"
                                    placeholder="駅名や住所を入力"
                                />
                            </div>
                            <div class="p-searchForm-distance">
                                <span class="p-searchForm-distance-label">距離：</span>
                                <div class="c-input c-input-select">
                                    <select
                                        id="select2"
                                        v-model="searchParameters.distance"
                                    >
                                        <option value="1">1km以内</option>
                                        <option value="3">3km以内</option>
                                        <option value="5">5km以内</option>
                                        <option value="10">10km以内</option>
                                        <option value="50">50km以内</option>
                                    </select>
                                </div>
                            </div>
                            <div class="p-searchForm-add">
                                <button
                                    type="button"
                                    class="c-btn c-btn-primary p-searchForm-btn"
                                    @click="openModal"
                                >
                                    <span>条件を<br />追加する</span>
                                </button>
                            </div>
                        </template>
                        <div class="u-display-sp p-searchForm-form-buttons">
                            <button
                                type="button"
                                class="c-btn c-btn-primary-outline clear p-searchForm-btn"
                                @click="clearInputForm"
                            >条件<br />クリア</button>
                            <button
                                type="submit"
                                class="c-btn c-btn-primary p-searchForm-btn"
                            >検索</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-searchForm-bottom">
                <div class="p-searchForm-tag-list u-display-pc">
                    <div
                        v-for="label in searchParameterLabels"
                        :key="`${label.name}: ${label.value}`"
                        class="tag"
                    >
                        <button
                            class="tag-button"
                            @click.stop="deleteSearchParameterLabel(label)"
                        >×</button>
                        {{ label.displayName }}
                    </div>
                </div>
                <div class="p-searchForm-bottom-option">
                    <button
                        type="submit"
                        class="c-btn c-btn-primary"
                    >検索</button>
                </div>
            </div>

            <pcg-modal
                class="p-searchFormFilter"
                :isActive="activeModal === 'searchModal'"
                :noPadding="true"
                :footerShadow="true"
                :bgImage="true"
                @close="activeModal = null"
            >
                <template #modalHeader>
                    <h2 class="c-title c-title-page">イベント検索 詳細条件</h2>
                </template>
                <form-detail
                    v-model:eventType="searchParameters.eventType"
                    v-model:leagueType="searchParameters.leagueType"
                    v-model:eventDayOfWeek="searchParameters.eventDayOfWeek"
                    v-model:eventOpenTime="searchParameters.eventOpenTime"
                    v-model:reservation="searchParameters.reservation"
                    @searchCount="formSubmitCount"
                />
                <template #modalFooter>
                    <div class="p-searchFormFilter-footer">
                        <div class="p-searchFormFilter-buttons">
                            <button
                                type="button"
                                class="c-btn c-btn-primary-outline u-mr"
                                @click="clearInputForm"
                            >条件<br class="u-display-sp">クリア</button>
                            <button type="submit" class="c-btn c-btn-primary">
                                検索
                            </button>
                        </div>
                    </div>
                </template>
            </pcg-modal>
        </form>
    </div>
</template>

<script>
import displayMethodMixin from "./methods/Display.js";
import prefectureMethodMixin from "./methods/Prefecture.js";
import FormDetail from "./parts/FormDetail.vue";
import pcgModal from "@/component/Modal.vue";
import Datepicker from "vue3-datepicker";
import dayjs from 'dayjs';
import { ja } from 'date-fns/locale';

export default {
    el: "#app",
    name: "eventSearchForm",
    props: {
    },
    emits: [
    ],
    components: {
        pcgModal,
        FormDetail,
        Datepicker,
    },
    mixins: [
        displayMethodMixin,
        prefectureMethodMixin,
    ],
    mounted() {
        this.setPrefectureList()
        this.setSelectorClass()
        window.addEventListener('click', this.checkClickElement);
    },
    data() {
        return {
            activeModal: "",
            searchParameters: {
                keyword: "",
                startDate: null,
                endDate: null,
                prefecture: [],
                address: "",
                distance: "",
                currentLat: "",
                currentLng: "",
                eventType: [],
                leagueType: [],
                eventDayOfWeek: "",
                eventOpenTime: "",
                reservation: "",
                offset: 0,
                accepting: "",
                order: 1,
            },
            selectSpCurrentPlace: null,
            latitude: null,
            longitude: null,
            inflection: require('inflection'),
            searchParameterLabels: [],
            eventAttributeLabels: {
                1: 'ルールを教わる',
                2: '初心者歓迎',
                3: '真剣勝負',
                4: 'リモートポケカ',
            },
            eventTypeLabels: {
                1: this.$root.getCommonText('champions_league'),
                2: 'シティリーグ',
                3: 'トレーナーズリーグ',
                4: 'ジムイベント',
                5: 'キャンペーン',
                6: 'オーガナイザーイベント',
                7: this.$root.getCommonText('shield_battle'),
            },
            leagueLabels: {
                1: 'オープン',
                2: 'ジュニア',
                3: 'シニア',
                4: 'マスター',
            },
            includeLeagueEventTypes: [
                '3:1',
                '3:2',
                '3:3',
                '3:7',
            ],
            eventDayOfWeekLabels: {
                0: '全ての曜日',
                1: '平日のみ',
                2: '土日祝',
            },
            eventOpenTimeLabels: {
                0: '全ての時間',
                1: '午前スタート',
                2: '午後スタート',
                3: '18時以降スタート',
            },
            reservationLabels: {
                1: '事前応募あり',
                2: '事前応募なし',
            },
        };
    },
    watch: {
        searchParameters: {
            handler() {
                this.setSearchParameterLabels()
                this.savePrefectureList()
            },
            deep: true,
        },
    },
    methods: {
        setPrefectureList: function () {
            if (localStorage.getItem('prefectureList') != null && this.$route.query['link'] == null) {
                this.searchParameters.prefecture = localStorage.getItem('prefectureList').split(',')
                this.setSelectorClass()
            }
        },
        savePrefectureList: function () {
            let prefecture = Array.isArray(this.searchParameters['prefecture'])
                ? this.searchParameters['prefecture']
                : this.searchParameters['prefecture'].split(',');
            if (prefecture != null && prefecture.length !== 0) {
                localStorage.setItem('prefectureList', prefecture)
            } else {
                localStorage.removeItem('prefectureList')
            }
        },
        deleteSearchParameterLabel: async function (label) {
            this.searchParameterLabels = []
            if (label.name === "eventType" || label.name === "leagueType" || label.name === "prefecture") {
                this.searchParameters[label.name] = this.searchParameters[label.name].filter(value => value !== label.value)
            }
            else if (label.name === "startDate" || label.name === "endDate") {
                this.searchParameters[label.name] = null
            }
            else {
                this.searchParameters[label.name] = ""
            }
            // 検索パラメータラベルの設定
            this.setSearchParameterLabels()
            this.changePrefecture()
            this.setSelectorClass()
        },
        formSubmit: function () {
            this.activeModal="";
            this.setSelectorClass()
            this.searchSubmit()
        },
        searchSubmit: async function () {
            this.searchParameters.offset = 0
            await this.updateQuery()
        },
        formSubmitCount: function () {
        },
        clearInputForm: function () {
            this.searchParameters.keyword = "";
            this.searchParameters.startDate = null;
            this.searchParameters.endDate = null;
            this.searchParameters.prefecture = [];
            this.searchParameters.address = "";
            this.searchParameters.distance = "";
            this.searchParameters.currentLat = "";
            this.searchParameters.currentLng = "";
            this.searchParameters.eventType = [];
            this.searchParameters.leagueType = [];
            this.searchParameters.eventDayOfWeek = "";
            this.searchParameters.eventOpenTime = "";
            this.searchParameters.reservation = "";
            this.prefectureAreaCheck = []

            this.selectSpCurrentPlace = null;
            this.longitude = null;
            this.latitude = null;

            this.setSearchParameterLabels()
        },
        changeTab: function (tabName) {
            this.selectTab(tabName);
            this.clearInputForm();
        },
        openModal() {
            this.activeModal = 'searchModal'
            this.openPrefecture = false
        },
        checkAllRegion($event) {
            const that = this
            const selectArea = $event.target.value
            const prefList = this.areaList[selectArea].pref
            const isChecked = this.prefectureAreaCheck.indexOf(selectArea) > -1

            if (isChecked) {
                // あとから配列ごと書き換えるためにDeepCopyを作成
                const replaceList = that.searchParameters.prefecture.map((val) => {return val})
                prefList.forEach(function(i) {
                    const value = `${i}`
                    const checkArray = that.searchParameters.prefecture.indexOf(value)
                    if (checkArray === -1) {
                        replaceList.push(value)
                    }
                })
                // 1件ずつpushしても変更を検知してくれないため変更後の配列を丸ごと代入する
                that.searchParameters.prefecture = replaceList
            } else {
                let filterList = that.searchParameters.prefecture
                prefList.forEach(function(i) {
                    const value = `${i}`
                    filterList = filterList.filter(n => n !== value)
                })
                that.searchParameters.prefecture = filterList
            }
            this.setSelectorClass()
        },
        changePrefecture() {
            for (const [key, ] of Object.entries(this.areaList)) {
                const CheckboxList = document.querySelectorAll(`input.check-prefecture-${key}`)
                let checkNum = 0
                CheckboxList.forEach(i => {
                    if (i.checked) {
                        checkNum++
                    }
                })
                if (CheckboxList.length === checkNum) {
                    if (this.prefectureAreaCheck.indexOf(key) === -1) {
                        this.prefectureAreaCheck.push(key)
                    }
                } else {
                    if (this.prefectureAreaCheck.indexOf(key) > -1) {
                        this.prefectureAreaCheck = this.prefectureAreaCheck.filter(n => n !== key)
                    }
                }
            }
            this.setSelectorClass()
        },
        togglePrefectureModal() {
            if (this.$store.state.isSp) {
                if (this.openPrefecture) {
                    // ×で閉じたときは開く前の選択状態に戻す
                    this.searchParameters.prefecture = this.selectedSpPrefecture
                    this.prefectureAreaCheck = this.prefectureSpAreaCheck
                } else {
                    this.selectedSpPrefecture = this.searchParameters.prefecture.map((val) => {return val})
                    this.prefectureSpAreaCheck = this.prefectureAreaCheck.map((val) => {return val})
                }
            }
            this.openPrefecture = !this.openPrefecture
        },
        searchPrefectureModal() {
            this.openPrefecture = !this.openPrefecture
        },
        updateQuery: async function () {
            const query = await this.createQueryFromSearchParameters()
            this.$router.push({name: 'EventSearch', query: query})
        },
        createQueryFromSearchParameters: async function() {
            const query = {}
            Object.keys(this.searchParameters).forEach((searchName) => {
                if (
                    this.searchParameters[searchName] === null
                    || this.searchParameters[searchName] === ''
                    || (Array.isArray(this.searchParameters[searchName])  && !this.searchParameters[searchName].length)
                ) {
                    return
                }
                if (searchName === 'prefecture') {
                    // 選択されている地域が1件だけの時は配列にする処理をしないとStringとして扱われてしまう
                    const prefecture = Array.isArray(this.searchParameters[searchName])
                        ? this.searchParameters[searchName]
                        : this.searchParameters[searchName].split(',');
                    query[this.inflection.underscore(searchName)] = prefecture
                } else {
                    query[this.inflection.underscore(searchName)] =
                        (searchName === 'startDate' || searchName === 'endDate')
                            ? this.formatDate(this.searchParameters[searchName])
                            : this.searchParameters[searchName]
                }
            })

            if (this.searchParameters['prefecture'] != null && this.searchParameters['prefecture'].length !== 0) {
                localStorage.setItem('prefectureList', query['prefecture'])
            } else {
                localStorage.removeItem('prefectureList')
            }

            if (
                this.selectSearchTab === 'place'
                && this.searchParameters['address']
                && !this.searchParameters['distance']
            ) {
                // distanceがない場合はデフォルトの値を入れて検索する
                const defaultDistance = '1'
                this.searchParameters['distance'] = defaultDistance
            }

            if (this.selectSpCurrentPlace !== null && this.selectSpCurrentPlace) {
                await this.setPositionParameters()
                if (this.latitude !== null && this.longitude !== null) {
                    query['current_lat'] = this.latitude;
                    query['current_lng'] = this.longitude;
                }
                else {
                    delete query['distance']
                }
            } else if (this.searchParameters['currentLat'] === null
                    || this.searchParameters['currentLat'] === ''
                    || this.searchParameters['currentLng'] === null
                    || this.searchParameters['currentLng'] === ''
            ) {
                delete query['current_lat']
                delete query['current_lng']
            }

            query['offset'] = this.searchParameters.offset

            return query
        },
        setSearchParameterLabels: function () {
            this.searchParameterLabels = []
            if (this.searchParameters.keyword) {
                this.searchParameterLabels.push({
                    name: 'keyword',
                    displayName: this.searchParameters.keyword,
                    value: this.searchParameters.keyword,
                })
            }
            if (this.searchParameters.startDate) {
                this.searchParameterLabels.push({
                    name: 'startDate',
                    displayName: '開始日' + this.formatDate(this.searchParameters.startDate),
                    value: this.searchParameters.startDate,
                })
            }
            if (this.searchParameters.endDate) {
                this.searchParameterLabels.push({
                    name: 'endDate',
                    displayName: '終了日' + this.formatDate(this.searchParameters.endDate),
                    value: this.searchParameters.endDate,
                })
            }
            if (this.searchParameters.prefecture) {
                let prefectureText = ''
                if (this.prefectureList == null) {
                    const api = '/master/prefecture'
                    this.axios.get(api)
                    .then(response => {
                        let prefectureList = response.data.prefectureList
                        for (let [, prefecture] of Object.entries(
                            this.searchParameters.prefecture
                        )) {
                            let prefectureName = this.trimPrefecture(prefectureList[prefecture].title)
                            this.searchParameterLabels.push({
                                name: 'prefecture',
                                displayName: prefectureName,
                                value: prefecture,
                            })
                            prefectureText += `${prefectureName} `
                        }
                        const button = document.querySelector('#selectPrefectureText')
                        if (button) {
                            button.innerText = prefectureText.length ? prefectureText : '都道府県から絞り込む'
                        }
                    })
                } else {
                    for (let [, prefecture] of Object.entries(
                        this.searchParameters.prefecture
                    )) {
                        let prefectureName = this.trimPrefecture(this.$store.getters.getPrefectureName(prefecture))
                        this.searchParameterLabels.push({
                            name: 'prefecture',
                            displayName: prefectureName,
                            value: prefecture,
                        })
                        prefectureText += `${prefectureName} `
                    }
                    const button = document.querySelector('#selectPrefectureText')
                    if (button) {
                        button.innerText = prefectureText.length ? prefectureText : '都道府県から絞り込む'
                    }
                }
            }
            if (this.searchParameters.address) {
                this.searchParameterLabels.push({
                    name: 'address',
                    displayName: this.searchParameters.address,
                    value: this.searchParameters.address,
                })
            }
            if (this.searchParameters.distance) {
                this.searchParameterLabels.push({
                    name: 'distance',
                    displayName: this.searchParameters.distance + 'km以内',
                    value: this.searchParameters.distance,
                })
            }
            if (this.searchParameters.eventType) {
                for (let [, eventType] of Object.entries(
                    this.searchParameters.eventType
                )) {
                    let eventTypeList = eventType.split(':')
                    this.searchParameterLabels.push({
                        name: 'eventType',
                        displayName:`${this.eventAttributeLabels[eventTypeList[0]]}:${this.eventTypeLabels[eventTypeList[1]]}`,
                        value: eventType,
                    })
                }
            }
            if (this.searchParameters.leagueType) {
                for (let [, league] of Object.entries(this.searchParameters.leagueType)) {
                    this.searchParameterLabels.push({
                        name: 'leagueType',
                        displayName: this.leagueLabels[league],
                        value: league,
                    })
                }
            }
            if (this.searchParameters.eventDayOfWeek) {
                this.searchParameterLabels.push({
                    name: 'eventDayOfWeek',
                    displayName: this.eventDayOfWeekLabels[this.searchParameters.eventDayOfWeek],
                    value: this.searchParameters.eventDayOfWeek,
                })
            }
            if (this.searchParameters.eventOpenTime) {
                this.searchParameterLabels.push({
                    name: 'eventOpenTime',
                    displayName: this.eventOpenTimeLabels[this.searchParameters.eventOpenTime],
                    value: this.searchParameters.eventOpenTime,
                })
            }
            if (this.searchParameters.reservation) {
                this.searchParameterLabels.push({
                    name: 'reservation',
                    displayName: this.reservationLabels[this.searchParameters.reservation],
                    value: this.searchParameters.reservation,
                })
            }
        },
        setPositionParameters: async function () {
            if (!this.selectSpCurrentPlace) {
                return
            }

            try {
                let position = await this.getCurrentPosition()
                this.latitude = position.coords.latitude
                this.longitude = position.coords.longitude
            } catch (error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        break
                    case error.POSITION_UNAVAILABLE:
                        break
                    case error.TIMEOUT:
                        break
                    default:
                }
                this.latitude = null
                this.longitude = null
            }
        },
        getCurrentPosition: function () {
            /*
                This API requires HTTPS
                ref. https://sites.google.com/a/chromium.org/dev/Home/chromium-security/deprecating-powerful-features-on-insecure-origins
            */
            this.$store.commit('setIsLoading', true)
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.$store.commit('setIsLoading', false)
                    resolve(position)
                }, reject)
            })
        },
        formatDate: function (datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        checkClickElement(event) {
            if (!this.$el.querySelector('.select-prefecture-wrapper')) {
                return
            }

            if(
                'selectPrefectureText' !== event.target.id &&
                !this.$el.querySelector('.select-prefecture-wrapper').contains(event.target) &&
                this.openPrefecture
            ) {
                this.openPrefecture = false;
            }
        },
        trimPrefecture(name) {
            return name.replace(/(都|府|県)$/g, '')
        }
    },
    computed: {
        isSp() {
            return this.$store.state.isSp;
        },
        prefectureList: function () {
            return this.$store.getters.getPrefectureList();
        },
        jaLocal() {
            return ja;
        },
    },
};
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './styles/modal';

.p-searchForm.eventTopForm {
    .p-searchForm-bottom {
        display: flex;
        justify-content: space-between;
        @include g.sp {
            display: none;
        }
        & > .p-searchForm-bottom-option {
            margin-left: 78px;
            .c-btn {
                width: 216px;
            }
        }
    }
    @include g.pc {
        .p-searchForm-form {
            padding-left: 20px;
            padding-right: 20px;
        }
        .p-searchForm-keyword {
            input {
                width: 239px;
            }
        }
        .p-searchForm-tag-list {
            &:not(:empty) {
                padding-top: 0;
            }
        }
        .p-searchForm-bottom {
            margin-top: 20px;
            .p-searchForm-bottom-option {
                padding-top: 8px;
            }
        }
    }
    @include g.sp {
        .p-searchForm-tab {
            padding: 0 12px;
        }
        .p-searchForm-form {
            padding: 24px 18px 32px;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
        .p-searchForm-date,
        .p-searchForm-place,
        .p-searchForm-distance {
            & > span {
                width: 3em;
            }
            .c-input {
                margin-left: 12px;
            }
        }
        .c-input-date {
            & > .input {
                padding-right: 16px;
            }
            span:not(.input) {
                margin: 0 3px;
                font-size: 12px;
            }
        }
        .p-searchForm-tag-list {
            &:empty {
                margin-top: 9px;
            }
        }
        .p-searchForm-form-buttons {
            padding-top: 31px;
            margin-top: 24px;
            border-top: 1px solid g.$color-border-gray;
        }
    }
}
</style>
