<template>
<div class="eventState" v-if="Object.keys(eventData).length">
    <div class="c-content">
        <div
            class="eventState-top"
            :class="{noState: !state}"
        >
            <div
                class="eventState-date"
                :class="{noImage: !eventData.image}"
            >
                <div class="left">
                    <div class="date">
                        <div class="date-day" :class="{ 'sat': dataFormat(eventData.eventDate, 6) === '土', 'sun': dataFormat(eventData.eventDate, 6) === '日' || eventData.holidayFlg === 1 }">
                            {{ dataFormat(eventData.eventDate,2) }}
                        </div>
                        <div class="date-time">{{ dataFormat(eventData.eventStartedAt,4) + ' ～ ' + (eventData.eventEndedAt ? dataFormat(eventData.eventEndedAt,4) : '') }}</div>
                    </div>
                    <div v-if="isCancel" class="date-cancel">
                        <span>このイベントは中止になりました</span>
                    </div>
                    <div class="supplement">{{ eventData.eventDaySupply }}</div>
                    <div
                        v-if="eventData.eventTypeId === 1"
                        class="sponsorship noLink"
                    >
                        主催者：{{ '株式会社ポケモン' }}
                    </div>
                    <div
                        v-else
                        class="sponsorship"
                    >
                        主催者：<router-link :to="{ name: 'EventStore', params: { id: eventData.shopId } }">{{ eventData.shopName }}</router-link>
                    </div>
                    <div class="address">
                        <div v-if="eventData.address">
                            <span class="postcode" v-if="eventData.zipCode">〒{{ zipCodeFormat(eventData.zipCode) }} </span>
                            {{ eventData.address }}
                        </div>
                        <div v-if="eventData.venue">
                            <free-text :freeText="eventData.venue"></free-text>
                        </div>
                    </div>
                </div>
                <div
                    v-if="eventData.image"
                    class="right"
                >
                    <img :src="eventData.image" class="u-mb">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import stateData from '../methods/data'
import freeText from '@/component/FreeText.vue'

export default {
    name: 'EventDetail',
    mixins: [stateData],
    components: {
        freeText
    },
    props: {
        state: {
            type: String,
            required: true,
        },
        eventData: {
            type: Array,
            require: true,
        },
    },
    methods: {
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? zip : zip.slice(0, 3) + '-' + zip.slice(3)
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '../style/content';
@use '../style/apply';

.eventState {
    &-top {
        margin-top: 30px;
        padding-top: 35px;
        border-top: 1px solid g.$color-border-gray;
        &.noState {
            border-bottom: 1px solid g.$color-border-gray;
            .eventState-date {
                margin-bottom: 30px;
            }
        }
    }
    &-date {
        &.noImage {
            text-align: center;
        }
    }
    .date {
        &-day {
            font-size: 24px;
            line-height: 1;
            font-weight: bold;
            color: g.$color-black;

            &.sat {
                color: g.$color-font-blue;
            }
            &.sun {
                color: g.$color-font-red;
            }
        }
        &-time {
            padding-left: 4px;
            padding-top: 4px;
            font-size: 18px;
            line-height: 1.5;
            font-weight: bold;
            color: g.$color-black;
        }
        &-cancel {
            margin-top: 20px;
            padding: 8px;
            text-align: center;
            font-weight: bold;
            color: g.$color-error-red;
            background: g.$color-error-red-bg;
            border: 1px solid g.$color-error-red;
            border-radius: 4px;
        }
    }
    .supplement {
        @include g.font-sm;
        &:empty {
            display: none;
        }
    }
    .sponsorship {
        margin-top: 20px;
        padding-top: 24px;
        border-top: 1px dotted g.$color-border-gray;
        font-weight: bold;
        &.noLink {
            @include g.font-sm;
            font-weight: normal;
        }
    }
    .address {
        @include g.font-sm;
        padding-top: 12px;
        & > span {
            display: block;
        }
    }
    .postcode {
        margin-right: 1rem;
    }
    @include g.pc {
        &-date {
            display: flex;
            gap: 40px;
            margin-bottom: 32px;
            & > .left {
                flex: 1;
            }
            & > .right {
                width: 300px;
            }
        }
        .supplement {
            padding-top: 24px;
            font-size: 14px;
        }
    }
    @include g.sp {
        &-top {
            margin-top: 20px;
            padding: 24px 0 0;
        }
        &-date {
            & > .right {
                display: none;
            }
        }
        .date {
            text-align: center;
        }
        .supplement {
            padding-top: 20px;
            text-align: left;
        }
        .sponsorship {
            padding-top: 20px;
            text-align: left;
        }
        .address {
            text-align: left;
        }
    }
}
</style>
