<template>
    <div class="eventOrganizer">
        <div class="organizer">
            <template v-if="isTPC">
                株式会社ポケモン
            </template>
            <template v-else-if="shopId">
                <router-link
                :to="{
                    name: 'EventStore',
                    params: { id: shopId }
                }"
                >{{ urlTitle }}</router-link>
            </template>
            <template v-else>
                {{ urlTitle }}
            </template>
        </div>
        <div v-if="!isTPC && tel" class="tel">
            <span class="u-display-pc">TEL: {{ tel }}</span>
            <span class="u-display-sp">TEL: <a :href="`tel:${tel}`">{{ tel }}</a></span>
        </div>
        <div v-if="!isTPC && shopId" class="otherEvent">
            <router-link
                :to="{
                    name: 'EventSearch',
                    query: { shop_id: shopId },
                }"
                class="c-link-arrow"
            >この主催者のイベント一覧</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventOrganizer',
    props: {
        isTPC: {
            type: Boolean,
        },
        urlTitle: {
            type: String,
        },
        tel: {
            type: String,
        },
        shopId: {
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.eventOrganizer {
    .otherEvent {
        font-weight: 500;
    }
    @include g.pc {
        position: relative;
        margin-top: 28px;
        .otherEvent {
            position: absolute;
            right: 20px;
            bottom: 0;
        }
    }
    @include g.sp {
        margin-top: 24px;
        .otherEvent {
            padding-top: 22px;
            text-align: center;
        }
    }
}
</style>
