<template>
  <section class="c-content">
    <div class="u-text-center">
      <img src="@/assets/img/common/trainers_logo.png" class="u-mt u-mb" />
    </div>
    <h2 class="c-title c-title-page">
      ポケモンカードゲーム<br />
      プレイヤーズクラブ<br class="u-display-sp" />追加情報入力
    </h2>
    <div class="u-text-center page-description">
      <p>お手数ですが、会員情報の追加入力をお願いします。</p>
    </div>
    <error-msg
        v-if="error"
        :message="error"
        class="u-mb-lg error-msg"
    >
    </error-msg>
    <form>
      <div class="form u-mb c-table-list">
        <div class="c-table-list-row">
          <div class="column-left">メールアドレス</div>
          <div class="column-right">
            {{ syncedMail }}
          </div>
        </div>
        <div class="c-table-list-row">
          <div class="column-left">
            <label for="formNickname"> ニックネーム </label>
          </div>
          <div class="column-right form-input-nickname">
            <input
              class="c-input c-input-text u-width-100"
              placeholder="20文字まで"
              id="formNickname"
              type="text"
              v-model="syncedNickname"
              maxlength="20"
              :class="{'c-input-error': formNicknameValid}"
            />
            <p class="error u-text-warning-icon" v-if="formNicknameValid">
              ニックネームを20文字以内で入力してください。
            </p>
          </div>
        </div>
        <div class="c-table-list-row">
          <div class="column-left">お名前</div>
          <div class="column-right">
            {{ syncedLastName }} {{ syncedFirstName }}
          </div>
        </div>
        <div class="c-table-list-row">
          <div class="column-left">フリガナ</div>
          <div class="column-right">
            {{ syncedLastNameKana }} {{ syncedFirstNameKana }}
          </div>
        </div>
        <div class="c-table-list-row">
          <div class="column-left">生年月日</div>
          <div class="column-right form-input-flex">{{ dayjsFormat(syncedBirthday, 'YYYY年MM月DD日') }}</div>
        </div>
        <div class="c-table-list-row">
          <div class="column-left">性別</div>
          <div class="column-right">
              <div class="u-display-flex">
                <div class="c-radio u-mr-lg">
                  <input
                    id="formMale"
                    name="gender"
                    type="radio"
                    value="1"
                    v-model="syncedGender"
                    :class="{'c-input-error': genderValid}"
                  />
                  <label for="formMale"> 男性 </label>
                </div>
                <div class="c-radio u-mr-lg">
                  <input
                    id="formFemale"
                    name="gender"
                    type="radio"
                    value="2"
                    v-model="syncedGender"
                  />
                  <label for="formFemale"> 女性 </label>
                </div>
                <div class="c-radio">
                  <input
                    id="formUnspecified"
                    name="gender"
                    type="radio"
                    value="3"
                    v-model="syncedGender"
                  />
                  <label for="formUnspecified"> 指定しない </label>
                </div>
              </div>
            <p class="error u-text-warning-icon" v-if="genderValid">性別を選択してください。</p>
          </div>
        </div>
        <div class="c-table-list-row input-required-row">
          <div class="column-left">
            <label for="formPostcode"> 郵便番号 </label>
          </div>
          <div class="column-right form-input-postcode">
            <div class="u-flex">
              <div class="input-container">
                <input
                  class="c-input c-input-text u-mr-lg"
                  placeholder="例）1066108"
                  id="formPostcode"
                  type="text"
                  v-model="syncedPostcode"
                  maxlength="7"
                  :class="{'c-input-error': formPostcodeValid || formPostcodeSearchValid}"
                />
                <p class="u-text-kome u-text-exsmall kome-margin">
                  ハイフンなしで入力してください。
                </p>
              </div>
              <div class="postcode-btn-container u-display-pc">
                <button
                  type="button"
                  class="postcode-btn"
                  @click="serachPostCode()"
                >
                  郵便番号から住所を入力
                </button>
              </div>
            </div>
            <div class="postcode-btn-container u-display-sp">
            <button
                type="button"
                class="postcode-btn"
                @click="serachPostCode()"
            >
                郵便番号から住所を入力
            </button>
            </div>
          <p class="error u-text-warning-icon u-mt-sm" v-if="formPostcodeValid">
            郵便番号を7桁の数字で入力してください。
          </p>
          <p class="error icon-exclamation u-mt-sm" v-if="formPostcodeSearchValid">
            郵便番号から住所を特定できませんでした。
          </p>
          </div>
        </div>
        <div class="c-table-list-row">
          <div class="column-left">
            <label for="formPrefectures"> 都道府県 </label>
          </div>
          <div class="column-right form-input-prefecture">
            <div
                class="c-input c-input-select"
                :class="{'c-input-error': formPrefecturesValid}"
            >
                <select id="formPrefectures" v-model="syncedPrefectures">
                    <option value="">─</option>
                    <option
                        v-for="prefecture in prefectureList"
                        :key="prefecture.id"
                        :value="prefecture.id"
                    >{{ prefecture.title }}</option>
                </select>
            </div>
            <p class="error u-text-warning-icon" v-if="formPrefecturesValid">
              都道府県を選択してください。
            </p>
          </div>
        </div>
        <div class="c-table-list-row input-required-row">
          <div class="column-left">
            <label for="formAddress"> 住所 </label>
          </div>
          <div class="column-right">
            <input
              class="c-input c-input-text u-width-100"
              placeholder="例）渋谷区六本木6-10-1"
              id="formAddress"
              type="text"
              v-model="syncedAddress"
              maxlength="200"
              :class="{'c-input-error': formAddressValid}"
            />
            <p class="error u-text-warning-icon" v-if="formAddressValid">
              住所を200文字以内で入力してください。
            </p>
          </div>
        </div>
        <div class="c-table-list-row input-required-row">
          <div class="column-left">
            <label for="formPhoneNumber"> 携帯電話番号 </label>
          </div>
          <div class="column-right input-required form-input-tel">
            <input
              class="c-input c-input-text u-width-100"
              placeholder="例）09012345678"
              id="formPhoneNumber"
              type="tel"
              v-model="syncedPhoneNumber"
              maxlength="20"
              :class="{'c-input-error': formPhoneNumberValid || formPhoneNumberDuplicateValid}"
            />
            <p class="u-text-kome u-text-exsmall kome-margin">
              ハイフンなしで入力してください。
            </p>
            <p class="u-text-kome u-text-exsmall">
              SMSを受け取れる電話番号を登録してください。
            </p>
            <p v-if="formPhoneNumberValid" class="error u-text-warning-icon u-mt-sm">
                携帯電話番号を入力してください。
            </p>
            <p v-if="formPhoneNumberLengthValid" class="error u-text-warning-icon u-mt-sm">
                携帯電話番号は、11桁で入力してください。
            </p>
            <p class="error u-text-warning-icon u-mt-sm u-mb-sm" v-if="formPhoneNumberDuplicateValid">
              {{ phoneErrMessage }}
            </p>
          </div>
        </div>
        <div class="c-table-list-row">
          <div class="column-left">マイページ公開/非公開</div>
          <div class="column-right">
            <div class="u-display-flex u-mb-sm">
              <div class="c-radio u-mr">
                <input
                  id="formMypageDisplay"
                  name="mypage"
                  type="radio"
                  value="1"
                  v-model="syncedMypage"
                  :class="{'c-input-error': formMypageValid}"
                />
                <label for="formMypageDisplay"> 公開 </label>
              </div>
              <div class="c-radio u-mr">
                <input
                  id="formMypageHide"
                  name="mypage"
                  type="radio"
                  value="0"
                  v-model="syncedMypage"
                />
                <label for="formMypageHide"> 非公開 </label>
              </div>
            </div>
            <p class="u-text-kome u-text-exsmall kome-margin">
              ニックネーム、都道府県、イベント参加情報が公開されます。
            </p>
            <p class="error u-text-warning-icon" v-if="formMypageValid">
              マイページ公開/非公開を選択してください。
            </p>
          </div>
        </div>
        <div class="c-table-list-row input-required-row">
          <div class="column-left">プロモーションメール</div>
          <div class="column-right form-input-promotional">
            <div class="u-display-flex u-mb-sm">
              <div class="c-radio u-mr">
                <input
                  id="formPromotionalReceive"
                  name="promotionalEmail"
                  type="radio"
                  value="1"
                  v-model="syncedPromotionalEmail"
                  :class="{'c-input-error': formPromotionalValid}"
                />
                <label for="formPromotionalReceive"> 受け取る </label>
              </div>
              <div class="c-radio u-mr">
                <input
                  id="formPromotionalUnreceive"
                  name="promotionalEmail"
                  type="radio"
                  value="0"
                  v-model="syncedPromotionalEmail"
                />
                <label for="formPromotionalUnreceive"> 受け取らない </label>
              </div>
            </div>
            <p class="u-text-kome u-text-exsmall kome-margin">
              当社からのお知らせ（広告メール）をお送りします。
            </p>
            <p class="error u-text-warning-icon" v-if="formPromotionalValid">
              プロモーションメールの受け取り可否を選択してください。
            </p>
          </div>
        </div>
        <div class="c-table-list-row">
          <div class="column-left wide">アバター</div>
          <div class="column-right">
            <div class="c-input-avatar-wrap" v-if="avatarInfo">
              <div class="c-input c-input-avatar">
                <input id="avatar-1" type="radio" disabled>
                <label for="avatar-1" class="avater-label">
                  <img :src="avatarInfo.image_url">
                </label>
              </div>
            </div>
            <p class="u-text-kome u-text-exsmall kome-margin">
              アバターは会員登録完了後、マイページメニュー「アバター変更」より変更が可能です。
            </p>
          </div>
        </div>
        <div class="c-table-list-row input-required-row">
          <div class="column-left wide">同意事項</div>
          <div class="column-right">
            <!-- TODO: 18歳未満の時だけ表示する -->
            <template v-if="minorsFlg">
              <div class="parental-consent-wrapper">
                <div class="c-checkbox u-mb-sm">
                  <input
                    id="formParentalConsent"
                    name="parentalConsent"
                    type="checkbox"
                    v-model="syncedParentalConsent"
                  />
                  <label for="formParentalConsent">
                    保護者の同意を得ました
                  </label>
                </div>
                <p class="u-text-kome u-text-exsmall u-mb kome-margin">
                  18歳未満の方は、保護者の同意が必要です。<br />
                  同意を得た上で、チェックボックスにチェックを入れてください。
                </p>
              </div>
              <p class="error error-parental-consent u-text-warning-icon" v-if="formParentalConsentValid">
                保護者の同意を行ってください。
              </p>
            </template>
            <div class="tos-wrapper">
              <div class="c-checkbox u-mb-sm">
                <input
                  id="formTermsOfService"
                  name="termsOfService"
                  type="checkbox"
                  v-model="syncedTermsOfService"
                />
                <label for="formTermsOfService">プレイヤーズクラブの利用規約に同意する</label>
              </div>
              <p class="u-text-small">
                <a class="c-link" :href="getPolicyLinkURL()" target="_blank"
                  >プレイヤーズクラブの利用規約</a
                >をご確認の上、チェックして登録してください。
              </p>
            </div>
            <p class="error u-text-warning-icon" v-if="formTermsOfServiceValid">
              プレイヤーズクラブの利用規約の同意を行ってください
            </p>
          </div>
        </div>
        <div class="c-table-list-row input-required-row">
          <div class="column-left wide">
            <label for="formPassword"> パスワード </label>
          </div>
          <div class="column-right form-input-password">
            <password-input
              v-model:password="syncedPassword"
              :hasError="formPasswordValid"
              placeholderMEssage="8文字以上"
              id="formPassword"
              name="password"
              :isNewPassword="true"
            ></password-input>
            <div class="password-text">
              <p class="u-text-kome u-text-exsmall kome-margin">
                アルファベット大文字、アルファベット小文字、数字、記号のうち2種類以上を含めて<br />入力してください。
              </p>
              <p class="error u-text-warning-icon" v-if="formPasswordValid">
                アルファベット大文字、アルファベット小文字、数字、記号のうち2種類以上を含めた8文字以上を入力してください。
              </p>
            </div>
            <password-input
              v-model:password="syncedPasswordReEnter"
              :hasError="formPasswordReEnterValid"
              placeholderMEssage="パスワードを再入力してください"
              id="formPasswordReEnter"
              name="passwordReEnter"
              :isNewPassword="true"
            ></password-input>
            <p class="error u-text-warning-icon" v-if="formPasswordReEnterValid">
              パスワードが一致しません。
            </p>
          </div>
        </div>
      </div>
      <div class="submit-button-container u-text-center">
        <button type="button" class="c-btn c-btn-primary" @click="inputCheck">
          確認する
        </button>
      </div>
    </form>
    <div class="u-text-center u-mb">
      <img src="@/assets/img/common/trainers_logo.png" class="u-mt u-mb" />
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import axiosJsonpAdapter from 'axios-jsonp'
import passwordInput from '@/component/PasswordInput.vue'
import errorMsg from '@/component/ErrorMsg.vue'

export default {
  name: "MigrationInput",
  components: {
    passwordInput,
    errorMsg,
  },
  data: function () {
    return {
      formNicknameValid: false,
      genderValid: false,
      formPostcodeValid: false,
      formPrefecturesValid: false,
      formAddressValid: false,
      formPhoneNumberValid: false,
      formPhoneNumberLengthValid: false,
      formMypageValid: false,
      formPromotionalValid: false,
      formParentalConsentValid: false,
      formTermsOfServiceValid: false,
      formPasswordValid: false,
      formPasswordReEnterValid: false,
      formPhoneNumberDuplicateValid: false,
      minorsFlg: false,
      scrollElementId: "",
      phoneErrMessage: "",
      error: null,
    };
  },
  props: {
    authCode: String,
    playerId: String,
    email: String,
    birthday: Object,
    nickname: String,
    lastName: String,
    firstName: String,
    lastNameKana: String,
    firstNameKana: String,
    gender: String,
    postcode: String,
    prefectures: Number,
    address: String,
    phoneNumber: Number,
    mypage: String,
    promotionalEmail: String,
    parentalConsent: Boolean,
    termsOfService: Boolean,
    password: String,
    passwordReEnter: String,
    avatarInfo: Object
  },
  watch: {
    'birthday': function() {
      var today = new Date();
      if (this.syncedBirthday != null) {
          var birthdayDate = dayjs(this.syncedBirthday.date);
          var thisYearBirthday = new Date(
              today.getFullYear(),
              birthdayDate.month(),
              birthdayDate.date()
          );
          var age = today.getFullYear() - birthdayDate.year();
          if ((today < thisYearBirthday ? age - 1 : age) < 18) {
              //18才未満なら保護者同意欄表示フラグをオンにする
              this.minorsFlg = true;
          }
          console.log(this.minorsFlg)
      }
    }
  },
  created: function() {
    this.passwordReset()
  },
  computed: {
    syncedAuthCode: {
      get() {
        return this.authCode;
      },
      set(value) {
        this.$emit("update:authCode", value);
      },
    },
    syncedPlayerId: {
      get() {
        return this.playerId;
      },
      set(value) {
        this.$emit("update:playerId", value);
      },
    },
    syncedMail: {
      get() {
        return this.email;
      },
      set(value) {
        this.$emit("update:email", value);
      },
    },
    syncedBirthday: {
      get() {
        return this.birthday == null || this.birthday == '' ? null : this.birthday;
      },
      set(value) {
        this.$emit("update:birthday", value);
      },
    },
    syncedNickname: {
      get() {
        return this.nickname;
      },
      set(value) {
        this.$emit("update:nickname", value);
      },
    },
    syncedLastName: {
      get() {
        return this.lastName;
      },
      set(value) {
        this.$emit("update:lastName", value);
      },
    },
    syncedFirstName: {
      get() {
        return this.firstName;
      },
      set(value) {
        this.$emit("update:firstName", value);
      },
    },
    syncedLastNameKana: {
      get() {
        return this.lastNameKana;
      },
      set(value) {
        this.$emit("update:lastNameKana", value);
      },
    },
    syncedFirstNameKana: {
      get() {
        return this.firstNameKana;
      },
      set(value) {
        this.$emit("update:firstNameKana", value);
      },
    },
    syncedGender: {
      get() {
        return this.gender;
      },
      set(value) {
        this.$emit("update:gender", value);
      },
    },
    syncedPostcode: {
      get() {
        return this.postcode;
      },
      set(value) {
        this.$emit("update:postcode", value);
      },
    },
    syncedPrefectures: {
      get() {
        return this.prefectures;
      },
      set(value) {
        this.$emit("update:prefectures", value);
      },
    },
    syncedAddress: {
      get() {
        return this.address;
      },
      set(value) {
        this.$emit("update:address", value);
      },
    },
    syncedPhoneNumber: {
      get() {
        return this.phoneNumber;
      },
      set(value) {
        this.$emit("update:phoneNumber", value);
      },
    },
    syncedMypage: {
      get() {
        return this.mypage;
      },
      set(value) {
        this.$emit("update:mypage", value);
      },
    },
    syncedPromotionalEmail: {
      get() {
        return this.promotionalEmail;
      },
      set(value) {
        this.$emit("update:promotionalEmail", value);
      },
    },
    syncedParentalConsent: {
      get() {
        return this.parentalConsent;
      },
      set(value) {
        this.$emit("update:parentalConsent", value);
      },
    },
    syncedTermsOfService: {
      get() {
        return this.termsOfService;
      },
      set(value) {
        this.$emit("update:termsOfService", value);
      },
    },
    syncedPassword: {
      get() {
        return this.password;
      },
      set(value) {
        this.$emit("update:password", value);
      },
    },
    syncedPasswordReEnter: {
      get() {
        return this.passwordReEnter;
      },
      set(value) {
        this.$emit("update:passwordReEnter", value);
      },
    },
    prefectureList: function () {
      return this.$store.getters.getPrefectureList();
    }
  },
  methods: {
    passwordReset() {
      this.syncedPassword = '';
      this.syncedPasswordReEnter = '';
    },
    inputCheck() {
      //入力チェック
      if (this.isInvalid()) {
        this.error = '入力内容に不備があります。エラー内容を確認のうえ、入力をお願いします。'
        this.scrollTop();
        return;
      }
      let params = new URLSearchParams();
      params.append("phone_number", this.phoneNumber);
      params.append("birthday", this.dayjsFormat(this.birthday, 'YYYY-MM-DD'));
      params.append("player_id", this.playerId);

      const api = "/migration_valid";
      this.axios
        .post(api, params)
        .then(() => {
          this.$router.push({ hash: "#confirm" });
        })
        .catch((err) => {
          this.formPhoneNumberDuplicateValid = true;
          this.phoneErrMessage = err.response.data.message;
            const element = document.getElementById('formPhoneNumber');
            element.scrollIntoView({ block: "center" });
        });
    },
    isInvalid() {
      var retBool = false;
      this.formPhoneNumberDuplicateValid = false;
      this.phoneErrMessage = "";
      this.scrollElementId = "";
      this.formNicknameValid = false;
      this.genderValid = false;
      this.formPostcodeValid = false;
      this.formPrefecturesValid = false;
      this.formAddressValid = false;
      this.formPhoneNumberValid = false;
      this.formPhoneNumberLengthValid = false;
      this.formMypageValid = false;
      this.formPromotionalValid = false;
      this.formParentalConsentValid = false;
      this.formTermsOfServiceValid = false;
      this.formPasswordValid = false;
      this.formPasswordReEnterValid = false;
      this.formPostcodeSearchValid = false;

      //ニックネーム
      if (!this.nickname) {
        this.formNicknameValid = true;
        retBool = true;
      }

      //性別
      if (!this.gender) {
        this.genderValid = true;
        retBool = true;
      }

      //郵便番号
      let postcode = String(this.postcode)
      if (!this.postcode || !postcode.match(/[0-9]{7}/gi)) {
        this.formPostcodeValid = true;
        retBool = true;
      }

      //都道府県
      console.log(this.prefectures)
      if (!this.prefectures) {
        this.formPrefecturesValid = true;
        retBool = true;
      }

      //住所
      if (!this.address) {
        this.formAddressValid = true;
        retBool = true;
      }

      //電話番号
      if(!this.phoneNumber)
      {
        this.formPhoneNumberValid = true
        retBool= true
      }
      if (this.phoneNumber && !this.phoneNumber.match(/^([0-9]{11})$/gi)) {
        this.formPhoneNumberLengthValid = true
        retBool= true
      }

      //公開情報
      console.log(this.mypage)
      if (this.mypage === '') {
        this.formMypageValid = true;
        retBool = true;
      }

      //プロモーションメール
      console.log(this.promotionalEmail)
      if (!this.promotionalEmail) {
        this.formPromotionalValid = true;
        retBool = true;
      }

      //保護者同意
      if (this.minorsFlg && !this.parentalConsent) {
        this.formParentalConsentValid = true;
        retBool = true;
      }

      //プレイヤーズ同意
      console.log(this.termsOfService)
      if (!this.termsOfService) {
        this.formTermsOfServiceValid = true;
        retBool = true;
      }

      //パスワード
      console.log(this.password)
      if (
        !this.password ||
        !this.password.match(
        /^((?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])|(?=.*[A-Z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])|(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]))([a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]){8,}$/g
        )
      ) {
        this.formPasswordValid = true;
        retBool = true;
      }

      //パスワード(再入力)
      console.log(this.passwordReEnter)
      if (this.password !== this.passwordReEnter) {
        this.formPasswordReEnterValid = true;
        retBool = true;
        console.log('hasError')
      }

      return retBool;
    },
    dayjsFormat(date, format) {
      dayjs.locale(ja)
      if (date !== null && typeof date === 'object' && 'date' in date) {
          return dayjs(date.date).format(format)
      }
      return format
    },
    serachPostCode() {
        this.formPostcodeSearchValid = false
        let api = process.env.VUE_APP_ZIP_CODE_URL
        api = api + this.syncedPostcode
        this.axios.get(api, {adapter: axiosJsonpAdapter})
        .then(response => {
            if (response.data.status === 400) {
                this.formPostcodeSearchValid = true
                this.scrollElementId = this.scrollElementId === '' ? 'formPostcode': this.scrollElementId
                let element = document.getElementById(this.scrollElementId)
                element.scrollIntoView({block: 'center'})
                return
            } else if (response.data.results === null) {
                this.formPostcodeSearchValid = true
                this.scrollElementId = this.scrollElementId === '' ? 'formPostcode': this.scrollElementId
                let element = document.getElementById(this.scrollElementId)
                element.scrollIntoView({block: 'center'})
                return
            } else {
                let prefecture;
                for(let key in this.prefectureList) {
                    let pref = this.prefectureList[key];
                    if (pref.title == response.data.results[0].address1) {
                        prefecture = pref
                    }
                }
                this.syncedPrefectures = prefecture.id;
                this.syncedAddress = response.data.results[0].address2 + response.data.results[0].address3;
            }
        })
        .catch(() =>{
            this.formPostcodeSearchValid = true
            this.scrollElementId = this.scrollElementId === '' ? 'formPostcode': this.scrollElementId
            const element = document.getElementById(this.scrollElementId)
            element.scrollIntoView({block: 'center'})
        })
    },
    getPolicyLinkURL() {
        const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
        return base_url + '/event/players_policy/'
    },
    scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@use "~@/assets/style/global" as g;
.c-content {
  @include g.pc {
    width: 760px;
  }
}

.c-title-page {
  margin-bottom: 36px;
}

.page-description {
  @include g.pc {
    margin-bottom: 56px;
  }
  @include g.sp {
    margin-bottom: 28px;
    text-align: left;
  }
}

.form {
  width: 100%;
  border-top: 1px solid #b3d8ee;
  padding-top: 36px;
  padding-bottom: 40px;
  @include g.pc {
    border-bottom: 1px solid #b3d8ee;
  }
  @include g.sp {
    padding-top: 26px;
  }

  &-input {
    &-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-nickname {
      @include g.sp {
        & > input[type="text"] {
          width: 100%;
        }
      }
    }
    &-postcode {
      @include g.sp {
        display: block;
      }
      .input-container {
        @include g.pc {
          & > input[type="text"] {
            width: 230px;
            margin-bottom: 8px;
          }
        }
        @include g.sp {
          & > input[type="text"] {
            width: 100%;
            margin-right: 0;
          }
        }
      }

      .postcode-btn-container {
        &.u-display-pc {
            @include g.sp {
                display: none;
            }
        }
        @include g.sp {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }
        & > button {
          display: inline-block;
          font-size: g.$font-size-small;
          color: g.$blue-40;
          cursor: pointer;

          @include g.pc {
            &:hover {
              text-decoration: underline;
              color: g.$color-link-hover;
            }
          }
        }
      }
    }
    &-prefecture {
      .c-input-select {
        select {
          width: 230px;
        }
      }
    }
    &-tel {
      @include g.pc {
        & > input[type="tel"] {
          margin-bottom: 8px;
        }
      }
    }
    &-password {
      @include g.pc {
        & > input[type="password"] {
          margin-bottom: 8px;
        }
        & > .password-text {
          margin-bottom: 28px;
          & > p {
            & + .error {
                height: 40px;
              }
            }
        }
      }
      @include g.sp {
        & > input[type="password"] {
          margin-bottom: 8px;
        }
        & > .password-text {
          margin-bottom: 16px;
          & > p {
            & + .error {
              height: 80px;
            }
          }
        }
      }
    }
    &-promotional {
      @include g.pc {
        & > p {
          & + .error {
            margin-bottom: 0px;
          }
        }
      }
      @include g.sp {
        & > p {
          & + .error {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
.error-parental-consent {
  @include g.pc {
    margin-bottom: 16px;
  }
  @include g.sp {
    margin-bottom: 16px;
  }
}
.c-table-list {
  &::before {
    border: 0;
  }
  .input-required-row {
    background: #edf6fb;
    .column-left {
      background: #edf6fb;
    }
    @include g.sp {
      .column-right {
        margin-bottom: 0;
        padding-bottom: 24px;
      }
    }
  }
  &-row {
    @include g.sp {
      margin: 0 -20px;
      padding: 0 20px;
    }
    &:nth-of-type(1) {
      border-top: 0;
    }
    &:not(:last-child) {
      border-bottom: 0;
    }
    .column {
      @include g.pc {
        &-left {
          flex: 0 0 220px;
          box-sizing: content-box;
          padding-right: 0;
          padding-left: 30px;
          &.wide {
            align-items: normal;
          }
        }
        &-right {
          padding-left: 0;
          padding-right: 30px;
        }
      }
      @include g.sp {
        &-left {
          padding: 10px 0 0 0;
          margin-bottom: 0px;
          font-size: 14px;
          line-height: 1.5;
        }
        &-right {
          padding: 10px 0 0 0;
          margin-bottom: 24px;
          font-size: 18px;
        }
      }
      &-left {
        background-color: g.$color-white;
      }
    }
  }
}
.parental-consent-wrapper,
.tos-wrapper {
  border: 1px solid g.$color-border-gray;
  border-radius: 10px;
  padding: g.$margin-large;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  @include g.sp {
    padding: g.$margin;
  }
}
.submit-button-container {
  @include g.pc {
    padding: 56px;
  }
  @include g.sp {
    padding: 56px 10px;
    .confirm-btn {
      width: 100%;
    }
  }
}
.error {
    color: g.$color-error-red;
    font-size: g.$font-size-small;
    height: 20px;
    font-weight: bolder;
    @include g.sp {
        margin-top: 12px;
    }
}
.kome-margin{
  @include g.sp {
    margin-top: 4px;
  }
}
</style>
