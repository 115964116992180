<template>
  <system-page :page-settings="page">
    <div class="c-content u-text-center">
      <p class="message">
        SMSの認証が完了しました！<br>
        引き続き、ポケモンカードゲーム プレイヤーズクラブをお楽しみください。
      </p>
      <button
        type="button"
        class="c-btn c-btn-primary-outline"
         v-on:click="mypage"
      >
        マイページへ
      </button>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'

export default {
  name: 'SmsIdentifyCompletePage',
  components: {
    SystemPage,
  },
  data: function() {
    return {
      page: {
        title: 'SMS認証完了',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ SMS認証完了のページ。',
        breadcrumb: [
          { name: 'SMS認証', path: '' },
        ],
      },
    }
  },
  methods: {
    mypage(){
      this.$router.push('/mypage')
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

@include g.pc {
  .message {
    margin-bottom: 55px;
  }
}

@include g.sp {
  .message {
    margin-bottom: 50px;
  }
}
</style>
