<template>
    <footer class="footer">
        <button
            @click="toTop()"
            class="footer-toPageTop"
        >
            TO PAGE TOP
        </button>
        <div class="footer-content-wrap">
            <div class="footer-content">
                <nav class="footer-nav">
                    <ul>
                        <li><a :href="getLinkURL('/contact')">お問い合わせ</a></li>
                        <li><a class="c-link" :href="getLinkURL('/event/players_policy/')" target="_blank">プレイヤーズクラブ利用規約</a></li>
                        <li><a :href="getLinkURL('/policy.html')">サイトのご利用</a></li>
                        <li><a :href="getLinkURL('/sitemap.html')">サイトマップ</a></li>
                        <li><a :href="getLinkURL('/link.html')">リンク</a></li>
                    </ul>
                </nav>
                <div class="footer-rightNotation">
                    <p>©Pokémon. ©Nintendo/Creatures Inc./GAME FREAK inc.</p>
                    <p>ポケットモンスター・ポケモン・Pokémonは任天堂・クリーチャーズ・ゲームフリークの登録商標です。</p>
                    <p>このホームページに掲載されている内容の著作権は(株)クリーチャーズ、(株)ポケモンに帰属します。 このホームページに掲載された画像その他の内容の無断転載はお断りします。</p>
                    <p>このウェブサイト(https://players.pokemon-card.com/)は、株式会社ポケモン(https://www.pokemon.co.jp/corporate/)が運営しております。</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'pcgFooter',
    components: {},
    props: {
        isEventPage: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        getLinkURL(path) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return `${base_url}${path}`
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.footer {
    color: g.$color-font-white;
    margin-top: -56px;

    &-image {
        display: block;
        margin: 0 auto g.$margin-large;

        @include g.sp {
            display: none;
        }
    }
    &-toPageTop {
        display: block;
        width: 195px;
        height: 48px;
        font-size: 18px;
        margin: 0 auto -6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: g.$color-black;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        cursor: pointer;

        @include g.pc {
            &:hover {
                color: rgba(g.$color-white, .7);
            }
        }
    }

    &-content {
        @include g.pc {
            max-width: 1180px;
            min-width: g.$content-width-pc;
            margin-left: auto;
            margin-right: auto;
            padding: 42px 0 44px;
        }
        &-wrap {
            width: 100%;
            background-color: g.$color-black;
            @include g.sp {
                padding: 53px 20px 43px;
            }
        }
    }

    &-nav {
        padding-bottom: 27px;
        font-size: 12px;

        a {
            position: relative;
            display: flex;
            align-items: center;
            color: g.$color-font-white;
            padding-right: 24px;
        }

        @include g.pc {
            & > ul {
                display: flex;

                & > li {
                    &:not(:first-child) {
                        margin-left: 32px;
                    }
                }
            }
            a {
                &::after {
                    @include g.setIconFont('\e909');
                    position: absolute;
                    display: inline-block;
                    right: 0;
                    font-size: 12px;
                    line-height: 1;
                }
                &:hover {
                    color: g.$color-link-hover-gray;
                    text-decoration: underline;
                }
            }
        }

        @include g.sp {
            & > ul {
                & > li {
                    margin-top: 27px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
            a {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                font-size: 14px;
                &::after {
                    @include g.setIconFont('\e92e');
                    font-size: 10px;
                    margin-left: 25px;
                }
            }
        }
    }

    &-rightNotation {
        color: #676767;
        font-size: 10px;
    }
}
</style>
