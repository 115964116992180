<template>
  <span class="event">
    {{ type }}
  </span>
</template>

<script>
export default {
  name: 'EventClassification',
  props: {
    type: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

$bg-color: #0F2538;

.event {
  height: 24px;
  padding: 3px 10px 4px;
  font-size: g.$font-size-exsmall;
  color: g.$color-font-white;
  background-color: $bg-color;
  border-radius: 3px;
}
</style>
