<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <error-msg class="u-mb-lg error-msg" v-if="noDeckCount > 0">
                <p class="un-registered-event-count">
                    デッキが未登録のイベントが<span class="strong"> {{ noDeckCount }} 件</span>あります。<br>
                    デッキの登録は、イベント開催当日の8:30までに登録してください。
                </p>
                <p>
                    <router-link :to="{name: 'MyEventList'}" class="c-link-arrow">出場予定のイベント</router-link>
                </p>
            </error-msg>
            <error-msg class="u-mb-lg error-msg" v-if="error !== null">
                <p>{{ error }}</p>
            </error-msg>
        </div>
        <section class="my-deck-register c-gradientBox u-text-center">
            <h3 class="c-title">マイデッキ登録</h3>
            <template v-if="myDeckList.length < 15">
                <p class="u-text-small">マイデッキは最大15個まで登録ができます</p>
            </template>
            <template v-else>
                 <p class="u-text-small">
                    マイデッキの登録上限は、15個までです。<br>
                    デッキを削除すると、新しいデッキを登録できます。
                 </p>
            </template>
            <div class="u-flex u-flex-center">
                <button
                    @click="openDeckRegistrationModal"
                    type="button"
                    class="c-btn c-btn-primary"
                    :disabled="myDeckList.length >= 15"
                >マイデッキ登録</button>
            </div>
        </section>
        <div class="c-content">
            <section class="my-deck-content">
                <h2 class="c-title c-title-h2">マイデッキリスト</h2>
                <template v-if="myDeckList.length">
                    <ul class="my-deck-list">
                        <li class="my-deck" v-for="(deck, index) in myDeckList" :key="index">
                            <div class="publish-tags">
                                <p class="publish-tag published" v-if="deck.publish_flg === 1">公開</p>
                                <p class="publish-tag not-publish" v-else>非公開</p>
                            </div>
                            <h3 class="deck-title">{{ deck.deck_name }}</h3>
                            <p class="deck-info">
                                <span class="deck-code">{{ deck.deck_code}}</span>
                                <span class="deck-date">登録日：{{ formatDate(deck.create_at.date) }}</span>
                            </p>
                            <ul class="tags">
                                <li
                                    class="tag"
                                    v-for="reguration in deck.available_regulations"
                                    :key="reguration.id"
                                >
                                    {{ reguration }}
                                </li>
                            </ul>
                            <div class="buttons">
                                <a
                                    @click="confirmDeleteMyDeck(deck.id)"
                                    href="#"
                                    class="deck-delete"
                                >
                                    <span>削除する</span>
                                </a>
                                <a
                                    :href="getDeckLinkURL(deck)"
                                    class="deck-show c-btn c-btn-primary c-btn-arrow"
                                    target="_blank"
                                >
                                    デッキを見る
                                </a>
                            </div>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <div class="no-deck u-text-center u-text-small">
                        マイデッキが登録されていません。
                    </div>
                </template>
            </section>
            <div class="u-text-center to-mypage">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイページへ
                </router-link>
            </div>
        </div>
    </system-page>

    <pcg-modal
      class="deck-registration-modal"
      :isActive="activeModal === 'deckRegistrationModal'"
      :bgImage="true"
      :noPadding="true"
      :isDeckResister="true"
      @close="activeModal = null"
    >
        <template #modalHeader>
          <h2>デッキ登録</h2>
        </template>
        <nav class="deck-registration-menu">
            <a href="#deckMaking">デッキ作成</a>
            <a href="#deckCodeRegistration">デッキコード登録</a>
        </nav>
        <div class="deck-registration-body">
            <error-msg
                class="u-mb-lg deck-registration-error-msg"
                :justifySp="'left'"
                :message="deckRegistError"
                v-if="deckRegistError !== null"
            >
            </error-msg>
            <error-msg
                class="u-mb-lg error-msg deck-registration-error-msg"
                :justifySp="'left'"
                :message="'デッキが登録できませんでした'"
                v-if="deckNameError !== null || deckCodeError !== null"
            >
            </error-msg>
            <div class="deck-registration-row">
                <div id="deckMaking" class="deck-registration-col c-box">
                    <h3 class="u-text-center u-text-large u-text-bold u-mb">新しくデッキを作る</h3>
                    <div class="deck-registration-message">
                        <p>カードを選んで、自分だけのデッキを作ろう！</p>
                        <p>作成したデッキから、「デッキコード 」を発行すると、「マイデッキ」に登録できるよ。</p>
                    </div>
                    <div class="u-text-center">
                        <a href="https://www.pokemon-card.com/deck/deck.html" class="c-btn c-btn-primary-outline c-link" target="_blank">デッキ作成ツールへ</a>
                    </div>
                </div>
                <div id="deckCodeRegistration" class="deck-registration-col c-box">
                    <h3 class="u-text-center u-text-large u-text-bold u-mb">デッキコードを登録する</h3>
                    <div class="deck-registration-message">
                        <p>「デッキ作成ツール」で発行したデッキコードを入力して、マイデッキ登録を行ってください。</p>
                    </div>
                    <form @submit.prevent="sendDeckRegistration(publishFlg, deckCode, deckName)">
                        <div class="deck-registration-status">
                            <h4 class="u-mb">デッキの公開/非公開</h4>
                            <div class="u-display-flex u-flex-wrap u-flex-center">
                                <div class="c-radio u-mr">
                                    <input
                                        v-model="publishFlg"
                                        id="radioPublish"
                                        name="radioPublish"
                                        type="radio"
                                        value="1"
                                        checked
                                    >
                                    <label for="radioPublish">公開</label>
                                </div>
                                <div class="c-radio">
                                    <input
                                        v-model="publishFlg"
                                        id="radioNoPublish"
                                        name="radioPublish"
                                        type="radio"
                                        value="0"
                                    >
                                    <label for="radioNoPublish">非公開</label>
                                </div>
                            </div>
                            <p class="c-input-error-text" v-if="publishFlgError !== null">{{ publishFlgError }}</p>
                        </div>
                        <div class="u-mb deck-registration-input">
                            <label for="deckCode" class="u-mb-sm u-display-block c-input-require">デッキコード</label>
                            <input
                                v-model="deckCode"
                                id="deckCode"
                                type="text"
                                class="c-input c-input-text u-width-100"
                                :class="{ 'c-input-error': deckCodeError || deckRegistError }"
                            >
                            <p class="note">※ 半角英数字</p>
                            <p class="c-input-error-text" v-if="deckCodeError !== null">{{ deckCodeError }}</p>
                        </div>
                        <div class="u-mb-lg deck-registration-input">
                            <label for="deckCode" class="u-mb-sm u-display-block c-input-require">デッキの名前</label>
                            <input
                                v-model="deckName"
                                id="deckName"
                                type="text"
                                class="c-input c-input-text u-width-100"
                                :class="{ 'c-input-error': deckNameError }"
                            >
                            <p class="note">※ 全角半角20文字以内</p>
                            <p class="c-input-error-text" v-if="deckNameError !== null">{{ deckNameError }}</p>
                        </div>
                        <div class="u-flex u-flex-center">
                            <button type="submit" class="c-btn c-btn-primary">登録する</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </pcg-modal>

    <pcg-modal
      class="deck-complete-modal"
      :isActive="activeModal === 'deckCompleteModal'"
      :bgImage="true"
      :isDeckResister="true"
      @close="activeModal = null"
    >
        <template #modalHeader>
          <h2>マイデッキ登録完了</h2>
        </template>
        <div class="deck-complete-body">
            <p class="u-text-center u-mb-exlg">デッキを登録しました。「マイデッキ」から確認できます。</p>
            <div>
                <error-msg class="u-mb-lg error-msg" v-if="noDeckCount > 0">
                    <p class="un-registered-event-count">
                        デッキが未登録のイベントが<span class="strong"> {{ noDeckCount }} 件</span>あります。<br>
                        デッキの登録は、イベント開催当日の8:30までに登録してください。
                    </p>
                    <p>
                        <router-link :to="{name: 'MyEventList'}" class="c-link-arrow">出場予定のイベント</router-link>
                    </p>
                </error-msg>
                <error-msg class="u-mb-lg error-msg" v-if="error !== null">
                    <p>{{ error }}</p>
                </error-msg>
            </div>
            <div class="deck-complete-next">
                <p class="u-text-center u-text-small u-mb-20" v-if="myDeckList.length < 15">続けてマイデッキを登録する方はこちら</p>
                <div class="u-flex u-flex-center">
                    <button
                        type="button"
                        class="c-btn c-btn-primary-outline"
                        @click="openDeckRegistrationModal"
                        v-if="myDeckList.length < 15"
                    >
                        マイデッキ登録へ
                    </button>
                    <button
                        type="button"
                        class="c-btn c-btn-primary-outline"
                        @click="activeModal = null"
                        v-else
                    >
                        閉じる
                    </button>
                </div>
            </div>
        </div>
    </pcg-modal>
    <pcg-modal
      class="deck-delete-modal"
      :isActive="activeModal === 'deckDeleteModal'"
      @close="closeDeckDeleteModal()"
    >
        <template #modalHeader>
            <h2>マイデッキ削除確認</h2>
        </template>
        <div class="deck-delete-body">
            <p class="u-text-center u-mb-lg">このデッキを削除します。<br class="u-display-sp">よろしいですか?</p>
            <div class="u-mb-exlg u-text-center">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline u-mr-lg"
                    @click="closeDeckDeleteModal()"
                >
                    キャンセル
                </button>
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    @click="deleteMyDeck()"
                >
                    削除する
                </button>
            </div>
        </div>
    </pcg-modal>
    <pcg-modal
      class="deck-delete-complete-modal"
      :isActive="activeModal === 'deckDeleteCompleteModal'"
      @close="closeDeckDeleteModal()"
    >
        <template #modalHeader>
            <h2>マイデッキを<br class="u-display-sp">削除しました!</h2>
        </template>
        <div class="deck-delete-body">
            <div class="u-flex u-flex-center u-mb-exlg">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeDeckDeleteModal()"
                >
                    閉じる
                </button>
            </div>
        </div>
    </pcg-modal>
    <sp-menu />
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import dayjs from "dayjs"
import pcgModal from '@/component/Modal.vue'
import SpMenu from '@/views/Mypage/component/SpMenu.vue'

export default {
    name: 'HomePage',
    components: {
        SystemPage,
        errorMsg,
        pcgModal,
        SpMenu
    },
    props: {},
    data() {
        return {
            noDeckCount: 0,
            myDeckList: [],
            error: null,
            deckCode: '',
            deckName: '',
            publishFlg: 1,
            publishFlgError: null,
            deckRegistError: null,
            deckCodeError: null,
            deckNameError: null,
            activeModal: null,
            deleteDeckId: null,
        }
    },
    created: function() {
        this.init()
    },
    methods: {
        init() {
            const getEntryEventApi = '/get_entry_event_info'
            const getMyDeckApi = '/deck_search'

            this.axios.post(getEntryEventApi)
            .then(response => {
                this.noDeckCount = response.data.no_deck_count
            })
            .catch(err => {
                if (err.response && err.response.data.status === 401) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                if (err.response.status === 404) {
                    this.noDeckCount = 0
                } else {
                    this.error = err.response.data.message
                }
            })

            this.axios.post(getMyDeckApi)
            .then(response => {
                this.myDeckList = response.data.deck
            })
            .catch(err => {
                if (err.response.status === 404) {
                    this.myDeckList = []
                } else {
                    this.error = err.response.data.message
                }
            })
            .finally(() => {
                if (this.$route.query['code']) {
                    if (this.myDeckList.length >= 15) {
                        this.error = 'デッキ登録数が最大です。不要なデッキを削除してから登録してください。'
                        return
                    }

                    this.deckCode = this.$route.query['code']
                    this.openDeckRegistrationModal()
                }
            })
        },
        formatDate(datetime) {
            return dayjs(datetime).format("YYYY/MM/DD");
        },
        confirmDeleteMyDeck(id) {
            this.deleteDeckId = id
            this.activeModal = 'deckDeleteModal'
        },
        deleteMyDeck() {
            const deleteMyDeckApi = '/deck_delete'
            let params = new URLSearchParams()

            params.append('id', this.deleteDeckId)
            this.axios.post(deleteMyDeckApi, params)
            .then(() => {
                this.deleteDeckId = null
                this.init()
                this.activeModal = 'deckDeleteCompleteModal'
            })
            .catch(err => {
                this.deleteDeckId = null
                this.error = err.response.data.message
                this.activeModal = null
            })
        },
        openDeckRegistrationModal() {
          this.activeModal = 'deckRegistrationModal'
        },
        sendDeckRegistration(publishFlg, deckCode, deckName) {
            const registerMyDeckApi = '/deck_register'
            let params = new URLSearchParams()
            let errorFlg = false
            this.publishFlgError = null
            this.deckCodeError = null
            this.deckNameError = null
            this.deckRegistError = null

            if (!publishFlg) {
                this.publishFlgError = '公開／非公開を選択してください'
                errorFlg = true
            }

            if (!deckCode) {
                this.deckCodeError = 'デッキコードを入力してください'
                errorFlg = true
            }
            else if (!deckCode.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/))
            {
                this.deckCodeError = '半角英数字記号で入力してください'
                errorFlg = true
            }
            else if (deckCode.length != 20)
            {
                this.deckCodeError = 'デッキコードは-を含めた20桁で入力してください'
                errorFlg = true
            }

            if (!deckName || deckName.length > 20) {
                this.deckNameError = '全角半角20文字以内で入力してください'
                errorFlg = true
            }

            if (errorFlg) {
                this.scrollModal()
                return
            }

            params.append('publish_flg', publishFlg)
            params.append('deck_code', deckCode)
            params.append('deck_name', deckName)
            this.axios.post(registerMyDeckApi, params)
            .then(() => {
                this.activeModal = 'deckCompleteModal'
                this.resetDeckParams()
                let query = Object.assign({}, this.$route.query);
                delete query.code;
                this.$router.replace({ query });
                this.init()
            })
            .catch(err => {
                this.deckRegistError = err.response.data.message
                this.scrollModal()
            })
        },
        resetDeckParams() {
            this.deckCode = ''
            this.deckName = ''
            this.publishFlg = 1
        },
        getDeckLinkURL(deck) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/deck/confirm.html/deckID/' + deck.deck_code
        },
        closeDeckDeleteModal() {
            this.activeModal = null
            this.deleteDeckId = null
        },
        scrollModal() {
            const elements = document.getElementsByClassName('deck-registration-modal')
            elements.item(0).scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
    },
    computed: {
        page() {
            let page =  {
                title: 'マイデッキ',
                titleForHeader: '',
                desc: '',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            }
            let player = this.$store.getters.getUser();
            if (!player) {
                return page
            }
            let defaultTitle = page.title;
            page.titleForHeader = player.nickname + "さんの" + defaultTitle
            page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんのマイデッキ。";
            return page
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style/my-deck-content';

.error-msg {
    font-size: 16px;

    .un-registered-event-count {
        margin-bottom: 10px;

        .strong {
            font-weight: bold;
            font-size: 18px;
        }
    }

    a {
        color: g.$color-link;
    }
}

.my-deck-register {
    padding: 34px 0 38px;
    margin: 0 auto 60px;
    width: 960px;

    .c-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 12px;
        color: g.$color-black;
    }

    p {
        margin-bottom: 17px;
    }
}

.to-mypage {
    margin-bottom: 16px;
}

.deck-registration-modal {
    h2 {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        color: g.$color-black;
    }

    .deck-registration-menu {
        display: none;
    }

    .deck-registration-body {
        padding: 0 30px 70px;

        .deck-registration-error-msg {
            margin: 0 10px 30px;
        }

        .deck-registration-row {
            display: flex;
            color: g.$color-black;
        }

        .deck-registration-col {
            margin: 0 10px;
            border-width: 6px;
            padding: 40px 30px;

            .deck-registration-message {
                margin-bottom: 30px;

                p {
                    margin-bottom: 10px;
                    font-weight: 500;
                }
            }

            .deck-registration-status {
                background: #F8F8F8;
                text-align: center;
                padding: 18px 0;
                border-radius: 12px;
                margin-bottom: 26px;

                h4 {
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            .deck-registration-status {
                .u-mr {
                    margin-right: 40px;
                }
            }

            .deck-registration-input {
                label {
                    font-size: 14px;
                    font-weight: 500;
                }

                .note {
                    font-size: 12px;
                    color: g.$color-black-a060;
                    margin-top: 5px;
                }
            }
        }
    }

    .c-btn {
        width: 290px;
    }
}

.deck-complete-modal {
    h2 {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        color: g.$color-black;
    }

    .deck-complete-next {
        border: 1px solid transparent;
        border-top-color: #B3D8EE;
        border-bottom-color: #B3D8EE;
        padding: 24px 0;
        margin-bottom: 75px;
    }
}

.deck-delete-modal, .deck-delete-complete-modal {
    h2 {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
    }
}

@include g.sp {
    .error-msg {
        font-size: 14px;

        .un-registered-event-count {
            margin-bottom: 14px;

            .strong {
                font-weight: bold;
                font-size: 16px;
            }
        }
    }

    .my-deck-register {
        padding: 28px 0 32px;
        width: auto;

        .c-title {
            font-size: 18px;
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 11px;
        }
    }

    .my-deck-content {
        .my-deck-list {
            .my-deck {
                width: 100%;
            }

            .publish-tags {
                margin-bottom: 11px;
            }

            .deck-title {
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 7px;
            }

            .deck-info {
                margin-bottom: 13px;

                .deck-code {
                    display: block;
                }
                .deck-date {
                    display: block;
                }
            }

            .buttons {
                justify-content: space-between;

                .deck-delete {
                    margin: 0;
                }

                .deck-show {
                    width: 195px;
                }
            }
        }
    }

    .to-mypage {
        display: flex;
        justify-content: center;
        margin: 0;
    }

    .deck-registration-modal {
        h2 {
            font-size: 24px;
        }

        .deck-registration-menu {
            display: flex;

            a {
                display: block;
                flex: 1;
                color: g.$color-font;
                text-align: center;
                font-size: g.$font-size-small;
                font-weight: bold;
                padding-bottom: 20px;
                position: relative;

                &::after {
                    font-family: 'pcg_icon';
                    content: '\e900';
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom: 0px;
                    font-size: 10px;
                }
            }
        }

        .deck-registration-body {
            background: #CFE3EF;
            display: block;
            padding: 20px 0;

            .deck-registration-error-msg {
                margin: 0 15px 20px;
            }

            .deck-registration-row {
                display: block;
            }

            .deck-registration-col {
                background: g.$color-white;
                margin: 0 15px 20px;
                border-radius: 8px;
                padding: 24px 24px 20px;

                h3 {
                    font-size: 18px;
                }

                .deck-registration-message {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .c-btn {
            width: 242px;
        }
    }

    .deck-complete-modal {
        h2 {
            font-size: 24px;
        }

        .u-mb-exlg {
            margin-bottom: 40px;
        }

        .deck-complete-next {
            margin-bottom: 40px;

            .c-btn {
                width: 275px;
            }
        }
    }

    .deck-delete-modal {
        h2 {
            font-size: 24px;
            line-height: 1.5;
        }

        .u-mb-exlg {
            margin-bottom: 24px;
        }

        .c-btn {
            margin: 0 auto 16px;
            width: 275px;
        }
    }

    .deck-delete-complete-modal {
        h2 {
            font-size: 24px;
            line-height: 1.5;
        }

        .c-btn {
            width: 275px;
        }

        .u-mb-exlg {
            margin-bottom: 40px;
        }
    }
}
</style>
