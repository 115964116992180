<template>
    <nav class="menu">
        <ul class="list">
            <li>
                <router-link
                    :to="{name: 'Mypage'}"
                    class="link mypage"
                >
                    マイページ
                </router-link>
            </li>
            <li>
                <router-link
                    :to="{name: 'MyEventList'}"
                    class="link list"
                >
                    出場予定イベント
                </router-link>
            </li>
            <li>
                <router-link
                    :to="{name: 'MypageEventResult'}"
                    class="link result"
                >
                    イベント成績
                </router-link>
            </li>
            <li>
                <router-link
                    :to="{name: 'DeckList'}"
                    class="link deck"
                >
                    マイデッキ
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'MypageMenu',
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: g.$z-index-header - 1;
    width: 100%;
    height: 88px;
    background: g.$color-gradietion;
    box-shadow: 0 -3px 6px rgba(g.$color-black, .16);
    @include g.pc {
        display: none;
    }
}

.list {
    display: flex;
    height: 54px;
    & > li {
        position: relative;
        flex: 1;
        &:not(:last-child) {
            & > .link::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 1px;
                height: 24px;
                margin: auto 0;
                background: rgba(g.$color-white, .6);
            }
        }
    }
    .link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
        color: g.$color-white;
        &::before {
            margin-bottom: 5px;
            font-weight: normal;
            line-height: 1;
        }
        &.mypage::before {
            @include g.setIconFont("\e906");
            font-size: 24px;
        }
        &.list::before {
            @include g.setIconFont("\e91e");
            font-size: 19px;
        }
        &.result::before {
            @include g.setIconFont("\e923");
            font-size: 20px;
        }
        &.deck::before {
            @include g.setIconFont("\e90f");
            font-size: 20px;
        }
    }
}
</style>
