<template>
    <div class="item">
        <div class="item-question">
            {{ question }}
        </div>
        <div class="item-answer">
            <div class="main">
                <slot></slot>
                <div class="sub">
                    <slot name="sub"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        question: {
            type: String,
            required: true,
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.item {
    margin-top: 28px;
    padding: 20px;
    border: 1px solid g.$color-black-a025;
    border-radius: 8px;
    @include g.sp {
        margin-top: 24px;
    }
}

.item-question,
.item-answer {
    display: flex;
    &::before {
        display: inline-block;
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        margin-right: 8px;
        line-height: 26px;
        text-align: center;
        font-weight: bold;
        color: g.$color-white;
        border-radius: 100%;
    }
}

.item-question {
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    border-bottom: 1px dotted g.$color-black;
    &::before {
        content: 'Q';
        background: g.$color-blue-a060;
    }
}

.item-answer {
    &::before {
        content: 'A';
        background: g.$color-green-a060;
    }

    & > .main {
        flex: 1;
        min-width: 0;
        ::v-slotted(p) {
            &:not(:first-child) {
                margin-top: 8px;
            }
            & + .c-list {
                margin-top: 8px;
            }
        }
        & > .sub {
            &:empty {
                display: none;
            }
            width: 100%;
            margin-top: 14px;
            padding: 16px;
            font-size: g.$font-size-small;
            line-height: 21px;
            font-weight: 500;
            background: g.$color-green-a010;
            border-radius: 8px;
            ::v-slotted(.c-link) {
                line-height: g.$line-height-small;
                font-weight: normal;
                word-break: break-all;
            }
            ::v-slotted(p) {
                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }
    }
}
</style>
