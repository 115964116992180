<template>
    <system-page :page-settings="page">
        <p class="u-text-center message">
            登録されているメールアドレス宛にパスワード再設定のためのメールをお送りいたします。<br>
            登録済みのメールアドレスと生年月日を入力してください。
        </p>
        <error-msg
            v-if="error"
            class="u-mb-lg error-msg"
            :message="error"
        >
        </error-msg>
        <div class="c-box login">
            <div class="form">
                <form @submit.prevent="sendMail(formMailAddress, birthday)">
                    <div class="mailAddress">
                        <label for="formMailAddress" class="u-mb-sm u-display-block">メールアドレスまたはプレイヤーID</label>
                        <input
                            v-model="formMailAddress"
                            id="formMailAddress"
                            type="text"
                            class="c-input c-input-text u-width-100"
                            :class="{ 'c-input-error': hasMailError }"
                        >
                    </div>
                    <div>
                        <select-birthday v-model:birthday="birthday" v-model:hasError="hasBirthdayError"></select-birthday>
                    </div>
                    <div class="u-text-center">
                        <button
                            type="submit"
                            class="c-btn c-btn-primary"
                        >メールを送信する</button>
                    </div>
                </form>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import selectBirthday from '@/component/SelectBirthday.vue'

export default {
    name: 'MailRegisterPage',
    components: {
        SystemPage,
        errorMsg,
        selectBirthday,
    },
    data: function() {
        return {
            page: {
                title: 'パスワード再設定',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ パスワード再設定のページ。',
                breadcrumb: [
                ],
            },
            birthday: {
                year: null,
                month: null,
                day: null,
            },
            formMailAddress: null,
            error: null,
            hasMailError: false,
            hasBirthdayError: false,
        }
    },
    methods: {
        sendMail(formMailAddress, birthday) {
            this.hasMailError = false
            this.hasBirthdayError = false
            if (!formMailAddress) {
                this.error = 'メールアドレスを入力してください。'
                this.hasMailError = true
                window.scrollTo({top: 0,})
                return
            }
            if (!birthday.year || !birthday.month || !birthday.day) {
                this.error = '生年月日をすべて入力してください。'
                this.hasBirthdayError = true
                window.scrollTo({top: 0,})
                return
            }

            let params = new URLSearchParams()
            params.append('mail_address', formMailAddress)
            params.append('birthday', birthday.year + "/" + birthday.month + "/" + birthday.day)
            params.append('is_mypage', '0')

            this.axios.post('/send_change_mail', params)
            .then(() => {
                this.$router.push({name: 'PasswordMailComplete'})
            })
            .catch(err => {
                if (err.response && err.response.data.code === 403) {
                    this.error = '入力されたメールアドレスと生年月日が一定回数連続して一致しなかったためログインを制限させていただきました。しばらく時間を置いて再度お試しください。'
                } else {
                    this.error = err.response.data.message
                }
                window.scrollTo({top: 0,})
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.error-msg {
    @include g.pc {
        width: 620px;
    }
    @include g.sp {
        width: 335px;
    }
}

.message {
    padding: 0 20px 50px 20px;
}

.login {
    padding: 50px;
    width: 620px;
    margin: 0 auto;
    font-size: 14px;

    .form {
        padding: 0 50px;

        .mailAddress {
            margin-bottom: 44px;
        }
        .selectBirthday {
            margin-bottom: 56px;
        }
        .birthday {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .password {
        margin-bottom: g.$margin;
    }
}

@include g.sp {
    .message {
        padding: 0 20px 28px 20px;
        text-align: left;
    }

    .login {
        width: 100%;
        padding: 0 20px;

        .form {
            padding: 0;
            .mailAddress {
                margin-bottom: 28px;
            }
            .selectBirthday {
                margin-bottom: 48px;
            }
        }
    }
}

</style>
