<template>
    <div class="c-content c-content-wide-sp">
        <div class="eventResult-table">
            <div class="eventResult-table-title-pc">
                <div class="header-title event">
                    イベント名
                </div>
                <div class="header-title rank">
                    成績
                </div>
                <div class="header-title point">
                    獲得チャンピオン<br>
                    シップポイント
                </div>
                <div class="header-title deck">
                    参加デッキ
                </div>
            </div>
            <div
                v-for="result,index in resultList"
                :key="`event-${index}`"
                class="eventResult-table-row"
            >
                <div class="eventResult-table-eventTitle">
                    <div class="eventResult-table-title-top">
                         <template v-if="result.event_holding_id != NULL && linkEventTypes.includes(result.event_type_id) && result.hidden_flg != 1">
                            <router-link
                                :to="{name: 'EventResult', params: {id: result.event_holding_id }}"
                            >
                                <span class="title">{{ result.event_name }}</span>
                                <span class="date">{{ dayjsFormat(result.event_date.date) }}</span>
                            </router-link>
                        </template>
                        <template v-else>
                            <span class="title">{{ result.event_name }}</span>
                            <span class="date">{{ dayjsFormat(result.event_date.date) }}</span>
                        </template>
                    </div>
                    <div class="eventResult-table-title-bottom">
                        <span class="area">{{ result.prefecture_title }}</span>
                        <template v-if="result.event_type_id === 1">
                            <span class="organizer">
                                株式会社ポケモン
                            </span>
                        </template>
                        <template v-else>
                            <router-link
                                v-if="result.shop_id"
                                :to="`/event/search/${result.shop_id}/list`"
                                class="organizer"
                            >
                                {{ result.shop_name }}
                            </router-link>
                            <span
                                class="organizer"
                                v-else-if="result.shop_name_display"
                            >
                                {{ result.shop_name_display }}
                            </span>
                            <span
                                class="organizer"
                                v-else
                            >
                                {{ result.venue ? result.venue : '' }}
                            </span>
                        </template>
                    </div>
                </div>
                <div class="eventResult-table-rank">
                    <div class="eventResult-table-title">
                        成績
                    </div>
                    <div class="eventResult-table-content">
                        <template v-if="displayParticipation(result)">
                            <span>参加</span>
                        </template>
                        <template v-else>
                            <span :class="rankClass(result.rank, result.event_type_id)">
                                <span class="number">{{ displayRank(result.rank, result.event_type_id) }}</span>
                                {{ displayText(result) }}
                            </span>
                            <span v-if="result.event_type_id != 1 && result.participant > 0">（{{ result.participant }}人中）</span>
                        </template>
                    </div>
                </div>
                <div class="eventResult-table-point">
                    <div class="eventResult-table-title">
                        獲得チャンピオン<br>
                        シップポイント
                    </div>
                    <div class="eventResult-table-content">
                        <span class="point">
                            <template v-if="result.champion_ship_point > 0">
                                <span class="point-number">{{ result.champion_ship_point }}</span>pt
                            </template>
                            <template v-else>
                                <span class="point-number">―</span>
                            </template>
                        </span>
                    </div>
                </div>
                <div class="eventResult-table-deck">
                    <a
                        v-if="result.deck_code"
                        class="deck-link"
                        :href="getDeckLinkURL(result.deck_code)"
                        target="_blank"
                    >デッキを見る</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    name: 'EventResultTable',
    components: {
    },
    props: {
        resultList: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            linkEventTypes: [1, 2, 7], // チャンピオンズリーグ・シティリーグ・シールド戦
        }
    },
    methods: {
        dayjsFormat(date) {
            dayjs.locale(ja)

            return dayjs(date).format('YYYY/MM/DD（dd）')
        },
        getDeckLinkURL(deck_code) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/deck/confirm.html/deckID/' + deck_code
        },
        displayRank(rank, eventType) {
            if (eventType === 1) {
                return rank
            }

            const rankNumber = parseInt(rank)
            if (rankNumber === 1 || rankNumber === 2) {
                return rankNumber
            } else if (rankNumber === 3 || rankNumber === 4) {
                return '3～4'
            } else if (rankNumber >= 5 && rankNumber <= 8) {
                return '5～8'
            } else if (rankNumber >= 9 && rankNumber <= 16) {
                return '9～16'
            } else if (rankNumber >= 17 && rankNumber <= 32) {
                return '17～32'
            } else if (rankNumber >= 33 && rankNumber <= 48) {
                return '33～48'
            } else if (rankNumber >= 49 && rankNumber <= 64) {
                return '49'
            } else if (rankNumber >= 65) {
                return '65'
            } else if (!rankNumber) {
                return rank
            }
        },
        rankClass(rank, eventType) {
            const rankNumber = parseInt(rank)
            if (rankNumber < 4) {
                return `rank-${rank}`
            }
            if (rankNumber === 4 && eventType != 1) {
                return `rank-3`
            }
        },
        displayParticipation(result) {
            // rankはStringの形式でくるので===で比較しない
            if (result.event_type_id === 1 && result.rank == 9999) {
                return true
            } else if (result.event_type_id != 1 && result.rank == 999) {
                return true
            } else {
                return false
            }
        },
        displayText(result) {
            // rankが数字以外で来たときは"位（以降）"の表示をしない
            if (parseInt(result.rank)) {
                return result.rank > 48 && result.event_type_id != 1 ?  '位以降' : '位'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use './style.scss';
</style>
