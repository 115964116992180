import { createStore } from 'vuex'
import Axios from 'axios'

const store = createStore({
    state() {
        const userToken = localStorage.getItem('userToken')
        if (userToken && userToken.length > 0) {
            Axios.defaults.headers.common['X-Authentication'] = userToken
        }
        const userTokenDeadline = localStorage.getItem('userTokenDeadline')
        if (userTokenDeadline && userTokenDeadline.length > 0) {
            Axios.defaults.headers.common['X-Authentication-Deadline'] = userTokenDeadline
        }

        return {
            isBrowserBack: false,
            isLoading: false,
            loggedIn: ((userToken && userToken.length > 0) ? true : false),
            userName: null,
            messageUnreadCount: null,
            master: {},
            isSp: false,
            prefectureList: [],
            phoneNumber: null,
            mail: null,
            user: null
        }
    },
    getters: {
        getPrefectureList(state) {
            return function () {
                if (state.prefectureList.length !== 0) {
                    return state.prefectureList
                }
                const api = '/master/prefecture'
                Axios.get(api)
                .then(response => {
                    state.prefectureList = response.data.prefectureList
                    return state.prefectureList
                })
            }
        },
        getPrefectureName(state) {
            return function (id) {
                if (state.prefectureList.length !== 0) {
                    return state.prefectureList[id].title
                }
                const api = '/master/prefecture'
                Axios.get(api)
                .then(response => {
                    state.prefectureList = response.data.prefectureList
                    return state.prefectureList[id].title
                })
                .catch(() => {
                    return null
                })
            }
        },
        getUser(state) {
            return function() {
                if (!state.loggedIn) {
                    return null
                }

                if (state.user) {
                    return state.user
                }

                Axios.post('/get_player_account')
                .then(response => {
                    state.user = response.data.player
                    return response.data.player
                })
                .catch(() => {
                    localStorage.removeItem('userToken')
                    localStorage.removeItem('userTokenDeadline')
                    localStorage.removeItem('playerName')
                    state.loggedIn = false
                    state.userName = null
                    state.user = null
                    Axios.defaults.headers.common['X-Authentication'] = null
                    Axios.defaults.headers.common['X-Authentication-Deadline'] = null
                    return null
                })
            }
        }
    },
    mutations: {
        checkDevice(state) {
            const mobileDeviceSize = 768 + 1
            const ua = navigator.userAgent
            if (ua.indexOf('iPhone') > 0 | ua.indexOf('iPad') > 0 | ua.indexOf('Android') > 0) {
                state.isSp = true
            } else if (window.innerWidth < mobileDeviceSize) {
                state.isSp = true
            } else {
                state.isSp = false
            }
        },
        setIsLoggedIn: function (state, isLoggedIn) {
            state.loggedIn = isLoggedIn
        },
        setUserName: function (state, userName) {
            state.userName = userName
        },
        setLoginState(state, params) {
            localStorage.setItem('userToken', params.userToken)
            localStorage.setItem('userTokenDeadline', params.userTokenDeadline)
            localStorage.setItem('playerName', params.userName)
            state.loggedIn = true
            state.userName = params.userName
            state.mail = params.mail
            state.phoneNumber = params.phoneNumber
            Axios.defaults.headers.common['X-Authentication'] = params.userToken
            Axios.defaults.headers.common['X-Authentication-Deadline'] = params.userTokenDeadline
        },
        setLogoutState(state) {
            localStorage.removeItem('userToken')
            localStorage.removeItem('userTokenDeadline')
            localStorage.removeItem('playerName')
            state.loggedIn = false
            state.userName = null
            state.user = null
            Axios.defaults.headers.common['X-Authentication'] = null
            Axios.defaults.headers.common['X-Authentication-Deadline'] = null
        },
        setPhoneNumber(state, phoneNumber) {
            state.phoneNumber = phoneNumber
        },
        setMail(state, mail) {
            state.mail = mail
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setIsBrowserBack(state, isBrowserBack) {
            state.isBrowserBack = isBrowserBack
        },
        refreshUser(state) {
            Axios.post('/get_player_account')
            .then(response => {
                state.user = response.data.player
                return response.data.player
            })
            .catch(() => {
                return null
            })
        }
    },
    actions: {},
})

export default store
