<template>
    <system-page :page-settings="page">
        <div class="c-content c-content-wide-sp">
            <nav class="periodFilter">
                <div class="c-input c-input-select">
                    <select v-model="years.selectYear" id="select1" @change="getJudgeHistory">
                        <option :value="null">すべて</option>
                        <option v-for="year in years.yearList" :key="year" :value="year">{{ year }}/01/01〜{{ year }}/12/31</option>
                    </select>
                </div>
            </nav>
            <section class="history">
                <div class="c-content" v-if="events && events.length === 0">
                    <div class="history empty">
                        <header class="empty">現在、表示できるジャッジ履歴はありません。</header>
                    </div>
                </div>
                <div v-else>
                    <div class="c-content">
                        <div class="history">
                            <header>イベント名</header>
                        </div>
                    </div>
                    <div 
                        class="event-list"
                        v-for="event in events"
                        :key="event.event_date"
                    >
                        <div class="info">
                            <h3>{{ event.event_title }}</h3>
                            <time v-if="event.event_date && event.event_date.date"
                                :datetime="dayjsFormat(event.event_date.date, 'YYYY/MM/DD')">
                                {{ dayjsFormat(event.event_date.date, 'YYYY/MM/DD') }}
                                （{{ dayjsFormat(event.event_date.date, 'ddd') }}）
                            </time>
                        </div>
                        <div class="place">
                            <div class="prefecture">{{ event.prefecture }}</div>
                            <div v-if="event.event_type_id === 1" class="shop">
                                {{ '株式会社ポケモン' }}
                            </div>
                            <div v-else class="shop">
                                <router-link :to="{ name: 'EventStore', params: { id: event.shop_id }}">
                                    {{ event.shop_name }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <pcg-pager @pageFeed="pageFeed" :page="page"></pcg-pager>
                    <div class="pageCount">
                        {{ page.currentPage }}ページ ／ {{ page.totalPage }}ページ中
                    </div>
                </div>
            </section>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import PcgPager from '@/component/Pager.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    name: 'JudgeHistoryPage',
    components: {
        SystemPage,
        PcgPager
    },
    data: function() {
        return {
            user: null,
            page: {
                title: 'ジャッジ履歴',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                    { name: 'オーガナイザー', path: '/mypage#judge' },
                ],
                currentPage: 1,
                totalPage: 1,
                total: 0,
                modalTotal: 0,
                perPage: 10,
            },
            years:{
                oldestYear: 2023,
                selectYear: null,
                lastSelectedYear: null,
                yearList: [],
            },
            events:null,
        }
    },
    created: function(){
        this.createYearList()
        this.page.currentPage = isNaN(this.$route.query["page"]) ? 1 : Number(this.$route.query["page"])
        this.years.selectYear = isNaN(this.$route.query["year"]) ? null : Number(this.$route.query["year"])    
        this.getJudgeHistory()
    },
    watch: {
        $route() {
            if (this.$route.path !== this.$router.resolve({ name: "MyJudgeHistory" }).href) {
                return
            }
            location.reload()
        }
    },
    methods: {
        getJudgeHistory: async function () {
            const targetUrl = '/judge/history'
            let that = this
            let params = new URLSearchParams()

            if (this.years.selectYear) {
                params.append('year', this.years.selectYear)
            }
            if (this.years.selectYear !== this.years.lastSelectedYear) {
                that.page.currentPage = 1
                let queryData = Object.assign({}, this.$route.query)
                queryData.page = 1
                if (this.years.selectYear) {
                    queryData.year = this.years.selectYear
                } else {
                    delete queryData.year
                }
                this.$router.push({query: queryData})
            }
            this.years.lastSelectedYear = this.years.selectYear
            
            params.append('offset', (that.page.currentPage - 1) * that.page.perPage)
            params.append('limit', that.page.perPage)

            this.axios.post(targetUrl, params)
            .then(response => {
                that.events = response.data.result
                that.page.totalCount = response.data.count
                that.page.totalPage = Math.ceil(response.data.count / that.page.perPage)
            })
            .catch(err => {
                if (err.response && err.response.status === 400) {
                    // 主に、ジャッジ権限がないユーザーがアクセスした場合
                    this.$router.push({name: 'NotFound'})
                }
                if (err.response && err.response.status === 401) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                if (err.response.status === 404) {
                    that.page.currentPage = 1
                    that.events = []
                } else {
                    that.error = err.response.data.message
                }
            })
        },
        createYearList() {
            let currentYear = (new Date).getFullYear()
            this.years.yearList = [...Array(currentYear - this.years.oldestYear + 1)].map((_, i) => i + this.years.oldestYear)
        },
        pageFeed(count) {
            this.page.currentPage = count
            let queryData = Object.assign({}, this.$route.query)
            queryData.page = count
            this.$router.push({query: queryData})
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
    },   
}
</script>

<style lang="scss" scoped>
@use './style';
</style>
