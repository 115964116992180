<template>
    <system-page :page-settings="page">
        <component
            :is="subPage"
            v-model:email="formData.email"
            v-model:nickname="formData.nickname"
            v-model:lastName="formData.lastName"
            v-model:firstName="formData.firstName"
            v-model:lastNameKana="formData.lastNameKana"
            v-model:firstNameKana="formData.firstNameKana"
            v-model:birthdayYear="formData.birthday.year"
            v-model:birthdayMonth="formData.birthday.month"
            v-model:birthdayDay="formData.birthday.day"
            v-model:gender="formData.gender"
            v-model:postcode="formData.postcode"
            v-model:prefectures="formData.prefectures"
            v-model:address="formData.address"
            v-model:phoneNumber="formData.phoneNumber"
            v-model:mypage="formData.mypage"
            v-model:promotionalEmail="formData.promotionalEmail"
            v-model:parentalConsent="formData.parentalConsent"
            v-model:termsOfService="formData.termsOfService"
            v-model:password="formData.password"
            v-model:passwordReEnter="formData.passwordReEnter"
            v-model:authCode="formData.authCode"
            v-model:avatarInfo="avatarInfo"
        ></component>
    </system-page>
</template>

<script>
import UserRegisterInput from '@/views/Register/UserRegisterInput.vue'
import UserRegisterConfirm from '@/views/Register/UserRegisterConfirm.vue'
import systemPage from '@/component/SystemPage.vue'
import dayjs from 'dayjs'
export default {
    name: 'UserRegisterPage',
    components: {
        systemPage,
    },
    data() {
        return {
            page: {
                title: '',
                breadcrumb: [],
            },
            subPage: null,

            formData: {
                email: '',
                nickname: '',
                lastName: '',
                firstName: '',
                lastNameKana: '',
                firstNameKana: '',
                birthday: {
                    year: '',
                    month: '',
                    day: '',
                },
                gender: '',
                postcode: '',
                prefectures: '',
                address: '',
                phoneNumber: '',
                mypage: '',
                promotionalEmail: '',
                avatar: '',
                parentalConsent: false,
                termsOfService: false,
                password: '',
                passwordReEnter: '',
                authCode: '',
            },
            avatarInfo: null
        }
    },
    beforeMount() {
        this.setPage()
    },
    created: function() {
        this.fetchAvatar()
        this.authentication()
    },
    computed: {},
    watch: {
        '$route.hash': function() {
            this.setPage()
            window.scrollTo({
                top: 0,
            })
        }
    },
    methods: {
        setPage() {
            switch (this.$route.hash) {
                case '#confirm':
                    this.subPage = UserRegisterConfirm
                    this.page.title = '登録内容確認'
                    this.page.desc = 'ポケモンカードゲーム プレイヤーズクラブ 登録内容確認のページ。',
                    this.page.breadcrumb = [
                        { name: '新規会員登録', path: '/login/register/mail' },
                        { name: 'ポケモンカードゲーム プレイヤーズクラブ会員登録', path: '/login/register/user' },
                    ]
                    break
                default:
                    this.subPage = UserRegisterInput
                    this.page.breakedTitle = 'ポケモンカードゲーム<br>プレイヤーズクラブ<br class="u-display-sp">会員登録'
                    this.page.desc = 'ポケモンカードゲーム プレイヤーズクラブ プレイヤーズクラブ会員登録のページ。',
                    this.page.title = 'ポケモンカードゲーム プレイヤーズクラブ会員登録'
                    this.page.breadcrumb = [
                        { name: '新規会員登録', path: '/login/register/mail' },
                    ]
            }
        },
        authentication() {
            const api = '/authentication_email'
            const params = {
                authCode: this.$route.query.auth_code,
            }

            this.axios.get(api, {params})
            .then(response => {
                this.formData.email = response.data.mail
                this.formData.authCode = String(this.$route.query.auth_code)
                let birthday = dayjs(response.data.birthday.date)
                const birthdayDate = new Date(birthday.year(), birthday.month(), birthday.date())
                this.formData.birthday.year = String(birthdayDate.getFullYear())
                this.formData.birthday.month = ('0' + (birthdayDate.getMonth() + 1)).slice(-2)
                this.formData.birthday.day = ('0' + birthdayDate.getDate()).slice(-2)
            })
            .catch(err => {
                this.$router.push({name: 'UserRegisterError',params: { message: err.response.data.message }})
            })
        },
        fetchAvatar() {
            const api = '/get_avatar_info';
            let params = new URLSearchParams();
            params.append("avatar_id", 1);

            this.axios.get(api, {params: params})
            .then(response => {
                this.avatarInfo = response.data.avatar
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
</style>
