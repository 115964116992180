<template>
  <system-page :page-settings="page">
    <div class="u-text-center u-mb-exlg message">
      <p class="u-mb">
        パスワード再設定のためのメールを送信しました。<br>
        メールに記載されているURLを24時間以内にクリックして 、 再設定を完了してください 。
      </p>
      <p class="u-mb">
        しばらくたってもメールがこない場合は、<br>
        入力したメールアドレスに間違いはないかをご確認ください。
      </p>
    </div>
    <div class="u-text-center">
      <router-link
          :to="{name: 'ProfilePasswordReissue'}"
          class="c-btn c-btn-primary-outline"
        >パスワード変更メール送信</router-link>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'

export default {
  name: 'MypagePasswordMailComplatePage',
  components: {
    SystemPage,
  },
  data: function() {
    return {
      page: {
        title: 'メール送信完了',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ メール送信完了のページ。',
        breadcrumb: [
          { name: 'マイページ', path: '/mypage' },
          { name: 'ユーザー情報', path: '/mypage/profile' },
        ],
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

@include g.sp {
  .message {
    padding: 0 20px;
    text-align: left;
  }
}
</style>
