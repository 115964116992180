<template>
  <div class="errorMsg c-warningBox">
    <div
      class="icon-exclamation"
      :class="'sp-' + justifySp"
      v-if="message"
    >
      <div class="u-text-bold">{{ message }}</div>
    </div>
    <div
      class="icon-exclamation"
      :class="'sp-' + justifySp"
      v-for="(error, index) in messages"
      :key="index + ':' + error"
    >
      <div class="u-text-bold">{{ error }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ErrorMsg',
  props: {
    message: {
      type: String,
    },
    messages: {
      type: Array
    },
    justifySp: {
      type: String,
      default: 'left'
    }
  },
  data: function() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
.errorMsg {
  margin-right: auto;
  margin-left: auto;
  padding: 16px;
  .icon-exclamation {
    display: flex;
    justify-content: center;
    .u-text-bold {
      // 親についてるfont-family: pcg_iconの影響で0が消えてしまう。
      font-family: g.$font-family;
    }
    &::before {
      margin-right: 6px;
    }
    &:not(:last-child) {
      margin-bottom: g.$margin-small;
    }
    &.sp-left {
      @include g.sp {
        justify-content: left;
        text-align: left;
      }
    }
    &.sp-right {
      @include g.sp {
        justify-content: right;
        text-align: right;
      }
    }
  }
}

</style>
