<template>
  <system-page :page-settings="page">
    <error-msg class="u-mb-lg error-msg" v-if="error" :message="error">
    </error-msg>
    <div class="c-box login">
      <div class="form">
        <form>
          <div class="u-mb">
            <label for="formMailAddress" class="u-mb-sm u-display-block">メールアドレスまたはプレイヤーID</label>
            <input
              v-model="playerId"
              id="formMailAddress"
              type="text"
              class="c-input c-input-text u-width-100"
              :class="{'c-input-error': hasPlayerIdError}"
            >
          </div>
          <div class="u-mb">
            <label for="formMailAddress" class="u-mb-sm u-display-block">パスワード</label>
            <password-input
                v-model:password="password"
                :hasError="hasPasswordError"
                id="formPassword"
            ></password-input>
            <p class="u-text-center"><router-link :to="{ name: 'PasswordReissue' }">パスワードを忘れた方</router-link></p>
          </div>
          <div class="u-text-center">
            <button
              @click.prevent="login"
              type="submit"
              :disabled="processing"
              class="c-btn c-btn-primary"
            >
              ログイン
            </button>
          </div>
        </form>
      </div>
      <p class="center-line"><span class="note">※現在、ソーシャルアカウントでのログインはメンテナンス中です。</span></p>
      <!-- <p class="center-line"><span class="note">または、ソーシャルアカウントでログイン</span></p>
      <div class="social-buttons u-text-center">
        <button type="button"
          class="c-btn google"
          @click="authSNS('google')"
        >
         <div class="google-icon"><img src="@/assets/img/login/google_logo.svg"></div>
         <p>Sign in with Google</p>
        </button>

        <button type="button"
          class="c-btn facebook"
          @click="authSNS('facebook')"
        >
          Facebookでログイン
        </button>

        <button type="button"
          class="c-btn twitter"
          @click="authSNS('twitter')"
        >
          Twitterでログイン
        </button>
      </div> -->
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import passwordInput from '@/component/PasswordInput.vue'

export default {
  name: 'QrLogin',
  components: {
    SystemPage,
    errorMsg,
    passwordInput,
  },
  props: {},
  data: function() {
    return {
      page: {
        title: 'イベントチェックイン',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ イベントチェックインのページ。',
      },
      error: null,
      playerId: '',
      password: '',
      hasPlayerIdError: false,
      hasPasswordError: false,
      processing: false,
      snsUrl : {
        twitter: this.axios.defaults.baseURL + '/qr/login/auth_twitter',
        google: this.axios.defaults.baseURL + '/qr/login/auth_google',
        facebook: this.axios.defaults.baseURL + '/qr/login/auth_facebook',
      },
    }
  },
  created: function() {
    if (this.$route.query.code && this.$route.query.code != 200) {
      this.error = this.$route.query.message;
    }
    this.init()
  },
  methods: {
    init() {

      this.apiParamsUrl = null;
      if (this.$route.query.url) {
        this.apiParamsUrl = this.$route.query.url;
      }
      else {
        this.apiParamsUrl = "";
      }

      /*if (this.$route.query.url_judge) {
        this.apiParamsUrlJudge = this.$route.query.url_judge;
      }
      else {
        this.apiParamsUrlJudge = "";
      }*/
      if (this.$route.query.league) {
        this.apiParamsLeague = this.$route.query.league;
      }
      else {
        this.apiParamsLeague = "";
      }

    },
    authSNS(sns) {
      // const authSNSApi = this.axios.defaults.baseURL + `/qr/login/auth_${sns}?url=${this.apiParamsUrl}&url_judge=${this.apiParamsUrlJudge}&league=${this.apiParamsLeague}`;
      const authSNSApi = this.axios.defaults.baseURL + `/qr/login/auth_${sns}?url=${this.apiParamsUrl}&league=${this.apiParamsLeague}`;
      window.location.href = authSNSApi
    },
    login() {
        if (!this.playerId) {
                this.error = 'メールアドレスを入力してください。'
                this.hasPlayerIdError = true
                return
        }
        if (!this.password) {
                this.error = 'パスワードを入力してください。'
                this.hasPasswordError = true
                return
        }
      this.processing = true;

      let params = new URLSearchParams;
      params.append('player_id', this.playerId)
      params.append('password', this.password)
      if (this.$route.query.url) {
        params.append('url', this.$route.query.url)
      }
      if (this.$route.query.league) {
        params.append('league', this.$route.query.league)
      }
      if (this.$route.query.judge_date_id) {
        params.append('judge_date_id', this.$route.query.judge_date_id)
      }
      if (this.$route.query.judge_date_hash) {
        params.append('judge_date_hash', this.$route.query.judge_date_hash)
      }

      const api = '/qr_login'

      this.axios.post(api, params)
      .then(response => {
        // TODO: ログイン後にユーザ情報を保存する処理を書いていません
        if (0 === Number(response.data.status)) {
          this.error = response.data.message;
          this.processing = false;

          return
        }

        this.$store.commit('setLoginState', {
            userToken: response.data.token,
            userTokenDeadline: response.data.token_deadline,
            userName: response.data.user_name,
            mail: response.data.email,
            phoneNumber: response.data.phone_number
        })

        if (response.data.is_judge) {
          this.$router.push({name: 'MyJudgeEventDetail', params: {
            id: response.data.event_holding_id,
          }})

          return
        }

        this.$router.push({name: 'QrPlayerSuccess', params: {message: response.data.message}})
      })
      .catch(err => {
            this.processing = false;
            if (err.response.data.code == '400') {
              if (err.response.data.age_error) {
                this.$router.push({name: 'QrAgeError'})

                return
              }
              this.$router.push({name: 'QrPlayerError', params: {message: err.response.data.message}})
              return
            }
            this.error = err.response.data.message
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.login {
  padding: 50px;
  width: 640px;
  margin: 0 auto;
  font-size: 14px;

  .form {
    padding: 0 50px g.$margin-large;

    .birthday {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .password-outer {
      margin-bottom: 24px;

      .password {
          margin-bottom: 17px;
      }
  }
}

.note {
  color: g.$color-black-a060;
}

.center-line {
  position: relative;
  text-align: center;
  margin: 0 50px g.$margin-large;

  span {
    background: g.$color-white;
    padding: 0 20px;
  }
}


.social-buttons {
    font-size: 0;
    padding-bottom: 28px;
    width: 420px;
    margin: 0 auto 36px;

    .c-btn {
        color: g.$color-white;
        border-radius: 10px;
        margin: 0 auto 12px;
        font-size: g.$font-size-small;
        position: relative;
        width: 214px;
        height: 40px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .26);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        padding-left: 30px;

        &:hover {
            text-decoration: none;
        }

        @mixin buttonIcon {
            content: '';
            font-family: 'pcg_icon';
            position: absolute;
            left: 10px;
            font-size: 20px;
        }

        &.facebook {
            background: g.$color-facebook;

            &::before {
                @include buttonIcon;
                content: '\e922';
            }
        }

        &.twitter {
            background: g.$color-twitter;

            &::before {
                @include buttonIcon;
                content: '\e930';
            }
        }

        &.google {
            display: inline-flex;
            font-family: 'Roboto', sans-serif;
            color: #757575;
            padding-left: 0;

            .google-icon {
                padding: 0 10px;
                font-size: 0;
                line-height: 0;

                img {
                    height: 20px;
                    width: 20px;
                }
            }

            p {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.link {
  font-size: g.$font-size;
}

@include g.sp {
  .login {
    width: 100%;
    padding: 0 20px;

    .form {
      padding: 0;
    }
  }

  .center-line {
    margin: 20px 0 20px;

    span {
      padding: 0;
    }
  }

  .social-buttons {
    width: 100%;
    padding-top: 20px;
  }
}

.error-msg {
    @include g.pc {
        width: 480px;
    }
    @include g.sp {
        width: 335px;
    }
}
</style>
