<template>
    <a
        v-if="information.link_flg"
        :href="information.link_url"
        target="_blank"
        class="information"
    >
        <time>{{ dayjsFormat(information.update_at.date, 'YYYY/MM/DD') }}</time>
        <h4 :class="[isNewInformation(information.update_at.date) ? 'new' : '']">{{ information.title }}</h4>
    </a>
    <router-link
        v-else
        :to="routePage ? {name: 'NewsDetail', params: {id: information.id }, query:{routePage: 'Mypage'}} : {name: 'NewsDetail', params: {id: information.id }}"
        class="information"
    >
        <time>{{ dayjsFormat(information.update_at.date, 'YYYY/MM/DD') }}</time>
        <h4 :class="[isNewInformation(information.update_at.date) ? 'new' : '']">{{ information.title }}</h4>

    </router-link>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

export default {
    props: {
        information: {
            require: true,
        },
        routePage: {
            type: String,
        },
    },
    methods: {
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
        isNewInformation(updateAt) {
            dayjs.locale(ja)
            dayjs.extend(isSameOrAfter)

            const today = dayjs(new Date()).startOf('day')
            const updateAt7day = dayjs(updateAt).startOf('day').add(7, 'd')

            return updateAt7day.isSameOrAfter(today)
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
$new-graient: linear-gradient(
        #c22b8a 0%,
        #c22b8a 26.11%,
        #e51ddd 100%
    );

.information {
    position: relative;
    align-items: center;
    margin-bottom: 12px;
    border: 1px solid g.$color-black-a025;
    border-radius: 12px;
    background: g.$color-white;
    @include g.pc {
        display: flex;
        height: 72px;
        padding: 24px 32px;
        &:hover {
            text-decoration: none;
            border: 1px solid g.$color-black-a060;
            box-shadow: 2px 2px 8px rgba(g.$color-black, .12);
        }
    }
    @include g.sp {
        display: block;
        padding: 16px 20px 20px;
    }

    &::after {
        @include g.setIconFont('\e909');
        position: absolute;
        top: 0;
        right: 24px;
        bottom: 0;
        display: inline-block;
        height: fit-content;
        margin: auto;
        font-size: 12px;
        line-height: 1;
        color: g.$color-black;
        @include g.sp {
            right: 12px;
        }
    }
    &[target="_blank"]::after {
        content: '\e905';
    }

    time {
        flex: 0 0 150px;
        color: g.$color-black-a060;
        @include g.sp {
            display: block;
            line-height: 1;
        }
    }

    h4 {
        font-weight: 500;
        color: g.$color-font;
        @include g.sp {
            margin-top: 10px;
        }

        &.new::before {
            content: 'NEW';
            display: inline-block;
            width: 48px;
            height: 24px;
            padding: 0 5px;
            margin-right: 12px;
            text-align: center;
            font-size: g.$font-size-small;
            line-height: 24px;
            font-weight: bold;
            color: g.$color-white;
            border-radius: 4px;
            background: $new-graient;
        }
    }
}
</style>
