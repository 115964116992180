<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <error-msg class="u-mb-lg" v-if="error !== null">
                <p class="u-mb">
                    {{ error }}
                </p>
            </error-msg>
            <error-msg class="u-mb-lg" v-if="player.sms_auth_flg === 0">
                <p class="u-mb">
                    SMS認証が完了していないか、再登録が必要なため、イベント応募ができません。
                </p>
                <p>
                    <a
                        href=""
                        @click.prevent="smsIdentify"
                        class="link-arrow"
                    >SMS認証する</a>
                </p>
            </error-msg>
            <error-msg class="u-mb-lg" v-else-if="player.new_phone_number !== null">
                <p class="u-mb">
                    電話番号の変更が完了しておりません。SMS認証完了後に電話番号が変更されます。<br>
                    SMS認証を行なってください。
                </p>
                <p>
                    <a
                        href=""
                        @click.prevent="smsIdentify"
                        class="link-arrow"
                    >SMS認証する</a>
                </p>
            </error-msg>
            <div class="profile">
                <div class="title-container">
                    <h2 class="c-title c-title-h2">メールアドレス</h2>
                    <router-link :to="{name: 'MailChange'}" class="c-btn c-btn-primary btn-change">変更する</router-link>
                </div>
                <ul class="c-table-list u-mb-lg c-table-list-margin-not-pass">
                    <li class="c-table-list-row bottom-border">
                        <h4 class="column-left">メールアドレス</h4>
                        <p class="column-right">{{ player.mail_address }}</p>
                    </li>
                </ul>
                <div class="title-container">
                    <h2 class="c-title c-title-h2">アカウント情報</h2>
                    <router-link :to="{name: 'ProfileEdit'}" class="c-btn c-btn-primary btn-change">変更する</router-link>
                </div>
                <ul class="c-table-list c-table-list-no-border u-mb-lg c-table-list-margin-not-pass">
                    <li class="c-table-list-row">
                        <h4 class="column-left">ニックネーム</h4>
                        <p class="column-right">{{ player.nickname }}</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">姓 名</h4>
                        <p class="column-right">{{ player.family_name }} {{ player.first_name}}</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">フリガナ</h4>
                        <p class="column-right">{{ player.family_name_kana }} {{ player.first_name_kana}}</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">生年月日</h4>
                        <p class="column-right">{{ dayjsFormat(player.birthday, 'YYYY年MM月DD日') }}</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">性別</h4>
                        <p class="column-right" v-if="player.sex === 1">男性</p>
                        <p class="column-right" v-else-if="player.sex === 2">女性</p>
                        <p class="column-right" v-else>指定しない</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">郵便番号</h4>
                        <p class="column-right" v-if="player.postal_code === null">未設定</p>
                        <p class="column-right" v-else>{{ player.postal_code }}</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">都道府県</h4>
                        <p class="column-right" v-if="player.prefecture === null">未設定</p>
                        <p class="column-right" v-else>{{ player.prefecture }}</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">住所</h4>
                        <p class="column-right">{{ player.address }}</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">携帯電話番号</h4>
                        <div class="column-right">
                            <span :class="{'u-text-danger' : (player.sms_auth_flg === 0 || player.new_phone_number) }">
                                {{ player.new_phone_number ? player.new_phone_number : player.phone_number }}
                            </span>
                            <template v-if="player.sms_auth_flg === 0 || player.new_phone_number !== null">
                                <span class="unauthenticated">未認証</span>
                                <a @click.prevent="smsIdentify" class="sms link-arrow">SMS認証する</a><br>
                                <span v-if="player.sms_auth_flg === 1" class="u-text-danger">SMS認証後に電話番号が変更されます</span>
                            </template>
                        </div>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">マイページ<br class="u-display-pc">公開／非公開</h4>
                        <p class="column-right" v-if="player.publish_flg === 1">公開</p>
                        <p class="column-right" v-else>非公開</p>
                    </li>
                    <li class="c-table-list-row">
                        <h4 class="column-left">プロモーション<br class="u-display-pc">メール</h4>
                        <p class="column-right" v-if="player.mail_notification_flg === 1">受け取る</p>
                        <p class="column-right" v-else>受け取らない</p>
                    </li>
                </ul>
                <div class="title-container">
                    <h2 class="c-title c-title-h2">パスワード</h2>
                    <router-link :to="{name: 'ProfilePasswordReissue'}" class="c-btn c-btn-primary btn-change">変更する</router-link>
                </div>
                <ul class="c-table-list u-mb-lg c-table-list-margin-pass">
                    <li class="c-table-list-row bottom-border">
                        <h4 class="column-left">パスワード</h4>
                        <p class="column-right">********</p>
                    </li>
                </ul>
                <div class="u-text-right">
                    <router-link :to="{name: 'WithdrawConfirm'}" class="link-arrow">退会する</router-link>
                </div>
            </div>
            <section class="u-text-center">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイページへ
                </router-link>
            </section>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    name: 'HomePage',
    components: {
        SystemPage,
        errorMsg,
    },
    props: {},
    data: function() {
        return {
            player: {},
            error: null,
        }
    },
    created: function() {
        this.init()
    },
    computed: {
        page() {
            let page =  {
                title: 'ユーザー情報',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            }

            let player = this.$store.getters.getUser();
            if (!player) {
                return page
            }
            let defaultTitle = page.title;
            page.titleForHeader = player.nickname + "さんの" + defaultTitle
            page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんの" + defaultTitle + "。";
            return page
        },
    },
    methods: {
        init() {
            const getPlayerApi = '/get_player_account'

            this.axios.post(getPlayerApi)
            .then(response => {
                let player = response.data.player
                this.player = player
                this.player.birthday = this.player.birthday.date // player.birthday.dateをそのまま利用しようとするとエラーになったため
            })
            .catch(err => {
                if (err.response && (err.response.data.status === 401 || err.response.data.code === 404)) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                this.error = err.response.data.message
            })
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
        smsIdentify() {
            this.$store.commit('setPhoneNumber', this.player.new_phone_number ? this.player.new_phone_number : this.player.phone_number)
            this.$router.push({name: 'SmsIdentify', params: { isMypage: '1' }, query: { phoneNumber : this.player.new_phone_number ? this.player.new_phone_number : this.player.phone_number }})
        },
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.link-arrow {
    &::after {
        @include g.setIconFont("\e909");
        margin-left: g.$margin-small;
    }
}
.profile {
    @include g.pc {
        padding-top: 46px;
        width: g.$content-width-pc-sm;
    }
    @include g.sp {
        padding-top: 46px;
    }
    margin: 0 auto 70px;
    .c-title-h2 {
        border-bottom: 1px solid #B3D8EE;
        padding-bottom: 11px;
    }
    .title-container {
        position: relative;
        width: 100%;
        .btn-change {
            position: absolute;
            top: 18px;
            right: 0;
            transform: translateY(-50%);
            width: 105px;
            height: 28px;
            line-height: 28px;
            font-size: g.$font-size-small;
        }
    }

    .c-title::before {
        content: none;
    }

    .c-table-list {
        @include g.pc {
            margin-bottom: 83px;
            &-row { margin: 0 10px; }
        }
        @include g.sp {
            &-margin-not-pass {
                margin-bottom: 83px;
            }
            &-margin-pass {
                margin-bottom: 20px;
            }
        }

        &::before {
            border: 0;
        }
        &-row {
            @include g.sp {
                padding-top: 12px;
            }
            &:nth-of-type(1) {
                border-top: 0;
            }
            &:not(:last-child) {
                border-bottom: 0;
            }
            .column {
                @include g.sp {
                    &-left {
                        padding: 0;
                        margin-bottom: 10px;
                    }
                    &-right {
                        padding: 0;
                        margin-bottom: 20px;
                    }
                }
                @include g.pc {
                    &-left { padding-right: 0; }
                    &-right { padding-left: 0; }
                }
                &-left {
                    background-color: g.$color-white;
                }
            }
        }
    }

    .sms {
        margin-left: g.$margin;
        font-size: g.$font-size-small;
    }

    .unauthenticated {
        border: 1px solid #C7C7C7;
        border-radius: 3px;
        padding: 3px 8px;
        margin-left: g.$margin;
        font-size: g.$font-size-exsmall;
        &::before {
            @include g.setIconFont("\e910");
            color: g.$color-error-red;
            margin-right: 3px;
        }
    }
}

.button-outer {
    border-top: 1px solid;
    padding-top: g.$margin;
}

</style>
