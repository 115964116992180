<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <p class="message">
                会員登録のご案内をメールでお送りします。<br>
                登録用のメールアドレスと、生年月日を入力してください。
            </p>
            <error-msg
                v-if="error"
                :message="error"
                class="u-mb-lg error-msg u-text-pre-line"
            >
            </error-msg>
            <div class="c-box pc-width-640">
                <form @submit.prevent="send(email, birthday)">
                    <label for="formMailAddress">メールアドレス</label>
                    <input
                        v-model="email"
                        id="formMailAddress"
                        type="text"
                        name="formMailAddress"
                        class="c-input c-input-text u-width-100"
                        :class="{ 'c-input-error': hasMailError }">
                    <select-birthday
                        v-model:birthday="birthday"
                        v-model:hasError="hasBirthdayError"
                        name="birthday"
                        id="birthday"
                    ></select-birthday>
                    <p class="u-text-kome u-text-exsmall">生年月日は登録後に変更することはできません。</p>
                    <vue-recaptcha
                        class="reCaptcha"
                        ref="recaptcha"
                        :sitekey="siteKey"
                        @verify="onVerify"
                        @expired="onExpired"
                    />
                    <div class="u-text-center">
                        <button type="submit" class="c-btn c-btn-primary">メールを送信する</button>
                    </div>
                </form>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import selectBirthday from '@/component/SelectBirthday.vue'
import { VueRecaptcha } from 'vue-recaptcha'
import formMethod from '@/utils/form'

export default {
    name: 'MailRegisterPage',
    components: {
        SystemPage,
        errorMsg,
        selectBirthday,
        VueRecaptcha,
    },
    data: function() {
        return {
            page: {
                title: '新規会員登録',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ 新規会員登録のページ。',
                breadcrumb: [],
            },
            birthday: {
                year: null,
                month: null,
                day: null,
            },
            error: null,
            siteKey: '',
            recaptchaToken: '',
            hasMailError: false,
            hasBirthdayError: false,
        }
    },
    created: function() {
        if (this.$store.state.loggedIn) {
            this.$router.push({name: 'Mypage'})
        }
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src','https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
        document.head.appendChild(recaptchaScript)
        this.siteKey = process.env.VUE_APP_SITE_KEY
    },
    methods: {
        send(formMailAddress,birthday) {
            this.hasMailError = false
            this.hasBirthdayError = false
            if (!formMailAddress) {
                this.error = 'メールアドレスを入力してください。'
                this.hasMailError = true
                this.scrollTop();

                return
            } else if (!this.isMailAddress(formMailAddress)){
                this.error = '正しいメールアドレスを入力してください。'
                this.hasMailError = true
                this.scrollTop();

                return
            }
            if (!birthday.year || !birthday.month || !birthday.day) {
                this.error = '生年月日を入力してください。'
                this.hasBirthdayError = true
                this.scrollTop();

                return
            }

            if (new Date(birthday.year + '/' + birthday.month + '/' + birthday.day) > new Date()) {
                this.error = '生年月日が不正です。'
                this.hasBirthdayError = true
                this.scrollTop();

                return
            }

            let params = new URLSearchParams
            params.append('mailAddress', formMailAddress)
            params.append('birthday', birthday.year + '/' + birthday.month + '/' + birthday.day)
            params.append('recaptchaToken', this.recaptchaToken)

            const api = '/send_authentication_email'

            this.axios.post(api, params)
            .then(() => {
                //メール送信画面へ遷移
                this.$router.push({name: 'MailRegisterComplete'})
            })
            .catch(err => {
                this.error = err.response.data.message
                this.scrollTop();
                this.resetRecaptcha()
            })
        },
        onVerify(response) {
            this.recaptchaToken = response
        },
        onExpired() {
            this.recaptchaToken = ''
        },
        resetRecaptcha() {
            this.$refs.recaptcha.reset()
        },
        isMailAddress(mailAddress) {
            return formMethod.isMailAddress(mailAddress)
        },
        scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.error-msg {
    @include g.pc {
        width: 640px;
    }
    @include g.sp {
        width: 335px;
    }
}

.selectBirthday {
    margin-bottom: g.$margin-small;
}

.c-box {
    font-size: 14px;

    label {
        display: block;
        margin-bottom: 10px;
    }
}

@include g.pc {
    .pc-width-640 {
        width: 640px;
        margin-right: auto;
        margin-left: auto;
    }

    .message {
        margin-bottom: 40px;
        text-align: center;
    }

    .c-box {
        padding: 55px 100px;

        input {
            margin-bottom: 40px;
        }
    }

    .u-text-kome {
        margin-bottom: 45px;
    }

    .reCaptcha {
        margin-bottom: 55px;
        & > div {
            margin-right:auto;
            margin-left:auto;
        }
    }
}

@include g.sp {
    .message {
        margin-bottom: 30px;
    }

    .c-box {
        input {
            margin-bottom: g.$margin-large;
        }
    }

    .u-text-kome {
        margin-bottom: g.$margin-large;
    }

    .reCaptcha {
        margin-bottom: 50px;
        & > div {
            margin-right:auto;
            margin-left:auto;
        }
    }
}
</style>
<style lang="scss">
// vue-recaptchaのコンポーネントにスタイルを適用するためグローバルのスタイルを定義
.reCaptcha {
    & > div {
        margin-right:auto;
        margin-left:auto;
    }
}
</style>
