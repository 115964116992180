<template>
    <pcg-page :page-settings="page">
        <error-msg
            v-if="error"
            class="u-mb-lg c-content"
            :message="error"
        ></error-msg>

        <div v-if="eventDetailData" class="c-content">
            <div class="eventTitle">
                <div class="eventTitle-title">
                    <h1 class="c-title-event">{{ eventDetailData.event_title }}</h1>
                </div>
                <div class="eventTitle-date">
                    <span
                        v-if="eventDetailData.event_date.date"
                        class="date"
                        :class="{
                                'sat': dayjsFormat(eventDetailData.event_date.date, 'ddd') === '土',
                                'sun': dayjsFormat(eventDetailData.event_date.date, 'ddd') === '日' || eventDetailData.holiday_flg === 1
                        }"
                    >
                        <time :datetime="dayjsFormat(eventDetailData.event_date.date, 'YYYY/MM/DD')">
                            {{ dayjsFormat(eventDetailData.event_date.date, 'YYYY/MM/DD') }}
                        </time>
                        <time :datetime="(eventDetailData.event_date.date, 'ddd')">
                            （{{ dayjsFormat(eventDetailData.event_date.date, 'ddd') }}）
                        </time>
                    </span>
                    <span v-if="eventDetailData.event_started_at.date" class="time">
                        <time :datetime="dayjsFormat(eventDetailData.event_started_at.date, 'HH:mm')">
                            {{ dayjsFormat(eventDetailData.event_started_at.date, 'HH:mm') }}
                        </time>
                        ～
                        <time v-if="eventDetailData.event_ended_at && eventDetailData.event_ended_at.date"
                            :datetime="dayjsFormat(eventDetailData.event_ended_at.date, 'HH:mm')">
                            {{ dayjsFormat(eventDetailData.event_ended_at.date, 'HH:mm') }}
                        </time>
                    </span>
                </div>
                <div class="eventTitle-flex">
                    <div class="eventTitle-category">
                        <div
                            v-if="eventDetailData.event_league"
                            class="eventTitle-league"
                        >
                            {{ eventDetailData.event_league }}リーグ
                        </div>
                    </div>
                    <div class="eventTitle-category-bottom">
                        <div class="eventTitle-rule">
                            {{ eventDetailData.regulation }}
                        </div>
                        <div
                            v-if="eventDetailData.deck_count"
                            class="eventTitle-deck"
                        >
                            {{ displayDeckCount(eventDetailData.deck_count) }}
                        </div>
                        <div
                            v-if="eventDetailData.csp_flg"
                            class="c-eventPoint c-eventPoint-championship"
                        >
                            {{ 'チャンピオンシップポイント対象' }}
                        </div>
                        <div class="eventTitle-link-wrapper">
                            <router-link
                                v-if ="eventDetailData.event_holding_id"
                                :to="{
                                    name: 'EventDetail',
                                    params: {
                                        id: eventDetailData.event_holding_id,
                                        trainersFlg: eventDetailData.trainers_flg,
                                        shopId: eventDetailData.shop_id ? eventDetailData.shop_id : 0,
                                        eventDate: eventDetailData.event_date_params ? eventDetailData.event_date_params : 0,
                                        dateId: eventDetailData.date_id ? eventDetailData.date_id : 0
                                        }
                                }"
                                class="eventTitle-link"
                            >
                                イベント詳細
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eventDetail">
                <div
                    v-if="eventDetailData.event_type_id === 1"
                    class="sponsorship noLink"
                >
                    <span class="title">主催者</span>{{ '株式会社ポケモン' }}
                </div>
                <div
                    v-else
                    class="sponsorship"
                >
                    <span class="title">主催者</span><router-link
                                v-if ="eventDetailData.event_holding_id"
                                :to="{
                                    name: 'EventStore',
                                    params: { id: eventDetailData.shop_id }
                                    }"
                            >
                                {{ eventDetailData.shop_name }}
                            </router-link>
                </div>
                <div class="address">
                    <span class="title">会場</span>
                    <div v-if="eventDetailData.address">
                        <span class="postcode" v-if="eventDetailData.zip_code">〒{{ zipCodeFormat(eventDetailData.zip_code) }}</span>
                        {{ eventDetailData.address }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="eventDetailData" class="c-content">
            <h2 class="c-title-h2">大会報告</h2>
            <div class="eventReportForm">
                <textarea
                    id="eventReportInput"
                    class="c-input c-input-textarea"
                    v-model="eventReportText"
                ></textarea>
                <div class="u-text-center">
                    <button
                        @click="resetReportText"
                        class="c-btn c-btn-primary-outline u-mr"
                    >リセット</button>
                    <button
                        :disabled="eventReportText === ''"
                        @click="sendEventReportModal"
                        class="c-btn c-btn-primary"
                    >報告する</button>
                </div>
            </div>
            <ul v-if="reportList.event.length > 0" class="eventReport">
                <li
                    v-for="report in reportList.event"
                    :key="report.judge_note_id"
                    class="eventReport-text"
                >
                    <div class="text">{{ report.text }}</div>
                    <div class="time">{{ dayjsFormat(report.writing_datetime.date, 'HH:mm') }}</div>
                </li>
            </ul>
        </div>

        <div v-if="eventDetailData" id="jumpListTop" class="c-content">
            <h2 class="c-title-h2">参加者一覧</h2>
            <div class="userListForm">
                <div class="top">
                    <div class="userListForm-input">
                        <input
                            type="text"
                            id="entryPlayersFormKeyword"
                            name="entryPlayersFormKeyword"
                            class="c-input c-input-text search"
                            v-model="entryPlayersForm.keyword"
                            placeholder="プレイヤー名、プレイヤーID"
                        >
                    </div>
                    <button
                        @click="searchKeyword"
                        class="c-btn c-btn-sm c-btn-primary"
                    >
                        絞り込み
                    </button>
                    <button
                        @click="clearSearchKeyword"
                        class="c-btn c-btn-sm c-btn-primary-outline"
                    >
                        クリア
                    </button>
                </div>
                <div class="bottom">
                    <div class="c-checkbox">
                        <input
                            type="checkbox"
                            id="entryPlayersFormDeckUnregistered"
                            name="entryPlayersFormDeckUnregistered"
                            v-model="entryPlayersForm.deckUnregistered"
                            @change="changeDeckUnregisteredFlg"
                        >
                        <label for="entryPlayersFormDeckUnregistered">デッキ未登録者を表示</label>
                    </div>
                </div>
            </div>
            <div>
                <div class="c-table-list c-table-list-wide">
                    <div class="c-table-list-row title">
                        <div class="column-title user">プレイヤー名</div>
                        <div class="column-title deck">参加デッキ</div>
                        <div class="column-title report">報告</div>
                    </div>
                    <div
                        v-for="(player, index) in entryPlayers"
                        :key="`userList${index}`"
                        class="c-table-list-row"
                    >
                        <div class="column-left">
                            <div class="column-top">
                                <div class="user">
                                    <div class="title">プレイヤー名</div>
                                    <router-link
                                        v-if="player.public_flg === 1"
                                        :to="{ name: 'OtherPlayerMypage', params: {'id': player.player_id} }"
                                        class="user-link user-display"
                                    >
                                        <span class="user-name">{{ player.nickname }}</span>
                                        <span class="user-id">プレイヤーID：{{ player.player_id }}</span>
                                    </router-link>
                                    <div v-else
                                        class="user-display"
                                    >
                                        <span class="user-name">{{ player.nickname }}</span>
                                        <span class="user-id">プレイヤーID：{{ player.player_id }}</span>
                                    </div>
                                </div>
                                <div class="deck">
                                    <div class="title">参加デッキ</div>
                                    <span class="text" :class="{'noRegister': !player.deck_code}">
                                        デッキ{{ player.deck_code ? '登録済' : '未登録' }}
                                    </span>
                                </div>
                                <div class="report">
                                    <button
                                        @click="sendUserReportModal(player)"
                                        class="c-btn c-btn-sm c-btn-primary-outline"
                                    >報告する</button>
                                </div>
                            </div>
                            <div class="column-bottom">
                                <ul
                                    v-if="Object.keys(reportList.player).indexOf(player.player_id) !== -1"
                                    class="userReport"
                                >
                                    <li
                                        v-for="report in reportList.player[player.player_id]"
                                        :key="report.judge_note_id"
                                        class="userReport-text"
                                    >
                                        <div class="text">{{ report.text }}</div>
                                        <div class="time">{{ dayjsFormat(report.writing_datetime.date, 'HH:mm') }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="pagination.totalPage > 1">
                    <pcg-pager
                        class="u-mb-lg u-mt-lg"
                        :page="pagination"
                        @pageFeed="setPage"
                    />
                    <div class="u-text-center" v-if="0 < pagination.totalCount">
                        {{ pagination.currentPage }}ページ ／ {{ pagination.totalPage }}ページ中
                    </div>
                </template>
            </div>
            <div class="c-content u-text-center u-mt-lg u-mb-lg">
                <router-link
                    :to="{name: 'Mypage', hash:'#judge'}"
                    class="c-btn c-btn-primary-outline"
                >マイページへ</router-link>
            </div>
        </div>
    </pcg-page>

    <pcg-modal
        class="eventReportModal"
        :isActive="activeModal === 'eventReportModal'"
        :bgImage="true"
        @close="closeEventReportModal"
    >
        <template #modalHeader>
            <h2 class="c-title c-title-page">大会報告</h2>
        </template>
        <template v-if="eventReportModal.status === 'main'">
            <p class="text-top">大会報告を送信します。<br>よろしいですか？</p>
            <p class="text-bottom">※大会報告は、開催日から2日後の23:59まで何度でも送信が可能です。</p>
            <div class="buttons">
                <button
                    @click="closeEventReportModal"
                    class="c-btn c-btn-primary-outline"
                >キャンセル</button>
                <button
                    @click="sendEventReport"
                    class="c-btn c-btn-primary"
                >送信する</button>
            </div>
        </template>
        <template v-if="eventReportModal.status === 'result'">
            <p class="text-top">報告内容を送信しました</p>
            <div class="buttons">
                <button
                    @click="closeEventReportModal"
                    class="c-btn c-btn-primary-outline"
                >閉じる</button>
            </div>
        </template>
    </pcg-modal>
    <pcg-modal
        class="userReportModal eventReportModal"
        :isActive="activeModal === 'userReportModal'"
        :bgImage="true"
        @close="closeUserReportModal"
    >
        <template #modalHeader>
            <h2 class="c-title c-title-page">プレイヤー報告</h2>
        </template>
        <template v-if="userReportModal.status === 'main'">
            <error-msg
                v-if="userReportModal.error && userReportModal.errorMessage"
                class="text-error"
                :message="userReportModal.errorMessage"
            ></error-msg>
            <div v-if="userReportModal.selectPlayer" class="userReportModal-table">
                <div class="userReportModal-table-tr">
                    <div class="userReportModal-table-th">プレイヤー名</div>
                    <div class="userReportModal-table-td">{{ userReportModal.selectPlayer.nickname }}</div>
                </div>
                <div class="userReportModal-table-tr">
                    <div class="userReportModal-table-th">プレイヤーID</div>
                    <div class="userReportModal-table-td">{{ userReportModal.selectPlayer.player_id }}</div>
                </div>
                <div class="userReportModal-table-tr textarea">
                    <div class="userReportModal-table-th"><label for="userReportText">報告内容</label></div>
                    <div class="userReportModal-table-td">
                        <textarea
                            id="userReportText"
                            class="c-input c-input-textarea"
                            name="userReportText"
                            v-model="userReportModal.reportText"
                        ></textarea>
                        <div class="c-checkbox">
                            <input
                                type="checkbox"
                                id="userReportCritical"
                                name="userReportCritical"
                                v-model="userReportModal.criticalFlg"
                            >
                            <label for="userReportCritical">特に深刻な報告</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button
                    @click="closeUserReportModal"
                    class="c-btn c-btn-primary-outline"
                >キャンセル</button>
                <button
                    :disabled="userReportModal.reportText === ''"
                    @click="sendUserReport"
                    class="c-btn c-btn-primary"
                >送信する</button>
            </div>
        </template>
        <template v-if="userReportModal.status === 'result'">
            <p class="text-top">報告内容を送信しました</p>
            <div class="buttons">
                <button
                    @click="closeUserReportModal"
                    class="c-btn c-btn-primary-outline"
                >閉じる</button>
            </div>
        </template>
    </pcg-modal>
</template>

<script>
import PcgPage from '@/component/Page.vue'
import ErrorMsg from '@/component/ErrorMsg.vue'
import PcgModal from '@/component/Modal.vue'
import pcgPager from '@/component/Pager.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    name: 'MyJudgeEventDetailPage',
    components: {
        PcgPage,
        pcgPager,
        ErrorMsg,
        PcgModal
    },
    data() {
        return {
            page: {
                title: '',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                    { name: 'オーガナイザー', path: '/mypage#judge' },
                ]
            },
            error: null,
            activeModal: '',
            eventHoldingId: null,
            eventDetailData: null,
            reportList: {
                event: [],
                player: {},
            },
            entryPlayersForm: {
                keyword: null,
                deckUnregistered: false,
            },
            entryPlayers: [],
            eventReportText: '',
            eventReportModal: {
                status: 'main',
                errorMessage: null
            },
            userReportModal: {
                status: 'main',
                selectPlayer: null,
                reportText: '',
                criticalFlg: false,
                error: false,
                errorMessage: null
            },
            pagination: {
                currentPage: 1,
                totalPage: 1,
                totalCount: 0,
                offset: 0,
                perPage: 32,
            },
        }
    },
    created() {
        this.eventHoldingId = this.$route.params.id
        this.fetchEventData()
        this.fetchJoiningPlayerList()
        this.fetchJudgeNoteList()
    },
    methods: {
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? zip : zip.slice(0, 3) + '-' + zip.slice(3)
        },
        setPage(newPage) {
            this.pagination.currentPage = newPage
            this.pagination.offset = (newPage - 1) * this.pagination.perPage
            this.fetchJoiningPlayerList()
            this.jumpListTop()
        },
        jumpListTop() {
            const element = document.getElementById('jumpListTop')
            element.scrollIntoView({
                behavior: 'smooth'
            })
        },
        resetReportText() {
            this.eventReportText = ''
        },
        sendUserReportModal(player) {
            this.userReportModal.selectPlayer = player
            this.activeModal = 'userReportModal'
        },
        sendUserReport() {
            if (!this.userReportModal.reportText) {
                this.userReportModal.error = true
                this.userReportModal.errorMessage = '報告内容を入力してください'

                return
            }

            if (this.userReportModal.reportText.length > 200) {
                this.userReportModal.error = true
                this.userReportModal.errorMessage = '200文字以内で記入してください。'

                return
            }

            const path = '/judge/send/note'
            const params = new URLSearchParams
            params.append('event_holding_id', this.eventHoldingId)
            params.append('player_id', this.userReportModal.selectPlayer.player_id)
            params.append('text', this.userReportModal.reportText)
            params.append('critical_flg', this.userReportModal.criticalFlg ? '1' : '0')

            this.axios.post(path, params)
            .then(() => {
                this.fetchJudgeNoteList()
                this.userReportModal.status = 'result'
                this.resetUserReportForm()
            }).catch(err => {
                this.userReportModal.error = true
                this.userReportModal.errorMessage = err.response.data.message
            })
        },
        closeUserReportModal() {
            this.resetUserReportForm()
            this.userReportModal.status = 'main'
            this.activeModal = null
        },
        resetUserReportForm() {
            this.userReportModal.selectPlayer = null
            this.userReportModal.reportText = ''
            this.userReportModal.criticalFlg = false
            this.userReportModal.error = false
            this.userReportModal.errorMessage = null
        },
        sendEventReportModalError(errorMessage) {
            this.error = errorMessage
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        sendEventReportModal() {
            if (this.eventReportText.length > 1000) {
                this.sendEventReportModalError('報告内容は1000文字以内で入力してください')

                return
            }
            if (this.eventReportText.length === 0) {
                this.sendEventReportModalError('報告内容を入力してください')

                return
            }

            this.activeModal = 'eventReportModal'
        },
        sendEventReport() {
            const path = '/judge/send/note'
            const params = new URLSearchParams
            params.append('event_holding_id', this.eventHoldingId)
            params.append('text', this.eventReportText)
            params.append('critical_flg', '0')

            this.axios.post(path, params)
            .then(() => {
                this.fetchJudgeNoteList()
                this.eventReportModal.status = 'result'
                this.eventReportText = ''
                this.error = null
            }).catch(err => {
                this.error = err.response.data.message
            })
        },
        closeEventReportModal() {
            this.eventReportModal.status = 'main'
            this.activeModal = null
        },
        async fetchEventData() {
            try {
                const response = await this.axios.get('/judge/event/detail', {
                    params: { event_holding_id: this.eventHoldingId }
                })
                this.eventDetailData = response.data.event
                this.eventDetailData.event_date_params = dayjs(this.eventDetailData.event_date.date).format('YYYYMMDD')
                this.page.title = this.eventDetailData.event_title
            } catch (error) {
                this.$router.push({name: 'NotFound'})
            }
        },
        async fetchJudgeNoteList() {
            const that = this
            that.reportList.player = {}

            this.axios.get('/get_judge_note', {
                params: { event_holding_id: this.eventHoldingId }
            })
            .then(response => {
                this.reportList.event = response.data.event

                response.data.player.forEach(report => {
                    if (Object.keys(that.reportList.player).indexOf(report.target_player_id) === -1) {
                        that.reportList.player[report.target_player_id] = []
                    }
                    that.reportList.player[report.target_player_id].push(report)
                })
            })
            .catch(err => {
                if (err.response.data.code !== 404) {
                    this.error = err.response.data.message;
                }
            })
        },
        async fetchJoiningPlayerList() {
            const params = {}

            if (this.entryPlayersForm.keyword) {
                params['keyword'] = this.entryPlayersForm.keyword
            }

            if (this.entryPlayersForm.deckUnregistered === true) {
                params['deck_unregistered'] = '1'
            }

            params['event_holding_id'] = this.eventHoldingId
            params['offset'] = this.pagination.offset

            this.axios.get('/joining_player_list', { params: params })
            .then(response => {
                this.pagination.totalCount = response.data.total
                this.pagination.totalPage = Math.ceil(this.pagination.totalCount / this.pagination.perPage) || 1
                this.entryPlayers = response.data.entry_players
            })
            .catch(err => {
                this.error = err.response.data.message
            })
        },
        changeDeckUnregisteredFlg() {
            this.pagination.offset = 0
            this.fetchJoiningPlayerList()
            this.jumpListTop()
        },
        searchKeyword() {
            this.pagination.offset = 0
            this.fetchJoiningPlayerList()
            this.jumpListTop()
        },
        clearSearchKeyword() {
            this.pagination.offset = 0
            this.entryPlayersForm.keyword = ''
            this.fetchJoiningPlayerList()
            this.jumpListTop()
        },
        displayDeckCount(deck_count) {
            if (!isNaN(deck_count)) {
                return deck_count + '枚'
            } else {
                return deck_count
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@use './style';
</style>
