<template>
    <system-page :page-settings="page">
        <p class="u-text-center message">
            パスワードの再設定を行います。<br>
            新しいパスワードを入力してください。
        </p>
        <error-msg
            v-if="error"
            class="u-mb-lg error-msg"
            :message="error"
        >
        </error-msg>
        <div class="c-box login">
            <div class="form">
                <form @submit.prevent="passwordReset(formPassword, formPasswordRetype)">
                    <div class="u-mb-sm">
                        <label for="formPassword" class="u-mb-sm u-display-block">パスワード</label>
                        <password-input
                            v-model:password="formPassword"
                            :hasError="hasPasswordError || hasPasswordNotMatchError"
                            placeholderMEssage="8文字以上"
                            id="formPassword"
                            :isNewPassword="true"
                        ></password-input>
                    </div>
                        <p class="u-text-exsmall u-text-kome">
                            アルファベット大文字、アルファベット小文字、数字、記号のうち2種類以上を含めて入力してください。
                        </p>
                    <div class="password">
                        <password-input
                            v-model:password="formPasswordRetype"
                            placeholderMEssage="パスワードを再入力してください"
                            :hasError="hasPasswordError || hasPasswordNotMatchError"
                            id="formPassword"
                            :isNewPassword="true"
                        ></password-input>
                    </div>
                    <div class="u-text-center">
                        <button type="submit" class="c-btn c-btn-primary">設定する</button>
                    </div>
                </form>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import passwordInput from '@/component/PasswordInput.vue'

export default {
    name: 'MailRegisterPage',
    components: {
        SystemPage,
        errorMsg,
        passwordInput,
    },
    data: function() {
        return {
            page: {
                title: 'パスワード再設定',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ パスワード再設定のページ。',
                breadcrumb: [
                    { name: 'ログイン', path: '/login' },
                ],
            },
            formPassword: null,
            formPasswordRetype: null,
            error: null,
            hasPasswordError: false,
            hasReEnterPasswordError: false,
            hasPasswordNotMatchError: false,
        }
    },
    created: function() {
        this.checkCorrectUrl()
    },
    methods: {
        checkCorrectUrl() {
            let params = new URLSearchParams()
            params.append('key', this.$route.query.key)

            this.axios.post('/check_authentication_password', params)
            .then(() => {})
            .catch(() => {
                this.$router.push({name: 'NotFound'})
            })
        },
        passwordReset(formPassword, formPasswordRetype) {
            this.hasPasswordError = false
            this.hasReEnterPasswordError = false
            this.hasPasswordNotMatchError = false

            if (!formPassword) {
                this.error = 'パスワードを入力してください。'
                this.hasPasswordError = true
                window.scrollTo({top: 0,})
                return
            }
            if(!this.formPassword.match(/^((?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])|(?=.*[A-Z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])|(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]))([a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]){8,}$/g))
            {
                this.error = 'アルファベット大文字、アルファベット小文字、数字、記号のうち2種類以上を含めた8文字以上を入力してください。'
                this.hasPasswordError = true
                window.scrollTo({top: 0,})
                return
            }
            if (!formPasswordRetype) {
                this.error = 'パスワード（再入力）を入力してください。'
                this.hasReEnterPasswordError = true
                window.scrollTo({top: 0,})
                return
            }
            if (formPassword !== formPasswordRetype) {
                this.error = 'パスワードとパスワード（再入力）が一致していません。'
                this.hasPasswordNotMatchError = true
                window.scrollTo({top: 0,})
                return
            }

            let params = new URLSearchParams()
            params.append('key', this.$route.query.key)
            params.append('password', formPassword)

            this.axios.post('/authentication_change_mail', params)
            .then(() => {
                this.$router.push({name: 'PasswordComplete'})
            })
            .catch(err => {
                if (err.response) {
                    this.error = err.response.data.message
                    window.scrollTo({top: 0,})
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.error-msg {
    @include g.pc {
        width: 620px;
    }
    @include g.sp {
        width: 335px;
    }
}

.message {
    margin-bottom: 40px;
}
.login {
    padding: 56px 70px;
    width: 620px;
    margin: 0 auto;
    font-size: 14px;

    .form {
        padding: 0;

        .u-text-kome {
            margin-bottom: 21px;
        }
        .password {
            margin-bottom: 56px;
        }
    }
}

@include g.sp {
    .message {
        padding: 0 20px;
        margin-bottom: 28px;
        text-align: left;
    }

    .login {
        width: 100%;
        padding: 0 20px;

        .form {
            padding: 0;
            .u-text-kome {
                margin-bottom: 17px;
            }
            .password {
                margin-bottom: 48px;
            }
        }
    }
}
</style>
