<template>
  <system-page :page-settings="page">
    <div class="c-content u-text-center complete">
      <div class="complete-text">
        <p class="u-mb">
          新しいメールアドレスへ確認用のメールを送信しました。
        </p>
        <p>メールに記載されているURLを24時間以内にクリックして、メールアドレスの変更を完了してください。</p>
      </div>
      <div class="to-mypage">
      <router-link to="/mypage" class="c-btn c-btn-primary-outline">マイページ</router-link>
      </div>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
  name: 'MailChangeComplatePage',
  components: {
    SystemPage,
  },
  data: function() {
    return {
      page: {
        title: 'メール送信完了',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ メール送信完了のページ。',
        breadcrumb: [
          { name: 'マイページ', path: '/mypage' },
          { name: 'ユーザー情報', path: '/mypage/profile' },
          { name: 'メールアドレス変更', path: '/mypage/user/mail' },
        ],
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style.scss';

.complete {
  .c-btn {
    margin-top: 55px;
  }

  @include g.sp {
    &-text {
      text-align: left;
    }
    .c-btn {
      margin-top: 50px;
    }
  }
}
.to-mypage {
  line-height: 1;
}
</style>
