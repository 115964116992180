<template>
    <div class="c-content">
        <h2 class="c-title-h2">この主催者の同日開催イベント</h2>
        <div class="anotherList">
            <router-link
                v-for="event in eventData.same_day_event"
                :key="`alist-${event}`"
                :to="{ name: 'EventDetail', params: { id: event.id,
                                                      trainersFlg: event.trainers_flg,
                                                      shopId: event.shop_id ? event.shop_id : 0,
                                                      eventDate: event.event_date ? event.event_date : 0,
                                                      dateId: event.date_id ? event.date_id : 0} }"
                target="_blank"
            >
                <div class="link">
                    <div class="left">
                        <div class="eventDate">
                            <span>{{ dataFormat(event.event_started_at,4) }}～{{ dataFormat(event.event_ended_at,4) }}</span>
                        </div>
                        <div class="statusArea" v-if="event.recruit_flg === 1 || event.discontinuance_flg === 1">
                            <div class="status"
                                v-if="event.discontinuance_flg === 0"
                                :class="{
                                    'status-1': event.entryStatusCode === 1,
                                    'status-2': event.entryStatusCode === 2,
                                    'status-3': event.entryStatusCode === 3,
                                    'status-5': event.entryStatusCode === 5,
                                    'status-6': event.entryStatusCode === 6
                                }"
                            >{{ event.entryStatus }}</div>
                            <div class="status status-4" v-else>
                                イベント中止
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="title">{{ event.event_title }}</div>
                        <div class="league" v-if="event.league_id != '5'">{{ event.league_title + 'リーグ' }}</div>
                        <div class="status"
                            v-if="event.recruit_flg === 1"
                            :class="{
                                'status-1': event.entryStatusCode === 1,
                                'status-2': event.entryStatusCode === 2,
                                'status-3': event.entryStatusCode === 3,
                                'status-5': event.entryStatusCode === 5,
                                'status-6': event.entryStatusCode === 6
                            }"
                        >{{ event.entryStatus }}</div>
                        <div class="status status-4"
                            v-else-if="event.discontinuance_flg === 1"
                        >イベント中止</div>
                        <div class="classification">
                            <event-kinds type="rule" v-if="event.eventAttrId === 1" />
                            <event-kinds type="beginner" v-if="event.eventAttrId === 2" />
                            <event-kinds type="serious-game" v-if="event.eventAttrId === 3"/>
                            <event-kinds type="remote" v-if="event.eventAttrId === 4" />
                            <span
                                class="c-eventPoint c-eventPoint-championship"
                                v-if="event.csp_flg === 1"
                            >
                                {{ 'チャンピオンシップポイント対象' }}
                            </span>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="otherEventLink" v-if="shopId">
            <router-link
                :to="{
                    path: '/event/search',
                    query: { shop_id: shopId },
                }"
                class="c-link-arrow"
            >
                この主催者のイベント一覧
            </router-link>
        </div>
    </div>
</template>

<script>
import stateData from '../methods/data'
import eventKinds from '@/component/EventKinds.vue'
export default {
    name: 'EventAnotherList',
    mixins: [stateData],
    components: {
        eventKinds,
    },
    props: {
        eventData: {
            type: Array,
            require: true,
        },
        shopId: {
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '../style/content';
@use '../style/listItem';
@use '~@/views/Event/style/color' as c;

@mixin arrow {
    &::after {
        @include g.setIconFont('\e909');
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 9px;
        margin: auto;
        width: fit-content;
        height: fit-content;
        font-size: 20px;
        @include g.sp {
            right: 0px;
            font-size: 12px;
        }
    }
}

.c-content {
    & > h2.c-title-h2 {
        margin-top: 56px;
        @include g.sp {
            margin-top: 48px;
        }
    }
}
.anotherList {
    & > a {
        display: inline-block;
        width: 100%;
        margin-top: 16px;
        color: g.$color-font;
        &:first-child {
            margin-top: 28px;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .link {
        background: g.$color-white;
        border: 1px solid g.$color-border-gray;
        border-radius: 16px;
        .right {
            .title {
                font-weight: bold;
            }
            .league {
                margin-top: 8px;
                color: g.$color-league;
                font-weight: bold;
                line-height: 1;
                &::before {
                    content: '◆';
                    font-size: 12px;
                    font-weight: normal;
                    margin-right: 4px;
                }
            }
            .classification {
                margin-top: 16px;
            }
        }
        @include g.pc {
            @include arrow;
            position: relative;
            display: flex;
            padding: 24px 0;
            &:hover {
                border: 1px solid g.$color-black-a060;
                outline: 1px solid g.$color-black-a060;
                box-shadow: 4px 4px 16px rgba(g.$color-black, 0.12);
            }
            .left {
                display: flex;
                align-items: center;
                padding: 0 32px 0 32px;
                font-size: 18px;
                border-right: 1px solid g.$color-border-gray;
                .statusArea {
                    position: relative;
                    margin-top: 15px;
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        display: block;
                        width: 20px;
                        height: 1px;
                        margin: auto;
                        border-top: 1px solid g.$color-black-a025;
                    }
                }
            }
            .right {
                flex: 1;
                padding-left: 32px;
                padding-right: 62px;
                .title {
                    font-size: 20px;
                }
                .league {
                    font-size: 14px;
                }
            }
        }
        @include g.sp {
            padding: 20px;
            border-radius: 12px;
            .left {
                border-bottom: 1px solid g.$color-border-gray;
            }
            .right {
                .title {
                    @include arrow;
                    position: relative;
                    padding-right: 20px;
                    font-size: 18px;
                }
                .league {
                    font-size: 13px;
                }
                .c-eventPoint {
                    display: block;
                }
            }
        }
    }
}
.otherEventLink {
    width: 100%;
    font-weight: 500;
    @include g.pc {
        margin-top: 24px;
        text-align: right;
    }
    @include g.sp {
        margin-top: 32px;
        text-align: center;
    }
}
</style>
