<template>
    <div class="mypage-wrapper" :class="isLogin ? 'login' : 'no-login'">
        <div class="mypage" :class="isLogin ? 'login' : 'no-login'">
            <template v-if="!isLogin">
                <div class="mypage-left">
                    <div class="players-logo">
                        <img src="@/assets/img/common/players_logo.png">
                    </div>
                </div>
                <div class="mypage-center">
                    <p class="text">
                        マイページでは、イベントのエントリー確認や<br>マイデッキの登録ができるよ
                    </p>
                </div>
                <div class="mypage-right">
                    <ul class="link">
                        <li>
                            <router-link :to="{name: 'Mypage'}">マイページ</router-link>
                        </li>
                        <li><router-link :to="{name: 'MailRegister'}">新規会員登録</router-link></li>
                    </ul>
                </div>
            </template>
            <template v-else>
                <div class="mypage-left">
                    <div v-if="user" class="container">
                        <div class="player-info">
                            <div class="avatar">
                                <img
                                    :src="user.avatar_image"
                                    alt="アバター"
                                >
                            </div>
                            <div class="info">
                                <p class="league">{{ user.current_league }}リーグ</p>
                                <p class="name">{{ user.nickname }}</p>
                                <p class="small">
                                    <span class="player-id id-margin">プレイヤーID : {{ user.player_id }}</span>
                                    <span class="prefecture">エリア : {{ user.prefecture }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="player-status">
                            <ul class="ps-table">
                                <li class="ps-tr csp">
                                    <div class="u-display-flex">
                                        <h4 class="ps-th">チャンピオンシップ<br>ポイント</h4>
                                        <p class="ps-td csp">
                                            <template v-if="user.champion_ship_point != null">{{ user.champion_ship_point }}pt</template>
                                            <template v-else>0pt</template><br>
                                            <span class="small">トレーナーズリーグ：{{ user.csp_from_trainers ? user.csp_from_trainers : '0' }}pt/最大30pt</span>
                                        </p>
                                    </div>
                                </li>
                                <li class="ps-tr ranking">
                                    <div class="u-display-flex">
                                        <h4 class="ps-th">ランキング</h4>
                                        <p class="ps-td ranking">{{ user.current_ranking ? user.current_ranking : '-' }}位</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mypage-right">
                    <ul class="link">
                        <li><router-link :to="{name: 'Mypage'}">マイページ</router-link></li>
                        <li><router-link :to="{name: 'Avatar'}">アバター変更</router-link></li>
                        <li><router-link :to="{name: 'MyEventList'}">出場予定のイベント</router-link></li>
                        <li><router-link :to="{name: 'MypageEventResult'}">イベント成績</router-link></li>
                        <li><router-link :to="{name: 'Profile'}">ユーザー情報</router-link></li>
                        <li><router-link :to="{name: 'DeckList'}">マイデッキ</router-link></li>
                        <li><router-link :to="{name: 'Faq'}">よくあるご質問</router-link></li>
                        <li class="logout"><button class="logout-btn" @click="logout">ログアウト</button></li>
                    </ul>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderPlayerContent',
    computed: {
        isLogin() {
            return this.$store.state.loggedIn
        },
        user() {
            return this.$store.getters.getUser()
        }
    },
    watch: {
        '$route': function() {
            this.user = this.$store.getters.getUser()
        },
    },
    methods: {
        logout() {
            this.$store.commit('setLogoutState')
            this.$router.push({name: 'Login'})
        },
    },
}
</script>

<style lang="scss" scoped>
@use "~@/assets/style/global" as g;

.link {
    & > li {
        & > a {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 150px;
            height: 40px;
            font-size: 13px;
            color: g.$color-font-white !important;
            background: g.$color-blue;
            border-radius: 4px;
            @include g.pc {
                &:hover {
                    background: g.$color-green;
                    text-decoration: none;
                }
            }
            &::after {
                @include g.setIconFont('\e909');
                position: absolute;
                font-size: 10px;
                right: 12px;
            }
        }
    }
}

.mypage {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-wrapper {
        width: 100%;
    }
    &.no-login {
        padding: 32px 80px 33px;
        .mypage {
            &-left {
                display: flex;
                align-items: center;
                height: 100%;
                & > .players-logo {
                    width: 140px;
                    height: fit-content;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &-center {
                padding: 0 31px 0 27px;
                font-size: 12px;
            }
            &-right {
                width: 310px;
                height: 100%;
                .link {
                    display: flex;
                    justify-content: space-between;
                    align-content: center;
                    align-items: center;
                    flex-wrap: nowrap;
                    height: 100%;
                }
            }
        }
    }
    &.login {
        padding: 32px 70px 33px;
        .mypage {
            &-left {
                width: 400px;
                & > .container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .player-info {
                        width: 100%;
                        display: flex;
                        .avatar {
                            width: 66px;
                            height: 66px;
                            margin-right: 20px;
                        }
                        .info {
                            .league {
                                font-size: 12px;
                                color: g.$color-league;
                            }
                            .name {
                                @include g.font-normal;
                                padding: 8px 0 9px;
                                line-height: 1;
                                font-weight: bold;
                                color: g.$color-black-a060;
                            }
                            .small {
                                font-size: 11px;
                            }
                        }
                    }
                    .player-status {
                        width: 324px;
                        margin-top: 18px;
                        .ps-table {
                            border-radius: g.$border-radius-5;

                            .ps-tr {
                                display: flex;
                                margin-bottom: 10px;
                                border: 1px solid #BAE3F0;
                                border-radius: 6px;

                                &.csp {
                                    height: 54px;
                                }
                                &.ranking {
                                    height: 48px;
                                }

                                &:nth-last-of-type(1) {
                                    margin-bottom: 0;
                                }
                            }

                            .ps-th {
                                display: flex;
                                // justify-content: center;
                                align-items: center;
                                flex: 0 0 123px;
                                padding-left: 12px;
                                height: 100%;
                                background: #d5eff5;
                                color: #31576f;
                                font-size: 11px;
                                font-weight: bold;
                                line-height: 18px;
                                text-align: left;
                            }

                            .ps-td {
                                font-size: 16px;
                                line-height: 1;
                                flex-shrink: 0;
                                font-weight: bold;
                                padding: 10px 12px 12px;

                                &.csp {
                                    padding: 8px 12px;
                                }
                                &.ranking {
                                    padding: 12px;
                                }
                                .small {
                                    display: inline-block;
                                    color: g.$color-black-a060;
                                    font-size: 10px;
                                    font-weight: normal;
                                    height: 15px;
                                    @include g.sp {
                                        // 10px未満のfont-size指定を無視するブラウザがあるため縮小変形にて対応
                                        transform: scale(0.75);
                                        transform-origin: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-right {
                width: 330px;
                height: 190px;
                .link {
                    display: flex;
                    justify-content: space-between;
                    align-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    height: 100%;
                    a {
                        width: 160px;
                    }
                    .logout {
                        display: flex;
                        align-items: flex-end;
                        align-content: flex-end;
                        justify-content: flex-end;
                        width: 160px;
                        height: 40px;
                        &-btn {
                            font-size: 13px;
                            color: g.$color-blue;
                            cursor: pointer;
                            &::after {
                                @include g.setIconFont('\e90d');
                                font-size: 12px;
                                margin-left: 5px;
                            }
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include g.sp {
    .mypage {
        &-wrapper {
            padding: 20px 16px;
            background: g.$color-gradietion;
            &.no-login {
                display: none;
            }
        }
        &.no-login {
            display: none;
        }
        &.login {
            flex-direction: column;
            padding: 20px 20px 20px 10px;
            background-color: g.$color-white;
            border-radius: 8px;
            .mypage {
                &-left {
                    width: 100%;
                    & > .container {
                        .player-info {
                            .avatar {
                                width: 66px;
                                height: 66px;
                            }
                            .info {
                                .league {
                                    font-size: 14px;
                                }
                                .name {
                                    font-size: 18px;
                                }
                                .small {
                                    font-size: 12px;
                                    & > span {
                                        display: block;
                                    }
                                }
                            }
                        }
                        .player-status {
                            width: 100%;
                            padding-left: 10px;
                            .ps-table {
                                .ps-th {
                                    flex: 0 0 132px;
                                    padding: 14px 12px 15px;
                                }
                                .ps-td {
                                    padding: 10px;
                                    font-size: 20px;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
                &-right {
                    width: 100%;
                    height: auto;
                    padding-top: 20px;
                    padding-left: 10px;
                    .link {
                        li {
                            width: calc(50% - (7px / 2));
                            margin-bottom: 7px;
                        }
                        a {
                            width: 100%;
                        }
                        .logout {
                            width: calc(50% - (7px / 2));
                        }
                    }
                }
            }
        }
    }
}
.id-margin {
    margin-right: 5px;
}

</style>
