<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <error-msg v-if="message" class="u-text-bold">
                <p class="u-text-pre-line u-text-warning-icon">{{ message }}</p>
            </error-msg>
            <div class="to-top">
                <router-link
                    :to="{name: 'EventTop'}"
                    class="c-btn c-btn-primary-outline"
                >
                    イベントトップへ
                </router-link>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
export default {
    name: 'QrLoginPlayerErrorPage',
    components: {
        SystemPage,
        errorMsg
    },
    props: {
        message: {
            type: String,
            default: "",
        },
    },
    data: function() {
        return {
            page: {
                title: 'エラーが発生しました',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ エラーのページ。',
            },
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-warningBox {
    width: g.$content-width-pc-sm;
    @include g.sp {
        width: 100%;
    }
}

.to-top {
    margin-top: 56px;
    line-height: 1;
    text-align: center;
    @include g.sp {
        margin-top: 48px;
    }
}

.complete {
    &-text {
        margin-top: 30px;
        text-align: center;
    }

    @include g.pc {
        .c-btn {
            margin-top: 55px;
        }
    }

    @include g.sp {
        .c-btn {
            margin-top: 50px;
        }
    }
}
</style>
