<template>
  <system-page :page-settings="page">
    <p class="u-text-center message u-mb-lg">
      パスワードを再設定しました。<br>
      ログイン画面から、再度ログインをお試しください。
    </p>
    <div class="u-text-center to-login">
      <a href="#" class="c-btn c-btn-primary-outline" @click.prevent="logout()">ログイン</a>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'

export default {
  name: 'PasswordCompletePage',
  components: {
    SystemPage,
  },
  data: function() {
    return {
      page: {
        title: 'パスワード再設定完了',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ パスワード再設定完了のページ。',
        breadcrumb: [
          { name: 'ログイン', path: '/login' },
          { name: 'パスワード再設定', path: '/login/password/reset' }
        ],
      }
    }
  },
  methods: {
    logout() {
        this.$store.commit('setLogoutState')
        this.$router.push({name: 'Login'})
    },
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.message {
  @include g.pc {
    margin-bottom: 56px;
  }
  @include g.sp {
    padding: 0 20px;
    text-align: left;
    margin-bottom: 48px;
  }
}
.to-login {
  line-height: 1;
}

</style>
