<template>
    <system-page :page-settings="page">
        <div class="c-content u-text-center complete">
            <div class="u-text-center">
                <p class="u-mb-lg">
                    あなたの所属するリーグは…<br>
                    <span class="league">{{ league }}リーグ</span>です
                </p>
                <div class="c-warningBox u-mb-lg">
                    <p class="u-text-bold u-text-center">会員登録はまだ完了していません。</p>
                </div>
                <p class="u-mb-lg complete-text-left">登録いただいた携帯電話番号へSMSをお送りしました。</p>
                <p class="complete-text-left">お送りしたSMSの認証番号を入力してください。</p>
                <p class="complete-text complete-text-left">
                    ポケモンカードのイベントの応募時には、<br class="u-display-sp">SMS認証が完了している必要があります。
                </p>
                <div class="to-sms">
                    <router-link to="/login/register/sms" class="c-btn c-btn-primary">認証画面へ</router-link>
                </div>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'

export default {
    name: 'UserRegisterCompletePage',
    components: {
        SystemPage,
    },
    data: function() {
        return {
            page: {
                title: '仮登録完了',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ 仮登録完了のページ。',
                breadcrumb: [
                    { name: '新規会員登録', path: '/login/register' },
                    { name: 'ポケモンカードゲーム プレイヤーズクラブ会員登録', path: '' },
                    { name: '登録内容確認', path: '' },
                ],
            },
            league: '',
        }
    },
    created: function() {
        this.init()
    },
    methods: {
        init()
        {
            if (this.$route.params.league) {
                this.league = this.$route.params.league
            } else {
                this.$router.push({name: 'NotFound'})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.to-sms {
    line-height: 1;
}
.complete {
    color: g.$color-black;
    .league {
        font-weight: bold;
        color: g.$color-league;
    }
    .c-warningBox {
        @include g.pc {
            width: 480px;
            margin: 0 auto 30px;
        }
        @include g.sp {
            height: 56px;
            padding: 16px 30px;

        }
    }
    .complete-text {
        margin-bottom: 56px;
        @include g.sp {
            margin-bottom: 48px;
            &-left {
                text-align: left;
            }
        }
    }
}
</style>
