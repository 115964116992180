<template>
    <system-page :page-settings="page">
        <div v-if="!resendPhoneNumber" class="c-content pc-text-align-center">
            <error-msg
                class="u-mb-lg"
            >
                <p>電話番号の取得に失敗しました。</p>
                <p>お手数ですが、ログインからやりなおしてください。</p>
            </error-msg>
            <div class="u-text-center to-mypage">
                <router-link :to="{name: 'Mypage'}" class="c-btn c-btn-primary-outline">
                    マイページ
                </router-link>
            </div>
        </div>
        <div v-else class="c-content pc-text-align-center">
            <p v-if="isMypage" class="message">
                登録いただいた携帯電話番号へSMSをお送りしました。
                認証番号を入力してください。
            </p>
            <p v-else class="message">
                携帯電話番号へお送りした認証番号を入力してください。
            </p>
            <error-msg
                v-if="error"
                :message="error"
                class="u-mb-lg error-msg pc-width-640"
            >
            </error-msg>
            <div class="c-box pc-width-640">
                <form @submit.prevent="send()">
                    <label class="confirmationCode">
                        <span>
                            認証番号
                        </span>
                        <input
                            type="text"
                            class="c-input c-input-text u-text-left"
                            :class="{'c-input-error': error}"
                            placeholder="認証番号を入力"
                            v-model="authCode"
                        >
                    </label>
                    <div class="u-text-center">
                        <button
                            type="submit"
                            class="c-btn c-btn-primary"
                        >
                            認証する
                        </button>
                    </div>
                </form>
            </div>
            <a
                class="arrowLink"
                @click="activeModal = 'modalResendingSms'"
            >
                認証番号を再送する
            </a>
        </div>

        <pcg-modal
            class="modalResendingSms"
            :isActive="activeModal === 'modalResendingSms'"
            @close="closeModal()"
        >
            <template #modalHeader>
                <h2 class="c-title c-title-page modal-title">認証番号再送</h2>
            </template>
            <error-msg
                v-if="errorModal"
                class="u-mb-lg pc-width-640"
            >
                <p>{{ errorModal }}</p>
            </error-msg>
            <p class="pc-text-align-center">
                登録された携帯電話番号へ認証番号をお送りします。<br>
                番号が異なる場合は、「ユーザ情報」より、アカウント情報の変更を行ってください。
            </p>
            <contact-adress-box
                class="adressBox"
                :addressName="'携帯電話番号'"
                :addressValue="resendPhoneNumber"
            ></contact-adress-box>
            <template #modalFooter>
                <button
                    class="c-btn c-btn-primary"
                    @click="resendSms()"
                >
                    認証番号を再送する
                </button>
            </template>
        </pcg-modal>
        <pcg-modal
            class="modalResendingSmsComplete"
            :isActive="activeModal === 'modalResendingSmsComplete'"
            @close="closeModal()"
        >
            <template #modalHeader>
                <h2 class="c-title c-title-page modal-title">登録された携帯電話番号へ<br>認証番号を送信しました</h2>
            </template>
            <p>
                しばらくたってもSMSが届かない場合は、お手数ですが、再度認証番号の再送を行ってください。
            </p>
            <template #modalFooter>
                <button
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </template>
        </pcg-modal>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import pcgModal from '@/component/Modal.vue'
import contactAdressBox from '@/component/ContactAdressBox.vue'

export default {
    name: 'SmsIdentifyPage',
    components: {
        SystemPage,
        errorMsg,
        pcgModal,
        contactAdressBox,
    },
    data: function() {
        return {
            page: {
                title: 'SMS認証',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ SMS認証のページ。',
                breadcrumb: []
            },
            resendPhoneNumber: '',
            authCode: '',
            activeModal: null,
            error: null,
            errorModal: null,
            isMypage: false,
            user: null,
        }
    },
    created: function() {
        this.init()
        if(this.$route.params.isMypage)
        {
            this.resendSmsIsMypage()
        }
    },
    methods: {
        init: async function()
        {
            this.resendPhoneNumber = this.$route.query.phoneNumber || this.$store.state.phoneNumber
            if (this.resendPhoneNumber === null || this.resendPhoneNumber === '') {
                await this.getUser()
                this.resendPhoneNumber = this.user ? this.user.phone_number : ''
            }
        },
        getUser: async function()
        {
            await this.axios.post('/get_player_account')
            .then(response => {
                this.user = response.data.player
            })
            .catch(() => {
                return null
            })
        },
        send()
        {
            if (!this.authCode) {
                this.error = '認証番号を入力してください。'
                return
            }

            let params = new URLSearchParams
            params.append('auth_code', this.authCode)
            params.append('phone_number', this.resendPhoneNumber)
            if(this.$store.state.mail !== null)
            {
                params.append('mail', this.$store.state.mail)
            }

            const api = '/authentication_sms'

            this.axios.post(api, params)
            .then(() => {
                    //SMS認証完了画面へ遷移
                    this.$router.push({name: 'SmsIdentifyComplete'})
        })
            .catch(err => {
                this.error = err.response.data.message
            })

        },
        closeModal() {
            this.activeModal = null
        },
        resendSms() {
            let params = new URLSearchParams
            params.append('phone_number', this.resendPhoneNumber)
            if(this.$store.state.mail !== null)
            {
                params.append('mail', this.$store.state.mail)
            }

            const api = '/send_authentication_sms'

            this.axios.post(api, params)
            .then(() => {
                this.activeModal = 'modalResendingSmsComplete'
        })
            .catch(err => {
                this.errorModal = err.response.data.message
            })
        },
        resendSmsIsMypage() {
            let params = new URLSearchParams
            params.append('phone_number', this.resendPhoneNumber)

            const api = '/send_authentication_sms'

            this.axios.post(api, params)
            .then(() => {
                this.activeModal = ''
                this.isMypage = true
        })
            .catch(err => {
                this.error = err.response.data.message
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.error-msg {
    @include g.pc {
        width: 480px;
        text-align: left;
    }
    @include g.sp {
        width: 335px;
    }
}

.arrowLink {
    position: relative;
    height: 24px;

    &::after {
            content: "\e909";
            font-family: "pcg_icon";
    }
}

.to-mypage {
    line-height: 1;
}

@include g.pc {
    .pc-text-align-center {
        text-align: center;
    }

    .pc-width-640 {
        width: 640px;
        margin-right: auto;
        margin-left: auto;
    }

    .message {
        margin-bottom: 40px;
    }

    .c-box {
        margin-bottom: 40px;
        padding: 55px;
        font-size: 14px;
    }

    .confirmationCode {
        display: block;
        margin-bottom: 40px;

        input {
            width: 290px;
            margin-left: g.$margin;
        }
    }

    .modal > p {
        text-align: center;
    }

    .modalResendingSms {
        p {
            margin-bottom: 40px;
        }
    }

    .modal {
        .container {
            .content {
                .modalHeader {
                    padding: 72px 40px 35px;
                    .c-title {
                        font-size: 32px;
                    }
                }
                .modalFooter {
                    padding: 40px 30px 72px;
                }
            }
        }
    }
}

@include g.sp {
    .message {
        margin-bottom: 30px;
    }

    .c-box {
        margin-bottom: 35px;
        padding-bottom: 35px;
        border-bottom: 1px solid g.$color-blue-a040;
        border-radius: 0;
        font-size: 14px;
    }

    .confirmationCode {
        display: block;
        margin-bottom: 50px;

        span {
            display: block;
            margin-bottom: 10px;
        }

        input {
            width: 100%;
        }
    }

    .arrowLink {
        display: block;
        text-align: center;
    }

    .modalResendingSms {
        p {
            margin-bottom: 20px;
        }
    }

    .modal {
        .container {
            .content {
                .modalHeader {
                    .c-title {
                        font-size: 24px;
                        line-height: 34px;
                    }
                }
            }
        }
    }

    ::v-deep .modal {
        .container {
            .content {
                .modalHeader {
                        padding-left: 20px;
                        padding-right: 20px;
                }
            }
        }
    }
}
</style>
