<template>
    <system-page :page-settings="page">
        <div class="c-content u-text-center complete">
            <div class="u-text-center">
                <p class="u-mb-lg">
                    あなたの所属するリーグは…<br>
                    <span class="league">{{ league }}リーグ</span>です
                </p>
                <p class="u-mb-lg">
                    追加情報の登録が完了しました！
                </p>
                <div class="c-warningBox u-mb-lg ">
                    <p class="u-text-bold u-text-center">引き続いて、「SMS認証」を行ってください。</p>
                </div>
                <p class="u-mb-lg complete-text-left">登録いただいた携帯電話番号へSMSをお送りしました。</p>
                <p class="complete-text-left">お送りしたSMSの認証番号を入力してください。</p>
                <p class="complete-text complete-text-left">
                    ポケモンカードのイベントの応募時には、<br class="u-display-sp">SMS認証が完了している必要があります。
                </p>
                <div class="to-sms margin">
                    <router-link to="/login/register/sms" class="c-btn c-btn-primary">認証画面へ</router-link>
                </div>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'

export default {
    name: 'UserRegisterCompletePage',
    components: {
        SystemPage,
    },
    data: function() {
        return {
            page: {
                title: '追加情報登録完了',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ 追加情報登録完了のページ。',
                breadcrumb: [
                { name: 'アカウント移行', path: '/migration' },
                ],
            },
            league: '',
        }
    },
    created: function() {
        this.init()
    },
    methods: {
        init()
        {
            if (this.$route.params.league) {
                this.league = this.$route.params.league
            } else {
                this.$router.push({name: 'NotFound'})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.complete {
    &-label {
        padding: g.$margin 0;
        border-top: g.$border-dotted;
        border-bottom: g.$border-dotted;
        & > p {
            &::before {
                content: '！';
                display: inline-block;
                width: 26px;
                height: 26px;
                margin-right: 25px;
                background-color: g.$red-10;
                border-radius: 50%;
            }
        }
    }

    .league {
        font-weight: bold;
        color: g.$color-league;
    }
}
.margin {
    margin-top: 60px;
}
.c-warningBox {
    @include g.pc {
        width: 480px;
        margin: 0 auto 30px;
    }
}
</style>
