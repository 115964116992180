<template>
    <div class="c-input-password password-outer">
        <input
            id="formPassword"
            :type="inputPasswordType"
            class="c-input c-input-text u-width-100 password"
            :class="{'c-input-error': hasError}"
            @input="$emit('update:password',$event.target.value)"
            :placeholder="placeholderMEssage"
            :autocomplete="isNewPassword ? 'new-password' : null"
        >
        <p class="icon-eye" v-if="!isPasswordView" @click="changePasswordView"></p>
        <p class="icon-eye_close" v-if="isPasswordView" @click="changePasswordView"></p>
    </div>
</template>

<script>
export default {
    name: 'PasswordInput',
    props: {
        hasError: {
            type: Boolean
        },
        placeholderMEssage: {
            type: String
        },
        isNewPassword: {
            type: Boolean,
            default: false,
        }
    },
    data: function() {
        return {
            isPasswordView: false
        }
    },
    computed: {
        inputPasswordType() {
            return this.isPasswordView ? "text" : "password";
        },
    },
    methods: {
        changePasswordView() {
            this.isPasswordView = !this.isPasswordView
        },
    }
}
</script>
