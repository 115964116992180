<template>
    <pcg-page :page-settings="page">
        <div>
            <div class="c-content">
                <error-msg
                    v-if="error"
                    class="u-mb-lg pc-width-640"
                >
                    <p><pre>{{ error }}</pre></p>
                </error-msg>
            </div>
            <div v-if="isDisplay" class="detail-mb">
                <div class="c-content">
                    <div class="eventTitle">
                        <div class="eventTitle-classification">
                            <event-classification
                                class="u-mr"
                                :type="eventData.eventTypeName"
                            ></event-classification>
                                <event-kinds class="u-mr" type="rule" v-if="eventData.eventAttrId === 1" />
                                <event-kinds class="u-mr" type="beginner" v-if="eventData.eventAttrId === 2" />
                                <event-kinds class="u-mr" type="serious-game" v-if="eventData.eventAttrId === 3"/>
                                <event-kinds class="u-mr" type="remote" v-if="eventData.eventAttrId === 4" />
                        </div>
                        <div class="eventTitle-title">
                            <h1 class="c-title-event">{{ eventData.orgEventTitle ? eventData.orgEventTitle : eventData.eventTitle }}</h1>
                            <div class="eventTitle-leagueImg">
                                <!-- <img v-if="eventData.eventTypeId == 1" src="@/assets/img/event/league/champions.png"> -->
                                <img v-if="eventData.eventTypeId == 2" src="@/assets/img/event/league/city.png">
                                <img v-if="eventData.eventTypeId == 3" src="@/assets/img/event/league/trainer.png">
                                <img v-if="eventData.eventTypeId == 4" src="@/assets/img/event/league/gym.png">
                                <!-- <img v-if="eventData.eventTypeId == 5" src="@/assets/img/event/league/campaign.png"> -->
                                <img v-if="eventData.eventTypeId == 6" src="@/assets/img/event/league/organizer.png">
                                <!-- <img v-if="eventData.eventTypeId == 7" src="@/assets/img/event/league/sealed.png"> -->
                            </div>
                        </div>
                        <div class="eventTitle-category">
                            <div
                                v-if="eventData.leagueTitle"
                                class="eventTitle-league"
                            >
                                {{ eventData.leagueTitle }}リーグ
                            </div>
                            <div
                                v-if="eventData.cspFlg === '1'"
                                class="c-eventPoint c-eventPoint-championship"
                            >
                                {{ 'チャンピオンシップポイント対象' }}
                            </div>
                        </div>
                    </div>
                </div>
                <event-state
                    @openModal="openApplicationModal()"
                    :state="eventStatus"
                    :eventData="eventData"
                    :isEntry="isEntry"
                    :recruitFlg="eventData.recruitFlg"
                ></event-state>
                <nav class="c-content c-content-wide-sp">
                    <ul class="c-anchorLink">
                        <li>
                            <a href="#anchor1"><span class="u-display-pc">イベント</span>概要</a>
                        </li>
                        <li>
                            <a href="#anchor2">主催者</a>
                        </li>
                        <li v-if="isOffline">
                            <a href="#anchor3">会場</a>
                        </li>
                        <li v-if="canApply">
                            <a href="#anchor4"><span class="u-display-pc">イベント</span>応募</a>
                        </li>
                        <li v-if="sameDayEventData.length !== 0">
                            <a href="#anchor5">同日開催<span class="u-display-pc">イベント</span></a>
                        </li>
                    </ul>
                </nav>
                <div id="anchor1" class="c-content overview">
                    <h2 class="c-title-h2">イベント概要</h2>
                    <div v-if="!isOrganizer" class="overview-text">
                        {{ eventData.detail }}
                    </div>
                    <div class="c-table-list c-table-list-wide">
                        <div class="c-table-list-row" v-if="isOrganizer">
                            <div class="column-left">イベント種別</div>
                            <div class="column-right">
                                {{ eventData.eventTitle }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isTPC">
                            <div class="column-left">リーグ</div>
                            <div class="column-right">
                                {{ eventData.leagueTitle }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="!isTPC">
                            <div class="column-left">参加条件</div>
                            <div class="column-right">
                                {{ eventData.entryCondition ? eventData.entryCondition : 'なし' }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isTPC">
                            <div class="column-left">レギュレーション</div>
                            <div class="column-right" v-if="eventData.deckCount">
                                {{ eventData.regulationTitle }}<br>
                                {{ displayDeckCount(eventData.deckCount)}}
                            </div>
                            <div class="column-right" v-else>
                                {{ eventData.regulationTitle }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="!isTPC">
                            <div class="column-left">対戦形式<br>レギュレーション</div>
                            <div class="column-right" v-if="eventData.deckCount">
                                {{ eventData.regulationTitle }}<br>
                                {{ displayDeckCount(eventData.deckCount)}}
                            </div>
                            <div class="column-right" v-else>
                                {{ eventData.regulationTitle }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isOrganizer || isTPC || (isNotTPCorOrganizer && isReservation)">
                            <div class="column-left">{{ isEntryEventType ? '事前応募' : '事前予約' }}</div>
                            <div class="column-right" v-if="isReservation">
                                    <template v-if="( eventData.eventTypeId == 1 || eventData.eventTypeId == 2 || eventData.eventTypeId == 7 ) && ( eventData.autoAdditionalEntryFlg && isFirstEntryEnded )">
                                    あり/先着<br>
                                    追加先着応募受付期間に「イベント応募へ」ボタンより応募してください。
                                    </template>
                                    <template v-else-if="( eventData.eventTypeId == 1 || eventData.eventTypeId == 2 || eventData.eventTypeId == 7 ) && eventData.entryTypeId != 1">
                                    あり/抽選<br>
                                    抽選応募受付期間に「イベント応募へ」ボタンより応募してください。
                                    </template>
                                    <template v-else-if="( eventData.eventTypeId == 1 || eventData.eventTypeId == 2 || eventData.eventTypeId == 7 ) && eventData.entryTypeId == 1">
                                    あり/先着<br>
                                    先着応募受付期間に「イベント応募へ」ボタンより応募してください。
                                    </template>
                                    <template v-else-if="isOrganizer || isTPC || isNotTPCorOrganizer">
                                    あり<br>
                                    予約方法は主催者へお問い合わせください。
                                    </template>
                            </div>
                            <div class="column-right" v-else>
                                    なし
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isEntryEventType">
                            <div class="column-left">デッキの事前登録</div>
                            <div class="column-right" v-if="eventData.deckRegistFlg === 1">
                                    デッキの登録が必要です。<br>
                                    当選後、
                                    <span class="u-text-bold">{{dataFormat(eventData.deckRegistEndedAt,1)}}</span>
                                    までに「マイページ」からデッキの登録を行なってください。
                            </div>
                            <div class="column-right" v-else>
                                なし
                            </div>
                        </div>
                        <div class="c-table-list-row">
                            <div class="column-left">参加費</div>
                            <div v-if="eventData.entryFee" class="column-right">
                                {{ displayEntryFee(eventData.entryFee) }}
                            </div>
                            <div v-else class="column-right">
                                無料
                            </div>
                        </div>
                        <div class="c-table-list-row">
                            <div class="column-left">定員</div>
                            <div class="column-right">
                                {{ eventData.capacity + '人' }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isPrize && isNotTPCorOrganizer">
                            <div class="column-left">プレゼント</div>
                            <div class="column-right">
                                <free-text :freeText="eventData.prize"></free-text>
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isOrgEventInfo && isOrganizer">
                            <div class="column-left">イベント内容</div>
                            <div class="column-right u-text-pre-line">
                                {{ eventData.orgEventDetail }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isOrgEventInfo && isOrganizer">
                            <div class="column-left">持ち物</div>
                            <div class="column-right u-text-pre-line">
                                {{ eventData.orgEventGoods }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isStaff && (isNotTPCorOrganizer || isOrganizer)">
                            <div class="column-left">イベント担当者</div>
                            <div class="column-right">
                                {{ eventData.staff }}
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isRemarks && (isNotTPCorOrganizer || isOrganizer)">
                            <div class="column-left">その他</div>
                            <div class="column-right">
                                <free-text :freeText="eventData.remarks"></free-text>
                            </div>
                        </div>
                        <div class="c-table-list-row" v-if="isEventInquiry && isOrganizer">
                            <div class="column-left">お問い合わせ先</div>
                            <div class="column-right">
                                <free-text :freeText="eventData.eventInquiry"></free-text>
                            </div>
                        </div>
                        <div class="c-table-list-row bottom-border u-mb-lg"></div>
                    </div>
                    <span class="u-text-bold" v-if="eventData.eventTypeId !== 1 && eventData.eventTypeId !== 6">
                        <a href="https://support.pokemon.jp/form_app/report/pokemon-card/event/" target="_blank">イベントの品質など、お気づきの点があればこちら</a>
                    </span>
                </div>
                <div id="anchor2" class="c-content">
                    <h2 class="c-title-h2">主催者</h2>
                    <event-organizer
                        :isTPC="isTPC"
                        :urlTitle="eventData.shopName"
                        :tel="eventData.tel"
                        :shopId="eventData.shopId"
                    ></event-organizer>
                </div>
                <div
                    id="anchor3"
                    class="c-content"
                    v-if="isOffline"
                >
                    <h2 class="c-title-h2">会場</h2>
                    <event-map
                        :eventData="eventData"
                        :mapUrl="mapUrl"
                    ></event-map>
                </div>
                <event-application
                    id="anchor4"
                    v-if="!isCancel && isEntry && eventStatus && eventData.recruitFlg === 1"
                    @openModal="openApplicationModal()"
                    :state="eventStatus"
                    :eventData="eventData"
                    :isArrivingFirst="isArrivingFirst"
                    :isDrawing="isDrawing"
                    :isReservoir="isReservoir"
                    :isEntrying="isEntrying"
                    :isAdditionalEntry="isAdditionalEntry"
                ></event-application>
                <event-another-list
                    id="anchor5"
                    v-if="sameDayEventData.length !== 0"
                    :eventData="sameDayEventData"
                    :shopId="shopId"
                ></event-another-list>
                <event-application-modal
                    :isActiveModal="activeModal == 'ApplicationModal'"
                    @close="activeModal = null"
                    :eventData="eventData"
                    :age="age"
                    :isArrivingFirst="isArrivingFirst"
                    :isDrawing="isDrawing"
                    :isReservoir="isReservoir"
                    :isEntrying="isEntrying"
                    :isAdditionalEntry="isAdditionalEntry"
                    :isUsedPriority="isUsedPriority"
                ></event-application-modal>
                <div class="c-content u-text-small note note-text" v-if="eventData.eventTypeId === 1">
                    <ul>
                        <li class="u-text-kome" v-if="isArrivingFirst">本イベントの受付は先着制となります。</li>
                        <li class="u-text-kome" v-if="isDrawing">本イベントの受付は抽選制となります。</li>
                        <li class="u-text-kome">天気などの都合により、イベントが予告なく中止となる場合がございます。予めご了承ください。</li>
                        <li class="u-text-kome" v-if="isArrivingFirst || isDrawing">エントリーはお一人様につき1回のみです。</li>
                        <li class="u-text-kome" v-if="isArrivingFirst || isDrawing">お一人で複数のエントリーが認められた場合、今年度のチャンピオンシップポイントは全て無効になります。また、その他ペナルティが科される場合があります。</li>
                        <li class="u-text-kome">18歳未満の方は、プレイヤーズクラブへの登録及び本大会へのエントリーの際に、保護者の同意が必要です。</li>
                        <li class="u-text-kome">ご利用の端末で、@players.pokemon-card.comおよび@pokemon-support.comからのメールを受信できるよう、あらかじめ設定下さい。</li>
                        <li class="u-text-kome">お申込み内容に不明な点があった場合、メールにて内容を確認させていただく場合があります。</li>
                        <li class="u-text-kome">大会当日、正当な理由なく欠場された場合、今後開催するイベントにご参加いただけなくなる場合があります。</li>
                    </ul>
                </div>
                <div class="c-content u-text-small note note-text" v-if="eventData.eventTypeId === 2">
                    <ul>
                        <li class="u-text-kome">本イベントは、株式会社ポケモンが主催するイベントではございません。</li>
                        <li class="u-text-kome">指定の日時までに、デッキ登録をお願いします。</li>
                        <li class="u-text-kome">天気などの都合により、イベントが予告なく変更・中止となる場合がございます。</li>
                        <li class="u-text-kome">定員になり次第、受付を終了させていただく場合がございます。</li>
                    </ul>
                </div>
                <div class="c-content u-text-small note note-text" v-if="eventData.eventTypeId === 7 || eventData.eventTypeId === 3">
                    <ul>
                        <li class="u-text-kome">本イベントは、株式会社ポケモンが主催するイベントではございません。</li>
                        <li class="u-text-kome">事前のデッキ登録は不要です。</li>
                        <li class="u-text-kome">天気などの都合により、イベント内容が予告なく変更・中止となる場合がございます。</li>
                        <li class="u-text-kome">定員になり次第、受付を終了させていただく場合がございます。</li>
                    </ul>
                </div>
                <div class="c-content u-text-small note note-text" v-if="eventData.eventTypeId === 4">
                    <ul>
                        <li class="u-text-kome">本イベントは、株式会社ポケモンが主催するイベントではございません。</li>
                        <li class="u-text-kome">天気などの都合により、イベント内容が予告なく変更・中止となる場合がございます。</li>
                        <li class="u-text-kome">定員になり次第、受付を終了させていただく場合がございます。</li>
                        <li class="u-text-kome">プレゼントなどは、イベントやお店により異なります。また、無くなり次第終了となりますので予めご了承ください。</li>
                        <li class="u-text-kome">本イベントのレギュレーションや参加条件は、お店によって異なります。</li>
                    </ul>
                </div>
                <div class="c-content u-text-small note note-text" v-if="eventData.eventTypeId === 6">
                    <ul>
                        <li class="u-text-kome">公認自主イベントは、株式会社ポケモンが主催するイベントではございません。</li>
                        <li class="u-text-kome">天気などの都合により、イベント内容が予告なく変更・中止となる場合がございます。</li>
                        <li class="u-text-kome">定員になり次第、受付を終了させていただく場合がございます。</li>
                        <li class="u-text-kome">プレゼントなどは、無くなり次第終了となりますので予めご了承ください。</li>
                    </ul>
                </div>
                <div class="c-content u-text-small note note-text" v-if="eventData.eventTypeId === 5">
                    <ul>
                        <li class="u-text-kome">申請内容に虚偽があった場合やプレイヤーズクラブ上で複数アカウントを取得していた場合は、</li>
                        <li class="u-text-kome">該当するすべてのアカウントが抽選の対象外になります。また、今後の株式会社ポケモンが主催する公式大会、公認大会への出場を禁じます。</li>
                    </ul>
                </div>
            </div>
        </div>
    </pcg-page>
</template>

<script>
import stateData from './methods/data'
import pcgPage from '@/component/Page.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import eventClassification from '@/component/EventClassification.vue'
import eventKinds from '@/component/EventKinds.vue'
import eventState from './component/EventDetailState.vue'
import eventOrganizer from './component/EventOrganizer.vue'
import eventMap from './component/EventDetailMap.vue'
import eventApplication from './component/EventDetailApplication.vue'
import eventApplicationModal from './component/EventDetailApplicationModal.vue'
import eventAnotherList from './component/EventDetailAnotherList.vue'
import freeText from '@/component/FreeText.vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween';
import timezone from "dayjs/plugin/timezone";


export default {
    name: 'EventDetailPage',
    mixins: [stateData],
    components: {
        pcgPage,
        errorMsg,
        eventClassification,
        eventKinds,
        eventState,
        eventOrganizer,
        eventMap,
        eventApplication,
        eventApplicationModal,
        eventAnotherList,
        freeText,
    },
    data: function() {
        return {
            page: {
                title: 'イベント詳細',
                desc: '',
                breadcrumb: [
                    { name: 'イベント', path: '/' },
                    { name: 'イベント検索', path: '/event/search' },
                ],
            },
            eventData: [],
            sameDayEventData: [],
            error: null,
            activeModal: '',
            eventStatus: '',
            entryPlayerCount: 0,
            conformEntryPlayerCount: 0,
            waitingEntryPlayerCount: 0,
            isTPC: false,
            isOrganizer: false,
            isNotTPCorOrganizer: false,
            isEntryEventType: false,
            shopId: '',
            age: '',
            isLoggedIn: false,
            isArrivingFirst: false,
            isDrawing: false,
            isReservoir: false,
            isAdditionalEntry: false,
            isEntrying: false,
            isEntry: true,
            mapUrl: '',
            isDisplay: false,
            isUsedPriority: false,
            isFirstEntryEnded: false,
        }
    },
    computed: {
        canApply() {
            return this.isEntryEventType && this.isEntry
        },
        isOffline() {
            // 会場
            return ((this.eventData.venue !== null && this.eventData.venue !== '') || (this.eventData.address !== null && this.eventData.address !== ''))
        },
        isStaff() {
            // イベント担当者
            return this.eventData.staff !== null && this.eventData.staff !== ''
        },
        isReservation() {
            // 事前エントリー
            return this.eventData.reservation !== null && this.eventData.reservation === '1'
        },
        isEventInquiry() {
            // 問い合わせ先
            return this.eventData.eventInquiry !== null && this.eventData.eventInquiry !== ''
        },
        isRemarks() {
            //その他
            return this.eventData.remarks !== null && this.eventData.remarks !== ''
        },
        isOrgEventInfo() {
            //イベント内容
            return (this.eventData.orgEventDetail !== null && this.eventData.orgEventDetail !== '') || (this.eventData.orgEventGoods !== null && this.eventData.orgEventGoods !== '')
        },
        isPrize() {
            //プレゼント
            return this.eventData.prize !== null && this.eventData.prize !== ''
        }
    },
    created: function() {
            dayjs.extend(isBetween);
            dayjs.extend(timezone);
            dayjs.tz.setDefault("Asia/Tokyo");
            this.process()
    },
    mounted:function() {
    },
    methods: {
        async process() {
            await Promise.all([
                this.getDetail(),
                this.getSameDayEvent(),
                this.getConformEntryPlayer(),
                this.getEntryPlayer(),
                this.getloginInfo(),
            ])
            .then(() => {
                this.init()
                this.getPriority()
            });
        },
        openApplicationModal() {
            if(this.isLoggedIn){
                this.activeModal = 'ApplicationModal'
            }
            else{
                //未ログインの場合、ログインページへ遷移
                this.$router.push({name: 'Login' , query: {redirect: this.$route.path}})
            }
        },
        getDetail() {
            return new Promise((resolve) => {
                const api = '/event_detail_search?event_holding_id=' + this.$route.params.id + '&trainers_flg=' + this.$route.params.trainersFlg + '&shop_id=' + this.$route.params.shopId + '&event_date=' + this.$route.params.eventDate+ '&date_id=' + this.$route.params.dateId

                this.axios.get(api)
                .then(response => {
                    this.eventData = response.data.event
                    this.shopId = this.eventData.shopId
                    let eventTitle = this.eventData.eventTypeId === 6 ? this.eventData.orgEventTitle : this.eventData.eventTitle
                    this.page.title = eventTitle
                    this.page.desc = "ポケモンカードゲーム プレイヤーズクラブの「" + eventTitle + "」の詳細はこちら。"
                    this.isDisplay = true
                    resolve();
                })
                .catch(err =>{
                    if ( err.response.status === 404){
                        if (this.$store.state.isBrowserBack === true && this.$router.referrer.name === 'NotFound') {
                            // 404からのブラウザバックで404の場合はさらに前の画面へ遷移
                            this.$router.go(-1)
                        } else {
                            this.$router.push({name: 'NotFound'})
                        }
                    }
                    this.error = err.response.data.message
                    this.isDisplay = false
                })
            });
        },
        getSameDayEvent() {
            return new Promise((resolve) => {
                const api = '/same_day_event?event_holding_id=' + this.$route.params.id

                this.axios.get(api)
                .then(response => {
                    this.sameDayEventData = response.data.same_day_event
                    resolve();
                })
                .catch(err =>{
                    this.error = err.response.data.message
                })
            });
        },
        getEntryPlayer() {
            return new Promise((resolve) => {
                const api = '/entry_player?event_holding_id=' + this.$route.params.id

                this.axios.get(api)
                .then(response => {
                    this.entryPlayerCount = Number(response.data.eventEntryPlayerCount)
                    resolve();
                })
                .catch(err =>{
                    this.error = err.response.data.message
                })
            });
        },
        getConformEntryPlayer() {
            return new Promise((resolve) => {
                const api = '/entry_player?conform=1&event_holding_id=' + this.$route.params.id

                this.axios.get(api)
                .then(response => {
                    this.conformEntryPlayerCount = Number(response.data.confirmedEntryPlayerCount)
                    this.waitingEntryPlayerCount = Number(response.data.waitingEntryPlayerCount)
                    resolve();
                })
                .catch(err =>{
                    this.error = err.response.data.message
                })
            });
        },
        getloginInfo() {
            return new Promise((resolve) => {
                const api = '/event_login_info'

                this.axios.get(api)
                .then(response => {
                    if (response.data.isLogin == '1')
                    {
                        this.age = response.data.age
                        this.isLoggedIn = true
                    }
                    resolve();
                })
                .catch(err =>{
                    this.error = err.response.data.message
                })
            });
        },
        getPriority() {
            if (this.isLoggedIn && this.eventData.priorityFlg === 1) {
                const api = '/event_priority?event_holding_id=' + this.$route.params.id
                this.axios.get(api)
                .then(response => {
                    if (response.data.priority_application === 1)
                    {
                        this.isUsedPriority = true
                    }
                })
                .catch(err =>{
                    this.error = err.response.data.message
                })
            }
        },
        init() {
            //エントリー形式
            let today = dayjs(new Date())
            let entryStartedAt = this.eventData.entryStartedAt && this.eventData.entryStartedAt !== '' ? dayjs(this.eventData.entryStartedAt.date) : null
            let entryEndedAt = this.eventData.entryEndedAt && this.eventData.entryEndedAt !== '' ? dayjs(this.eventData.entryEndedAt.date) : null
            let lotteryDeadline = this.eventData.lotteryDeadline !== null && this.eventData.lotteryDeadline !== '' ? dayjs(this.eventData.lotteryDeadline.date) : null
            let additionalEntryStartAt = this.eventData.additionalEntryStartDate && this.eventData.additionalEntryStartDate !== '' ? dayjs(this.eventData.additionalEntryStartDate.date) : null
            let additionalEntryEndAt = this.eventData.additionalEntryEndDate && this.eventData.additionalEntryEndDate !== '' ? dayjs(this.eventData.additionalEntryEndDate.date) : null

            if (this.eventData.entryTypeId == '1') {
                this.isFirstEntryEnded = entryEndedAt < today
                if (entryStartedAt !== null && entryStartedAt.isAfter(today)) {
                        // 受付開始前
                        this.isArrivingFirst = true
                        this.eventStatus = 'arrivingFirstBeforeReception'
                } else if (entryStartedAt !== null  && entryEndedAt !== null  && today.isBetween(entryStartedAt, entryEndedAt)) {
                    // 受付期間中
                    this.isArrivingFirst = true
                    this.isEntrying = true
                    this.eventStatus = 'arrivingFirstAccepting'
                    if (this.eventData.entryRestartFlg) {
                        if (this.eventData.capacity <= this.conformEntryPlayerCount) {
                            if (this.eventData.cancelFlg != 1) {
                                // 満員
                                this.isArrivingFirst = true
                                this.eventStatus = 'maxEntry'
                            } else if (this.eventData.reservoirCount == 0) {
                                // 満員
                                this.isArrivingFirst = true
                                this.eventStatus = 'maxEntry'
                            } else if (this.eventData.reservoirCount <= this.waitingEntryPlayerCount) {
                                // 満員
                                this.isArrivingFirst = true
                                this.eventStatus = 'maxEntry'
                            } else {
                                //リザーバー応募
                                this.isArrivingFirst = true
                                this.isReservoir = true
                                this.eventStatus = 'reservoirAccepting'
                            }
                        }
                    } else {
                        if (this.eventData.capacity <= this.conformEntryPlayerCount) {
                            if (this.eventData.cancelFlg != 1) {
                                // 満員
                                this.isArrivingFirst = true
                                this.eventStatus = 'maxEntry'
                            } else if (this.eventData.reservoirCount == 0) {
                                // 満員
                                this.isArrivingFirst = true
                                this.eventStatus = 'maxEntry'
                            } else if (this.eventData.reservoirCount <= this.waitingEntryPlayerCount) {
                                // 満員
                                this.isArrivingFirst = true
                                this.eventStatus = 'maxEntry'
                            } else if (this.eventData.fullOccupiedFlg) {
                                // 満員
                                this.isArrivingFirst = true
                                this.eventStatus = 'maxEntry'
                            } else {
                                //リザーバー応募
                                this.isArrivingFirst = true
                                this.isReservoir = true
                                this.eventStatus = 'reservoirAccepting'
                            }
                        } else if (this.eventData.fullOccupiedFlg) {
                            // 満員
                            this.isArrivingFirst = true
                            this.eventStatus = 'maxEntry'
                        }
                    }
                } else if (this.eventData.autoAdditionalEntryFlg) {
                    // 追加応募あり
                    if (additionalEntryStartAt !== null && additionalEntryStartAt.isAfter(today)) {
                        if (this.eventData.capacity <= this.conformEntryPlayerCount) {
                            if (this.eventData.cancelFlg != 1) {
                                // 満員
                                this.isArrivingFirst = true
                                this.isAdditionalEntry = true
                                this.eventStatus = 'maxEntry'
                            } else if (this.eventData.reservoirCount == 0) {
                                // 満員
                                this.isArrivingFirst = true
                                this.isAdditionalEntry = true
                                this.eventStatus = 'maxEntry'
                            } else if (this.eventData.reservoirCount <= this.waitingEntryPlayerCount) {
                                // 満員
                                this.isArrivingFirst = true
                                this.isAdditionalEntry = true
                                this.eventStatus = 'maxEntry'
                            } else {
                                //リザーバー応募前
                                this.isArrivingFirst = true
                                this.isReservoir = true
                                this.isAdditionalEntry = true
                                this.eventStatus = 'beforeAdditionalReservoirAccepting'
                            }
                        } else {
                            // 応募前
                            this.isArrivingFirst = true
                            this.isAdditionalEntry = true
                            this.eventStatus = 'beforeAdditionalAccepting'
                        }
                    } else if (additionalEntryStartAt !== null && additionalEntryEndAt !== null && today.isBetween(additionalEntryStartAt, additionalEntryEndAt)) {
                        if (this.eventData.capacity <= this.conformEntryPlayerCount) {
                            if (this.eventData.cancelFlg != 1) {
                                // 満員
                                this.isArrivingFirst = true
                                this.isAdditionalEntry = true
                                this.eventStatus = 'maxEntry'
                                this.isEntrying = true
                            } else if (this.eventData.reservoirCount == 0) {
                                // 満員
                                this.isArrivingFirst = true
                                this.isAdditionalEntry = true
                                this.eventStatus = 'maxEntry'
                                this.isEntrying = true
                            } else if (this.eventData.reservoirCount <= this.waitingEntryPlayerCount) {
                                // 満員
                                this.isArrivingFirst = true
                                this.isAdditionalEntry = true
                                this.eventStatus = 'maxEntry'
                                this.isEntrying = true
                            } else {
                                //リザーバー応募中
                                this.isArrivingFirst = true
                                this.isReservoir = true
                                this.isAdditionalEntry = true
                                this.isEntrying = true
                                this.eventStatus = 'reservoirAccepting'
                            }
                        } else {
                            this.isArrivingFirst = true
                            this.isAdditionalEntry = true
                            this.isEntrying = true
                            this.eventStatus = 'arrivingFirstAccepting'
                        }
                    } else {
                        // 受付終了
                        this.isArrivingFirst = true
                        this.eventStatus = 'close'
                    }
                } else {
                    // 受付終了
                        this.isArrivingFirst = true
                        this.eventStatus = 'close'
                }
            } else if (this.eventData.entryTypeId == '2' || this.eventData.entryTypeId == '3') {
                this.isFirstEntryEnded = entryEndedAt < today
                if (entryStartedAt !== null  && entryStartedAt.isAfter(today)) {
                        // 受付開始前
                        this.isDrawing = true
                        this.eventStatus = 'drawingBeforeReception'
                } else if (entryStartedAt !== null  && entryEndedAt !== null  && today.isBetween(entryStartedAt, entryEndedAt)) {
                        // 受付期間中
                        this.isDrawing = true
                        this.isEntrying = true
                        this.eventStatus = 'drawingAccepting'
                } else {
                    if (this.eventData.entryTypeId == '3' && lotteryDeadline !== null && lotteryDeadline.isAfter(today)) {
                        this.isDrawing = true
                        this.eventStatus = 'lotteryWaiting'
                    } else {
                        if (this.eventData.autoAdditionalEntryFlg) {
                            // 追加応募あり
                            if (additionalEntryStartAt !== null && additionalEntryStartAt.isAfter(today)) {
                                if (this.eventData.capacity <= this.conformEntryPlayerCount) {
                                    if (this.eventData.cancelFlg != 1) {
                                        // 満員
                                        this.isArrivingFirst = true
                                        this.isAdditionalEntry = true
                                        this.eventStatus = 'maxEntry'
                                    } else if (this.eventData.reservoirCount == 0) {
                                        // 満員
                                        this.isArrivingFirst = true
                                        this.isAdditionalEntry = true
                                        this.eventStatus = 'maxEntry'
                                    } else if (this.eventData.reservoirCount <= this.waitingEntryPlayerCount) {
                                        // 満員
                                        this.isArrivingFirst = true
                                        this.isAdditionalEntry = true
                                        this.eventStatus = 'maxEntry'
                                    } else {
                                        //リザーバー応募前
                                        this.isArrivingFirst = true
                                        this.isReservoir = true
                                        this.isAdditionalEntry = true
                                        this.eventStatus = 'beforeAdditionalReservoirAccepting'
                                    }
                                } else {
                                    // 応募前
                                    this.isArrivingFirst = true
                                    this.isAdditionalEntry = true
                                    this.eventStatus = 'beforeAdditionalAccepting'
                                }
                            } else if (additionalEntryStartAt !== null && additionalEntryEndAt !== null && today.isBetween(additionalEntryStartAt, additionalEntryEndAt)) {
                                if (this.eventData.capacity <= this.conformEntryPlayerCount) {
                                    if (this.eventData.cancelFlg != 1) {
                                        // 満員
                                        this.isArrivingFirst = true
                                        this.isAdditionalEntry = true
                                        this.eventStatus = 'maxEntry'
                                        this.isEntrying = true
                                    } else if (this.eventData.reservoirCount == 0) {
                                        // 満員
                                        this.isArrivingFirst = true
                                        this.isAdditionalEntry = true
                                        this.eventStatus = 'maxEntry'
                                        this.isEntrying = true
                                    } else if (this.eventData.reservoirCount <= this.waitingEntryPlayerCount) {
                                        // 満員
                                        this.isArrivingFirst = true
                                        this.isAdditionalEntry = true
                                        this.eventStatus = 'maxEntry'
                                        this.isEntrying = true
                                    } else {
                                        //リザーバー応募中
                                        this.isArrivingFirst = true
                                        this.isReservoir = true
                                        this.isAdditionalEntry = true
                                        this.isEntrying = true
                                        this.eventStatus = 'reservoirAccepting'
                                    }
                                } else {
                                    // 追加応募中
                                    this.isArrivingFirst = true
                                    this.isAdditionalEntry = true
                                    this.isEntrying = true
                                    this.eventStatus = 'arrivingFirstAccepting'
                                }
                            } else {
                                // 受付終了
                                this.isDrawing = true
                                this.eventStatus = 'close'
                            }
                        } else {
                            // 受付終了
                            this.isDrawing = true
                            this.eventStatus = 'close'
                        }
                    }
                }
            } else if (this.eventData.entryTypeId == '4') {
                this.isEntry = false
            }

            if (this.eventData.discontinuanceFlg == '1') {
                // イベント中止
                this.eventStatus = 'cancel'
            }

            //表示切り替え
            switch(this.eventData.eventTypeId){
                case 1:
                    //チャンピオンズリーグ
                    this.isTPC = true
                    this.isEntryEventType = true
                    break
                case 2:
                    //シティリーグ
                    this.isNotTPCorOrganizer = true
                    this.isEntryEventType = true
                    break
                case 3:
                    // トレーナーズイベント
                    this.isNotTPCorOrganizer = true
                    break
                case 4:
                    // ジムイベント
                    this.isNotTPCorOrganizer = true
                    break
                case 5:
                    // キャンペーン
                    this.isTPC = true
                    this.isEntryEventType = true
                    break
                case 6:
                    //オーガナイザーイベント
                    this.isOrganizer = true
                    break
                case 7:
                    // シールド戦
                    this.isNotTPCorOrganizer = true
                    this.isEntryEventType = true
                    break
            }

            this.mapUrl = 'https://maps.google.co.jp/maps?output=embed&q=' + encodeURIComponent(this.eventData.geocoding)
        },
        displayDeckCount(deckCount) {
            if (!isNaN(deckCount)) {
                return deckCount + '枚'
            } else {
                return deckCount
            }
        },
        displayEntryFee(entryFee) {
            if (!isNaN(entryFee)) {
                return entryFee + '円'
            } else {
                return entryFee
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style/content';
@use './style/detail';

// アンカーリンク用の設定
// ヘッダーに被らないようにpadding/marginで位置調整をする
@include g.sp {
    #anchor1 {
        padding-top: 68px;
        margin-top: -20px;
    }
    #anchor2 {
        padding-top: 20px;
        margin-top: -20px;
    }
    #anchor3 {
        padding-top: 20px;
        margin-top: -20px;
    }
    #anchor4 {
        padding-top: 20px;
        margin-top: -20px;
    }
    #anchor5 {
        padding-top: 20px;
        margin-top: -20px;
    }
}
</style>
