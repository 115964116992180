<template>
    <div :class="{active: isOpenForm}" class="searchForm-wrapper">
        <!--イベント検索へのリンク-->
        <div class="searchForm-content">
            <div class="searchForm-tab-wrapper">
                <ul class="searchForm-tab-list">
                    <li class="searchForm-tab-item">
                        <button
                            @click="selectTab('card')"
                            :class="{active: showTab('card')}"
                            type="button"
                        >
                            <span>カード</span>
                        </button>
                    </li>
                    <li class="searchForm-tab-item">
                        <button
                            @click="selectTab('qa')"
                            :class="{active: showTab('qa')}"
                            type="button"
                        >
                            <span>Q&amp;A</span>
                        </button>
                    </li>
                </ul>
            </div>
            <ul class="searchForm-group">
                <!--カード検索-->
                <li v-if="showTab('card')" class="searchForm-group-inner">
                    <form action="https://www.pokemon-card.com/card-search/index.php" method="get">
                        <div class="searchTextForm">
                            <input
                                type="text"
                                class="c-input c-input-text searchTextForm-text"
                                placeholder="名前やテキスト"
                                name="keyword"
                                value=""
                            >
                            <button type="submit" class="searchTextForm-button">
                                <div class="searchTextForm-button-inner">
                                    <i class="icon-search"></i>
                                    <span>検索</span>
                                </div>
                            </button>
                        </div>
                        <div class="searchRadioForm">
                            <div class="searchRadioForm-title">レギュレーション</div>
                            <div class="searchRadioForm-inner">
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="regulation_sidebar_form_XY"
                                        class="KSRadioInput"
                                        name="regulation_sidebar_form"
                                        value="XY"
                                        checked
                                    >
                                    <label for="regulation_sidebar_form_XY"> スタンダード </label>
                                </div>
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="regulation_sidebar_form_BW"
                                        class="KSRadioInput"
                                        name="regulation_sidebar_form"
                                        value="BW"
                                    >
                                    <label for="regulation_sidebar_form_BW"> エクストラ </label>
                                </div>
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="regulation_sidebar_form_DP"
                                        class="KSRadioInput"
                                        name="regulation_sidebar_form"
                                        value="DP"
                                    >
                                    <label for="regulation_sidebar_form_DP"> 殿堂 </label>
                                </div>
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="regulation_sidebar_forma_ll"
                                        class="KSRadioInput"
                                        name="regulation_sidebar_form"
                                        value="all"
                                    >
                                    <label for="regulation_sidebar_forma_ll"> すべてのカード </label>
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="sm_and_keyword" value="true">
                        <input type="hidden" name="illust" value="">
                    </form>
                </li>
                <!--Q&A検索-->
                <li v-if="showTab('qa')" class="searchForm-group-inner">
                    <form action="https://www.pokemon-card.com/rules/faq/search.php" method="get">
                        <div class="searchTextForm">
                            <input
                                type="text"
                                class="c-input c-input-text searchTextForm-text"
                                placeholder="カード名、テキストで検索"
                                name="freeword"
                                value=""
                            >
                            <button type="submit" class="searchTextForm-button">
                                <div class="searchTextForm-button-inner">
                                    <i class="icon-search"></i>
                                    <span>検索</span>
                                </div>
                            </button>
                        </div>
                        <div class="searchRadioForm">
                            <div class="searchRadioForm-title">レギュレーション</div>
                            <div class="searchRadioForm-inner">
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="regulation_sidebar_form_XY"
                                        class="KSRadioInput"
                                        name="regulation_sidebar_form"
                                        value="XY"
                                        checked
                                    >
                                    <label for="regulation_sidebar_form_XY"> スタンダード </label>
                                </div>
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="regulation_sidebar_form_BW"
                                        class="KSRadioInput"
                                        name="regulation_sidebar_form"
                                        value="BW"
                                    >
                                    <label for="regulation_sidebar_form_BW"> エクストラ </label>
                                </div>
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="regulation_sidebar_form_DP"
                                        class="KSRadioInput"
                                        name="regulation_sidebar_form"
                                        value="DP"
                                    >
                                    <label for="regulation_sidebar_form_DP"> 殿堂 </label>
                                </div>
                                <div class="c-radio">
                                    <input
                                        type="radio"
                                        id="regulation_sidebar_form_all"
                                        class="KSRadioInput"
                                        name="regulation_sidebar_form"
                                        value="all"
                                    >
                                    <label for="regulation_sidebar_form_all"> すべてのカード </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </li>
            </ul>
        </div>
    </div>
    <div
        v-if="isOpenForm"
        @click="closeMenu"
        class="close-area">
    </div>
</template>

<script>
export default {
    name: "HeaderSearchForm",
    props: {
        isOpenForm: {
            type: Boolean,
        },
    },
    data() {
        return {
            tabName: 'card',
        }
    },
    computed: {
        syncedisOpenForm: {
            get() {
                return this.$props.isOpenForm;
            },
            set(val) {
                this.$emit('update:isOpenForm', val)
            },
        }
    },
    emits: ['update:isOpenForm'],
    methods: {
        selectTab(name) {
            this.tabName = name
        },
        showTab(name) {
            return this.tabName === name
        },
        closeMenu() {
            console.log('click')
            this.syncedisOpenForm = false
        }
    },
}
</script>

<style lang="scss" scoped>
@use "~@/assets/style/global" as g;

$border-color: #ddd;

.searchForm {
    &-wrapper {
        // 表示切り替え
        position: fixed;
        top: 0;
        left: -100%;
        z-index: g.$z-index-header - 1;
        .searchForm-content {
            top: -200px;
            transition: top .3s;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 30px 20px 20px;
            width: 100%;
            max-width: 1000px;
            background: g.$color-white;
            box-shadow: rgba(g.$color-black, .75) -20px 121px 39px -128px inset;
        }
        &.active {
            left: 0;
            right: 0;
            .searchForm-content {
                top: g.$header-height-pc;
            }
        }
        @include g.sp {
            display: none;
        }
    }
}

.searchForm-tab {
    &-list {
        display: flex;
        width: 180px;
        transform: translateY(1px);
    }
    &-item {
        flex: 1;
        &:first-child {
            margin-right: 4px;
        }
        & > button {
            width: 100%;
            padding: 9px 22px 11px;
            font-size: g.$font-size-small;
            line-height: 1.2;
            font-weight: bold;
            color: g.$color-black;
            text-align: center;
            border-radius: 7px 7px 0 0;
            border: 1px solid $border-color;
            border-bottom: 0;
            cursor: pointer;
            &.active {
                cursor: default;
                border-bottom: 1px solid g.$color-white;
            }
            &:not(.active) {
                background: #eee;
                border: 1px solid #eee;
                border-bottom: 1px solid $border-color;
                @include g.pc {
                    &:hover {
                        background: $border-color;
                    }
                }
            }
        }
    }
}

.searchForm-group {
    padding: 28px 40px;
    border: 1px solid $border-color;
    .searchTextForm {
        display: flex;
        flex-wrap: nowrap;
        &-text {
            width: calc(100% - 120px);
            border: 2px solid $border-color;
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &-button {
            width: 120px;
            height: 48px;
            padding: 0;
            font-size: 18px;
            font-weight: bold;
            line-height: 48px;
            border-radius: 0 7px 7px 0;
            color: g.$color-white;
            background: g.$color-black;
            cursor: pointer;
            @include g.pc {
                &:hover {
                    opacity: .7;
                    transition: opacity .15s ease-out;
                }
            }
        }
        &-button-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                margin-right: .4em;
                font-size: 22px;
            }
        }
    }
}

.searchRadioForm {
    &-title {
        @include g.font-exsm;
        margin-top: 20px;
        color: g.$color-black;
        font-weight: bold;
    }
    &-inner {
        display: flex;
        margin-top: 12px;
        .c-radio {
            display: flex;
            align-items: center;
            margin-right: 21px;
            & > input[type="radio"] {
                & + label {
                    &::before,
                    &::after {
                        transform-origin: center center;
                        transform: scale(.8, .8);
                    }
                    font-size: g.$font-size-small;
                }
            }
        }
    }
}

.close-area {
    position: fixed;
    top: g.$header-height-pc;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: g.$z-index-header - 2;
    background: rgba(g.$color-black, .5);
    @include g.sp {
        display: none;
    }
}
</style>
