<template>
    <main>
        <pcg-breadcrumb :page-settings="pageSettings" />
        <slot></slot>
    </main>
</template>

<script>
import pcgBreadcrumb from '@/component/Breadcrumb.vue'
export default {
    name: 'pcgLayout',
    components: {
        pcgBreadcrumb,
    },
    props: {
        pageSettings: {
        type: Object,
        required: true,
        },
    },
    mounted() {
        this.createPageTitle();
    },
    data() {
        return {
            title: 'ポケモンカードゲーム プレイヤーズクラブ',
            desc: ''
        }
    },
    watch: {
        'pageSettings.desc': function() {
            this.createPageTitle()
        },
        'pageSettings.title': function() {
            this.createPageTitle()
        }
    },
    methods: {
        createPageTitle : function(){
            // タイトルを設定
            let setTitle = ''
            if (this.pageSettings.titleForHeader) {
                setTitle = this.pageSettings.titleForHeader + ' | ' + this.title;
                document.title = setTitle;
            }
            else if(this.pageSettings.title){
                setTitle = this.pageSettings.title + ' | ' + this.title;
                document.title = setTitle;
            } else {
                document.title = this.title;
            }

            // メタタグdescription設定
            // メタタグdescriptionの内容を動的に設定する場合、各ページのコンポーネントpageSettings.descに内容を記載する。
            if(this.pageSettings.desc){
                document.querySelector("meta[name='description']").setAttribute('content', this.pageSettings.desc)
            } else {
                document.querySelector("meta[name='description']").setAttribute('content', this.desc)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

main {
    padding: g.$margin-large 0 56px;
    background-repeat: repeat-x;
    background-position: top center, bottom center;

    @include g.pc {
        background-image: url('@/assets/img/common/bg_top.png'), url('@/assets/img/common/bg_bottom.png');
    }

    @include g.sp {
        padding: 28px 0 56px;
        background-image: url('@/assets/img/common/bg_top_sp.png'), url('@/assets/img/common/bg_bottom_sp.png');
    }
}
</style>
