export default {
    methods: {
        setState(event) {
            if (event.discontinuance_flg == '1') {
                // イベント中止
                event.displayStatus = 'canceled'
            } else {
                if (event.status_id == '1' || event.status_id == '3') {
                    // 抽選待ち、リザーバー
                    event.displayStatus = 'selectedReserver'
                }
            }
        },
    },
}


