<template>
    <div v-if="eventData.address" class="address">
        {{ (eventData.zipCode ? '〒' + zipCodeFormat(eventData.zipCode) : '') + ' ' + (eventData.address ? eventData.address : '') }}
    </div>
    <div
        v-if="eventData.venue"
        :class="{nameOnly: isNameOnly}"
        class="name"
    >
        <free-text :freeText="eventData.venue"></free-text>
    </div>
    <div v-if="eventData.access" class="route">
        <free-text :freeText="eventData.access"></free-text>
    </div>
    <div v-if="eventData.address" class="map">
        <iframe
            class="image"
            :src="mapUrl"
            allowfullscreen=""
            loading="lazy"
            >
        </iframe>
    </div>
</template>

<script>
import freeText from '@/component/FreeText.vue'

export default {
    name: 'EventMap',
    props: {
        eventData: {
            require: true,
        },
        mapUrl: {
            require: true,
        },
    },
    components: {
        freeText
    },
    computed: {
        isNameOnly() {
            return !this.eventData.access && !this.eventData.address
        }
    },
    methods: {
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? zip : zip.slice(0, 3) + '-' + zip.slice(3)
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '../style/content';

.name {
    @include g.font-lg;
    padding-bottom: 20px;
    margin-top: 28px;
    border-bottom: 1px dotted g.$color-black;
    word-break: break-word;
    &.nameOnly {
        border-bottom: none;
    }
    @include g.sp {
        margin-top: 24px;
    }
}
.address {
    margin-top: 20px;
}
.route {
    @include g.font-sm;
    padding: 16px 20px;
    margin-top: 20px;
    white-space: pre-line;
    background: g.$color-black-a005;
    border-radius: 4px;
    @include g.sp {
        margin-top: 16px;
    }
}
.map {
    margin-top: 28px;
    .image {
        width: 100%;
        height: 450px;
        border: 1px solid g.$color-border-gray;
    }
}
</style>
