<template>
    <div class="eventState">
        <event-detail
            :state="state"
            :eventData="eventData"
        />
        <template v-if="!isCancel && isEntry && state && recruitFlg">
            <div
                v-if="!isApply"
                class="status-noapply c-content"
            >
                <div class="status-text" :class="statusClass">
                    <span>{{ stateText[state] }}</span>
                </div>
            </div>
            <div
                v-else
                class="status-apply c-content c-content-wide-sp c-gradientBox"
            >
                <div class="status-balloon">
                    <div class="status-text">
                        <span>{{ stateText[state] }}</span>
                    </div>
                </div>
                <button
                    @click="openApplicationModal"
                    class="c-btn c-btn-primary"
                    :eventData="eventData"
                >イベント応募へ</button>
            </div>
        </template>
    </div>
</template>

<script>
import stateData from '../methods/data'
import eventDetail from './EventDetail.vue'

export default {
    name: 'EventState',
    mixins: [
        stateData,
    ],
    components: {
        eventDetail,
    },
    props: {
        state: {
            type: String,
            required: true,
        },
        eventData: {
            require: true,
        },
        isEntry: {
            type: Boolean,
            require: true,
        },
        recruitFlg: {
            type: Boolean,
            require: true,
        },
    },
    methods: {
        openApplicationModal() {
            this.$emit('openModal',this.eventData)
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '../style/content';
@use '../style/apply';

.eventState {
    &-top {
        margin-top: 32px;
        padding-top: 30px;
        border-top: 1px solid g.$color-border-gray;
    }
    .date {
        &-day {
            font-size: 24px;
            font-weight: bold;
        }
        &-time {
            padding-top: 4px;
            font-size: 18px;
            line-height: 1;
        }
    }
    .supplement {
        @include g.font-sm;
    }
    .sponsorship {
        @include g.font-sm;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px dotted g.$color-border-gray;
    }
    .address {
        @include g.font-sm;
        padding-top: 12px;
        & > span {
            display: block;
        }
    }
    @include g.pc {
        margin-bottom: 15px;
        &-date {
            display: flex;
            margin-bottom: 40px;
            & > .left {
                flex: 1;
                padding-right: 40px;
            }
            & > .right {
                width: 300px;
            }
        }
        .supplement {
            padding-top: 24px;
            font-size: 14px;
        }
        .address {
            padding-top: 7px;
        }
    }
    @include g.sp {
        margin-bottom: 20px;
        &-top {
            margin-top: 20px;
            padding-top: 24px;
        }
        &-date {
            & > .right {
                display: none;
            }
        }
        .date {
            text-align: center;
        }
        .supplement {
            padding-top: 20px;
        }
        .address {
            padding-top: 10px;
            .postcode {
                display: block;
            }
        }
    }
}
</style>
