<template>
    <div class="p-searchFormFilter-content">
        <h3 class="p-searchFormFilter-title c-title-h3">大会の種類</h3>
        <div class="p-searchFormFilter-settings">
            <div class="toggleContent-wrap">
                <button
                    v-if="isSp"
                    @click="toggleFormDetailMenu(1)"
                    type="button"
                    class="serious-game title-btn"
                    data-toggle-button="content1"
                ><span>真剣勝負</span></button>
                <h4 v-else class="serious-game title"><span>真剣勝負</span></h4>
                <div class="main toggleContent" data-toggle-content="content1">
                    <div class="main-container">
                        <div class="c-checkbox">
                            <input
                                id="league1"
                                name="league"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="3:1"
                            />
                            <label for="league1">{{ $root.getCommonText('champions_league') }}</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="league2"
                                name="league"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="3:2"
                            />
                            <label for="league2">シティリーグ</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="league3"
                                name="league"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="3:3"
                            />
                            <label for="league3">トレーナーズリーグ</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="league4"
                                name="league"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="3:7"
                            />
                            <label for="league4">{{ $root.getCommonText('shield_battle') }}</label>
                        </div>
                        <div class="sub" v-if="syncedEventType.indexOf('3:1') >= 0 || syncedEventType.indexOf('3:2') >= 0 || syncedEventType.indexOf('3:3') >= 0 || syncedEventType.indexOf('3:7') >= 0">
                            <div class="sub-title"><span>リーグ</span></div>
                            <div class="sub-container">
                                <div class="c-checkbox">
                                    <input
                                        id="league5"
                                        name="league"
                                        type="checkbox"
                                        v-model="syncedLeagueType"
                                        value="2"
                                    />
                                    <label for="league5">ジュニアリーグ</label>
                                </div>
                                <div class="c-checkbox">
                                    <input
                                        id="league7"
                                        name="league"
                                        type="checkbox"
                                        v-model="syncedLeagueType"
                                        value="3"
                                    />
                                    <label for="league7">シニアリーグ</label>
                                </div>
                                <div class="c-checkbox">
                                    <input
                                        id="league6"
                                        name="league"
                                        type="checkbox"
                                        v-model="syncedLeagueType"
                                        value="4"
                                    />
                                    <label for="league6">マスターリーグ</label>
                                </div>
                                <div class="c-checkbox">
                                    <input
                                        id="league8"
                                        name="league"
                                        type="checkbox"
                                        v-model="syncedLeagueType"
                                        value="1"
                                    />
                                    <label for="league8">オープンリーグ</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-container">
                        <div class="c-checkbox">
                            <input
                                id="league9"
                                name="league"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="3:4"
                            />
                            <label for="league9">ジムイベント</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="league10"
                                name="league"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="3:6"
                            />
                            <label for="league10">オーガナイザーイベント</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="toggleContent-wrap">
                <button
                    v-if="isSp"
                    @click="toggleFormDetailMenu(2)"
                    type="button"
                    class="beginner title-btn"
                    data-toggle-button="content2"
                ><span>初心者歓迎</span></button>
                <h4 v-else class="beginner title"><span>初心者歓迎</span></h4>
                <div class="main toggleContent" data-toggle-content="content2">
                    <div class="main-container">
                        <div class="c-checkbox">
                            <input
                                id="event11"
                                name="event1"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="2:4"
                            />
                            <label for="event11">ジムイベント</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="event12"
                                name="event1"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="2:6"
                            />
                            <label for="event12">オーガナイザーイベント</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="event13"
                                name="event1"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="2:7"
                            />
                            <label for="event13">{{ $root.getCommonText('shield_battle') }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="toggleContent-wrap">
                <button
                    v-if="isSp"
                    @click="toggleFormDetailMenu(3)"
                    type="button"
                    class="rule title-btn"
                    data-toggle-button="content3"
                ><span>ルールを教わる</span></button>
                <h4 v-else class="rule title"><span>ルールを教わる</span></h4>
                <div class="main toggleContent" data-toggle-content="content3">
                    <div class="main-container">
                        <div class="c-checkbox">
                            <input
                                id="event21"
                                name="event2"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="1:4"
                            />
                            <label for="event21">ジムイベント</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="event22"
                                name="event2"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="1:6"
                            />
                            <label for="event22">オーガナイザーイベント</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="event23"
                                name="event2"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="1:7"
                            />
                            <label for="event23">{{ $root.getCommonText('shield_battle') }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="toggleContent-wrap">
                <button
                    v-if="isSp"
                    @click="toggleFormDetailMenu(4)"
                    type="button"
                    class="remote title-btn"
                    data-toggle-button="content4"
                ><span>リモートポケカ</span></button>
                <h4 v-else class="remote title"><span>リモートポケカ</span></h4>
                <div class="main toggleContent" data-toggle-content="content4">
                    <div class="main-container">
                        <div class="c-checkbox">
                            <input
                                id="event24"
                                name="event2"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="4:4"
                            />
                            <label for="event24">ジムイベント</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="event25"
                                name="event2"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="4:6"
                            />
                            <label for="event25">オーガナイザーイベント</label>
                        </div>
                        <div class="c-checkbox">
                            <input
                                id="event26"
                                name="event2"
                                type="checkbox"
                                v-model="syncedEventType"
                                value="4:7"
                            />
                            <label for="event26">{{ $root.getCommonText('shield_battle') }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-searchFormFilter-content">
        <h3 class="p-searchFormFilter-title c-title-h3">イベント開催曜日・時間</h3>
        <div class="p-searchFormFilter-settings">
            <h4 class="title">イベント開催曜日</h4>
            <div class="main">
                <div class="main-container space-sm-left">
                    <div class="c-radio">
                        <input
                            v-model="syncedEventDayOfWeek"
                            value=""
                            id="eventWeek1"
                            name="eventWeek"
                            type="radio"
                            checked
                        />
                        <label for="eventWeek1">全ての曜日</label>
                    </div>
                    <div class="c-radio">
                        <input
                            v-model="syncedEventDayOfWeek"
                            value="1"
                            id="eventWeek2"
                            name="eventWeek"
                            type="radio"
                        />
                        <label for="eventWeek2">平日のみ</label>
                    </div>
                    <div class="c-radio">
                        <input
                            v-model="syncedEventDayOfWeek"
                            value="2"
                            id="eventWeek3"
                            name="eventWeek"
                            type="radio"
                        />
                        <label for="eventWeek3">土日祝</label>
                    </div>
                </div>
            </div>
            <h4 class="title"><span>イベント開催時間</span></h4>
            <div class="main">
                <div class="main-container space-sm-left">
                    <div class="c-radio">
                        <input
                            v-model="syncedEventOpenTime"
                            value=""
                            id="eventTime1"
                            name="eventTime"
                            type="radio"
                            checked
                        />
                        <label for="eventTime1">全ての時間</label>
                    </div>
                    <div class="c-radio">
                        <input
                            v-model="syncedEventOpenTime"
                            value="1"
                            id="eventTime2"
                            name="eventTime"
                            type="radio"
                        />
                        <label for="eventTime2">午前スタート</label>
                    </div>
                    <div class="c-radio">
                        <input
                            v-model="syncedEventOpenTime"
                            value="2"
                            id="eventTime3"
                            name="eventTime"
                            type="radio"
                        />
                        <label for="eventTime3">午後スタート</label>
                    </div>
                    <div class="c-radio">
                        <input
                            v-model="syncedEventOpenTime"
                            value="3"
                            id="eventTime4"
                            name="eventTime"
                            type="radio"
                        />
                        <label for="eventTime4">18時以降スタート</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-searchFormFilter-content">
        <h3 class="p-searchFormFilter-title c-title-h3">事前応募・予約</h3>
        <div class="p-searchFormFilter-settings">
            <div class="main">
                <div class="main-container space-sm-left space-sm-top">
                    <div class="c-radio">
                        <input
                            v-model="syncedReservation"
                            value=""
                            id="application1"
                            name="application"
                            type="radio"
                            checked
                        />
                        <label for="application1">すべて</label>
                    </div>
                    <div class="c-radio">
                        <input
                            v-model="syncedReservation"
                            value="1"
                            id="application2"
                            name="application"
                            type="radio"
                        />
                        <label for="application2">あり</label>
                    </div>
                    <div class="c-radio">
                        <input
                            v-model="syncedReservation"
                            value="2"
                            id="application3"
                            name="application"
                            type="radio"
                        />
                        <label for="application3">なし</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        eventType: Array,
        leagueType: Array,
        eventDayOfWeek: String,
        eventOpenTime: String,
        reservation: String,
    },
    emits: [
        'searchCount',
        'update:eventType',
        'update:leagueType',
        'update:eventDayOfWeek',
        'update:eventOpenTime',
        'update:reservation',
    ],
    computed: {
        isSp() {
            return this.$store.state.isSp;
        },
        syncedEventType: {
            get() {
                return this.$props.eventType;
            },
            set(val) {
                this.$emit("update:eventType", val);
                this.$emit("searchCount");
            },
        },
        syncedLeagueType: {
            get() {
                return this.$props.leagueType;
            },
            set(val) {
                this.$emit("update:leagueType", val);
                this.$emit("searchCount");
            },
        },
        syncedEventDayOfWeek: {
            get() {
                return this.$props.eventDayOfWeek;
            },
            set(val) {
                this.$emit("update:eventDayOfWeek", val);
                this.$emit("searchCount");
            },
        },
        syncedEventOpenTime: {
            get() {
                return this.$props.eventOpenTime;
            },
            set(val) {
                this.$emit("update:eventOpenTime", val);
                this.$emit("searchCount");
            },
        },
        syncedReservation: {
            get() {
                return this.$props.reservation;
            },
            set(val) {
                this.$emit("update:reservation", val);
                this.$emit("searchCount");
            },
        },
    },
    methods: {
        toggleFormDetailMenu(number) {
            const contentList = document.querySelectorAll(
                `.main[data-toggle-content=content${number}]`
            );
            const buttonList = document.querySelectorAll(
                `.title-btn[data-toggle-button=content${number}]`
            );

            contentList.forEach((item) => {
                toggleClass(item);
            });
            buttonList.forEach((item) => {
                toggleClass(item);
            });

            function toggleClass(item) {
                if (item.classList.contains("open")) {
                    item.classList.remove("open");
                } else {
                    item.classList.add("open");
                }
            }
        },
    },
};
</script>
