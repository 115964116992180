<template>
    <div class="eventApplication">
        <div class="c-content">
            <h2 class="c-title-h2">イベント応募</h2>
            <p>イベントの参加には、事前応募が必要です。</p>
            <p>「イベント応募へ」ボタンから、応募をしてください。</p>
            <p class="u-text-kome u-text-small note-text">イベントの応募には、ログインが必要です。</p>
            <p class="u-text-kome u-text-small note-text u-mb" v-if="isDrawing">このイベントは抽選応募になります。抽選の結果は、マイページにてご確認ください。</p>
            <p class="u-text-kome u-text-small note-text u-mb" v-if="isArrivingFirst || isReservoir">このイベントは先着応募になります。結果は、マイページにてご確認ください。</p>

            <div class="eventApplication-start" v-if="(isArrivingFirst || isDrawing) && !isClose">
                <div class="title" v-if="isAdditionalEntry">{{ '追加応募受付期間' }}</div>
                <div class="title" v-else-if="isArrivingFirst">{{ '先着応募受付期間' }}</div>
                <div class="title" v-else-if="isDrawing">{{ '抽選応募受付期間' }}</div>
                <div class="date" v-if="!isAdditionalEntry">
                    <span v-if="!isEntrying">{{ eventData.entryStartedAt ? dataFormat(eventData.entryStartedAt,1) : '' }} </span>〜
                    <span>{{ eventData.entryEndedAt ? dataFormat(eventData.entryEndedAt,1) : '' }}</span>
                </div>
                <div class="date" v-else>
                    <span v-if="!isEntrying">{{ eventData.additionalEntryStartDate ? dataFormat(eventData.additionalEntryStartDate,1) : '' }} </span>〜
                    <span>{{ eventData.additionalEntryEndDate ? dataFormat(eventData.additionalEntryEndDate,1) : '' }}</span>
                </div>
                <div
                    v-if="isDrawing"
                    class="drowind-lottery"
                >
                    <template v-if="eventData.entryTypeId === 2">
                        抽選結果発表：{{ dataFormat(eventData.lotteryDeadline,7) }}&emsp;※発表の時刻は前後する場合がございます。
                    </template>
                    <template v-else>
                        抽選結果発表：{{ dataFormat(eventData.lotteryDeadline,1) }}
                    </template>
                </div>
            </div>

            <div class="eventApplication-text">
                <template v-if="eventData.event_free_text">
                    <div v-for="value in eventData.event_free_text" v-bind:key="value.freeTitle">
                        <div v-if="value.freeTitle">
                            <div class="event-text-header">{{ value.freeTitle }}</div>
                            <div class="event-text-content u-text-pre-line">
                                <free-text :freeText="value.freeText"></free-text>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <eventOverview />
                </template>
            </div>
        </div>

        <div
            v-if="isReservoir"
            class="eventApplication-reservoir c-content"
        >
            <div class="eventApplication-reservoir-text u-text-danger u-text-center u-text-bold">
                この受付は、リザーバー応募です。
            </div>
            <div class="status-reservoir u-text-danger">
                キャンセルが発生した際に、繰上げでの参加のご連絡を<span class="u-text-bold">{{ dataFormat(eventData.eventDate,5) + '18:00' }}</span>までにメールまたはマイページ内「出場予定のイベント」にてお知らせします。
            </div>
        </div>
        <div
            class="status-apply c-content c-content-wide-sp c-gradientBox"
            v-if="isApply"
        >
            <button
                @click="openApplicationModal"
                class="c-btn c-btn-primary"
            >イベント応募へ</button>
        </div>
        <div
            v-else
            class="status-noapply c-content"
        >
            <div class="status-text" :class="statusClass">
                <span>{{ stateText[state] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import stateData from '../methods/data'
import eventOverview from './text/eventOverview.vue'
import freeText from '@/component/FreeText.vue'

export default {
    name: 'EventApplication',
    mixins: [stateData],
    components: {
        eventOverview,
        freeText
    },
    props: {
        state: {
            type: String,
            required: true,
        },
        eventData: {
            type: Array,
            require: true,
        },
        isArrivingFirst: {
            type: Boolean,
            default: false,
        },
        isDrawing: {
            type: Boolean,
            default: false,
        },
        isReservoir: {
            type: Boolean,
            default: false,
        },
        isEntrying: {
            type: Boolean,
            default: false,
        },
        isAdditionalEntry: {
            type: Boolean,
            default: false,
        },

    },
    methods: {
        openApplicationModal() {
            this.$emit('openModal')
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '../style/content';
@use '../style/apply';
@use '../style/text';

.c-content {
    & > h2.c-title-h2 {
        margin-top: 56px;
        margin-bottom: 28px;
        @include g.sp {
            margin-top: 48px;
            margin-bottom: 24px;
        }
    }
}

.note-text {
    color: g.$color-black-a060;
}

.eventApplication {
    .status-noapply {
        .status-text {
            width: 100%;
        }
    }
}
.drowind-lottery {
    margin-top: 9px;
    border-top: 2px dotted #dddddd;
    padding-top: 9px;
}
</style>
