<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <p class="message">
                新しいプレイヤーズクラブへのアカウント移行のメールをお送りします。<br>
                「ログインIDまたはプレイヤーIDと生年月日」または「メールアドレス」を入力してください。
            </p>
            <h2 class="c-title-page">ログインまたはプレイヤーID</h2>
            <error-msg
                v-if="errorLogin"
                :message="errorLogin"
                id="errorPlayer"
                class="u-mb-lg error-msg"
            >
            </error-msg>
            <div class="c-box pc-width-640">
                <form>
                    <label for="formId">ログインIDまたはプレイヤーID</label>
                    <input
                        v-model="id"
                        id="formId"
                        type="text"
                        class="c-input c-input-text u-width-100"
                        :class="{ 'c-input-error': hasMailError }">
                    <select-birthday
                        v-model:birthday="birthday"
                        :hasError="hasBirthdayError"></select-birthday>
                    <vue-recaptcha
                        class="reCaptcha"
                        ref="recaptcha"
                        :sitekey="siteKey"
                        @verify="onVerify"
                        @expired="onExpired"
                    />
                    <div class="u-text-center">
                        <button
                            type="button"
                            @click="submit('player')"
                            class="c-btn c-btn-primary"
                        >メールを送信する</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="c-content mailaddress">
            <h2 class="c-title-page">メールアドレス</h2>
            <error-msg
                v-if="errorMail"
                :message="errorMail"
                id="errorMail"
                class="u-mb-lg error-msg"
            >
            </error-msg>
            <div class="c-box pc-width-640">
                <form>
                    <div class="mail-wrapper">
                        <label for="formMailAddress">メールアドレス</label>
                        <input
                            v-model="email" id="formMailAddress"
                            type="text"
                            class="c-input c-input-text u-width-100"
                            :class="{ 'c-input-error': hasMailAddress }">
                    </div>
                    <vue-recaptcha
                        class="reCaptcha"
                        ref="recaptcha"
                        :sitekey="siteKey"
                        @verify="onVerify"
                        @expired="onExpired"
                    />
                    <div class="u-text-center">
                        <button
                            type="button"
                            @click="submit('mail')"
                            class="c-btn c-btn-primary"
                        >メールを送信する</button>
                    </div>
                </form>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import selectBirthday from '@/component/SelectBirthday.vue'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: 'MigrationSetting',
    components: {
        SystemPage,
        errorMsg,
        selectBirthday,
        VueRecaptcha,
    },
    data: function() {
        return {
            page: {
                title: 'アカウント移行',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ アカウント移行のページ。',
                breadcrumb: [],
            },
            birthday: {
                year: null,
                month: null,
                day: null,
            },
            id: '',
            email: '',
            errorLogin: null,
            errorMail: null,
            siteKey: '',
            recaptchaToken: '',
            hasMailError: false,
            hasBirthdayError: false,
            hasMailAddress: false,
        }
    },
    created: function() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src','https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
        document.head.appendChild(recaptchaScript)
        this.siteKey = process.env.VUE_APP_SITE_KEY
        if (this.$route.query['error_message']) {
            this.error = this.$route.query['error_message']
        }
    },
    methods: {
        async submit(formType) {
            const isMail = formType === 'mail'
            let params = new URLSearchParams
            const url = '/send_account_migration_email'
            this.hasMailError = false
            this.hasBirthdayError = false
            this.hasMailAddress = false
            this.errorLogin = null
            this.errorMail = null

            if (
                !isMail &&
                this.id == '' &&
                !(this.birthday.year && this.birthday.month && this.birthday.day)
            ) {
                this.setErrorMessage(isMail, '「ログインIDまたはプレイヤーIDと生年月日」を入力するか、「メールアドレス」を入力してください。')
                this.hasMailError = true
                this.hasBirthdayError = true
                this.scrollTop(isMail)
                return
            } else if (
                !isMail &&
                this.id == '' &&
                (this.birthday.year && this.birthday.month && this.birthday.day)
            ) {
                this.setErrorMessage(isMail, 'ログインIDまたはプレイヤーIDを入力してください。')
                this.hasMailError = true
                this.scrollTop(isMail)
                return
            } else if (
                !isMail &&
                this.id != '' &&
                !(this.birthday.year && this.birthday.month && this.birthday.day)
            ) {
                this.setErrorMessage(isMail, '生年月日を入力してください。')
                this.hasBirthdayError = true
                this.scrollTop(isMail)
                return
            } else if (isMail && this.email == '') {
                this.setErrorMessage(isMail, '「ログインIDまたはプレイヤーIDと生年月日」を入力するか、「メールアドレス」を入力してください。')
                this.hasMailAddress = true
                this.scrollTop(isMail)
                return
            }

            if (!isMail) {
                if (this.id != '') {
                    params.append('id', this.id)
                }

                if (this.birthday.year && this.birthday.month && this.birthday.day) {
                    params.append('birthday', this.birthday.year + '/' + this.birthday.month + '/' + this.birthday.day)
                }
            } else {
                if (this.email != '') {
                    params.append('mail_address', this.email)
                }
            }

            params.append('recaptchaToken', this.recaptchaToken)

            await this.axios.post(url, params)
                .then(() => {
                    this.$router.push({name: 'MigrationMailComplete'})
                })
                .catch(err => {
                    this.setErrorMessage(isMail, err.response.data.message)
                    this.scrollTop(isMail)
                    this.resetRecaptcha()
                })
        },
        onVerify(response) {
            this.recaptchaToken = response
        },
        onExpired() {
            this.recaptchaToken = ''
        },
        resetRecaptcha() {
            this.$refs.recaptcha.reset()
        },
        scrollTop(isMail) {
            this.$nextTick(function() {
                if (isMail) {
                    const element = document.getElementById('errorMail')
                    element.scrollIntoView({
                        block: 'center',
                        behavior: "smooth"
                    })
                } else {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                }
            })
        },
        setErrorMessage(isMail, message) {
            if (isMail) {
                this.errorMail = message
            } else {
                this.errorLogin = message
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
$border: #B3D8EE;

.selectBirthday {
    margin-bottom: g.$margin-small;
}

.c-box {
    font-size: 14px;

    input {
        margin-bottom: 40px;
    }

    label {
        display: block;
        margin-bottom: 10px;
    }

    .mail-wrapper {
        input {
            margin-bottom: 32px;
        }
    }
}

.error-msg {
    @include g.pc {
        width: 640px;
    }
    @include g.sp {
        width: 335px;
    }
}

@include g.pc {
    .pc-width-640 {
        width: 640px;
        margin-right: auto;
        margin-left: auto;
    }

    .c-title-page {
        font-size: 28px;
        line-height: 1.25;
        margin-bottom: 34px;
    }

    .message {
        margin-bottom: 30px;
        text-align: center;
    }

    .c-box {
        margin-bottom: 40px;
        padding: 55px 100px;
    }

    .u-text-kome {
        margin-bottom: 45px;
    }

    .reCaptcha {
        margin-top: 44px;
        margin-bottom: 55px;
        & > div {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

@include g.sp {
    .c-content {
        &.mailaddress {
            margin-top: 56px;
        }
    }

    .message {
        margin-bottom: 30px;
    }

    .c-title-page {
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 30px;
    }

    .c-box {
        border-radius: initial;
        input {
        margin-bottom: 32px;
        }
    }

    .u-text-kome {
        margin-bottom: g.$margin-large;
    }

    .reCaptcha {
        margin-top: 36px;
        margin-bottom: 48px;
        & > div {
            margin-right: auto;
            margin-left: auto;
        }
    }
}
</style>
