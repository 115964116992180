<template>
    <span :class="'event ' + type">
        {{ nameList[type] }}
    </span>
</template>

<script>
export default {
    name: 'EventKinds',
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data: function() {
        return {
            nameList: {
                // TODO: イベントの種類を正しいものにする
                'serious-game': '真剣勝負！',
                'beginner': '初心者歓迎',
                'rule': 'ルールを教わる！',
                'remote': 'リモートポケカ',
            },
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.event {
    position: relative;
    font-size: 13px;
    font-weight: 500;

    &::before {
        display: inline-block;
        margin-top: -3px;
        margin-right: 8px;
        line-height: 13px;
        font-weight: normal;
    }

    &.serious-game {
        &::before {
            @include g.setIconFont('\e914');
            color: g.$color-serious-game;
            font-size: 20px;
        }
    }
    &.beginner {
        &::before {
            @include g.setIconFont('\e915');
            color: g.$color-beginner;
            font-size: 20px;
        }
    }
    &.rule {
        &::before {
            @include g.setIconFont('\e916');
            color: g.$color-rule;
            font-size: 18px;
        }
    }
    &.remote {
        &::before {
            @include g.setIconFont('\e917');
            color: g.$color-remote;
            font-size: 13px;
        }
    }
}
</style>
