<template>
  <system-page :page-settings="page">
    <div class="c-content withdraw">
      <div class="withdraw-text">
        <p class="u-mb-exlg">退会が完了しました。</p>
      </div>
      <div class="withdraw-button">
        <router-link
          to="/"
          class="c-btn c-btn-primary-outline"
        >イベントTOPへ</router-link>
      </div>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
  name: 'WithdrawComplatePage',
  components: {
    SystemPage
  },
  data: function() {
    return {
      page: {
        title: '退会完了',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ 退会完了のページ。',
        breadcrumb: [],
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use './style.scss';
</style>
