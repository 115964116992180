<template>
    <system-page :page-settings="page">
        <error-msg class="u-mb-lg" v-if="error" :message="error">
        </error-msg>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'

export default {
    name: 'SnsHomePage',
    components: {
        SystemPage,
        errorMsg,
    },
    props: {},
    data() {
        return {
            page: {
                title: 'ログイン',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ ログインのページ。',
            },
            playerId: '',
            password: '',
            error: null,
        }
    },
    created: function () {
        if (this.checkParam()) {
            this.login();
        }
    },
    methods: {
        checkParam() {
            if (typeof this.$route.query['user_name'] === 'undefined' || this.$route.query['user_name'] === null) {
                this.error = 'ログイン処理に失敗しました。'
                return false
            }
            if (typeof this.$route.query['token'] === 'undefined' || this.$route.query['token'] === null) {
                this.error = 'ログイン処理に失敗しました。'
                return false
            }
            if (typeof this.$route.query['token_deadline'] === 'undefined' || this.$route.query['token_deadline'] === null) {
                this.error = 'ログイン処理に失敗しました。'
                return false
            }
            return true
        },
        login() {
            this.$store.commit('setLoginState', {
                userToken: this.$route.query['token'],
                userTokenDeadline: this.$route.query['token_deadline'],
                userName: this.$route.query['user_name']
            })
            this.$router.push({name: 'Mypage'})
        },
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.login {
    padding: 50px;
    width: 640px;
    margin: 0 auto;
    font-size: 14px;

    .form {
        padding: 0 50px 25px;

        .birthday {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .password-outer {
        margin-bottom: 18px;

        .password {
            margin-bottom: 17px;
        }
    }
}

.note {
    color: g.$color-black-a060;
}

.center-line {
    position: relative;
    text-align: center;
    margin: 0 50px 14px;

    span {
        background: g.$color-white;
        padding: 0 20px;
    }
}

.social-buttons {
    font-size: 0;
    padding-bottom: 28px;
    border-bottom: 1px solid #B3D8EE;
    width: 420px;
    margin: 0 auto 35px;

    .c-btn {
        color: g.$color-white;
        border-radius: 10px;
        margin: 0 auto 12px;
        font-size: g.$font-size-small;
        position: relative;
        width: 214px;
        height: 40px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.26);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        padding-left: 30px;

        &:hover {
            text-decoration: none;
        }

        @mixin buttonIcon {
            content: '';
            font-family: 'pcg_icon';
            position: absolute;
            left: 10px;
            font-size: 20px;
        }

        &.facebook {
            background: g.$color-facebook;

            &:hover {
                background-color: rgba(g.$color-facebook, .6);
            }

            &::before {
                @include buttonIcon;
                content: '\e922';
            }
        }

        &.twitter {
            background: g.$color-twitter;

            &:hover {
                background-color: rgba(g.$color-twitter, .6);
            }

            &::before {
                @include buttonIcon;
                content: '\e930';
            }
        }

        &.google {
            display: inline-flex;
            font-family: 'Roboto', sans-serif;
            color: #757575;
            padding-left: 0;

            &:hover {
                // gooogleボタンは背景白なので文字の色を薄くする。
                color: rgba(#757575, .6);
            }

            .google-icon {
                padding: 0 10px;
                font-size: 0;
                line-height: 0;

                img {
                    height: 20px;
                    width: 20px;
                }
            }

            p {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.no-account-msg {
    margin-bottom: 10px;
}

.no-account-notice {
    color: g.$color-black-a060;
    font-size: g.$font-size-exsmall;
}

.link {
    font-size: g.$font-size;
}

@include g.sp {
    .login {
        width: 100%;
        padding: 0 20px;

        .form {
            padding: 0 0 g.$margin-large;
        }
    }

    .center-line {
        margin: 0;
        margin-bottom: 20px;

        span {
            padding: 0;
        }
    }

    .social-buttons {
        width: 100%;
    }
}
</style>
