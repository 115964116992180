<template>
    <system-page :page-settings="pageWithTitle">
        <error-msg class="u-mb-lg" v-if="error !== null">
            <p>{{ error }}</p>
        </error-msg>
        <div class="c-content eventResult-select margin">
            <div class="c-input c-input-select">
                <select v-model="selectYear" id="select1" @change="filerYear">
                    <option :value="null">すべて</option>
                    <option v-for="year in yearList" :key="year" :value="year">{{year}}/01/01〜{{year}}/12/31</option>
                </select>
            </div>
        </div>
        <div class="c-content" v-if="eventList.length === 0">
            <div class="eventResult-noResult">表示できるイベントがありません。</div>
        </div>
        <event-result-table :resultList="eventList" v-else />
        <div class="eventResult-pager c-content" v-if="page.totalPage > 1">
            <pcg-pager @pageFeed="pageFeed" :page="page"/>
            <div class="eventResult-pager-number" v-if="0 < page.totalCount">
                {{ page.currentPage }}ページ ／ {{ page.totalPage }}ページ中
            </div>
        </div>
        <div class="u-text-center to-mypage margin">
            <router-link
                :to="{name: 'Mypage'}"
                class="c-btn c-btn-primary-outline"
            >
                マイページへ
            </router-link>
        </div>
    </system-page>
    <sp-menu />
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import pcgPager from '@/component/Pager.vue'
import EventResultTable from '@/views/Mypage/EventResult/EventResultTable.vue';
import errorMsg from '@/component/ErrorMsg.vue'
import SpMenu from '../component/SpMenu.vue'

export default {
    name: 'EventResultPage',
    components: {
        SystemPage,
        pcgPager,
        EventResultTable,
        errorMsg,
        SpMenu
    },
    data() {
        return {
            page: {
                currentPage: 0,
                totalPage: 0,
                totalCount: 0,
                perPage: 20,
                title: 'イベント成績',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            },
            oldestYear: 2017,
            selectYear: null,
            yearList: [],
            error: null,
            eventList: [],
        }
    },
    created: function() {
            this.createYearList()
            this.page.currentPage = isNaN(this.$route.query["page"]) ? 1 : Number(this.$route.query["page"])
            this.selectYear = isNaN(this.$route.query["year"]) ? null : Number(this.$route.query["year"])
            this.getEventResults()
    },
    watch: {
        $route() {
            location.reload()
        }
    },
    computed: {
        pageWithTitle: {
            get() {
                let page = this.page
                let player = this.$store.getters.getUser();
                if (!player) {
                    return page
                }
                let defaultTitle = page.title;
                page.titleForHeader = player.nickname + "さんの" + defaultTitle
                page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんの" + defaultTitle + "。";
                return page
            }
        },
    },
    methods: {
        getEventResults() {
            const getEventResultApi = '/get_event_result'
            let that = this
            let result = []
            let params = new URLSearchParams()

            if (this.selectYear) {
                params.append('year', this.selectYear)
            }
            this.axios.post(getEventResultApi, params)
            .then(response => {
                result = response.data.result
                let maxEventNum = result.length
                that.page.totalCount = result.length
                that.page.totalPage = Math.ceil(result.length / this.page.perPage)

                if (maxEventNum <= (this.page.currentPage - 1) * this.page.perPage) {
                    that.eventList = []
                } else if (maxEventNum > this.page.currentPage * this.page.perPage) {
                    that.eventList = result.slice((this.page.currentPage - 1) * this.page.perPage, this.page.currentPage * this.page.perPage)
                } else {
                    that.eventList = result.slice((this.page.currentPage - 1) * this.page.perPage)
                }
            })
            .catch(err => {
                if (err.response && err.response.data.status === 401) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                if (err.response.status === 404) {
                    that.page.currentPage = 0
                    that.eventList = []
                } else {
                    that.error = err.response.data.message
                }
            })
        },
        filerYear() {
            if (this.selectYear === null) {
                this.$router.push({query:{}})
            } else {
                this.$router.push({query: {year: this.selectYear}})
            }
        },
        createYearList() {
            let currentYear = (new Date).getFullYear()

            this.yearList = [...Array(currentYear - this.oldestYear + 1)].map((_, i) => i + this.oldestYear)
        },
        pageFeed(count)
        {
            let queryData = Object.assign({}, this.$route.query)
            queryData.page = count
            this.$router.push({query: queryData})
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style.scss';

.eventResult {
    &-select {
        padding-top: 0;
        @include g.sp {
            padding-top: 0;
        }
    }
}
.to-mypage {
    line-height: 1;
}
.margin {
    @include g.pc {
        margin-top: 42px;
    }
    @include g.sp {
        padding-top: 10px;
    }
}
</style>
