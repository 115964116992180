<template>
    <system-page :page-settings="page">
        <renewal-panel />
        <error-msg class="u-mb-lg error-msg" v-if="error" id="errMessage" :message="error">
        </error-msg>
        <div class="c-box login">
            <div class="form">
                <form @submit.prevent="login(playerId, password)">
                    <div class="u-mb">
                        <label for="formMailAddress" class="u-mb-sm u-display-block">メールアドレスまたはプレイヤーID</label>
                        <input
                            v-model="playerId"
                            id="formMailAddress"
                            type="text"
                            class="c-input c-input-text u-width-100"
                            :class="{'c-input-error': hasPlayerIdError}"
                        >
                    </div>
                    <div class="password-outer">
                        <label for="formMailAddress" class="u-mb-sm u-display-block">パスワード</label>
                        <password-input
                            v-model:password="password"
                            :hasError="hasPasswordError"
                            id="formPassword"
                        ></password-input>
                        <p class="u-text-center"><router-link :to="{ name: 'PasswordReissue' }">パスワードを忘れた方</router-link></p>
                    </div>
                    <div class="u-text-center">
                        <button type="submit" class="c-btn c-btn-primary">ログイン</button>
                    </div>
                </form>
            </div>
            <!-- <p class="center-line"><span class="note">または、ソーシャルアカウントでログイン</span></p>
            <div class="social-buttons u-text-center">
                <a v-bind:href="snsUrl.google" class="c-btn google">
                    <div class="google-icon"><img src="@/assets/img/login/google_logo.svg"></div>
                    <p>Sign in with Google</p>
                </a>
                <a v-bind:href="snsUrl.facebook" class="c-btn facebook">Facebookでログイン</a>
                <a v-bind:href="snsUrl.twitter" class="c-btn twitter">Twitterでログイン</a>
            </div> -->
            <div class="u-text-center">
                <p class="no-account-msg">アカウントをお持ちでない方はこちら</p>
                <p class="no-account-msg"><a href="/login/register/mail" class="link">新規会員登録</a></p>
                <p class="no-account-notice">
                    ポケモンカードのイベント応募には、<br>
                    ポケモンカードゲーム プレイヤーズクラブの<br class="u-display-sp">会員登録が必要です。
                </p>
            </div>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import passwordInput from '@/component/PasswordInput.vue'
import RenewalPanel from '@/layout/RenewalPanel.vue'

export default {
    name: 'HomePage',
    components: {
        SystemPage,
        errorMsg,
        passwordInput,
        RenewalPanel,
    },
    props: {},
    data() {
        return {
            page: {
                title: 'ログイン',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ ログインのページ。'
            },
            playerId: '',
            password: '',
            error: null,
            snsUrl : {
                twitter: this.axios.defaults.baseURL + '/login/auth_twitter',
                google: this.axios.defaults.baseURL + '/login/auth_google',
                facebook: this.axios.defaults.baseURL + '/login/auth_facebook',
            },
            hasPlayerIdError: false,
            hasPasswordError: false,
            errorMessage: {
                100: '登録されていないアカウントです。',
                200: '認証処理に失敗しました。',
            },

        }
    },
    created: function () {
        if (this.$store.state.loggedIn) {
            if (this.$route.query.event_holding_id) {
                this.qrCheckin()
            } else {
                this.$router.push({name: 'Mypage'})
            }
        }
        if (this.$route.query.code && this.$route.query.code != 200) {
            this.error = this.errorMessage[this.$route.query.message];
        }
    },
    methods: {
        login(playerId, password) {
            if (!playerId) {
                    this.error = 'メールアドレスを入力してください。'
                    this.hasPlayerIdError = true
                    const element = document.getElementById('errMessage')
                    element.scrollIntoView({block: 'center'})
                    return
            }
            if (!password) {
                    this.error = 'パスワードを入力してください。'
                    this.hasPasswordError = true
                    const element = document.getElementById('errMessage')
                    element.scrollIntoView({block: 'center'})
                    return
            }

            let params = new URLSearchParams()
            params.append('player_id', playerId)
            params.append('password', password)

            this.axios.post('/login_player', params)
            .then(response => {
                this.$store.commit('setLoginState', {
                    userToken: response.data.token,
                    userTokenDeadline: response.data.token_deadline,
                    userName: response.data.user_name,
                    mail: response.data.email,
                    phoneNumber: response.data.phone_number
                })
                if (this.$route.query.event_holding_id) {
                    this.qrCheckin()
                } else {
                    if (this.$route.query.redirect){
                        this.$router.push(this.$route.query.redirect)
                    }
                    else{
                        this.$router.push({name: 'Mypage'})
                    }
                }
            })
            .catch(err => {
                if (err.response && err.response.data.code === 401) {
                    this.error = 'メールアドレスまたはパスワードが正しくありません。'
                    this.hasPasswordError = true
                    this.hasPlayerIdError = true
                } else if (err.response && err.response.data.code === 403) {
                    this.error = '入力されたパスワードが一定回数連続して一致しなかったためログインを制限させていただきました。しばらく時間を置いて再度お試しください。'
                } else {
                    this.error = err.response.data.message
                }
                const element = document.getElementById('errMessage')
                element.scrollIntoView({block: 'center'})
            })
        },
        qrCheckin() {
            let params = new URLSearchParams
            const url = '/qr_checkin'
            params.append('event_holding_id', this.$route.query.event_holding_id)

            this.axios.post(url, params)
                .then(() => {
                    this.$router.push({name: 'QrCheckinComplete'})
                })
                .catch(err => {
                    this.error = err.response.data.message
                })
        },
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

::v-deep(.Renewal) {
    margin-top: 40px;
    margin-bottom: 56px;
    @include g.sp {
        margin-top: 32px;
        margin-bottom: 40px;
    }
}

.error-msg {
    @include g.pc {
        width: 640px;
    }
    @include g.sp {
        width: 335px;
    }
}

.login {
    padding: 56px 50px;
    width: 640px;
    margin: 0 auto;
    font-size: 14px;

    .form {
        padding: 0 50px 28px;

        .birthday {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .password-outer {
        margin-bottom: 24px;

        .password {
            margin-bottom: 17px;
        }
    }
}

.note {
    color: g.$color-black-a060;
}

.center-line {
    position: relative;
    text-align: center;
    margin: 0 50px 16px;

    span {
        background: g.$color-white;
        padding: 0 20px;
    }
}

.social-buttons {
    font-size: 0;
    padding-bottom: 28px;
    border-bottom: 1px solid #B3D8EE;
    width: 420px;
    margin: 0 auto 36px;

    .c-btn {
        color: g.$color-white;
        border-radius: 10px;
        margin: 0 auto 12px;
        font-size: g.$font-size-small;
        position: relative;
        width: 214px;
        height: 40px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .26);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        padding-left: 30px;

        &:hover {
            text-decoration: none;
        }

        @mixin buttonIcon {
            content: '';
            font-family: 'pcg_icon';
            position: absolute;
            left: 10px;
            font-size: 20px;
        }

        &.facebook {
            background: g.$color-facebook;

            &:hover {
                background-color: rgba(g.$color-facebook, .6);
            }

            &::before {
                @include buttonIcon;
                content: '\e922';
            }
        }

        &.twitter {
            background: g.$color-twitter;

            &:hover {
                background-color: rgba(g.$color-twitter, .6);
            }

            &::before {
                @include buttonIcon;
                content: '\e930';
            }
        }

        &.google {
            display: inline-flex;
            font-family: 'Roboto', sans-serif;
            color: #757575;
            padding-left: 0;

            &:hover {
                // gooogleボタンは背景白なので文字の色を薄くする。
                color: rgba(#757575, .6);
            }

            .google-icon {
                padding: 0 10px;
                font-size: 0;
                line-height: 0;

                img {
                    height: 20px;
                    width: 20px;
                }
            }

            p {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.no-account-msg {
    margin-bottom: 10px;
}

.no-account-notice {
    color: g.$color-black-a060;
    font-size: g.$font-size-exsmall;
}

.link {
    font-size: g.$font-size;
}

@include g.sp {
    .login {
        width: 100%;
        padding: 0 20px;

        .form {
            padding: 0 0 28px;
        }
    }

    .center-line {
        margin: 0;
        margin-bottom: 20px;

        span {
            padding: 0;
        }
    }

    .social-buttons {
        width: 100%;
        padding-top: 20px;
    }
}
</style>
