<template>
  <div
    v-if="isOpened"
    class="modal"
    @click="close"
  >
    <div
      class="container"
      @click.stop
    >
      <button
        v-if="!noClose"
        type="button"
        class="closeBtn icon-clear"
        @click="close"
      >
        &times;
      </button>
      <div
        class="content"
        :class="{ 'modalImage': bgImage, 'deckRegisterModal': isDeckResister }"
      >
        <div class="modalHeader">
          <slot name="modalHeader"></slot>
        </div>
        <div
          class="modalBody"
          :class="{ 'noPadding': noPadding }"
        >
          <div class="modalBody-content">
            <slot></slot>
          </div>
        </div>
        <div class="modalFooter" :class="{ 'footerShadow': footerShadow }">
          <slot name="modalFooter">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
    モーダル呼び出し例
    <pcg-modal
        :isActive="activeModal === 'hogeModal'"
        @close="activeModal = null"
    >
*/
export default {
  name: 'pcgModal',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    noClose: {
      type: Boolean,
      default: false
    },
    bgImage: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    footerShadow: {
      type: Boolean,
      default: false
    },
    isDeckResister: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },
  watch: {
    isActive: function() {
      this.changeModalStatus()
    },
  },
  created() {
    this.changeModalStatus()
  },
  beforeUnmount() {
    /*
      モーダルを開いたままブラウザの「戻る」でページを離れ、「進む」で再表示した場合、
      Vueの変数はリセットされるのでモーダルは消えるが、bodyに追加したスタイルは残る。
      そのためbeforeUnmountで自主的にスタイルを削除する必要がある。
    */
    this.removeStyle()
  },
  methods: {
    changeModalStatus() {
      if (this.isActive) {
        // モーダルを開く前のスクロールバー込の横幅を取得
        const bodyWidth = document.body.clientWidth
        this.isOpened = true
        const bodyStyle = document.body.style
        bodyStyle.overflow = 'hidden'
        // スクロールバーが消えた分のwidth差をpaddingとして追加
        bodyStyle.paddingRight = (document.body.clientWidth - bodyWidth) + 'px'
        this.$emit('open')
      }
      else {
        this.removeStyle()
        this.isOpened = false
      }
    },
    removeStyle() {
      const bodyStyle = document.body.style
      bodyStyle.overflow = null
      bodyStyle.paddingRight = null
    },
    close() {
      if (!this.noClose) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

$modalWidth: 880px;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 194px;
  padding-bottom: 80px;
  background-color: rgba(g.$color-black, .5);
  z-index: g.$z-index-modal;
  overflow-x: hidden;
  overflow-y: scroll;
  @include g.sp {
    width: 100vw;
  }

  .container {
    position: relative;
    .closeBtn {
      $size: 64px;

      position: absolute;
      top: -($size / 2);
      right: -22px;
      width: $size;
      height: $size;
      background-color: g.$color-black;
      border-radius: 50%;
      font-size: 0;
      cursor: pointer;

      &::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: fit-content;
        height: fit-content;
        margin: auto;
        font-size: 27px;
        color: g.$color-white;
      }
    }
    .content {
      width: $modalWidth;
      overflow: hidden;
      border-radius: 24px;
      background-color: g.$color-white;
      &.modalImage {
        background-image: url('@/assets/img/modal/bg.png');
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
        .modalHeader, .modalFooter {
          background-color: unset;
        }
      }
      &.deckRegisterModal {
        @include g.pc {
          width: g.$content-width-pc;
        }
      }
      .modalHeader {
        padding: 40px 40px 35px;
        background-color: g.$color-white;
        &:empty {
          display: none;
        }
      }
      .modalBody {
        padding: 0 80px;
        &.noPadding {
          padding: 0;
        }
      }
      .modalFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 40px 30px 48px;
        background-color: g.$color-white;
        &:empty {
          display: none;
        }
        &.footerShadow {
            box-shadow: 0 -6px 6px rgb(0 0 0 / 4%);
        }
      }
    }
  }
  @include g.sp {
    padding-top: 46px;
    padding-bottom: 46px;
    .container {
      margin: auto;
      .closeBtn {
        $size: 48px;

        position: absolute;
        top: -($size / 2);
        right: -8px;
        width: $size;
        height: $size;
        &::before {
          font-size: 21px;
        }
      }
      .content {
        width: calc(100vw - 40px);
        margin: 0 auto;
        .modalBody {
          padding: 0 20px;
        }
      }
    }
  }
}
</style>
