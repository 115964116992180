<template>
  <system-page :page-settings="page">
    <div class="c-content withdraw">
      <div class="c-warningBox">
        <p class="u-text-warning-icon">
          退会できません
        </p>
      </div>
      <p class="u-mb-exlg withdraw-text-left">応募中のイベントがあるため退会することができません。</p>
      <div class="withdraw-button">
        <router-link
          to="/mypage"
          class="c-btn c-btn-primary-outline"
        >戻る</router-link>
      </div>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
  name: 'WithdrawErrorPage',
  components: {
    SystemPage,
  },
  data: function() {
    return {
      page: {
        title: '退会',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ 退会のページ。',
        breadcrumb: [
          { name: 'マイページ', path: '/mypage' },
          { name: 'ユーザー情報', path: '/mypage/profile' },
        ],
      }
    }
  },
  methods: {
    send() {
      this.$router.push('/mypage/user/withdraw/complete')
    },
  },
}
</script>

<style lang="scss" scoped>
@use './style.scss';
@use '~@/assets/style/global' as g;

.c-warningBox {
  width: 640px;
  margin: 0 auto 25px;
  @include g.sp {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
