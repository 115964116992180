import { createRouter, createWebHistory } from 'vue-router'
import Store from '@/utils/store.js'

import NotFound from '@/views/Error/404'
import ServerError from '@/views/Error/ServerError'
import Login from '@/views/Login/Login'
import SnsLogin from '@/views/Login/SnsLogin'
import PasswordReissue from '@/views/PasswordReissue/PasswordReissue'
import PasswordMailComplete from '@/views/PasswordReissue/PasswordMailComplete'
import PasswordReset from '@/views/PasswordReissue/PasswordReset'
import PasswordComplete from '@/views/PasswordReissue/PasswordComplete'
import MailRegister from '@/views/Register/MailRegister'
import MailRegisterComplete from '@/views/Register/MailRegisterComplete'
import SmsIdentify from '@/views/Register/SmsIdentify'
import SmsIdentifyComplete from '@/views/Register/SmsIdentifyComplete'
import UserRegisterBase from '@/views/Register/UserRegisterBase'
import UserRegisterError from '@/views/Register/UserRegisterError'
import UserRegisterComplete from '@/views/Register/UserRegisterComplete'
import Mypage from '@/views/Mypage/Mypage'
import AccountAlignment from '@/views/Mypage/AccountAlignment'
// import JudgeDetail from '@/views/Judge/Detail'
import MyJudgeEventDetail from '@/views/Mypage/Judge/JudgeDetail'
import MyJudgeHistory from '@/views/Mypage/Judge/JudgeHistory.vue'
import MyEventList from '@/views/Mypage/MyEvent/MyEventList.vue'
import Profile from '@/views/Mypage/Profile/Profile'
import ProfileEditBase from '@/views/Mypage/Profile/ProfileEditBase'
import ProfileEditComplete from '@/views/Mypage/Profile/ProfileEditComplete'
import ProfilePasswordReissue from '@/views/Mypage/Profile/PasswordReissue'
import ProfilePasswordMailComplete from '@/views/Mypage/Profile/PasswordMailComplete'
import Avatar from '@/views/Mypage/Avatar/Avatar'
import MailChange from '@/views/Mypage/MailChange/Setting.vue'
import MailChangeComplete from '@/views/Mypage/MailChange/Complete.vue'
import MailChangeComplete_2 from '@/views/Mypage/MailChange/MailChangeComplete.vue'
import MailChangeError from '@/views/Mypage/MailChange/MailChangeError.vue'
import WithdrawConfirm from '@/views/Mypage/Withdraw/Confirm.vue'
import WithdrawComplete from '@/views/Mypage/Withdraw/Complete.vue'
import WithdrawError from '@/views/Mypage/Withdraw/Error.vue'
import BarcodeCheckin from '@/views/Mypage/BarcodeCheckin/Checkin.vue';
import QrCheckinComplete from '@/views/Mypage/QrCheckin/CheckinComplete.vue';
import MypageEventResult from '@/views/Mypage/EventResult/EventResult.vue';
import EventTop from '@/views/Event/Event.vue'
import EventSearch from '@/views/Event/Search.vue'
import EventStore from '@/views/Event/EventStore.vue'
import QrLogin from '@/views/QrLogin/Login'
import SnsQrLogin from '@/views/QrLogin/SnsLogin'
// import QrSelect from '@/views/QrLogin/Select'
import QrAgeError from '@/views/QrLogin/AgeError'
import QrPlayerError from '@/views/QrLogin/PlayerError'
// import QrJudgeSuccess from '@/views/QrLogin/JudgeSuccess'
// import QrJudgeError from '@/views/QrLogin/JudgeError'
import QrLoginForClJudge from '@/views/QrLoginForClJudge/Login'
import QrLoginForClJudgeError from '@/views/QrLoginForClJudge/Error'
import EventDetail from '@/views/Event/Detail.vue'
import EventResult from '@/views/Event/Result.vue'
import EventResultList from '@/views/Event/ResultList.vue'
import DeckList from '@/views/Deck/DeckList.vue'
import MigrationSetting from '@/views/Migration/Setting.vue'
import MigrationBase from '@/views/Migration/MigrationBase.vue'
import MigrationMailComplete from '@/views/Migration/MigrationMailComplete.vue'
import MigrationComplete from '@/views/Migration/MigrationComplete.vue'
import MigrationError from '@/views/Migration/MigrationError.vue'
import PlayerRanking from '@/views/Ranking/Ranking.vue'
import OtherPlayerMypage from '@/views/Other/Mypage.vue'
import NewsList from '@/views/News/List.vue'
import NewsDetail from '@/views/News/Detail.vue'
import Faq from '@/views/Faq/Faq.vue'

const routes = [
    { path: '/', name: 'EventTop', component: EventTop, },
    { path: '/:catchAll(.*)', component: NotFound, },
    { path: '/404', name: 'NotFound', component: NotFound, },
    { path: '/500', name: 'ServerError', component: ServerError, },
    { path: '/login', name: 'Login', component: Login, },
    { path: '/login/sns', name: 'SnsLogin', component: SnsLogin, },
    { path: '/login/password/mail_complete', name: 'PasswordMailComplete', component: PasswordMailComplete, },
    { path: '/login/password/reissue', name: 'PasswordReissue', component: PasswordReissue, },
    { path: '/login/password/reset', name: 'PasswordReset', component: PasswordReset, },
    { path: '/login/password/complete', name: 'PasswordComplete', component: PasswordComplete, },
    { path: '/login/register/mail', name: 'MailRegister', component: MailRegister, },
    { path: '/login/register/mail/complete', name: 'MailRegisterComplete', component: MailRegisterComplete, },
    { path: '/login/register/sms', name: 'SmsIdentify', component: SmsIdentify, },
    { path: '/login/register/sms/complete', name: 'SmsIdentifyComplete', component: SmsIdentifyComplete, },
    { path: '/login/register/user', name: 'UserRegister', component: UserRegisterBase, },
    { path: '/login/register/user/error', name: 'UserRegisterError', component: UserRegisterError, },
    { path: '/login/register/user/complete', name: 'UserRegisterComplete', component: UserRegisterComplete, },
    { path: '/migration', name: 'MigrationSetting', component: MigrationSetting, },
    { path: '/migration/mail/complete', name: 'MigrationMailComplete', component: MigrationMailComplete, },
    { path: '/migration/input', name: 'MigrationBase', component: MigrationBase, },
    { path: '/migration/complete', name: 'MigrationComplete', component: MigrationComplete, },
    { path: '/migration/error', name: 'MigrationError', component: MigrationError, },
    // { path: '/judge/detail/:id', name: 'JudgeDetail', component: JudgeDetail, },
    { path: '/mypage', name: 'Mypage', component: Mypage, meta: { requiresAuth: true } },
    { path: '/mypage/account_alignment', name: 'MypageAccountAlignment', component: AccountAlignment, meta: { requiresAuth: true } },
    { path: '/mypage/myevent/list', name: 'MyEventList', component: MyEventList, meta: { requiresAuth: true }},
    { path: '/mypage/judge/detail/:id', name: 'MyJudgeEventDetail', component: MyJudgeEventDetail, meta: { requiresAuth: true } },
    { path: '/mypage/judge/history', name: 'MyJudgeHistory', component: MyJudgeHistory, meta: { requiresAuth: true } },
    { path: '/mypage/profile', name: 'Profile', component: Profile, meta: { requiresAuth: true } },
    { path: '/mypage/profile/edit', name: 'ProfileEdit', component: ProfileEditBase, meta: { requiresAuth: true } },
    { path: '/mypage/profile/edit/complete', name: 'ProfileEditComplete', component: ProfileEditComplete, meta: { requiresAuth: true } },
    { path: '/mypage/profile/password/reissue', name: 'ProfilePasswordReissue', component: ProfilePasswordReissue, meta: { requiresAuth: true } },
    { path: '/mypage/profile/password/mail_complete', name: 'ProfilePasswordMailComplete', component: ProfilePasswordMailComplete, meta: { requiresAuth: true } },
    { path: '/mypage/avatar', name: 'Avatar', component: Avatar, meta: { requiresAuth: true } },
    { path: '/mypage/user/mail', name: 'MailChange', component: MailChange, meta: { requiresAuth: true } },
    { path: '/mypage/user/mail/complete', name: 'MailChangeComplete', component: MailChangeComplete, meta: { requiresAuth: true } },
    { path: '/mypage/user/mail/mail_change_complete', name: 'MailChangeComplete_2', component: MailChangeComplete_2, meta: { requiresAuth: true } },
    { path: '/mypage/user/mail/mail_change_error', name: 'MailChangeError', component: MailChangeError, meta: { requiresAuth: true } },
    { path: '/mypage/user/withdraw', name: 'WithdrawConfirm', component: WithdrawConfirm, meta: { requiresAuth: true } },
    { path: '/mypage/user/withdraw/complete', name: 'WithdrawComplete', component: WithdrawComplete, },
    { path: '/mypage/user/withdraw/error', name: 'WithdrawError', component: WithdrawError, },
    { path: '/mypage/barcode_checkin/:eventHoldingId/:trainersFlg', name: 'BarcodeCheckin', component: BarcodeCheckin, props: true, meta: { requiresAuth: true }},
    { path: '/mypage/checkin/complete', name: 'QrCheckinComplete', component: QrCheckinComplete, },
    { path: '/mypage/event_result', name: 'MypageEventResult', component: MypageEventResult, meta: { requiresAuth: true } },
    { path: '/qr/login', name: 'QrLogin', component: QrLogin, },
    { path: '/qr/login/sns', name: 'SnsQrLogin', component: SnsQrLogin, },
//    { path: '/qr/select', name: 'QrSelect', component: QrSelect, },
    { path: '/qr/age_error', name: 'QrAgeError', component: QrAgeError, },
    { path: '/qr/player_success', name: 'QrPlayerSuccess', component: QrCheckinComplete, props: true},
    { path: '/qr/player_error', name: 'QrPlayerError', component: QrPlayerError, props: true},
    // { path: '/qr/judge_success', name: 'QrJudgeSuccess', component: QrJudgeSuccess, props: true},
    // { path: '/qr/judge_error', name: 'QrJudgeError', component: QrJudgeError, props: true},
    { path: '/qr/login_for_cl_judge', name: 'QrLoginForClJudge', component: QrLoginForClJudge, },
    { path: '/qr/login_for_cl_judge_error', name: 'QrLoginForClJudgeError', component: QrLoginForClJudgeError, props: true},
    { path: '/event/search', name: 'EventSearch', component: EventSearch, },
    { path: '/event/detail/:id/:trainersFlg/:shopId/:eventDate/:dateId', name: 'EventDetail', component: EventDetail, },
    { path: '/event/detail/:id/result', name: 'EventResult', component: EventResult, },
    { path: '/event/search/:id/list', name: 'EventStore', component: EventStore, },
    { path: '/event/search/:id/:month/list', name: 'EventStoreMonth', component: EventStore, },
    { path: '/event/result/list', name: 'EventResultList', component: EventResultList, },
    { path: '/event/ranking', name: 'PlayerRanking', component: PlayerRanking, },
    { path: '/deck', name: 'DeckList', component: DeckList, meta: { requiresAuth: true } },
    { path: '/users/:id', name: 'OtherPlayerMypage', component: OtherPlayerMypage, },
    { path: '/news', name: 'NewsList', component: NewsList, },
    { path: '/news/:id', name: 'NewsDetail', component: NewsDetail, },
    { path: '/faq', name: 'Faq', component: Faq, },
    { path: '/event', redirect: { name: 'EventTop' } },
    // {
    //     path: '/style-guide',
    //     name: 'StyleGuide',
    //     component: () => import('@/views/StyleGuide/StyleGuide')
    // }
]

const scrollBehavior = function(to, from, savedPosition) {
    const position = {}
    if (savedPosition) {
        if (to.path === from.path && (!to.hash && from.hash)) {
            /*
                同一ページ内＋アンカーリンクでposition移動している場合、
                ブラウザの進む・戻るボタンでhash有り→hashなしのURL移動した際にsavedPositionが上手く取れず
                変なところに行くのでこのパターンの時だけトップに戻す
            */
            position.left = 0
            position.top = 0
        } else {
            position.left = savedPosition.left
            position.top = savedPosition.top
        }

        return position
    } else {
        if (to.hash && document.querySelector(to.hash)) {
            position.el = to.hash
        } else {
            position.left = 0
            position.top = 0
        }

        return new Promise(resolve => {
            resolve(position)
        })
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior,
})

router.beforeEach(function(to, from, next) {
    let elements = document.getElementsByTagName('body');
    elements[0].classList.add('remove-hover');
    setTimeout(() => {
        elements[0].classList.remove('remove-hover');
    }, 10)

    router['referrer'] = from;
    if (
        to.matched.some(record => {
            return record.meta.requiresAuth
        }) &&
        (!Store.state.loggedIn || !localStorage.getItem('userToken'))
    ) {
        if(Store.state.loggedIn)
        {
            Store.commit('setLogoutState');
        }
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        })
    } else {
        next()
    }
})

export default router
