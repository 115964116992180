<template>
    <system-page :page-settings="page">
        <div class="c-content">
            <error-msg
                v-if="error"
                class="u-mb-lg pc-width-640"
            >
                <p><pre>{{ error }}</pre></p>
            </error-msg>
        </div>
        <article class="information">
            <div class="information-title">
                <h2 class="title">{{ news.title }}</h2>
                <time class="date">{{ formatDate(news.update_at) }}</time>
            </div>
            <div class="information-main">
                <div class="text">
                    <free-text :freeText="news.text"></free-text>
                </div>
            </div>
        </article>
        <div class="information-nav">
            <router-link
                :to="backPageName ? {name: 'NewsList' , query:{routePage: 'Mypage'}} : {name: 'NewsList'}"
                class="c-btn c-btn-primary-outline"
            >
                お知らせ一覧へ
            </router-link>
        </div>
    </system-page>
</template>

<script>
import systemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import freeText from '@/component/FreeText.vue'
import dayjs from 'dayjs'

export default {
    name: 'NewsDetailPage',
    components: {
        systemPage,
        errorMsg,
        freeText,
    },
    data() {
        return {
            page: {
                title: 'お知らせ詳細',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ お知らせ詳細のページ。',
                breadcrumb: []
            },
            error: null,
            news: {
                title: '',
                text: '',
                update_at: null,
            },
            backPageName: ''
        }
    },
    created: function () {
        this.init()
        this.searchNews()
    },
    methods: {
        init() {
            if (this.$route.query["routePage"]){
                this.backPageName = this.$route.query["routePage"]
                this.page.breadcrumb = [
                    { name: 'マイページ', path: '/Mypage' },
                    { name: 'お知らせ一覧', path: '/news' },
                ]
            }
            else {
                this.page.breadcrumb = [
                    { name: 'イベント', path: '/' },
                    { name: 'お知らせ一覧', path: '/news' },
                ]
            }
        },
        searchNews() {
            if (this.$route.params.id === null) {
                this.error = 'お知らせが存在しません。'
                return
            }
            const targetUrl = "/api/information/detail?id=" + this.$route.params.id
            this.axios.get(targetUrl).then((response) => {
                this.news = response.data.information
            })
            .catch(err =>{
                if (err.response.status === 404) {
                    this.$router.push({name: 'NotFound'})
                } else {
                    this.error = err.response.data.message
                }
            })
        },
        formatDate: function (datetime) {
            if (datetime == null) {
                return ''
            }
            return dayjs(datetime.date).format('YYYY/MM/DD')
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

::v-deep(.c-title-page) {
    display: none;
}

.information {
    width: g.$content-width-pc-sm;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    @include g.sp {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.information-title {
    border-bottom: 1px solid g.$color-border-gray;
    padding-bottom: 29px;
    margin-bottom: 32px;
    @include g.sp {
        padding-bottom: 20px;
        margin-bottom: 24px;
    }
    & > .title {
        @include g.font-h1;
        font-weight: bold;
    }
    & > .date {
        display: inline-block;
        margin-top: 9px;
        font-weight: 500;
        color: g.$color-black-a040;
    }
}
.information-main {
    & > .c-title-h2 {
        margin-top: 56px;
        @include g.sp {
            margin-top: 48px;
        }
        & + .text {
            margin-top: 28px;
            @include g.sp {
                margin-top: 24px;
            }
        }
    }
    & > .text {
        white-space: pre-line;
        & + .text {
            margin-top: 16px;
        }
    }
}
.information-nav {
    margin-top: 56px;
    text-align: center;
    @include g.sp {
        margin-top: 48px;
    }
}
</style>
