<template>
    <pcg-page :page-settings="page">
        <div class="c-content">
            <error-msg
              v-if="error"
              :message="error"
              class="u-mb-lg"
            ></error-msg>
            <div class="eventTitle">
                <div class="eventTitle-classification">
                    <event-classification
                        class="u-mr"
                        :type="eventData.event_type_title"
                    ></event-classification>
                    <event-kinds class="u-mr" type="rule" v-if="eventData.eventAttrId === 1" />
                    <event-kinds class="u-mr" type="beginner" v-if="eventData.eventAttrId === 2" />
                    <event-kinds class="u-mr" type="serious-game" v-if="eventData.eventAttrId === 3"/>
                    <event-kinds class="u-mr" type="remote" v-if="eventData.eventAttrId === 4" />
                </div>
                <div class="eventTitle-title">
                    <h1 class="c-title-event">{{ eventData.event_title }}</h1>
                    <div class="eventTitle-leagueImg">
                        <!-- <img v-if="eventData.eventTypeId == 1" src="@/assets/img/event/league/champions.png"> -->
                        <img v-if="eventData.eventTypeId == 2" src="@/assets/img/event/league/city.png">
                        <img v-if="eventData.eventTypeId == 3" src="@/assets/img/event/league/trainer.png">
                        <img v-if="eventData.eventTypeId == 4" src="@/assets/img/event/league/gym.png">
                        <!-- <img v-if="eventData.eventTypeId == 5" src="@/assets/img/event/league/campaign.png"> -->
                        <img v-if="eventData.eventTypeId == 6" src="@/assets/img/event/league/organizer.png">
                        <!-- <img v-if="eventData.eventTypeId == 7" src="@/assets/img/event/league/sealed.png"> -->
                    </div>
                </div>
                <div class="eventTitle-category">
                    <div
                        v-if="eventData.league"
                        class="eventTitle-league"
                    >
                        {{ eventData.league }}
                    </div>
                    <div
                        v-if="eventData.csp_flg === 1"
                        class="c-eventPoint c-eventPoint-championship"
                    >
                        {{ 'チャンピオンシップポイント対象' }}
                    </div>
                </div>
            </div>
        </div>
        <event-detail
            state="drawingBeforeReception"
            :eventData="eventData"
        ></event-detail>
        <div class="c-content">
            <h2 class="c-title-h2 u-mb-lg">イベント結果</h2>
            <event-result-rank-table
                class="u-mb-exlg"
                :rankList="rankList"
            ></event-result-rank-table>
            <template v-if="pagination.totalPage > 1">
                <pcg-pager
                    class="u-mb-lg"
                    :page="pagination"
                    @pageFeed="setPage"
                />
                <div class="u-text-center" v-if="0 < pagination.totalCount">
                    {{ pagination.currentPage }}ページ ／ {{ pagination.totalPage }}ページ中
                </div>
            </template>
        </div>
        <div class="c-content u-text-center result-bottom">
            <router-link
                :to="{name: 'EventResultList'}"
                class="c-btn c-btn-primary-outline"
            >
                イベント結果一覧へ
            </router-link>
        </div>
    </pcg-page>
</template>

<script>
import pcgPage from '@/component/Page.vue'
import pcgPager from '@/component/Pager.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import eventClassification from '@/component/EventClassification.vue'
import eventKinds from '@/component/EventKinds.vue'
import eventDetail from './component/EventDetail.vue'
import eventResultRankTable from './component/EventResultRankTable.vue'

export default {
    name: 'EventResultPage',

    components: {
        pcgPage,
        pcgPager,
        errorMsg,
        eventClassification,
        eventKinds,
        eventDetail,
        eventResultRankTable,
    },

    data: function() {
        return {
            page: {
                title: 'イベント詳細',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ イベント詳細のページ。',
                breadcrumb: [
                    { name: 'イベント', path: '/' },
                    { name: 'イベント結果一覧', path: '/event/result/list' },
                ],
            },
            pagination: {
                currentPage: 1,
                totalPage: 1,
                totalCount: 0,
                offset: 0,
                perPage: 8,
            },
            eventData: [],
            rankList: [],
            error: null,
        }
    },

    created: function () {
            this.search()
    },

    methods: {
        search () {
                const params = {
                    event_holding_id: this.$route.params.id,
                    offset: this.pagination.offset,
                    per_page: this.pagination.perPage,
                }
                this.axios.get('/event_result_detail_search', {params})
                .then(response => {
                    this.pagination.totalCount = response.data.count
                    this.pagination.totalPage = Math.ceil(this.pagination.totalCount / this.pagination.perPage) || 1
                    this.eventData = response.data.event
                    this.rankList = response.data.results
                    let eventTitle = this.eventData.event_title
                    this.page.title = eventTitle
                    this.page.desc = "ポケモンカードゲーム プレイヤーズクラブの「" + eventTitle + "」の結果はこちら。"
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        if (this.$store.state.isBrowserBack === true && this.$router.referrer.name === 'NotFound') {
                            // 404からブラウザバックして404の場合はさらに前の画面へ遷移
                            this.$router.go(-1)
                        } else {
                            this.$router.push({name: 'NotFound'})
                        }
                    } else {
                        this.error = error.response?.data?.message || error.message
                    }
                })
        },

        setPage(newPage) {
            this.pagination.currentPage = newPage
            this.pagination.offset = (newPage - 1) * this.pagination.perPage
            this.search()
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style/content';
@use './style/detail';

.c-content:not(.overview) {
    & > h2.c-title-h2 {
        @include g.pc {
            margin-top: 56px;
        }
    }
}

.eventTitle-category {
    .c-eventPoint-championship {
        @include g.sp {
            margin-top: 11px;
        }
    }
}

.result-bottom {
    margin-top: 72px;
    margin-bottom: 72px;
    @include g.sp {
        margin-top: 52px;
    }
}

::v-deep(.c-rankTable) {
    .c-rankTable-row {
        & > .point {
            width: 67px;
        }
        & > .deck {
            width: 58px;
        }
    }
}

::v-deep(.eventState-top) {
    border-bottom: g.$border-solid;
}

::v-deep(.eventState-date) {
    margin-bottom: 30px;
}
</style>
