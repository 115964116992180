<template>
<p v-for="text in splitedText" :key="text">
    {{ text }}
</p>
</template>
<script>
export default {
    name: 'FreeText',
    props: {
        freeText: {
            type: String,
            required: true
        }
    },
    computed: {
        splitedText: function() {
            return this.freeText.split(/\r\n|\r|\n/)
        }
    }
}
</script>
