<template>
    <system-page :page-settings="pageWithTitle">
        <div class="u-flex u-flex-center u-mb-lg">
            <img
                :src="myAvatar"
                class="avatar-selected"
            >
        </div>
        <form @submit.prevent="keywordSearch()">
            <nav class="avatar-search c-content">
                <p>名前で絞り込む</p>
                <div class="avatar-search-form">
                    <input
                        type="text"
                        name="avatarName"
                        class="c-input c-input-text"
                        placeholder="ポケモンの名前を入力"
                        v-model="avatarName"
                    >
                </div>
                <button
                    class="c-btn c-btn-primary"
                    type="submit"
                >
                    絞り込み
                </button>
            </nav>
        </form>
        <div class="c-content">
            <div class="u-flex u-flex-space-between avatar-page-info" :class="{ 'no-result-info' : page.renderData.length === 0 }">
                <div class="avatar-count">
                    <span class="u-text-exlarge u-text-bold">{{ page.total }}</span>件
                </div>
                <div v-if="page.renderData.length !== 0" class="avatar-top-pagenation u-text-center">
                    <pcg-pager @pageFeed="pageFeed" :page="page" class="avatar-margin"/>
                </div>
                <div class="c-input c-input-select">
                    <select v-on:change="changeItemCount">
                        <option value="30">30件表示</option>
                        <option value="50">50件表示</option>
                        <option value="100">100件表示</option>
                        <option value="150">150件表示</option>
                    </select>
                </div>
            </div>
            <template v-if="page.renderData.length !== 0">
                <ul class="avatars">
                    <li
                        class="avatar"
                        v-for="avatar in page.renderData"
                        :key="avatar.avatar_id"
                    >
                        <img
                            class="avatar-selected"
                            @click="clickAvatar(avatar)"
                            :src="avatar.avatarImage"
                        >
                    </li>
                </ul>
                <div class="u-text-center u-mb-lg avatar-bottom-pagenation">
                    <pcg-pager @pageFeed="pageFeed" :page="page" class="avatar-margin" />
                </div>
                <div class="u-text-center avatar-pager" v-if="0 < page.total">
                    {{ page.currentPage }}ページ ／ {{ page.totalPage }}ページ中
                </div>
            </template>
            <div v-else>
                <no-result>
                    対象のアバターがみつかりませんでした。
                </no-result>
            </div>
            <div class="u-flex u-flex-center">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイページへ
                </router-link>
            </div>
        </div>
    </system-page>

    <pcg-modal
      class="avatar-confirm-modal"
      :isActive="activeModal === 'avatarConfirmModal'"
      @close="activeModal = null"
    >
        <template #modalHeader>
          <h2>アバター変更</h2>
        </template>
        <div class="avatar-confirm-body">
            <p class="u-text-center u-mb-lg">アバターを変更します。</p>
            <div class="u-flex u-flex-center u-mb">
                <img
                    :src="selectAvatar.avatarImage"
                    class="avatar-confirm-image"
                >
            </div>
            <h3>
                {{selectAvatar.title}}{{ selectAvatar.avatarDetail ? '(' + selectAvatar.avatarDetail + ')' :''}}
            </h3>
            <div class="avatar-confirm-buttons u-flex u-flex-center">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="close()"
                >キャンセル</button>
                <button
                    @click="resister()"
                    type="button"
                    class="c-btn c-btn-primary"
                >変更する</button>
            </div>
        </div>
    </pcg-modal>

    <pcg-modal
      class="avatar-complete-modal"
      :isActive="activeModal === 'avatarCompleteModal'"
      @close="activeModal = null"
    >
        <template #modalHeader>
          <h2>アバターを変更しました</h2>
        </template>
        <div class="avatar-complete-buttons u-flex u-flex-center">
            <button
                type="button"
                class="c-btn c-btn-primary-outline"
                @click="close()"
            >閉じる</button>
        </div>
    </pcg-modal>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import pcgPager from '@/component/Pager.vue'
import pcgModal from '@/component/Modal.vue'
import noResult from '@/component/NoResult.vue'

export default {
    name: 'AvatarList',
    components: {
        SystemPage,
        pcgPager,
        pcgModal,
        noResult,
    },
    props: {},
    data: function() {
        return {
            page: {
                title: 'アバター変更',
                desc: '',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
                renderData: [],
                currentPage: 1,
                totalPage: 1,
                total: 0,
                modalTotal: 0,
                perPage: 30,
                offset: 0,
            },
            activeModal: null,
            avatarName: '',
            myAvatar: '',
            selectAvatar: ''
        }
    },
    created: function() {
        this.init()
        this.search();
    },
    computed: {
        pageWithTitle: {
            get() {
                let page = this.page
                let player = this.$store.getters.getUser();
                if (!player) {
                    return page
                }
                let defaultTitle = page.title;
                page.titleForHeader = player.nickname + "さんの" + defaultTitle
                page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんの" + defaultTitle + "。";
                return page
            }
        },
    },
    methods: {
        search()  {
            const api = '/avatar_search'

            this.axios.get(api, {
            params: {
                avatarName: this.avatarName,
                limit: this.page.perPage,
                offset: this.page.offset
            }
            })
            .then((response) => {
                this.page.renderData = response.data.avatarList
                this.page.total = response.data.avatarCount
                this.page.modalTotal = response.data.avatarList
                    ? response.data.avatarList.length
                    : 0;
                this.page.totalPage = Math.ceil(response.data.avatarCount / this.page.perPage)
            });
        },
        init() {

            const api = '/my_avater_search'

            this.axios.post(api)
            .then((response) => {
                this.myAvatar = response.data.avatar[0].avatar_image
            })
            .catch(err => {
                if (err.response && err.response.data.status === 401) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                this.error = err.response.data.message
            })
        },
        clickAvatar(item) {
            this.selectAvatar = item
            this.activeModal = 'avatarConfirmModal'
        },
        resister() {
            let params = new URLSearchParams
            params.append('avatar_id', this.selectAvatar.avatar_id)

            const api = '/avatar_regist'

            this.axios.post(api, params)
            .then(() => {
                this.activeModal = 'avatarCompleteModal'
                this.$store.commit('refreshUser')
                this.init()
            })
            .catch(err => {
                this.error = err.response.data.message
            })
        },
        close() {
            this.activeModal = null
        },
        keywordSearch() {
            this.page.offset = 0
            this.page.currentPage = 1

            this.search()
        },
        changeItemCount(e) {
            this.page.perPage = e.target.value
            this.page.offset = 0
            this.page.currentPage = 1

            this.search()
        },
        pageFeed(count) {
            this.page.currentPage = count
            this.page.offset = (count - 1) * this.page.perPage
            this.search()
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.avatar-selected {
    @include g.pc {
        max-width: 140px;
        max-height: 140px;
    }
    @include g.sp {
        max-width: 100px;
        max-height: 100px;
    }
}

.avatar-search {
    background: #EBEBEB;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 47px;

    p {
        font-weight: bold;
        padding-right: 20px;
        color: g.$color-black;
    }

    .avatar-search-form {
        position: relative;
        margin-right: 20px;

        .c-input {
            width: 348px;
            padding-left: 50px;
            position: relative;
            z-index: 1;
        }

        &::after {
            content: "\e903";
            font-family: "pcg_icon";
            left: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            z-index: 2;
            display: flex;
            align-items: center;
            color: g.$color-black-a025;
            font-size: 24px;
            pointer-events: none;
        }
    }

    .c-btn {
        width: 113px;
        height: 48px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}

.avatar-page-info {
    margin-bottom: 60px;
    align-items: center;

    > div {
        flex: 1;
    }

    .c-input-select {
        justify-content: flex-end;

        select {
            width: 167px;
        }
    }

    .avatar-count {
        color: g.$color-black;
    }
}

.avatars {
    font-size: 0;
    line-height: 0;
    display: grid;
    margin-bottom: 36px;

    .avatar {
        margin-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
    }

    .avatar img{
        transition-duration: 0.5s;
    }

    @include g.pc {
        grid-template-columns: repeat(6, 1fr);
        .avatar:hover{
            box-shadow: 5px 5px 5px rgba(189, 187, 187, 0.5);
            transform: translateY(-10px);
            transition-duration: 0.2s;
            border-radius: 10px;
        }
    }
    @include g.sp {
        grid-template-columns: repeat(3, 1fr);
    }
}

.avatar-pager {
    margin-bottom: 60px;
}

.avatar-confirm-modal {
    h2 {
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: g.$color-black;
    }

    h3 {
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 50px;
        color: g.$color-black;
    }

    .avatar-confirm-body {
        p {
            color: g.$color-black;
        }
        .avatar-confirm-image {
            max-width: 160px;
            max-height: 160px;
        }
    }

    .avatar-confirm-buttons {
        padding-bottom: 72px;

        .c-btn {
            width: 303px;
            margin: 0 19px;
        }
    }

    ::v-deep .container .content .modalHeader {
        @include g.pc {
            padding-top: 72px;
        }
    }
}

.avatar-complete-modal {
    h2 {
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: g.$color-black;
    }

    .avatar-complete-buttons {
        padding-bottom: 72px;
    }

    ::v-deep .container .content .modalHeader {
        @include g.pc {
            padding-top: 72px;
        }
        @include g.sp {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.noResult {
    margin-bottom: 60px;
    @include g.sp {
        margin-bottom: 56px;
    }
}

@include g.sp {
    .avatar-selected {
    }

    .avatar-search {
        margin-bottom: 24px;

        p {
            display: none;
        }

        .avatar-search-form {
            .c-input {
                width: 100%;
                font-size: 16px;
                padding-left: 40px;
            }

            &::after {
                font-size: 18px;
                left: 15px;
            }
        }

        .c-btn {
            flex: 0 0 113px;
        }
    }

    .avatar-page-info {
        flex-wrap: wrap;

        .avatar-count {
            order: 1;
            flex: 0 0 50%;
            margin-bottom: 55px;

            span {
                font-size: 20px;
            }
        }

        .avatar-top-pagenation {
            flex: 0 0 100%;
            order: 3;
            display: flex;
            justify-content: flex-end;

            .pagination {
                text-align: right;
            }
        }

        .c-input-select {
            flex: 0 0 50%;
            order: 2;
            margin-bottom: 55px;
        }
    }

    .no-result-info {
        margin-bottom: 0px;
    }

    .avatars {
        margin: 0 -9px 20px;

        .avatar {
            padding: 0 9px 18px;
            margin: 0;
        }
    }

    .avatar-bottom-pagenation {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
    }

    .avatar-confirm-modal {
        h2 {
            font-size: 24px;
        }

        p {
            margin-bottom: 30px;
        }

        h3 {
            margin-bottom: 35px;
            line-height: 24px;
        }

        .avatar-confirm-buttons {
            padding-bottom: 25px;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            align-items: center;

            .c-btn {
                width: 275px;
                margin: 0 0 15px;
            }
        }
    }

    .avatar-complete-modal {
        h2 {
            font-size: 24px;
        }

        .avatar-complete-buttons {
            padding-bottom: 40px;

            .c-btn {
                width: 275px;
            }
        }
    }
}

.avatar-margin {
    @include g.sp {
        margin: 0 auto;
    }
}
</style>
