<template>
  <span class="tag" :class="style" v-if="name != ''">
    {{ name }}
  </span>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'EventPlayerStatus',
  props: {
    statusId: {
      type: Number,
      required: true,
    },
    entryTypeId: {
      type: Number,
      required: true,
    },
    lotteryDeadline: {
      type: String,
    },
  },
  data: function() {
    return {

    }
  },
  computed: {
      name() {
          let name = ''
          switch(this.statusId) {
            case 1: // APPLIED 応募済
                name =  '抽選待ち'
                break;
            case 2: // CONFIRMED 参加確定
                name =  '参加確定'
                break;
            case 3: // WAITING FOR CANCELLATION リザーバー
                name =  'リザーバー'
                break;
            case 4: // LOSE 落選
                name = '落選'
                break;
            case 5: // PARTICIPATION 参加
                // デザイン無し
                break;
            case 6: // CANCELL キャンセル
                // デザイン無し
                name = '出場キャンセル'
                break;
            default:
                break;
          }

          if (this.isBeforeLotteryDeadline()) {
            name =  '抽選待ち'
          }

          return name;
      },
      style() {
          let style = {}
          switch(this.statusId) {
            case 1: // APPLIED 応募済
                style = {
                    'applied': true
                }
                break;
            case 2: // CONFIRMED 参加確定
                style = {
                    'confirmed': true
                }
                break;
            case 3: // WAITING FOR CANCELLATION キャンセル待ち当選（リザーバー当選）
                style = {
                    'waitingForCancellation': true
                }
                break;
            case 4: // LOSE 落選
                style = {
                    'lose': true
                }
                break;
            case 5: // PARTICIPATION 参加
                // デザイン無し
                break;
            default: //
                break;
          }

          if (this.isBeforeLotteryDeadline()) {
            style = {
                'applied': true
            }
          }
          return style;
      },
    },
    methods: {
      isBeforeLotteryDeadline: function() {
        if (this.entryTypeId !== 3) {
            return false
        }
        let today = dayjs(new Date())
        let lottery = this.lotteryDeadline !== null && this.lotteryDeadline !== '' ? dayjs(this.lotteryDeadline.date) : null
        if (lottery !== null && lottery.isAfter(today)) {
            return true
        }
        return false
      },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.tag {
    @include g.pc {
      width: 144px;
      height: 32px;
      line-height: 32px;
    }

    @include g.sp {
      height: 28px;
      line-height: 28px;
      padding: 0 20px;
    }

    border-radius: 4px;
    background: #fff;
    border: 1px solid;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:not(:last-child) {
        margin-right: g.$margin-small;
    }

    &.applied {
        border-color: g.$color-font-green;
        background: g.$color-font-green;
        color: g.$color-font-white;
    }

    &.confirmed {
        border-color: g.$color-black-base;
    }

    &.waitingForCancellation {
        border-color: g.$color-font-green;
        color: g.$color-font-green;
    }

    &.lose {
        // グローバル変数使うと透明度の関係か線が濃くなってしまうため、直接カラーコードで指定
        border-color: #999999;
        background: #999999;
        color: g.$color-font-white;
    }
}
</style>
