<template>
    <div class="event-text-header">● 大会概要</div>
    <p class="event-text-content">
        各大会の上位入賞者には、ポケモンワールドチャンピオンシップスの出場権、<br>
        および賞品を獲得できるチャンピオンシップポイントが与えられます。
    </p>
    <div class="event-text-header">● 大会参加にあたってのお願い</div>
    <p>下記の点を遵守のうえご参加ください。</p>
    <ul class="event-text-content c-list c-list-ul">
        <li>14日以内に感染地域から帰国された方、あるいは14日以内に37.5℃以上の熱の症状があった方は参加をお控えください。</li>
        <li>大会に参加する際はマスクの着用をお願いします。</li>
        <li>大会開始前に手指の洗浄・消毒をお願いいたします。</li>
        <li>接触頻度を下げるため、相手の山札・手札を触らないようお願いいたします。詳しくは「シャッフルおよびトラッシュの確認について」をご確認ください。ただし、デッキの確認や、デッキシールドのチェックなどで、ジャッジが皆様のデッキを触る可能性がございます。あらかじめご了承ください。</li>
        <li>感染拡大防止の観点より、風邪または体調不良の自覚症状がある方は、ご参加をお控えください。また、感染時に重篤化する可能性の高い高齢者や持病をお持ちの方は、慎重にご検討いただいた上でご参加いただ けますようお願いいたします。</li>
        <li>その他、感染防止および円滑な運営のため、店舗やジャッジの指示に従ってください。指示に従っていただけない場合は、店舗やジャッジの判断で、参加をご遠慮いただいたり、失格とさせていただく場合がございます。</li>
        <li>シーズン中はお一人様につき1開催のみの参加が可能です。複数開催へのエントリーや参加、また不正行為が発覚した場合や、プレイヤーズクラブ上で偽名での登録、複数アカウントを利用していることが発覚した場合、故意かどうかに関わらず、 2021年シーズンのチャンピオンシップポイントを全て無効、および今後のポケモンカードの大会への参加資格を剥奪させていただく場合がございます。あらかじめご了承ください。</li>
    </ul>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
@use '../../style/text';
@use '~@/assets/style/global' as g;
</style>
