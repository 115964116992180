<template>
  <pcg-page :page-settings="page">
    <component
      :is="subPage"
      v-model:authCode="formData.authCode"
      v-model:playerId="formData.playerId"
      v-model:email="formData.email"
      v-model:nickname="formData.nickname"
      v-model:lastName="formData.lastName"
      v-model:firstName="formData.firstName"
      v-model:lastNameKana="formData.lastNameKana"
      v-model:firstNameKana="formData.firstNameKana"
      v-model:birthday="formData.birthday"
      v-model:gender="formData.gender"
      v-model:postcode="formData.postcode"
      v-model:prefectures="formData.prefectures"
      v-model:address="formData.address"
      v-model:phoneNumber="formData.phoneNumber"
      v-model:mypage="formData.mypage"
      v-model:promotionalEmail="formData.promotionalEmail"
      v-model:parentalConsent="formData.parentalConsent"
      v-model:termsOfService="formData.termsOfService"
      v-model:password="formData.password"
      v-model:passwordReEnter="formData.passwordReEnter"
      v-model:avatarInfo="avatarInfo"
    ></component>
  </pcg-page>
</template>

<script>
import MigrationInput from '@/views/Migration/MigrationInput.vue'
import MigrationConfirm from '@/views/Migration/MigrationConfirm.vue'
import pcgPage from '@/component/Page.vue'
export default {
  name: 'MigrationBase',
  components: {
    pcgPage,
  },
  data() {
    return {
      page: {
        title: '',
        breadcrumb: [],
      },
      subPage: null,

      formData: {
        authCode: '',
        playerId: '',
        email: '',
        nickname: '',
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        birthday: {},
        gender: '',
        postcode: '',
        prefectures:null,
        address: '',
        phoneNumber: '',
        mypage: '',
        promotionalEmail: '',
        avatar: '',
        parentalConsent: false,
        termsOfService: false,
        password: '',
        passwordReEnter: '',
      },
      avatarInfo: null
    }
  },
  beforeMount() {
    this.setPage()
  },
  created() {
    if (this.$store.state.loggedIn) {
      this.$store.commit('setLogoutState')
      location.reload()
    }
    this.fetchAvatar()
    this.fetchMigrationAccount()
  },
  computed: {},
  watch: {
    '$route.hash': function() {
      this.setPage()
      window.scrollTo({
          top: 0,
      })
    }
  },
  methods: {
    setPage() {
      switch (this.$route.hash) {
        case '#confirm':
          this.subPage = MigrationConfirm
          this.page.title = '追加情報入力確認'
          this.page.desc = 'ポケモンカードゲーム プレイヤーズクラブ 追加情報入力確認のページ。'
          this.page.breadcrumb = [
          ]
          break
        default:
          this.subPage = MigrationInput
          this.page.title = '追加情報入力'
          this.page.desc = 'ポケモンカードゲーム プレイヤーズクラブ 追加情報入力のページ。'
          this.page.breadcrumb = []
      }
    },
    fetchMigrationAccount() {
      const api = "/find_migration_account";
      const params = {
        'auth_code': this.$route.query.authCode,
      };
      this.axios
        .get(api, { params })
        .then((response) => {
          this.formData.authCode = String(this.$route.query.authCode);
          this.formData.playerId = response.data.player.player_id;
          this.formData.nickname = response.data.player.nickname;
          this.formData.gender = response.data.player.sex;
          this.formData.postcode = response.data.player.postal_code;
          this.formData.address = response.data.player.address;
          this.formData.phoneNumber = response.data.player.phone_number;
          this.formData.mypage = response.data.player.publish_flg;
          this.formData.email = response.data.player.mail_address;
          this.formData.birthday = response.data.player.birthday;
          this.formData.lastName = response.data.player.family_name;
          this.formData.lastNameKana = response.data.player.family_name_kana;
          this.formData.firstName = response.data.player.first_name;
          this.formData.firstNameKana = response.data.player.first_name_kana;
        })
        .catch(err => {
          // アカウント移行画面へ遷移してエラーメッセージを表示する
          this.$router.push({ name: "MigrationError", params: { message: err.response.data.message } });
        });
    },
    fetchAvatar() {
        const api = '/get_avatar_info';
        let params = new URLSearchParams();
        params.append("avatar_id", 1);

        this.axios.get(api, {params: params})
        .then(response => {
            this.avatarInfo = response.data.avatar
        })
    }
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
</style>
