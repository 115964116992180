<template>
    <system-page :page-settings="page">
        <div class="checkin-wrapper">
            <div class="checkin-content u-text-center">
                <div class="checkin-content-code">
                    <h2 class="c-title barcode-title">チェックイン用バーコード</h2>
                    <p>
                        イベント会場の受付で<span class="u-display-pc">、</span><br class="u-display-sp">
                        こちらのバーコードを認証してください。
                    </p>
                    <div class="qrcode">
                        <vue-qrcode
                            :value="checkinCode"
                            :width="202"
                            :height="202"
                            :margin="0"
                            tag="img"
                        ></vue-qrcode>
                    </div>
                </div>
            </div>
            <div class="checkin-content info-content" v-if="event">
                <div class="checkin-content-title">
                    <h2 class="c-title">
                        {{ event.eventTitle }}
                    </h2>
                    <div class="league">◆ {{ event.leagueTitle }}</div>
                </div>
                <div class="checkin-table">
                    <div class="checkin-table-row">
                        <div class="checkin-table-th">開催日</div>
                        <div class="checkin-table-td u-text-bold">
                            {{ dayjsFormat(event.eventDate.date, 'YYYY/MM/DD (dd)') }} {{ dayjsFormat(event.eventStartedAt.date, 'HH:mm') }} 〜 {{ event.eventEndedAt ? dayjsFormat(event.eventEndedAt.date, 'HH:mm') : '' }}
                        </div>
                    </div>
                    <div class="checkin-table-row">
                        <div class="checkin-table-th">開催時間</div>
                        <div class="checkin-table-td">{{ event.eventDaySupply }}</div>
                    </div>
                    <div class="checkin-table-row">
                        <div class="checkin-table-th">主催者</div>
                        <div class="checkin-table-td">
                            <span v-if="event.eventTypeId == 1">{{ '株式会社ポケモン' }}</span>
                            <span v-else>{{ event.shopName }}</span>
                        </div>
                    </div>
                    <div class="checkin-table-row">
                        <div class="checkin-table-th">会場</div>
                        <div class="checkin-table-td venue">
                            <span><template v-if="event.zipCode !== null">〒{{ zipCodeFormat(event.zipCode) }} </template>{{ event.address }}</span>
                            <span>{{ event.venue }}</span>
                            <p class="mapLink"><a :href="'https://www.google.com/maps/search/' + encodeURIComponent(event.geocoding)">Google Maps</a></p>
                        </div>
                    </div>
                    <div class="checkin-table-directions">
                        <div class="checkin-table-td-wide">
                            <div class="checkin-table-directions-content">
                                {{ event.access }}
                            </div>
                        </div>
                    </div>
                    <div class="checkin-table-row">
                        <div class="checkin-table-th">参加費</div>
                        <div v-if="event.entryFee" class="checkin-table-td">
                            {{ displayEntryFee(event.entryFee) }}
                        </div>
                        <div v-else>
                            無料
                        </div>
                    </div>
                    <div class="checkin-table-row">
                        <div class="checkin-table-th">定員</div>
                        <div class="checkin-table-td">{{ event.capacity }}人</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-content checkin-bottom">
            <div class="checkin-print">
                <a class="c-link" onclick="window.print();return false;">印刷</a>
            </div>
            <router-link
                to="/mypage"
                class="c-btn c-btn-primary-outline"
            >戻る</router-link>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import * as dayjs from 'dayjs'
import ja from 'dayjs/locale/ja' // import locale
import VueQrcode from 'vue-qrcode'

export default {
    name: 'BarcodeCheckinPage',
    components: {
        SystemPage,
        VueQrcode
    },
    data() {
        return {
            page: {
                title: 'バーコードチェックイン',
                breakedTitle: 'バーコード<br>チェックイン',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ バーコードチェックインのページ。',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            },
            eventHoldingId: null,
            trainersFlg: false,
            event: null,
            entryPlayerId: null,
            checkinCode: null,
            error: null
        }
    },
    created() {
        this.eventHoldingId = this.$route.params.eventHoldingId
        this.trainersFlg = this.$route.params.trainersFlg
        this.fetchEventDetail()
        this.fetchCheckinCode()
    },
    methods: {
        async fetchEventDetail() {
            let params = new URLSearchParams
            const url = '/event_detail_search'
            params.append('event_holding_id', this.eventHoldingId)
            params.append('trainers_flg', this.trainersFlg)

            await this.axios.get(url, { params: params })
                .then(response => {
                    this.event = response.data.event
                })
                .catch(err => {
                    this.error = err.response.data.message
                })
        },
        async fetchCheckinCode() {
            let params = new URLSearchParams
            const url = '/checkin_code'
            params.append('event_holding_id', this.eventHoldingId)

            await this.axios.post(url, params)
                .then(response => {
                    this.checkinCode = response.data.checkin_code
                })
                .catch(err => {
                    if (err.response && err.response.data.status === 401) {
                        this.$store.commit('setLogoutState')
                        this.$router.push({name: 'Login'})
                    }
                    this.error = err.response.data.message
                })
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja);
            return dayjs(date).format(format)
        },
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? zip : zip.slice(0, 3) + '-' + zip.slice(3)
        },
        displayEntryFee(entryFee) {
            if (!isNaN(entryFee)) {
                return entryFee + '円'
            } else {
                return entryFee
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.checkin {
    &-wrapper {
        width: 100%;
        padding: 48px 0;
        background: g.$color-gradietion;
        @include g.sp {
            padding: 16px;
        }
    }
    &-content {
        width: g.$content-width-pc-sm;
        margin-left: auto;
        margin-right: auto;
        padding: 36px 36px 48px;
        background: g.$color-white;
        border-radius: 16px;
        border: 1px solid g.$color-black-a025;
        @include g.sp {
            width: 100%;
            padding: 20px 20px 21px;
        }

        &.info-content {
            padding: 32px 36px 33px;
        }
        &:not(:first-child) {
            margin-top: 24px;
            @include g.sp {
                margin-top: 16px;
            }
        }

        &-code {
            .c-title {
                font-size: 28px;
                font-weight: bold;

                @include g.sp {
                    font-size: 18px;
                }
            }
            p {
                padding: 9px 0 54px;
                @include g.sp {
                    padding: 19px 0 27px;
                }
            }
        }
        &-title {
            padding-bottom: 24px;
            border-bottom: 1px solid g.$color-border-gray;
            @include g.sp {
                margin: 0 -20px;
                padding: 0 20px;
                padding-bottom: 17px;
            }
            .c-title {
                @include g.font-lg;
                font-weight: bold;
                @include g.sp {
                    font-size: 18px;
                }
            }
            .league {
                @include g.font-sm;
                color: g.$color-league;
                font-weight: bold;
                margin-top: 9px;
                @include g.sp {
                    font-size: 13px;
                }
            }
        }
        .qrcode {
            margin: auto;
            @include g.pc {
                width: 250px;
            }
            @include g.sp {
                width: 240px;
            }
        }
    }
    &-table {
        margin-top: 22px;
        @include g.sp {
            margin-top: 16px;
        }
        &-row {
            display: flex;
            @include g.sp {
                font-size: 15px;
            }
            &:not(:first-child) {
                @include g.pc {
                    margin-top: 6px;
                }
                @include g.sp {
                    padding-top: 13px;
                    padding-bottom: 13px;
                    border-top: 1px dotted g.$color-border-gray;
                }
            }
            &:first-child {
                @include g.sp {
                    padding-bottom: 12px;
                }
            }
        }
        &-th {
            flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            width: 5em;
            margin-right: 1em;
            font-size: 15px;
            &::after {
                content: '：';
            }
        }
        &-td {
            font-size: 15px;
            &.venue {
                & > span {
                    display: block;
                }
            }
        }
        &-directions {
            & > div {
                @include g.pc {
                    margin-top: 12px;
                    padding-left: 90px;
                }
                @include g.sp {
                    @include g.font-sm;
                    margin-bottom: 12px;
                }
            }
            &-content {
                white-space: pre-line;
                padding: 12px;
                background: g.$color-black-a003;
                border-radius: 4px;
                font-size: 14px;
                margin-bottom: 14px;
            }
        }
    }
    &-bottom {
        text-align: center;
        line-height: 1;
    }
    &-print {
        padding: 46px 0;
        @include g.sp {
            padding: 40px 0;
        }
        & > a {
            &::before {
                @include g.setIconFont("\e928");
                margin-right: 6px;
            }
        }
    }
}

</style>
