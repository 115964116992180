<template>
  <system-page :page-settings="page">
    <div class="c-content u-text-center complete">
      <div class="complete-text">
        <p class="u-mb-lg">メールアドレスの変更が完了しました！</p>
        <p class="u-text-small">引き続き、ポケモンカードゲームプレイヤーズクラブをお楽しみください。</p>
      </div>
      <div class="to-mypage">
        <router-link :to="{name: 'Mypage'}" class="c-btn c-btn-primary-outline">マイページ</router-link>
      </div>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
    components: {
        SystemPage,
    },
    data: function() {
        return {
            page: {
                title: 'メールアドレス変更完了',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ メールアドレス変更完了のページ。',
                breadcrumb: [
                { name: 'マイページ', path: '/mypage' }
                ],
            }
        }
    },
    created: function() {
        this.auth()
    },
    methods: {
        auth() {
            const params = new URLSearchParams()
            params.append('auth_code', this.$route.query.auth_code)

            this.axios.post('/mail_address_authentication', params)
            .then(() => {
            })
            .catch(err => {
                if (err.response && err.response.data.status === 401) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                this.$router.push({name: 'MailChangeError'})
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use './style.scss';

.complete {
  .c-btn {
    margin-top: 55px;
  }

  @include g.sp {
    &-text {
      text-align: left;
    }
    .c-btn {
      margin-top: 50px;
    }
  }
}
.to-mypage {
  line-height: 1;
}
</style>
