export default {
    data() {
        return {
            selectTabName: 'detail',
            openPrefecture: false,
        }
    },
    methods: {
        selectTab(select) {
            this.selectTabName = select
            this.openPrefecture = false
        },
        setTabClass(name) {
            return this.selectTabName === name ? 'active' : ''
        },
        displayTabContent(name) {
            return this.selectTabName === name
        },
        setSelectorClass: function() {
            const parameters = this.syncedSearchParameters ? this.syncedSearchParameters : this.searchParameters
            this.$nextTick(function() {
                const select = document.querySelectorAll('select, #selectPrefectureText')
                select.forEach((item) => {
                    if (
                        item.tagName === 'BUTTON' && parameters.prefecture.length > 0 ||
                        item.tagName === 'SELECT' && item.value
                    ) {
                        item.classList.remove('noValue')
                    } else if (
                        item.tagName === 'BUTTON' && parameters.prefecture.length === 0 ||
                        item.tagName === 'SELECT' && !item.value
                    ) {
                        item.classList.add('noValue')
                    } else {
                        return
                    }
                })
            })
        },
    },
}
