<template>
  <system-page :page-settings="page">
    <div class="c-content u-text-center">
      <p class="message">
        ユーザー情報の変更が完了しました！<br>
        引き続き、ポケモンカードゲーム プレイヤーズクラブをお楽しみください。
      </p>
      <div class="u-text-center to-mypage">
          <router-link
              :to="{name: 'Mypage'}"
              class="c-btn c-btn-primary-outline"
          >
              マイページへ
          </router-link>
      </div>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'

export default {
  name: 'ProfileEditComplete',
  components: {
    SystemPage,
  },
  computed: {
      page() {
          let page =  {
              title: 'ユーザー情報変更完了',
              desc: '',
              breadcrumb: [
                { name: 'マイページ', path: '/mypage' },
                { name: 'ユーザー情報', path: '/mypage/profile' },
                { name: 'ユーザー情報変更', path: '/mypage/profile/edit' },
              ],
          }

          let player = this.$store.getters.getUser();
          if (!player) {
              return page
          }
          let defaultTitle = page.title;
          page.titleForHeader = player.nickname + "さんの" + defaultTitle
          page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんの" + defaultTitle + "。";
          return page
      },
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

@include g.pc {
  .message {
    margin-bottom: 55px;
  }
}

@include g.sp {
  .message {
    margin-bottom: 50px;
  }
}
</style>
