<template>
    <template v-if="$route.name !== 'ServerError'">
        <div class="app wrapper">
            <pcg-header />
            <main>
                <router-view />
            </main>
            <pcg-footer :isPlayers="isPlayers" />
        </div>
    </template>
    <ServerError v-else />
    <loading-cover :display="$store.state.isLoading" />
</template>

<script>
import pcgHeader from '@/layout/Header/Header.vue'
import pcgFooter from '@/layout/Footer.vue'
import loadingCover from '@/component/Loading.vue'
import ServerError from '@/views/Error/ServerError.vue'
import commonText from '@/utils/commonText'

export default {
    name: 'App',
    components: {
        pcgHeader,
        pcgFooter,
        loadingCover,
        ServerError
    },
    data() {
        return {
            apiCall: [],
            isLoading: false,
        }
    },
    computed: {
        isPlayers() {
            return this.$route.meta.isPlayers
        },
    },
    setup() {
        const ua = navigator.userAgent
        if (ua.indexOf('iPhone') > 0 | ua.indexOf('iPad') > 0) {
            document.body.classList.add('ios')
        }
        if (ua.indexOf('Android') > 0) {
            document.body.classList.add('android')
        }
    },
    created() {
        const that = this
        this.$store.commit('checkDevice')

        let timer
        window.addEventListener('resize', function() {
            if (!timer) {
                clearTimeout(timer)
            }

            timer = setTimeout(function () {
                that.$store.commit('checkDevice')
            }, 500)
        })
        window.addEventListener("storage", function (storage) {
            if (storage.key === 'userToken' && localStorage.getItem('userToken') == null) {
                location.reload()
            }
        })
        history.replaceState(null, '', null)
        window.addEventListener('popstate', function() {
            that.$store.commit('setIsBrowserBack', true)
            window.setTimeout(() => {
                that.$store.commit('setIsBrowserBack', false)
            }, 500)
        })
        this.setAxiosSetting()
    },
    methods: {
        setAxiosSetting() {
            this.axios.interceptors.request.use(
                config => {
                    if (!config.params || !config.params.isLoadingHidden) {
                        this.$store.commit('setIsLoading', true)
                    }
                    this.apiCall.push(0)

                    return config
                },
                error => {
                    this.$store.commit('setIsLoading', true)
                    this.apiCall.push(0)

                    return Promise.reject(error)
                }
            )
            this.axios.interceptors.response.use(
                config => {
                    this.apiCall.pop()
                    if (this.apiCall.length < 1) {
                        this.$store.commit('setIsLoading', false)
                    }

                    return config
                },
                error => {
                    this.apiCall.pop()
                    if (this.apiCall.length < 1) {
                        this.$store.commit('setIsLoading', false)
                    }

                    const errorList = [500, 501, 502, 503]
                    if (errorList.indexOf(error.response.status) > -1) {
                        this.$router.push({name: 'ServerError'})
                    }

                    return Promise.reject(error)
                }
            )
        },
        getCommonText(name) {
            return commonText[name]
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

@include g.sp {
    main {
        padding-top: g.$header-height-sp;
    }
}
</style>
