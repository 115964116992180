<template>
    <system-page :page-settings="page">
        <div v-if="error" class="c-content">
            <no-result>
                <p><pre>{{ error }}</pre></p>
            </no-result>
        </div>
        <div v-if="apiError" class="c-content">
            <error-msg>
                <p><pre>{{ apiError }}</pre></p>
            </error-msg>
        </div>
        <div class="c-content">
            <error-msg
                v-if="no_deck_count > 0"
                class="deckUnregisteredBox u-mb-20"
            >
                <p class="alert">
                    デッキが未登録のイベントが<span class="count"> {{ no_deck_count }} 件</span>あります。<br>
                    デッキの登録は、イベント開催当日の8:30までに登録してください。
                </p>
            </error-msg>
            <section class="u-mb-lg">
                <div
                    v-if="reserver_count > 0"
                    class="noticeBox u-mb-sm"
                >
                    リザーバー当選のイベントが<span class="count"> {{ reserver_count }} 件</span>あります。
                </div>
                <div
                    v-if="waiting_count"
                    class="noticeBox u-mb-sm"
                >
                    抽選待ちのイベントが<span class="count"> {{ waiting_count }} 件</span>あります。
                </div>
            </section>
            <section class="eventListForm">
                <div class="eventListForm-item">
                    <label>イベントの種類</label>
                    <div
                        class="c-input c-input-select"
                        :class="{'c-input-error': false}"
                    >
                        <select v-model="displayEventType">
                            <option :value="0">全てのイベント</option>
                            <option :value="1">{{ eventTypeName[1] }}</option>
                            <option :value="2">{{ eventTypeName[2] }}</option>
                            <option :value="7">{{ eventTypeName[7] }}</option>
                        </select>
                    </div>
                </div>
                <div class="eventListForm-item">
                    <label>ステータス</label>
                    <div
                        class="c-input c-input-select"
                        :class="{'c-input-error': false}"
                    >
                        <select v-model="displayEntryType">
                            <option :value="0">全てのイベント</option>
                            <option :value="1">参加確定／リザーバー</option>
                            <option :value="2">応募済み</option>
                            <option :value="3">落選／キャンセル</option>
                        </select>
                    </div>
                </div>
            </section>
            <section class="eventsSection">
                <div
                    v-for="event in events"
                    :key="event.id"
                    class="eventBox"
                    :class="[
                        { 'unseated': !isLotteryWaiting(event) && event.status_id === 4 },
                        event.displayStatus,
                    ]"
                    :id="`eventBox-id${event.id}`"
                >
                    <header>
                        <router-link :to="{ name: 'EventDetail', params: {
                            id: event.id,
                            trainersFlg: event.trainers_flg,
                            shopId: event.shop_id ? event.shop_id : 0,
                            eventDate: event.event_date_params ? event.event_date_params : 0,
                            dateId: event.date_id ? event.date_id : 0
                        } }">
                            <div class="tagsBlock">
                                <template v-if="event.discontinuance_flg !== 1">
                                    <eventPlayerStatus
                                        :statusId="event.status_id"
                                        :entryTypeId="event.entry_type_id"
                                        :lotteryDeadline="event.lottery_deadline"
                                    ></eventPlayerStatus>
                                    <eventDeckSubmissionStatus
                                        v-if="!isLotteryWaiting(event) && event.deck_regist_flg === 1 && event.status_id !== 4 && event.status_id !== 6"
                                        :deckRegisterFlg="event.deck_code !== '' && event.deck_code !== null"
                                    ></eventDeckSubmissionStatus>
                                </template>
                                <template v-else>
                                    <span class="eventCanceled">このイベントは中止になりました</span>
                                </template>
                            </div>
                            <h3>
                                {{ event.event_title }}
                            </h3>
                            <div class="leaguesBlock">
                                <span class="league">{{ event.league_title }}</span>
                            </div>
                        </router-link>
                    </header>
                    <main>
                        <div class="spDetail closed">
                            <table>
                                <tr>
                                    <th>開催日時</th>
                                    <td>
                                        <time
                                            :datetime="dayjsFormat(event.event_date.date, 'YYYY/MM/DD')"
                                            class="u-text-bold"
                                        >
                                            {{
                                                dayjsFormat(event.event_date.date, 'YYYY/MM/DD（dd）')
                                                + dayjsFormat(event.event_started_at.date, 'HH:mm')
                                            }}
                                            ～
                                            {{ event.event_ended_at ? dayjsFormat(event.event_ended_at.date, 'HH:mm') : '' }}
                                        </time>
                                        <p
                                            v-if="event.event_day_supply !== null"
                                            class="font-small"
                                        >（{{ event.event_day_supply }}）</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>主催者</th>
                                    <td v-if="event.event_type_id === 1">株式会社ポケモン</td>
                                    <td v-else>{{ event.shop_name }}</td>
                                </tr>
                                <tr>
                                    <th>会場</th>
                                    <td>
                                        <p>
                                            <template v-if="event.zip_code !== null">〒{{ zipCodeFormat(event.zip_code) + ' ' }} </template>{{ event.address }}
                                        </p>
                                        <p>{{ event.venue }}</p>
                                        <p class="mapLink">
                                            <a
                                                :href="getMapUrl(event.geocoding)"
                                                target="_blank"
                                            >Google Maps</a>
                                        </p>
                                        <p
                                            v-if="event.access !== '' && event.access !== null"
                                            class="access"
                                        >
                                            {{ event.access }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>参加費</th>
                                    <td v-if="event.entry_fee">
                                        {{ displayEntryFee(event.entry_fee) }}
                                    </td>
                                    <td v-else>無料</td>
                                </tr>
                                <tr>
                                    <th>定員</th>
                                    <td>{{ event.capacity }}人</td>
                                </tr>
                            </table>
                            <div class="detailButtonBlock spDetailClosed">
                                <button
                                    type="button"
                                    class="c-btn c-btn-secondary-outline icon-plus"
                                    @click="openDetail($event)"
                                >
                                    詳細を表示する
                                </button>
                                <button
                                    type="button"
                                    class="c-btn c-btn-secondary-outline icon-minus"
                                    @click="closeDetail($event)"
                                >
                                    詳細を閉じる
                                </button>
                            </div>
                        </div>
                        <nav
                            v-if="displayNavigation(event) "
                        >
                            <!-- 抽選待ち -->
                            <template v-if="isLotteryWaiting(event)">
                                <div class="waitingLotteryBlock">
                                    <p>
                                        <span class="u-text-small">【抽選結果発表】</span><br>
                                        <template v-if="event.entry_type_id === 2">
                                            {{ dayjsAutoLottery(event.lottery_deadline.date, 'YYYY/MM/DD (dd)  HH:mm') }}
                                        </template>
                                        <template v-else>
                                            {{ dayjsFormat(event.lottery_deadline.date, 'YYYY/MM/DD (dd)  HH:mm') }}
                                        </template>
                                        <br>
                                        <span class="u-text-exsmall">※発表の時刻は前後する場合がございます。</span>
                                    </p>
                                </div>
                            </template>
                            <!-- 参加確定 -->
                            <template v-else-if="(isConfirmed(event.status_id) || isCheckedin(event.status_id) || isReservoir(event.status_id)) && (event.deck_regist_flg || (event.qr_checkin_flg === 1 && event.checkin_url !== null))">
                                <div class="deckRegistrationBlock"
                                    v-if="event.deck_regist_flg">
                                    <div v-if="new Date() <= getDeckRegistDate(event.event_date.date)">
                                        <p class="alert u-mb-20">
                                            <template v-if="event.deck_code !== '' && event.deck_code !== null">
                                                {{ dayjsFormat(event.event_date.date, 'YYYY/MM/DD (dd)') }} 08:30までデッキの変更が可能です。
                                            </template>
                                            <template v-else>
                                                {{ dayjsFormat(event.event_date.date, 'YYYY/MM/DD (dd)') }} 08:30までに<br>
                                                デッキを登録してください。
                                            </template>
                                        </p>
                                        <button
                                            v-if="event.deck_code !== '' && event.deck_code !== null"
                                            type="button"
                                            class="c-btn c-btn-primary-outline"
                                            @click="openDeckRegistrationModal(event)"
                                        >
                                            デッキを確認/変更する
                                        </button>
                                        <button
                                            v-else
                                            type="button"
                                            class="c-btn c-btn-primary"
                                            @click="openDeckRegistrationModal(event)"
                                        >
                                            <span class="icon-deck u-mr-sm"></span>デッキを登録する
                                        </button>
                                    </div>
                                    <div v-else-if="event.deck_code !== null">
                                        <a
                                            :href="getRegisterdDeckLinkURL(event.deck_code)"
                                            class="c-link c-link-arrow"
                                            target="_blank"
                                            @click.stop
                                        >
                                            登録デッキを見る
                                        </a>
                                    </div>
                                    <div v-else>
                                        デッキが登録されていません
                                    </div>
                                </div>
                                <!--
                                <div v-if="isConfirmed(event.status_id)" class="barcodeCheckinBlock">
                                    <router-link
                                        :to="{ name: 'BarcodeCheckin', params: {eventHoldingId: event.id, trainersFlg: event.trainers_flg}}"
                                        type="button"
                                        class="c-btn c-btn-primary icon-qr"
                                    >
                                        バーコードチェックイン
                                    </router-link>
                                </div>
                                -->
                                <div v-if="event.deck_regist_flg && event.qr_checkin_flg === 1 && event.checkin_url !== null" class="barcodeCheckinBlock"></div>
                                <div v-if="event.qr_checkin_flg === 1 && event.checkin_url !== null">
                                    <div class="u-text-center">
                                        <a :href="event.checkin_url" class="c-btn c-btn-primary" target="_blank"><span class="icon-qr"></span>入場用バーコードを表示</a>
                                    </div>
                                </div>
                                <!--
                                <div class="checkinBlock waiting" v-if="isCheckedin(event.status_id)">
                                    <p>
                                        【チェックイン】<br>
                                        {{ event.player_status_started_at }}
                                    </p>
                                </div>
                                -->
                            </template>
                            <template v-if="event.event_priority_flg === 1 && dayjsFormat(Date.now(), 'YYYYMMDD HH:mm') < dayjsFormat(event.entry_ended_at.date, 'YYYYMMDD HH:mm') " >
                                <div class="eventBox-priorityCheckbox">
                                    <div class="c-checkbox">
                                        <input
                                            :id="`priorityFlg${event.id}`"
                                            type="checkbox"
                                            name="priorityFlg"
                                            :value="event.id"
                                            v-model="priorityFlg"
                                            @change="changePriorityFlg"
                                        >
                                        <label :for="`priorityFlg${event.id}`">
                                            第１希望
                                        </label>
                                    </div>
                                </div>
                                <div
                                    :id="`eventBox-id${event.id}-priority`"
                                    class="eventBox-priorityDialog"
                                >
                                    <div class="message"></div>
                                </div>
                            </template>
                            <p
                                class="cancel"
                                @click="openCancelModal(event)"
                                v-if="isCancelDeadline(event) && !isCancelStop(event)"
                            >
                                応募キャンセル
                            </p>
                            <p
                                class="cancelStop"
                                v-if="isCancelStop(event)"
                            >
                                抽選終了までキャンセルいただけません。<br>
                                キャンセルをご希望の場合は<br>
                                抽選結果発表後に実施いただけます。
                            </p>
                        </nav>
                        <nav
                            v-if="displayCancelButton(event)"
                            class="cancelContainer"
                        >
                            <p
                                class="cancel"
                                @click="openCancelModal(event)"
                            >応募キャンセル</p>
                        </nav>
                    </main>
                </div>
            </section>
            <section class="u-text-center to-mypage">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイページへ
                </router-link>
            </section>
        </div>

        <pcg-modal
            class="deckRegistrationModal"
            :isActive="activeModal === 'deckRegistrationModal'"
            :bgImage="true"
            @close="closeModal()"
        >
            <template #modalHeader>
                <h2>デッキ登録</h2>
            </template>
            <header>
                <h3>{{ eventOnModal.event_title }}</h3>
                <div class="leaguesBlock">
                    <span class="league">{{ eventOnModal.league_title }}</span>
                </div>
            </header>
            <div class="dateBlock">
                <time
                    :datetime="dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD')"
                    class="u-text-bold"
                >{{ dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD（dd）') }}{{ dayjsFormat(eventOnModal.event_started_at.date, 'HH:mm') }} 〜 {{ eventOnModal.event_ended_at ? dayjsFormat(eventOnModal.event_ended_at.date, 'HH:mm') : '' }}</time>
            </div>
            <div class="decksBlock">
                <p class="u-mb-lg u-text-center">大会で使用するデッキを選択して、<br class="u-display-sp">デッキを登録してください。</p>
                <cityleague-message :event="eventOnModal"  class="u-mb-lg"></cityleague-message>
                <error-msg class="u-mb-lg" v-if="hasRegulationError()">
                    <p class="u-text-warning-icon">レギュレーションを満たしておりません。</p>
                </error-msg>
                <error-msg class="u-mb-lg" v-if="deckRegistError !== null">
                    <p class="u-text-warning-icon">{{ deckRegistError }}</p>
                </error-msg>
                <div class="decksWrapper">
                    <div
                        v-for="deck in mydecks" :key="deck.id"
                        class="deck"
                        :class="{ 'selected': selectedDeck == deck }"
                        @click="selectedDeck = deck"
                    >
                        <div class="c-radio">
                            <input
                                type="radio"
                                :id="'deck:' + deck.id"
                                v-model="selectedDeck"
                                name="deck"
                                :value="deck"
                            >
                            <label for="deck:1"></label>
                        </div>
                        <h4>{{ deck.deck_name }}</h4>
                        <p class="deckCode">{{ deck.deck_code }}</p>
                        <p class="u-mb">登録日：{{ dayjsFormat(deck.created_at, 'YYYY/MM/DD') }}</p>
                        <div class="deckTagsBlock">
                            <span
                                v-for="regulation in deck.available_regulations"
                                :key="regulation"
                                class="deckTag"
                            >
                                {{ regulation }}
                            </span>
                        </div>
                        <a
                            :href="getRegisterdDeckLinkURL(deck.deck_code)"
                            class="u-text-normal"
                            target="_blank"
                            @click.stop
                        >
                            デッキを見る
                        </a>
                    </div>
                </div>
            </div>
            <!-- shadowのスタイル当てるためcomponentのfooterスロットを使わず実装 -->
            <div class="modalFooter">
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    :disabled="selectedDeck === null"
                    @click="registerDeck()"
                >
                    デッキを登録する
                </button>
            </div>
        </pcg-modal>

        <pcg-modal
            class="deckRegistrationCompleteModal"
            :isActive="activeModal === 'deckRegistrationCompleteModal'"
            :bgImage="true"
            @close="closeModal()"
        >
            <template #modalHeader>
                <h2>デッキ登録完了</h2>
            </template>
            <p class="message strong">デッキの登録が完了しました！</p>
            <p class="message">{{ dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD (dd)') }} 08:30 までは<br class="u-text-sp">何度でも登録デッキの変更ができます。</p>
            <cityleague-message :event="eventOnModal"  class="u-mb-lg"></cityleague-message>
            <div class="c-box">
                <h3>{{ eventOnModal.event_title }}</h3>
                <div class="leaguesBlock u-text-center u-mb">
                    <span class="league">{{ eventOnModal.league_title }}</span>
                </div>
                <div class="dateBlock">
                    <time
                        :datetime="dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD')"
                        class="u-text-bold"
                    >{{ dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD（dd）') }}{{ dayjsFormat(eventOnModal.event_started_at.date, 'HH:mm') }} 〜 {{ eventOnModal.event_ended_at ? dayjsFormat(eventOnModal.event_ended_at.date, 'HH:mm') : '' }}</time>
                </div>
            </div>
            <template #modalFooter>
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </template>
        </pcg-modal>

        <pcg-modal
            class="cancelModal"
            :isActive="activeModal === 'cancelModal'"
            :bgImage="true"
            @close="closeModal()"
        >
            <template #modalHeader>
                <h2>出場キャンセル</h2>
            </template>
            <p class="message">
                本当に応募キャンセルしますか？
            </p>
            <div class="eventDetailBox">
                <div class="boxHeader">キャンセル対象イベント</div>
                <h3>{{ eventOnModal.event_title }}</h3>
                <div class="leaguesBlock">
                    <span class="league">{{ eventOnModal.league_title }}</span>
                </div>
                <div class="dateBlock">
                    <time
                        :datetime="dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD')"
                        class="u-text-bold"
                    >{{ dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD（dd）') }}{{ dayjsFormat(eventOnModal.event_started_at.date, 'HH:mm') }} 〜 {{ eventOnModal.event_ended_at ? dayjsFormat(eventOnModal.event_ended_at.date, 'HH:mm') : '' }}</time>
                </div>
                <table>
                    <tr>
                        <th>主催者</th>
                        <td v-if="eventOnModal.event_type_id === 1">株式会社ポケモン</td>
                        <td v-else>{{ eventOnModal.shop_name }}</td>
                    </tr>
                    <tr>
                        <th>会場</th>
                        <td>
                            <p><template v-if="eventOnModal.zip_code !== null">〒{{ zipCodeFormat(eventOnModal.zip_code) }} </template>{{ eventOnModal.address }}<br></p>
                            <p class="mapLink"><a :href="getMapUrl(eventOnModal.geocoding)" target="_blank">Google Maps</a></p>
                        </td>
                    </tr>
                    <tr>
                        <th>参加費</th>
                        <td v-if="eventOnModal.entry_fee">
                            {{ displayEntryFee(eventOnModal.entry_fee) }}
                        </td>
                        <td v-else>無料</td>
                    </tr>
                    <tr>
                        <th>定員</th>
                        <td>{{ eventOnModal.capacity }}人</td>
                    </tr>
                </table>
            </div>
            <template #modalFooter>
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    @click="cancelEvent()"
                >
                    出場をキャンセルする
                </button>
            </template>
        </pcg-modal>

        <pcg-modal
            class="cancelCompleteModal"
            :isActive="activeModal === 'cancelCompleteModal'"
            :bgImage="true"
            @close="closeModal()"
        >
            <template #modalHeader>
                <h2>出場キャンセル完了</h2>
            </template>
            <p class="message">
                イベントへの出場キャンセルが完了しました。<br>
                次回の応募をお待ちしております！
            </p>
            <div class="eventDetailBox">
                <div class="boxHeader">キャンセル対象イベント</div>
                <h3>{{ eventOnModal.event_title }}</h3>
                <div class="leaguesBlock">
                    <span class="league">{{ eventOnModal.league_title }}</span>
                </div>
                <div class="dateBlock">
                    <time
                        :datetime="dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD')"
                        class="u-text-bold"
                    >{{ dayjsFormat(eventOnModal.event_date.date, 'YYYY/MM/DD（dd）') }}{{ dayjsFormat(eventOnModal.event_started_at.date, 'HH:mm') }} 〜 {{ eventOnModal.event_ended_at ? dayjsFormat(eventOnModal.event_ended_at.date, 'HH:mm') : '' }}</time>
                </div>
            </div>
            <template #modalFooter>
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </template>
        </pcg-modal>
    </system-page>
    <sp-menu />
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import ErrorMsg from '@/component/ErrorMsg.vue'
import pcgModal from '@/component/Modal.vue'
import * as dayjs from 'dayjs'
import ja from 'dayjs/locale/ja' // import locale
import eventPlayerStatus from '@/component/EventPlayerStatus.vue'
import eventDeckSubmissionStatus from '@/component/EventDeckSubmissionStatus.vue'
import noResult from '@/component/NoResult.vue'
import state from './state'
import SpMenu from '../component/SpMenu.vue'
import CityleagueMessage from '../component/CityleagueMessage.vue'

export default {
    name: 'MyEventListPage',
    components: {
        SystemPage,
        ErrorMsg,
        pcgModal,
        eventPlayerStatus,
        eventDeckSubmissionStatus,
        noResult,
        SpMenu,
        CityleagueMessage
    },
    mixins: [state],
    props: {},
    data: function() {
        return {
            activeModal: null,
            selectedDeck: null,
            events: [],
            mydecks: [],
            no_deck_count: 0,
            reserver_count: 0,
            waiting_count: 0,
            eventOnModal: null,
            error: null,
            apiError: null,
            today: new Date(),
            deckRegistError: null,
            eventTypeList: [],
            priorityFlg: [],
            eventTypeName: {
                1: this.$root.getCommonText('champions_league'),
                2: 'シティリーグ',
                3: 'トレーナーズリーグ',
                4: 'ジムイベント',
                5: 'キャンペーン',
                6: 'オーガナイザーイベント',
                7: this.$root.getCommonText('shield_battle'),
            },
            displayEventType: 0,
            displayEntryType: 0,
            cancelDeadlineFormat: 'YYYYMMDD190000'
        }
    },
    created: function() {
        this.fetchEventList()
        this.fetchMyDeckList()
    },
    computed: {
        page() {
            let page = {
                title: '出場予定のイベント',
                titleForHeader: '',
                desc: '',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            }

            let player = this.$store.getters.getUser();
            if (!player) {
                return page
            }
            let defaultTitle = page.title;
            page.titleForHeader = player.nickname + "さんの" + defaultTitle
            page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんの" + defaultTitle + "。";
            return page
        },
    },
    watch: {
        displayEventType: function() {
            this.searchEventList()
        },
        displayEntryType: function() {
            this.searchEventList()
        },
    },
    methods: {
        async fetchEventList() {
            let params = new URLSearchParams
            const url = '/get_entry_event_info'
            const that = this
            await this.axios.post(url, params)
                .then(response => {
                    this.events = response.data.event
                    this.events.forEach(function(event) {
                        that.setState(event)

                        that.eventTypeList.push(event.event_type_id)
                        if (event.priority_flg === 1) {
                            that.priorityFlg.push(event.id)
                        }
                    })
                    this.eventTypeList = new Set(this.eventTypeList)
                    this.no_deck_count = response.data.no_deck_count
                    this.reserver_count = response.data.reserver_count
                    this.waiting_count = response.data.waiting_count
                })
                .catch(err => {
                    if (err.response && err.response.data.status === 401) {
                        this.$store.commit('setLogoutState')
                        this.$router.push({name: 'Login'})
                    }
                    this.error = err.response.data.message
                })
        },
        async fetchMyDeckList() {
            let params = new URLSearchParams
            const url = '/deck_search'
            await this.axios.post(url, params)
                .then(response => {
                    this.mydecks = response.data.deck
                })
                .catch(
                    //返却されるエラーがデッキが存在しない場合のみのため、メッセージは出力しない
                )
        },
        async searchEventList() {
            let params = new URLSearchParams
            const url = '/get_entry_event_info'
            const that = this

            params.append('filter_event_type', this.displayEventType)
            params.append('filter_status_type', this.displayEntryType)

            await this.axios.post(url, params)
                .then(response => {
                    this.events = response.data.event
                    this.events.forEach(function(event) {
                        that.setState(event)

                        that.eventTypeList.push(event.event_type_id)
                        if (event.priority_flg === 1) {
                            that.priorityFlg.push(event.id)
                        }
                    })
                    this.eventTypeList = new Set(this.eventTypeList)
                    this.no_deck_count = response.data.no_deck_count
                    this.reserver_count = response.data.reserver_count
                    this.waiting_count = response.data.waiting_count
                    this.error = ''
                })
                .catch(err => {
                    if (err.response && err.response.data.status === 401) {
                        this.$store.commit('setLogoutState')
                        this.$router.push({name: 'Login'})
                    }
                    this.error = err.response.data.message
                    this.eventTypeList = []
                    this.priorityFlg = []
                    this.events = []
                    this.no_deck_count = 0
                    this.reserver_count = 0
                    this.waiting_count = 0
                })
        },
        openDetail(event) {
            event.currentTarget.closest('.spDetail').classList.remove('closed')
        },
        closeDetail(event) {
            event.currentTarget.closest('.spDetail').classList.add('closed')
        },
        closeModal() {
            this.eventOnModal = null
            this.selectedDeck = null
            this.activeModal = null
            this.deckRegistError = null
        },
        openDeckRegistrationModal(event) {
            if (new Date() >= this.getDeckRegistDate(event.event_date.date))
            {
                this.error = 'デッキ登録可能時間を過ぎているため、登録/変更できません。'
                window.scroll({top: 0, behavior: 'smooth'})
                return
            }
            this.eventOnModal = event
            if (event.deck_code) {
                for (const deck of this.mydecks) {
                    if (event.deck_code == deck.deck_code) {
                        this.selectedDeck = deck
                    }
                }
            }

            this.activeModal = 'deckRegistrationModal'
        },
        openCancelModal(event) {
            this.eventOnModal = event
            this.activeModal = 'cancelModal'
        },
        async registerDeck() {
            let params = new URLSearchParams
            const url = '/event_deck_register'
            params.append('deck_code', this.selectedDeck.deck_code)
            params.append('event_holding_id', this.eventOnModal.id)

            await this.axios.post(url, params)
                .then(() => {
                    this.activeModal = 'deckRegistrationCompleteModal'
                    this.fetchEventList()
                })
                .catch(err => {
                    this.deckRegistError = err.response.data.message
                })
        },
        changePriorityFlg(event) {
            let params = new URLSearchParams
            const url = '/entry_priority_switch'
            params.append('event_holding_id', event.target.value)
            const target = document.querySelector(`#eventBox-id${event.target.value}-priority`)
            const checkbox = document.querySelector(`#priorityFlg${event.target.value}`)
            const message = target.querySelector(`.message`)

            if (target.classList.contains('active')) {
                target.classList.remove('active')
            }
            if (message.classList.contains('error')) {
                message.classList.remove('error')
            }

            this.axios.post(url, params)
                .then((response) => {
                    this.fetchEventList()

                    message.textContent = response.data.message
                    target.classList.add('active')
                    checkbox.disabled = true

                    setTimeout(() => {
                        target.classList.remove('active')
                        checkbox.disabled = false
                    }, 2000)
                })
                .catch(err => {
                    if (err.response.data.code === 400) {
                        message.textContent = err.response.data.message
                        target.classList.add('active')
                        checkbox.disabled = true

                        setTimeout(() => {
                            target.classList.remove('active')
                            checkbox.disabled = false
                        }, 2000)
                    } else {
                        this.apiError = err.response.data.message
                        window.scroll({top: 0, behavior: 'smooth'})
                    }
                    if (this.priorityFlg.includes(parseInt(event.target.value))) {
                        this.priorityFlg = this.priorityFlg.filter(val => val !== parseInt(event.target.value))
                    } else {
                        this.priorityFlg.push(event.target.value)
                    }
                })
        },
        async cancelEvent() {
            let params = new URLSearchParams
            const url = '/event_cancel'
            params.append('event_holding_id', this.eventOnModal.id)
            params.append('entry_type_id', this.eventOnModal.entry_type_id)

            await this.axios.post(url, params)
                .then(() => {
                    this.activeModal = 'cancelCompleteModal'
                    this.events = []
                    this.no_deck_count = 0
                    this.reserver_count = 0
                    this.waiting_count = 0
                    this.fetchEventList()
                })
                .catch(err => {
                    this.error = err.response.data.message
                    this.activeModal = ''
                })
        },
        dayjsFormat(date, format) {
            dayjs.locale(ja);
            return dayjs(date).format(format)
        },
        dayjsAutoLottery(date, format) {
            dayjs.locale(ja);
            return dayjs(date).add(6, 'h').format(format)
        },
        zipCodeFormat(zipCode) {
            if (zipCode === null || zipCode === '') {
                return ''
            }
            const zip = String(zipCode)

            return zip.includes('-') ? zip : zip.slice(0, 3) + '-' + zip.slice(3)
        },
        isConfirmed(statusId) {
            // CONFIRMED
            if (statusId == 2){
                return true;
            }
            else{
                return false;
            }
        },
        isLotteryWaiting(event) {
            // 先着順は常にfalse
            if (event.entry_type_id === 1) {
                return false
            }
            // 抽選日発表前の場合は常にtrue
            let today = dayjs(new Date())
            let lottery = event.lottery_deadline !== null && event.lottery_deadline !== '' ? dayjs(event.lottery_deadline.date) : null
            if (lottery !== null && lottery.isAfter(today)) {
                return true
            }

            // APPLIED
            if (event.status_id == 1){
                return true;
            }
            else{
                return false;
            }
        },
        isCheckedin(statusId) {
            // PARTICIPATION
            if (statusId == 5){
                return true;
            }
            else{
                return false;
            }
        },
        isReservoir(statusId) {
            // RESERVOIR
            if (statusId == 3){
                return true;
            }
            else{
                return false;
            }
        },
        hasRegulationError() {
            if (this.selectedDeck == null || this.eventOnModal == null) {
                return false;
            }

            if (Object.values(this.selectedDeck.available_regulations).includes(this.eventOnModal.regulation_name)) {
                return false;
            }

            return true;
        },
        getDeckLinkURL(deckCode) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/deck/deck.html?deckID=' + deckCode
        },
        getRegisterdDeckLinkURL(deckCode) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/deck/confirm.html/deckID/' + deckCode
        },
        getMapUrl(geocoding) {
            return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(geocoding)
        },
        getDeckRegistDate(eventDate) {
            // APIから来たままのdateではnew Date()時にSafariで変換できないため
            // `XXXX-XX-XX XX:XX:XX.XXXXXX` -> `XXXX-XX-XXTXX:XX:XX.XXXXXX`の形にする
            const setEventDate = eventDate.replace(/^(\d+-\d+-\d+)\s([:\d\\.]+)$/g, '$1T$2')
            let retEventDate = new Date(setEventDate)
            console.log(setEventDate)
            retEventDate.setHours(retEventDate.getHours() + 8)
            retEventDate.setMinutes(retEventDate.getMinutes() + 30)
            return retEventDate
        },
        displayNavigation(event) {
            if (
                event.discontinuance_flg == 0 &&
                event.status_id !== 6
            ) {
                if (this.isLotteryWaiting(event)) {
                    return true
                }
                if (this.isConfirmed(event.status_id) || this.isCheckedin(event.status_id) || this.isReservoir(event.status_id)) {
                    if (
                        event.deck_regist_flg || (event.qr_checkin_flg === 1 && event.checkin_url !== null)
                    ) {
                        // 参加が確定かつデッキ登録が必要で登録期間内
                        return true
                    } else if (this.isCheckedin(event.status_id)) {
                        return true
                    }
                }
            }

            return false
        },
        displayCancelButton(event) {
            // デッキ登録のcontentが出ないかつ応募キャンセルができるステータスと期間の時に表示する
            let deadline = dayjs(event.event_date.date).subtract(1, 'D').format(this.cancelDeadlineFormat)
            const isEventDate = deadline > this.dayjsFormat(this.today, 'YYYYMMDDHHmmss')
            const eventStatus = (event.discontinuance_flg == 0 && event.status_id !== 4 && event.status_id !== 6)

            return !this.displayNavigation(event) && new Date() <= this.getDeckRegistDate(event.event_date.date) && eventStatus && isEventDate
        },
        displayEntryFee(entryFee) {
            if (!isNaN(entryFee)) {
                return entryFee + '円'
            } else {
                return entryFee
            }
        },
        isCancelStop (event) {
            if (event.entry_type_id !== 3) {
                return false;
            }

            let today = dayjs(new Date())
            let entryEndedAt = dayjs(event.entry_ended_at.date);
            let lotteryDeadline = event.lottery_deadline !== null && event.lottery_deadline !== '' ? dayjs(event.lottery_deadline.date) : null;

            if (lotteryDeadline !== null && entryEndedAt.isBefore(dayjs(today), 'second') && dayjs(today).isBefore(lotteryDeadline, 'second')) {
                return true;
            } else {
                return false;
            }
        },
        isCancelDeadline (event) {
            let deadline = dayjs(event.event_date.date).subtract(1, 'D').format(this.cancelDeadlineFormat)

            return deadline > this.dayjsFormat(this.today, 'YYYYMMDDHHmmss');
        },
    },
}
</script>

<style lang="scss" scoped>
@use './style.scss';
</style>
